export default [
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Supply Chain Management (BCom)",
    "programLink": "https://admissions.carleton.ca/programs/supply-chain-management/",
    "entranceGrade": "80 - 80",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Commerce (Supply Chain Management) Pathway for Busin...",
    "programLink": "https://www.algonquincollege.com/business-hospitality/program/bachelor-of-commerce-supply-chain-management-honours-pathway-for-business-administration/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Supply Chain Management - Global",
    "programLink": "https://www.algonquincollege.com/business-hospitality/program/supply-chain-management-global/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,506",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Supply Chain Management - Global",
    "programLink": "https://www.algonquincollege.com/business-hospitality/program/supply-chain-management-global/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,506",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Commerce (Supply Chain Management) Pathway for Busin...",
    "programLink": "https://www.algonquincollege.com/business-hospitality/program/bachelor-of-commerce-supply-chain-management-honours-pathway-for-business-administration-supply-chain-management/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Commerce (Supply Chain Management) Pathway for Busin...",
    "programLink": "https://www.algonquincollege.com/business-hospitality/program/bachelor-of-commerce-supply-chain-management-honours-pathway-for-business-fundamentals/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Supply Chain Management",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/Business/Programs/SupplyChainManagement/index.htm",
    "entranceGrade": "75 - 80",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Supply Chain Management - Logistics (Optional Co-op)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/logistics-management/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,305",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Supply Chain Management (BCom)",
    "programLink": "https://admissions.carleton.ca/programs/supply-chain-management/",
    "entranceGrade": "80 - 80",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Commerce (Supply Chain Management)",
    "programLink": "https://www.algonquincollege.com/business-hospitality/program/bachelor-of-commerce-supply-chain-management/",
    "entranceGrade": "70",
    "tutionValue": "$18,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Commerce (Supply Chain Management) Pathway for Busin...",
    "programLink": "https://www.algonquincollege.com/business-hospitality/program/bachelor-of-commerce-supply-chain-management-honours-pathway-for-business/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Logistics and Supply Chain Management",
    "programLink": "https://www.trentu.ca/futurestudents/program/logistics-supply-chain-management?target=undergraduate",
    "entranceGrade": "75 - 100",
    "tutionValue": "$28,285",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Supply Chain Management and Logistics (MSCM)",
    "programLink": "https://umanitoba.ca/explore/programs-of-study/master-supply-chain-management-and-logistics-mscm",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Supply Chain Management @ Canadian College",
    "programLink": "https://www.stlawrencecollege.ca/programs/supply-chain-management/full-time/canadian-college",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Trent University - Durham Greater Toronto Area",
    "location": "Canada, Ontario",
    "programName": "Logistics and Supply Chain Management",
    "programLink": "https://www.trentu.ca/futurestudents/program/logistics-supply-chain-management?target=undergraduate",
    "entranceGrade": "75",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bow Valley College",
    "location": "Canada, Alberta",
    "programName": "Business Administration Diploma: Supply Chain Management",
    "programLink": "https://bowvalleycollege.ca/programs-courses/business/business-administration-diploma---supply-chain-management-major",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "16 Month(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Commerce - Supply Chain Management",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=78421",
    "entranceGrade": "65",
    "tutionValue": "$19,615",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "International Supply Chain Management (Post-Baccalaureate Diplom...",
    "programLink": "https://www.douglascollege.ca/program/pbdiscm",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Cape Breton University",
    "location": "Canada, Nova Scotia",
    "programName": "Supply Chain Management BBA",
    "programLink": "https://www.cbu.ca/academics/programs/supply-chain-management/",
    "entranceGrade": "65",
    "tutionValue": "$19,579",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Business Administration (Supply Chain Management)",
    "programLink": "https://www.sheridancollege.ca/programs/bachelor-of-business-administration-supply-chain-management",
    "entranceGrade": "65 - 65",
    "tutionValue": "$18,631",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Supply Chain Management",
    "programLink": "https://admissions.usask.ca/supply-chain-management.php",
    "entranceGrade": "75",
    "tutionValue": "$20,587",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Supply Chain Management Alpha International",
    "programLink": "https://www.stlawrencecollege.ca/services/international/affiliate-programs-in-canada",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "CDI College (Edmonton South Campus)",
    "location": "Canada, Alberta",
    "programName": "Logistics & Supply Chain Management",
    "programLink": "",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Concordia University - John Molson School of Business",
    "location": "Canada, Québec",
    "programName": "Supply Chain Management (MSCM)",
    "programLink": "https://www.concordia.ca/academics/graduate/supply-chain-management.html",
    "entranceGrade": "N/S",
    "tutionValue": "$30,054",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "New Brunswick Community College",
    "location": "Canada, New Brunswick",
    "programName": "Supply Chain Management and Logistics",
    "programLink": "https://nbcc.ca/programs-courses/program-details?baseCurriculumId=14a53fc2-c337-4bda-9092-640a655c447b",
    "entranceGrade": "N/S",
    "tutionValue": "$9,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Supply Chain Management",
    "programLink": "https://www.stlawrencecollege.ca/programs/supply-chain-management/full-time/cornwall",
    "entranceGrade": "N/S",
    "tutionValue": "$14,600",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Operations and Supply Chain Management",
    "programLink": "https://www.kpu.ca/melville/operations-supply-chain",
    "entranceGrade": "N/S",
    "tutionValue": "$22,754",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Supply Chain Management (MSCM)",
    "programLink": "https://edgeip.com/admin/index.asp",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "CDI College (Scarborough Campus)",
    "location": "Canada, Ontario",
    "programName": "Supply Chain Management",
    "programLink": "https://www.cdicollege.ca/?adkey=001453#gsc.tab=0",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "George Brown College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Supply Chain Management",
    "programLink": "https://www.georgebrown.ca/programs/project-management-program-online-postgraduate-b429",
    "entranceGrade": "N/S",
    "tutionValue": "$17,100",
    "length": "8 Month(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Logistics and Supply Chain Management",
    "programLink": "https://umanitoba.ca/student/admissions/programs/logistics-chain-management.html",
    "entranceGrade": "85 - 85",
    "tutionValue": "$22,100",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Logistics and Supply Chain Management",
    "programLink": "https://umanitoba.ca/student/admissions/programs/logistics-chain-management.html",
    "entranceGrade": "85 - 85",
    "tutionValue": "$22,100",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Advanced Supply Chain Management (Post-Baccalaureate Diploma)",
    "programLink": "https://www.douglascollege.ca/program/pbdadvscm",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Supply Chain Management Post-Baccalaureate Diploma",
    "programLink": "https://www.tru.ca/programs/catalogue/supply-chain-management-post-baccalaureate-diploma.html",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "N/S"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Industrial (Fast-Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/mechanical-engineering-technology-industrial-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Electromechanical Engineering Technician",
    "programLink": "https://www.algonquincollege.com/sat/program/electro-mechanical-engineering-technician-robotics/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,826",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Electro-Mechanical Engineering Technology – Automation and Robot...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/automation-and-robotics-technology-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,345",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering (BEng)",
    "programLink": "https://admissions.carleton.ca/programs/mechanical-engineering/",
    "entranceGrade": "75 - 85",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Design (Fast-Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/mechanical-engineering-technology-design-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Engineering (Automation and Robotics) Pathway for El...",
    "programLink": "https://www.algonquincollege.com/sat/program/bachelor-of-automation-and-robotics-pathway-for-electro-mechanical-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Electro-Mechanical Engineering Technician - Automation and Robot...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/automation-and-robotics-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,452",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Mechanical Engineering (BEng)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/mechanical-engineering.html",
    "entranceGrade": "85",
    "tutionValue": "$35,625",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Electro-Mechanical Engineering Technology – Automation and Robot...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/automation-and-robotics-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,452",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering (BEng)",
    "programLink": "https://admissions.carleton.ca/programs/mechanical-engineering/",
    "entranceGrade": "82 - 86",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Design (Fast-Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/mechanical-engineering-technology-design-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/mechanical-engineering/",
    "entranceGrade": "80 - 89",
    "tutionValue": "$38,472",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician - Design (Fast-Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/mechanical-engineering-technician-design-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,462",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Electro-Mechanical Engineering Technician – Automation and Robot...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/automation-robotics-technician-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,345",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Industrial (Fast-Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/mechanical-engineering-technology-industrial-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Science (Building Science) - Pathway for Mechanical ...",
    "programLink": "https://www.algonquincollege.com/acce/program/bachelor-of-building-science-pathway-for-mechanical-engineering/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Industrial",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/mechanical-engineering-technology-industrial/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,462",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Electromechanical Engineering Technician",
    "programLink": "https://www.algonquincollege.com/sat/program/electro-mechanical-engineering-technician-robotics/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,826",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology",
    "programLink": "https://www.algonquincollege.com/sat/program/mechanical-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$15,022",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/mechanical-engineering/",
    "entranceGrade": "80 - 89",
    "tutionValue": "$38,472",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Electro-Mechanical Engineering Technology – Automation and Robot...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/automation-and-robotics-technology-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,345",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering (BEng)",
    "programLink": "https://futurestudents.yorku.ca/program/mechanical-engineering",
    "entranceGrade": "80 - 100",
    "tutionValue": "$37,345",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://uwaterloo.ca/future-students/programs/mechanical-engineering?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "87 - 93",
    "tutionValue": "$73,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-mechanical-engineering-mechanical-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Design",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/mechanical-engineering-technology-design/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,462",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology",
    "programLink": "https://www.algonquincollege.com/sat/program/mechanical-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$15,022",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Electro-Mechanical Engineering Technology – Automation and Robot...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/automation-and-robotics-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,452",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Industrial",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/mechanical-engineering-technology-industrial/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,462",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-mechanical-engineering-mechanical-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Design",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/mechanical-engineering-technology-design/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,462",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician - Design",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/mechanical-engineering-technician-design/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,462",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biomedical and Mechanical Engineering (BEng)",
    "programLink": "https://admissions.carleton.ca/programs/biomedical-and-mechanical-engineering/",
    "entranceGrade": "82 - 86",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Engineering (Automation and Robotics) Pathway for Me...",
    "programLink": "https://www.algonquincollege.com/sat/program/bachelor-of-automation-and-robotics-pathway-for-mechanical-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.unb.ca/academics/programs/engineering/mechanical_sj.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician - Automated Manufacturing",
    "programLink": "https://www.conestogac.on.ca/fulltime/mechanical-engineering-technician-automated-manufacturing",
    "entranceGrade": "N/S",
    "tutionValue": "$17,528",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Automated Manufacturing",
    "programLink": "https://www.conestogac.on.ca/fulltime/mechanical-engineering-technology-automated-manufacturing",
    "entranceGrade": "N/S",
    "tutionValue": "$17,490",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://uoit.ca/programs/engineering-and-applied-science/mechanical-engineering-mechanical-engineering-and-management.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$39,808",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://uoit.ca/programs/engineering-and-applied-science/mechanical-engineering-mechanical-engineering-and-management.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$39,808",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Automated Manufacturing",
    "programLink": "https://www.conestogac.on.ca/fulltime/mechanical-engineering-technology-automated-manufacturing",
    "entranceGrade": "N/S",
    "tutionValue": "$16,570",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician - Automated Manufacturing",
    "programLink": "https://www.conestogac.on.ca/fulltime/mechanical-engineering-technician-automated-manufacturing",
    "entranceGrade": "N/S",
    "tutionValue": "$16,570",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/mechanical-engineering",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician - Non-Destructive Evaluation (...",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=76676",
    "entranceGrade": "N/S",
    "tutionValue": "$16,804",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "HBA/ Mechanical Engineering",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician - Tool and Die",
    "programLink": "https://www.fanshawec.ca/programs/tdi2s-mechanical-engineering-technician-tool-and-die/next",
    "entranceGrade": "N/S",
    "tutionValue": "$15,684",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Mechanical Engineering Technology - Design and Automation major",
    "programLink": "https://www.sait.ca/programs-and-courses/diplomas/mechanical-engineering-technology",
    "entranceGrade": "50 - 50",
    "tutionValue": "$19,314",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology",
    "programLink": "https://www.georgiancollege.ca/academics/programs/mechanical-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$14,413",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Automotive Product Design",
    "programLink": "https://www.stclaircollege.ca/programs/mechanical-engineering-technology-automotive-product-design",
    "entranceGrade": "N/S",
    "tutionValue": "$15,820",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Engineering (Mechanical Engineering)",
    "programLink": "https://www.sheridancollege.ca/programs/bachelor-of-engineering-mechanical",
    "entranceGrade": "70",
    "tutionValue": "$22,290",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/mechanical-engineering-program-co-op-mechc/",
    "entranceGrade": "84 - 89",
    "tutionValue": "$46,268",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Industrial Design",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/MIT.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,890",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=5030",
    "entranceGrade": "N/S",
    "tutionValue": "$16,452",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Confederation College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician",
    "programLink": "",
    "entranceGrade": "N/S",
    "tutionValue": "$27,900",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Mechanical Engineering (Grad. Cert.)",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=87709",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering and Biomedical Engineering",
    "programLink": "https://www.eng.uwo.ca/biomed/undergraduate/program-options.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology – Design (Fast Track/Co-Op)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/mechanical-engineering-technology-design-fast-track",
    "entranceGrade": "N/S",
    "tutionValue": "$18,036",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology",
    "programLink": "https://www.mohawkcollege.ca/programs/technology/mechanical-engineering-technology-529",
    "entranceGrade": "N/S",
    "tutionValue": "$17,922",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Mechanical Engineering Technology (Co-operative Education Design...",
    "programLink": "https://camosun.ca/programs-courses/find-program/mechanical-engineering-technology-diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$38,460",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Design",
    "programLink": "https://www.sheridancollege.ca/programs/mechanical-engineering-technology-design",
    "entranceGrade": "N/S",
    "tutionValue": "$19,037",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/mechanical-engineering-program-mech/",
    "entranceGrade": "84 - 89",
    "tutionValue": "$46,268",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering - Technology",
    "programLink": "https://www.canadorecollege.ca/programs/mechanical-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$17,490",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$64,300",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering and Computing Technology",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "6 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Mechanical Engineering Technology - Design and Analysis major",
    "programLink": "https://www.sait.ca/programs-and-courses/diplomas/mechanical-engineering-technology",
    "entranceGrade": "60 - 60",
    "tutionValue": "$19,314",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biomedical Mechanical Engineering and Computing Technology",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "87 - 89",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Electromechanical Engineering Technician - Mechatronics",
    "programLink": "https://catalog.cambriancollege.ca/emtn/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,611",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Mechanical Engineering Technology",
    "programLink": "https://camosun.ca/programs-courses/find-program/mechanical-engineering-technology-diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$38,460",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Academy of Learning Career College - Toronto (Downtown)",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Design Technology",
    "programLink": "https://www.academyoflearning.com/programs/visual-and-applied-design/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "34 Week(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Electromechanical Engineering Technician",
    "programLink": "https://appliedtechnology.humber.ca/programs/electromechanical-engineering-technician-automation-and-robotics-profile.html",
    "entranceGrade": "N/S",
    "tutionValue": "$17,618",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Mechanical Engineering Bridge to UBC",
    "programLink": "https://camosun.ca/programs-courses/find-program/mechanical-engineering-bridge-ubc-advanced-diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$15,190",
    "length": "8 Month(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.mun.ca/undergrad/programs/engineering/mechanical-engineering/",
    "entranceGrade": "80",
    "tutionValue": "$20,790",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Mechanical Engineering",
    "programLink": "https://admissions.usask.ca/mechanical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician - Design",
    "programLink": "https://www.sheridancollege.ca/programs/mechanical-engineering-technician-design",
    "entranceGrade": "N/S",
    "tutionValue": "$19,037",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Red River College Polytechnic",
    "location": "Canada, Manitoba",
    "programName": "Mechanical Engineering Technology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=11733",
    "entranceGrade": "N/S",
    "tutionValue": "$17,930",
    "length": "28 Month(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Electromechanical Engineering Technology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=31647",
    "entranceGrade": "N/S",
    "tutionValue": "$16,267",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "George Brown College",
    "location": "Canada, Ontario",
    "programName": "Electromechanical Engineering Technician",
    "programLink": "https://www.georgebrown.ca/programs/electromechanical-engineering-technician-program-t146",
    "entranceGrade": "N/S",
    "tutionValue": "$15,190",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering and Society",
    "programLink": "https://www.eng.mcmaster.ca/mech/programs/degree-options/bengsociety-1",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "chrome-extension://cjpalhdlnbpafiamejdnhcphjbkeiagm/document-blocked.html?details=%7B%22url%22%3A%22https%3A%2F%2Ffuture.uwindsor.ca%2Fmechanical-engineering%22%2C%22hn%22%3A%22future.uwindsor.ca%22%2C%22dn%22%3A%22uwindsor.ca%22%2C%22fs%22%3A%22%7C%7Cfuture.uwindsor.ca%5E%22%7D",
    "entranceGrade": "74 - 100",
    "tutionValue": "$43,421",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.ucalgary.ca/future-students/undergraduate/explore-programs/mechanical-engineering",
    "entranceGrade": "85",
    "tutionValue": "$20,172",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Building Sciences",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/MBT.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,890",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology – Design (Fast Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/mechanical-engineering-technology-design-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,036",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Mechanical Engineering Technology (Mechanical Systems Option)",
    "programLink": "https://www.bcit.ca/programs/mechanical-engineering-technology-mechanical-systems-option-diploma-full-time-635cdiplt/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Mechanical Engineering (MASc)",
    "programLink": "https://www.concordia.ca/academics/graduate/mechanical-engineering-masc.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/mechanical-engineering.php",
    "entranceGrade": "85",
    "tutionValue": "$31,493",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Mechanical Engineering",
    "programLink": "https://you.ubc.ca/ubc_programs/mechanical-engineering-vancouver/",
    "entranceGrade": "N/S",
    "tutionValue": "$54,987",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biomedical Mechanical Engineering and Computing Technology",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "87 - 89",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biomedical Mechanical Engineering",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "87 - 89",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering - Automotive (MEng)",
    "programLink": "https://www.uwindsor.ca/graduate-studies/506/Mengauto?elqTrackId=c34c9494c2094b9d8628a8aa162c17a1&elqaid=414&elqat=2",
    "entranceGrade": "73 - 100",
    "tutionValue": "$5,998",
    "length": "16 Month(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biomedical Mechanical Engineering",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "87 - 89",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology",
    "programLink": "https://appliedtechnology.humber.ca/programs/mechanical-engineering-technology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,394",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician - Industrial Maintenance",
    "programLink": "https://www.fanshawec.ca/programs/mim3s-mechanical-engineering-technician-industrial-maintenance/next",
    "entranceGrade": "N/S",
    "tutionValue": "$30,285",
    "length": "45 Week(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Electro-Mechanical Engineering Technology – Automation And Robot...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/automation-and-robotics-technology-fast-track",
    "entranceGrade": "N/S",
    "tutionValue": "$18,026",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician - Non-Destructive Evaluation",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=31653",
    "entranceGrade": "N/S",
    "tutionValue": "$16,397",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician",
    "programLink": "https://www.sheridancollege.ca/programs/mechanical-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$19,037",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.uwindsor.ca/graduate/1970/mechanical-engineering",
    "entranceGrade": "70 - 100",
    "tutionValue": "$9,198",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician (Tool Design)",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/MATD.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,890",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Electromechanical Engineering Technician - Robotics",
    "programLink": "https://www.stclaircollege.ca/programs/electromechanical-engineering-technician-robotics",
    "entranceGrade": "N/S",
    "tutionValue": "$15,778",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Mechanical Engineering",
    "programLink": "https://you.ubc.ca/ubc_programs/mechanical-engineering-vancouver/",
    "entranceGrade": "N/S",
    "tutionValue": "$54,987",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Nova Scotia Community College",
    "location": "Canada, Nova Scotia",
    "programName": "Mechanical Engineering Technology",
    "programLink": "https://www.nscc.ca/programs-and-courses/programs/plandescr.aspx?prg=METG&pln=MECENGTECH",
    "entranceGrade": "N/S",
    "tutionValue": "$11,690",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Mechanical Engineering",
    "programLink": "https://umanitoba.ca/student/admissions/programs/mechanical-engineering.html",
    "entranceGrade": "85 - 85",
    "tutionValue": "$22,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician - Industrial",
    "programLink": "https://www.stclaircollege.ca/programs/mechanical-engineering-technician-industrial-millwright",
    "entranceGrade": "N/S",
    "tutionValue": "$15,820",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering - Technician",
    "programLink": "https://www.canadorecollege.ca/programs/mechanical-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$17,490",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=5031",
    "entranceGrade": "N/S",
    "tutionValue": "$16,442",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Mechanical Engineering Technology - Design and Development major...",
    "programLink": "https://www.sait.ca/programs-and-courses/diplomas/mechanical-engineering-technology",
    "entranceGrade": "60 - 60",
    "tutionValue": "$19,314",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Mechanical Engineering (PhD)",
    "programLink": "https://www.concordia.ca/academics/graduate/mechanical-engineering-phd.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.uwindsor.ca/graduate-studies/335/mechanical-engineering?elqTrackId=d9c53022dbb6449e9b76398c6ca76fed&elqaid=414&elqat=2",
    "entranceGrade": "77 - 100",
    "tutionValue": "$8,863",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Mechanical Engineering - Mining Option",
    "programLink": "https://admissions.usask.ca/mechanical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Electromechanical Engineering Technology",
    "programLink": "https://appliedtechnology.humber.ca/programs/electromechanical-engineering-technology-automation-and-robotics-profile.html",
    "entranceGrade": "N/S",
    "tutionValue": "$17,618",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Mechanical Engineering Technology (Internship Designation)",
    "programLink": "https://camosun.ca/programs-courses/find-program/mechanical-engineering-technology-diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$38,460",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering - Technician",
    "programLink": "https://www.canadorecollege.ca/programs/mechanical-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$17,490",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Electro-Mechanical Engineering Technology – Automation And Robot...",
    "programLink": "https://www.centennialcollege.ca/404",
    "entranceGrade": "N/S",
    "tutionValue": "$18,026",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering - Engineering Management and Entrepreneur...",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Mechanical Engineering Bridge to UVic",
    "programLink": "https://camosun.ca/programs-courses/find-program/mechanical-engineering-bridge-uvic-advanced-diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$15,190",
    "length": "8 Month(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Electromechanical Engineering Technician - Mechatronics",
    "programLink": "https://catalog.cambriancollege.ca/emty/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,611",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Mechanical Engineering Technology (Mechanical Manufacturing Opti...",
    "programLink": "https://www.bcit.ca/programs/mechanical-engineering-technology-mechanical-manufacturing-option-diploma-full-time-635ediplt/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Law/ Mechanical Engineering",
    "programLink": "https://www.westerncalendar.uwo.ca/Modules.cfm?ModuleID=21455&SelectedCalendar=Live&ArchiveID=",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "6 Year(s)"
  },
  {
    "schoolName": "Okanagan College",
    "location": "Canada, British Columbia",
    "programName": "Mechanical Engineering Technology Diploma",
    "programLink": "https://www.okanagan.bc.ca/mechanical-engineering-technology-diploma",
    "entranceGrade": "60",
    "tutionValue": "$18,906",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering and Computing Technology",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Mechanical Engineering",
    "programLink": "https://umanitoba.ca/student/admissions/programs/mechanical-engineering.html",
    "entranceGrade": "85 - 85",
    "tutionValue": "$22,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering - Engineering Management and Entrepreneur...",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering - Technology",
    "programLink": "https://www.canadorecollege.ca/programs/mechanical-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$17,490",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "NAIT",
    "location": "Canada, Alberta",
    "programName": "Mechanical Engineering Technology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=7799",
    "entranceGrade": "65",
    "tutionValue": "$19,512",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Electromechanical Engineering Technician",
    "programLink": "https://www.sheridancollege.ca/programs/electromechanical-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$19,037",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.unb.ca/academics/programs/engineering/mechanical-f.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.unb.ca/academics/programs/engineering/mechanical-f.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Mechanical Engineering",
    "programLink": "https://admissions.usask.ca/mechanical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Mechanical Engineering (MEng)",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=39564",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/mech/programs/degree-options/beng-3",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Electromechanical Engineering Technology",
    "programLink": "https://www.sheridancollege.ca/programs/electromechanical-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$19,037",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Mechanical Engineering",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/mechanical-engineering",
    "entranceGrade": "95",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technician",
    "programLink": "https://appliedtechnology.humber.ca/programs/mechanical-engineering-technician.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,394",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology - Building Sciences",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/MBT.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,890",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Mechanical Engineering (BEng)",
    "programLink": "https://www.dal.ca/study/programs/undergraduate/engineering-beng.html",
    "entranceGrade": "70",
    "tutionValue": "$34,323",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Mechanical Engineering Technology (Mechanical Design Option)",
    "programLink": "https://www.bcit.ca/programs/mechanical-engineering-technology-mechanical-design-option-diploma-full-time-635ddiplt/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Mechanical Engineering & Management",
    "programLink": "https://www.eng.mcmaster.ca/mech/programs/degree-options/bengmanagement-0",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Red Deer Polytechnic",
    "location": "Canada, Alberta",
    "programName": "Mechanical Engineering Technology",
    "programLink": "https://reddeer.prod.acquia-sites.com/programs/mechanical-engineering-technology-diploma",
    "entranceGrade": "65",
    "tutionValue": "$21,442",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Technology",
    "programLink": "https://www.sheridancollege.ca/programs/mechanical-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$19,037",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Academy of Learning Career College - Ontario",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering Design Technology",
    "programLink": "https://www.academyoflearning.com/programs/visual-and-applied-design/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "34 Week(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Engineering (Automation and Robotics) - Pathway for ...",
    "programLink": "https://www.algonquincollege.com/sat/program/bachelor-of-automation-and-robotics-pathway-for-electrical-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician",
    "programLink": "https://www.algonquincollege.com/acce/program/electrical-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,826",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Electrical Engineering",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-electrical-engineering-electrical-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Electrical Engineering (BEng)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/electrical-engineering.html",
    "entranceGrade": "80",
    "tutionValue": "$35,625",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Electrical Engineering",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-electrical-engineering-electrical-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Electrician: Construction Maintenance - Electrical Engineering T...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/electrician-construction-maintenance-electrical-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,712",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Nanoengineering (BSc in Electrical Engineering)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-electrical-engineering-nanoengineering-option-co-operative-nanoengineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Nanoengineering (BSc in Electrical Engineering)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-electrical-engineering-nanoengineering-option-nanoengineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering (BEng)",
    "programLink": "https://admissions.carleton.ca/programs/electrical-engineering/",
    "entranceGrade": "75 - 85",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://www.algonquincollege.com/acce/program/electrical-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,826",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://www.algonquincollege.com/acce/program/electrical-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,826",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering (Co-operative Internship Program)",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/electrical-engineering/",
    "entranceGrade": "80 - 89",
    "tutionValue": "$38,472",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/electrical-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,632",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering (BEng)",
    "programLink": "https://futurestudents.yorku.ca/program/electrical-engineering",
    "entranceGrade": "80 - 100",
    "tutionValue": "$37,345",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician",
    "programLink": "https://www.algonquincollege.com/acce/program/electrical-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,826",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biomedical and Electrical Engineering (BEng)",
    "programLink": "https://admissions.carleton.ca/programs/biomedical-and-electrical-engineering/",
    "entranceGrade": "75 - 85",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/electrical-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$8,712",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/electrical-engineering/",
    "entranceGrade": "80 - 89",
    "tutionValue": "$38,472",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Engineering (Automation and Robotics) Pathway for El...",
    "programLink": "https://www.algonquincollege.com/sat/program/bachelor-of-automation-and-robotics-pathway-for-electrical-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://uwaterloo.ca/future-students/programs/electrical-engineering?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "87 - 93",
    "tutionValue": "$73,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Electrical Engineering",
    "programLink": "https://www.unb.ca/academics/programs/engineering/electrical_sj.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/engineering/electrical-engineering/index.php",
    "entranceGrade": "75 - 100",
    "tutionValue": "$39,808",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/engineering/electrical-engineering/index.php",
    "entranceGrade": "75 - 100",
    "tutionValue": "$39,808",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Fleming College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician",
    "programLink": "https://flemingcollege.ca/programs/electrical-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$18,872",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Fleming College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://flemingcollege.ca/programs/electrical-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$18,872",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://www.conestogac.on.ca/fulltime/electrical-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,570",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Electrical Engineering",
    "programLink": "https://umanitoba.ca/student/admissions/programs/electrical-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$22,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Electrical Engineering",
    "programLink": "https://www.unb.ca/academics/programs/engineering/electrical.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician",
    "programLink": "https://www.fanshawec.ca/programs/eln2-electrical-engineering-technician/next",
    "entranceGrade": "N/S",
    "tutionValue": "$15,752",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Electrical Engineering",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/electrical-engineering-faculty-engineering",
    "entranceGrade": "93",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=68958",
    "entranceGrade": "74 - 100",
    "tutionValue": "$43,421",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering and Biomedical Engineering",
    "programLink": "https://www.eng.uwo.ca/biomed/undergraduate/program-options.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "N/S"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "HBA/ Electrical Engineering",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technology - Control Systems",
    "programLink": "https://appliedtechnology.humber.ca/programs/electrical-engineering-technology-control-systems.html",
    "entranceGrade": "N/S",
    "tutionValue": "$17,618",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "NAIT",
    "location": "Canada, Alberta",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://www.nait.ca/programs/electrical-engineering-technology?term=2021-fall",
    "entranceGrade": "65",
    "tutionValue": "$18,000",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Electrical Engineering",
    "programLink": "https://admissions.usask.ca/electrical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saskatchewan Polytechnic (Moose Jaw Campus)",
    "location": "Canada, Saskatchewan",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://saskpolytech.ca/programs-and-courses/programs/Electrical-Engineering-Technology.aspx",
    "entranceGrade": "60",
    "tutionValue": "$14,625",
    "length": "76 Week(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://www.georgiancollege.ca/academics/programs/electrical-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$14,399",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician",
    "programLink": "https://www.sheridancollege.ca/programs/electrical-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$19,163",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Electrical Engineering",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/electrical-engineering-faculty-engineering",
    "entranceGrade": "93",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering - Engineering Management and Entrepreneur...",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Electrical Engineering",
    "programLink": "https://umanitoba.ca/student/admissions/programs/electrical-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$22,800",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Northern College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician",
    "programLink": "https://www.northerncollege.ca/program/electrical-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=80846",
    "entranceGrade": "N/S",
    "tutionValue": "$16,052",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Engineering (Electrical) Degree with Electrical Engi...",
    "programLink": "https://www.lakeheadgeorgian.ca/programs/engineering-electrical/?utm_source=GeorgianCollege&utm_medium=web&utm_campaign=Redirects&utm_content=direct",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://smithengineering.queensu.ca/ece/",
    "entranceGrade": "80 - 100",
    "tutionValue": "$61,487",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Electrical Engineering",
    "programLink": "https://www.ucalgary.ca/future-students/undergraduate/explore-programs/electrical-engineering",
    "entranceGrade": "85",
    "tutionValue": "$20,172",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Electrical Engineering",
    "programLink": "https://www.unb.ca/academics/programs/engineering/electrical.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Electrical and Computer Engineering - Electrical Engineering Opt...",
    "programLink": "https://www.dal.ca/study/programs/undergraduate/engineering-beng.html",
    "entranceGrade": "70",
    "tutionValue": "$34,323",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering and Computing Technology",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Law/ Electrical Engineering",
    "programLink": "https://www.westerncalendar.uwo.ca/Modules.cfm?ModuleID=21446&SelectedCalendar=Live&ArchiveID=",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "6 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Electrical Engineering",
    "programLink": "https://you.ubc.ca/ubc_programs/electrical-engineering-vancouver/",
    "entranceGrade": "65",
    "tutionValue": "$54,987",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician",
    "programLink": "https://www.stlawrencecollege.ca/programs/electrical-engineering-technician/full-time/kingston",
    "entranceGrade": "N/S",
    "tutionValue": "$17,484",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://www.sait.ca/programs-and-courses/diplomas/electrical-engineering-technology",
    "entranceGrade": "60 - 60",
    "tutionValue": "$20,307",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Nova Scotia Community College",
    "location": "Canada, Nova Scotia",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://www.nscc.ca/programs-and-courses/programs/plandescr.aspx?prg=ELTG&pln=ELEENGTECH",
    "entranceGrade": "N/S",
    "tutionValue": "$11,690",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician",
    "programLink": "https://www.georgiancollege.ca/academics/programs/electrical-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$14,399",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering - Engineering Management and Entrepreneur...",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-electrical-engineering-engineering-management-entrepreneurship-option/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://www.mohawkcollege.ca/programs/technology/electrical-engineering-technology-582#edit-group-work-experience",
    "entranceGrade": "N/S",
    "tutionValue": "$17,983",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering and Society",
    "programLink": "https://www.eng.mcmaster.ca/ece/programs/degree-options/bengsociety/electrical-engineering-and-society",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering and Computing Technology",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-electrical-engineering-bsc-computing-technology/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Electrical Engineering",
    "programLink": "https://you.ubc.ca/ubc_programs/electrical-engineering-vancouver/",
    "entranceGrade": "N/S",
    "tutionValue": "$54,987",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Electrical Engineering",
    "programLink": "https://www.mun.ca/undergrad/programs/engineering/electrical-engineering/",
    "entranceGrade": "80",
    "tutionValue": "$20,790",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://www.uwindsor.ca/graduate-studies/320/electrical-engineering?elqTrackId=772c3921b5684866a539cc776c8188e5&elqaid=414&elqat=2",
    "entranceGrade": "70 - 100",
    "tutionValue": "$9,198",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Northern College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://www.northerncollege.ca/program/electrical-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Electrical Engineering",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/electrical-engineering.php",
    "entranceGrade": "85 - 100",
    "tutionValue": "$31,493",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://www.uwindsor.ca/graduate-studies/320/electrical-engineering?elqTrackId=772c3921b5684866a539cc776c8188e5&elqaid=414&elqat=2",
    "entranceGrade": "77 - 100",
    "tutionValue": "$8,863",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Loyalist College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician - Industrial",
    "programLink": "https://loyalistcollege.com/program/electrical-techniques/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technology - Industrial",
    "programLink": "https://catalog.cambriancollege.ca/eety/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,956",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Physics and Electrical Engineering",
    "programLink": "https://www.uottawa.ca/faculty-science/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Electrical Engineering",
    "programLink": "https://admissions.usask.ca/electrical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Physics and Electrical Engineering",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "6 Year(s)"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician",
    "programLink": "https://www.stclaircollege.ca/programs/electrical-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$15,551",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician - Control Systems",
    "programLink": "https://appliedtechnology.humber.ca/programs/electrical-engineering-technician-control-systems.html",
    "entranceGrade": "N/S",
    "tutionValue": "$17,618",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/ece/programs/degree-options/beng/electrical-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician - Power",
    "programLink": "https://www.mohawkcollege.ca/programs/skilled-trades/electrical-engineering-technician-power-403-433",
    "entranceGrade": "N/S",
    "tutionValue": "$17,335",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Saskatchewan Polytechnic",
    "location": "Canada, Saskatchewan",
    "programName": "Electrical Engineering Technology",
    "programLink": "https://saskpolytech.ca/programs-and-courses/programs/Electrical-Engineering-Technology.aspx",
    "entranceGrade": "60",
    "tutionValue": "$19,193",
    "length": "76 Week(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering Technician - Industrial",
    "programLink": "https://catalog.cambriancollege.ca/eetn/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,956",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering",
    "programLink": "https://www.sheridancollege.ca/programs/bachelor-of-engineering-electrical",
    "entranceGrade": "70",
    "tutionValue": "$22,134",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering and Biomedical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/ece/programs/degree-options/bengbme/electrical-engineering-and-biomedical-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.algonquincollege.com/acce/program/civil-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Science (Building Science) - Pathway for Constructio...",
    "programLink": "https://www.algonquincollege.com/acce/program/bachelor-of-building-science-pathway-for-construction-and-civil-engineering/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.algonquincollege.com/acce/program/civil-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering (BEng)",
    "programLink": "https://admissions.carleton.ca/programs/civil-engineering/",
    "entranceGrade": "75 - 85",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering (BEng)",
    "programLink": "https://futurestudents.yorku.ca/program/civil-engineering",
    "entranceGrade": "80 - 100",
    "tutionValue": "$37,345",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering",
    "programLink": "https://uwaterloo.ca/future-students/programs/civil-engineering?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "84 - 89",
    "tutionValue": "$71,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Civil Engineering (BEng)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/civil-engineering.html",
    "entranceGrade": "83",
    "tutionValue": "$35,625",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Civil Engineering",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-civil-engineering-civil-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Civil Engineering",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-civil-engineering-civil-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/civil-engineering/",
    "entranceGrade": "80 - 85",
    "tutionValue": "$38,472",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Civil Engineering",
    "programLink": "https://www.unb.ca/academics/programs/engineering/civil_sj.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.conestogac.on.ca/fulltime/civil-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,570",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.conestogac.on.ca/fulltime/civil-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$17,375",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Loyalist College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://loyalistcollege.com/program/civil-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,468",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.stlawrencecollege.ca/programs/civil-engineering-technology/full-time/kingston",
    "entranceGrade": "N/S",
    "tutionValue": "$17,454",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Civil Engineering",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/civil-engineering",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Structural Geotechnical",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-civil-engineering-structural-geotechnical-option/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saskatchewan Polytechnic",
    "location": "Canada, Saskatchewan",
    "programName": "Civil Engineering Technologies - Civil Construction",
    "programLink": "https://saskpolytech.ca/programs-and-courses/programs/Civil-Engineering-Technologies.aspx",
    "entranceGrade": "N/S",
    "tutionValue": "$19,628",
    "length": "27 Month(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.mohawkcollege.ca/programs/technology/civil-engineering-technology-534",
    "entranceGrade": "N/S",
    "tutionValue": "$17,846",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Civil Engineering & Management",
    "programLink": "https://www.eng.mcmaster.ca/civil/programs/degree-options/bengmgt-0",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technician",
    "programLink": "https://www.georgiancollege.ca/academics/programs/civil-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$14,319",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Structural Geotechnical",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-civil-engineering-structural-geotechnical-option/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=96639",
    "entranceGrade": "N/S",
    "tutionValue": "$14,884",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Civil Engineering",
    "programLink": "https://you.ubc.ca/ubc_programs/civil-engineering-vancouver/",
    "entranceGrade": "N/S",
    "tutionValue": "$54,987",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saskatchewan Polytechnic (Moose Jaw Campus)",
    "location": "Canada, Saskatchewan",
    "programName": "Civil Engineering Technologies (Construction option or Water Res...",
    "programLink": "https://saskpolytech.ca/programs-and-courses/programs/Civil-Engineering-Technologies.aspx",
    "entranceGrade": "N/S",
    "tutionValue": "$13,633",
    "length": "76 Week(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Civil Engineering - Earth and Environment Option (BEng)",
    "programLink": "https://www.dal.ca/study/programs/undergraduate/engineering-beng.html",
    "entranceGrade": "70",
    "tutionValue": "$23,478",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Loyalist College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technician",
    "programLink": "https://loyalistcollege.com/program/civil-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering (Ph.D.)",
    "programLink": "https://www.lakeheadu.ca/programs/graduate/programs/doctoral/civil-engineering/node/43158",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Technician",
    "programLink": "https://www.canadorecollege.ca/programs/civil-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$17,490",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://appliedtechnology.humber.ca/programs/civil-engineering-technology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,394",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Confederation College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.confederationcollege.ca/program/civil-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Environmental Engineering with International...",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology - Transportation",
    "programLink": "https://www.mohawkcollege.ca/programs/technology/civil-engineering-technology-transportation-524",
    "entranceGrade": "N/S",
    "tutionValue": "$17,828",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Nova Scotia Community College",
    "location": "Canada, Nova Scotia",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.nscc.ca/programs-and-courses/programs/plandescr.aspx?prg=CETG&pln=CIVENGTECH",
    "entranceGrade": "N/S",
    "tutionValue": "$11,690",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Structural Engineering",
    "programLink": "https://www.eng.uwo.ca/undergraduate/programs/civil.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering and Computing Technology",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-civil-engineering-bsc-computing-technology/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/CVT.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,890",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technician",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/CVL.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,890",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Civil Engineering (MEng)",
    "programLink": "https://www.concordia.ca/academics/graduate/civil-engineering-meng.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering (MEng)",
    "programLink": "https://uwaterloo.ca/academic-calendar/graduate-studies/catalog#/programs/Syig1A0s3",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "16 Month(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Civil Engineering (MASc)",
    "programLink": "https://www.concordia.ca/academics/graduate/civil-engineering-masc.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Civil Engineering Technology (Internship Designation)",
    "programLink": "https://camosun.ca/programs-courses/find-program/civil-engineering-technology-diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$31,950",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Saskatchewan Polytechnic",
    "location": "Canada, Saskatchewan",
    "programName": "Civil Engineering Technologies - Water Resources",
    "programLink": "https://saskpolytech.ca/programs-and-courses/programs/Civil-Engineering-Technologies.aspx",
    "entranceGrade": "N/S",
    "tutionValue": "$19,628",
    "length": "27 Month(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Civil Engineering",
    "programLink": "https://www.unb.ca/academics/programs/engineering/civil-f.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Northern College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technician",
    "programLink": "https://www.northerncollege.ca/program/civil-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Civil Engineering",
    "programLink": "https://www.mun.ca/undergrad/programs/engineering/civil-engineering/",
    "entranceGrade": "80",
    "tutionValue": "$20,790",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technician",
    "programLink": "https://www.mohawkcollege.ca/programs/technology/civil-engineering-technician-421",
    "entranceGrade": "N/S",
    "tutionValue": "$17,335",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Civil Engineering - Infrastructure Option (BEng)",
    "programLink": "https://www.dal.ca/study/programs/undergraduate/engineering-beng.html",
    "entranceGrade": "70",
    "tutionValue": "$32,003",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Environmental Engineering",
    "programLink": "https://www.eng.uwo.ca/undergraduate/programs/civil-environmental.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Civil Engineering",
    "programLink": "https://you.ubc.ca/ubc_programs/civil-engineering-vancouver/",
    "entranceGrade": "N/S",
    "tutionValue": "$54,987",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Engineering Management and Entrepreneurship",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-civil-engineering-engineering-management-entrepreneurship-option/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "HBA/ Civil Engineering",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering",
    "programLink": "https://www.eng.mcmaster.ca/civil/programs/degree-options/beng-2",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=5879",
    "entranceGrade": "90 - 100",
    "tutionValue": "$61,487",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Confederation College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technician",
    "programLink": "https://www.confederationcollege.ca/program/civil-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$13,950",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering",
    "programLink": "https://www.uwindsor.ca/graduate-studies/312/civil-engineering?elqTrackId=aa749635d74c48358b5937ecebdbd52f&elqaid=414&elqat=2",
    "entranceGrade": "77 - 100",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Academy of Learning Career College - Toronto (Downtown)",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Design Technology",
    "programLink": "https://www.academyoflearning.com/programs/visual-and-applied-design/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "29 Week(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Civil Engineering (PhD)",
    "programLink": "https://www.concordia.ca/academics/graduate/civil-engineering-phd.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Civil Engineering",
    "programLink": "https://engineering.ok.ubc.ca/programs-admissions/civil/",
    "entranceGrade": "67",
    "tutionValue": "$52,872",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technician",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/CVL.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,890",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering & Society",
    "programLink": "https://www.eng.mcmaster.ca/civil/programs/degree-options/bengsociety-0",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Civil Engineering Bridge to UBC",
    "programLink": "https://camosun.ca/programs-courses/find-program/civil-engineering-bridge-ubc-advanced-diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$15,190",
    "length": "8 Month(s)"
  },
  {
    "schoolName": "NAIT",
    "location": "Canada, Alberta",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.nait.ca/programs/civil-engineering-technology?term=2021-fall",
    "entranceGrade": "75",
    "tutionValue": "$19,125",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technician",
    "programLink": "https://catalog.cambriancollege.ca/cvtn/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,998",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Civil Engineering Technology (Co-operative Education Designation...",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=4071",
    "entranceGrade": "N/S",
    "tutionValue": "$31,950",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=3697",
    "entranceGrade": "N/S",
    "tutionValue": "$15,952",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.georgiancollege.ca/academics/programs/civil-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$14,319",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://catalog.cambriancollege.ca/cvty/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,998",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Civil Engineering",
    "programLink": "https://www.unb.ca/academics/programs/engineering/civil-f.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.sait.ca/sait-schools/school-of-construction/construction-design-and-project-management-programs",
    "entranceGrade": "50 - 50",
    "tutionValue": "$20,212",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Structural Engineering with International De...",
    "programLink": "https://www.eng.uwo.ca/undergraduate/programs/civil.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Civil Engineering",
    "programLink": "https://admissions.usask.ca/civil-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technician",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=96641",
    "entranceGrade": "N/S",
    "tutionValue": "$14,884",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Civil Engineering",
    "programLink": "https://www.bcit.ca/programs/civil-engineering-diploma-full-time-5410diplt/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Civil Engineering",
    "programLink": "https://www.bcit.ca/programs/civil-engineering-bachelor-of-engineering-full-time-8660beng/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Red River College Polytechnic",
    "location": "Canada, Manitoba",
    "programName": "Civil Engineering Technology",
    "programLink": "https://catalogue.rrc.ca/Programs/WPG/Fulltime/CIVCF-DP",
    "entranceGrade": "N/S",
    "tutionValue": "$18,325",
    "length": "20 Month(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.stclaircollege.ca/programs/civil-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$15,960",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "College of the North Atlantic - Corner Brook Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.cna.nl.ca/program/civil-engineering-technology-co-op",
    "entranceGrade": "60",
    "tutionValue": "$14,057",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/CVT.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,890",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Engineering Management and Entrepreneurship",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Civil Engineering Technology",
    "programLink": "https://camosun.ca/programs-courses/find-program/civil-engineering-technology-diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$31,950",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering",
    "programLink": "https://www.uwindsor.ca/graduate-studies/312/civil-engineering?elqTrackId=6504ed537d784a97a6b8db1048be524c&elqaid=414&elqat=2",
    "entranceGrade": "70 - 100",
    "tutionValue": "$9,698",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Lethbridge College",
    "location": "Canada, Alberta",
    "programName": "Civil Engineering Technology",
    "programLink": "https://lethpolytech.ca/programs-and-courses/civil-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$15,585",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Law/ Civil Engineering",
    "programLink": "https://www.westerncalendar.uwo.ca/Modules.cfm?ModuleID=21544&SelectedCalendar=Live&ArchiveID=",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "6 Year(s)"
  },
  {
    "schoolName": "Okanagan College",
    "location": "Canada, British Columbia",
    "programName": "Civil Engineering Technology Diploma",
    "programLink": "https://www.okanagan.bc.ca/civil-engineering-technology-diploma",
    "entranceGrade": "50 - 67",
    "tutionValue": "$19,338",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Technology",
    "programLink": "https://www.canadorecollege.ca/programs/civil-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$17,490",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Saskatchewan Polytechnic",
    "location": "Canada, Saskatchewan",
    "programName": "Civil Engineering Technologies",
    "programLink": "https://saskpolytech.ca/programs-and-courses/programs/Civil-Engineering-Technologies.aspx",
    "entranceGrade": "N/S",
    "tutionValue": "$19,628",
    "length": "27 Month(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Technician",
    "programLink": "https://www.canadorecollege.ca/programs/civil-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$17,490",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering and Computing Technology",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-civil-engineering-bsc-computing-technology/#text",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Infrastructure Technology",
    "programLink": "https://www.eng.mcmaster.ca/sept/programs/degree-options/btech/civil-engineering-infrastructure-technology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Civil Engineering",
    "programLink": "https://admissions.usask.ca/civil-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Environmental and Water Resources",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-civil-engineering-environmental-water-resources-option/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Civil Engineering",
    "programLink": "https://www.unbc.ca/programs/civil-engineering",
    "entranceGrade": "75 - 100",
    "tutionValue": "$26,750",
    "length": "N/S"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Technology",
    "programLink": "https://www.canadorecollege.ca/programs/civil-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$17,490",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering (MASc)",
    "programLink": "https://graduate.carleton.ca/cu-programs/civil-engineering-masters/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Academy of Learning Career College - Ontario",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering Design Technology",
    "programLink": "https://www.academyoflearning.com/programs/visual-and-applied-design/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "34 Week(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering - Envrionmental and Water Resources",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-civil-engineering-environmental-water-resources-option/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Civil Engineering",
    "programLink": "https://umanitoba.ca/student/admissions/programs/civil-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$22,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "NAIT",
    "location": "Canada, Alberta",
    "programName": "Civil Engineering Technology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=68868",
    "entranceGrade": "75",
    "tutionValue": "$19,125",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-civil-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=21419",
    "entranceGrade": "74 - 100",
    "tutionValue": "$43,421",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering",
    "programLink": "https://graduate.carleton.ca/cu-programs/civil-engineering-phd/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Civil Engineering",
    "programLink": "https://uwaterloo.ca/academic-calendar/graduate-studies/catalog#/programs/Sy-jekRRi3",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Civil Engineering",
    "programLink": "https://umanitoba.ca/student/admissions/programs/civil-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$22,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering",
    "programLink": "https://uwaterloo.ca/future-students/programs/chemical-engineering?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "84 - 89",
    "tutionValue": "$73,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Chemical Engineering - Computer Process Control",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-chemical-engineering-computer-process-control-chemical-engineering-computer-process-control.html",
    "entranceGrade": "N/S",
    "tutionValue": "$40,290",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering Co-op",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/chemical-engineering-co-op/",
    "entranceGrade": "80 - 84",
    "tutionValue": "$38,472",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Chemical Engineering",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-chemical-engineering-co-operative-chemical-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Chemical Engineering",
    "programLink": "https://www.unb.ca/academics/programs/engineering/chemical-engineering_sj.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering Technology",
    "programLink": "https://www.mohawkcollege.ca/programs/technology/chemical-engineering-technology-533",
    "entranceGrade": "N/S",
    "tutionValue": "$17,943",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering",
    "programLink": "https://uwaterloo.ca/academic-calendar/graduate-studies/catalog#/programs/HkGixk0Ai3",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Chemical Engineering Technology",
    "programLink": "https://www.sait.ca/programs-and-courses/diplomas/chemical-engineering-technology",
    "entranceGrade": "50 - 50",
    "tutionValue": "$20,386",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Chemical Engineering",
    "programLink": "https://admissions.usask.ca/chemical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering and Computing Technology",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-chemical-engineering-bsc-computing-technology/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering Technology",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/CHY.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,738",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Chemical Engineering - Petroleum Option",
    "programLink": "https://admissions.usask.ca/chemical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Chemical Engineering",
    "programLink": "https://admissions.usask.ca/chemical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Chemical Engineering (BEng)",
    "programLink": "https://www.dal.ca/study/programs/undergraduate/engineering-beng.html",
    "entranceGrade": "70",
    "tutionValue": "$32,003",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Chemical Engineering - Biochemical Option",
    "programLink": "https://admissions.usask.ca/chemical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Chemical Engineering (MASc)",
    "programLink": "https://www.concordia.ca/academics/graduate/chemical-engineering-certificate.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering",
    "programLink": "https://uwaterloo.ca/academic-calendar/graduate-studies/catalog#/programs/BJesgkRAsh",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Loyalist College",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering Technology",
    "programLink": "https://loyalistcollege.com/program/chemical-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,468",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering (BEng)",
    "programLink": "https://laurentian.ca/program/chemical-engineering/details",
    "entranceGrade": "70",
    "tutionValue": "$32,268",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering: Trent/Swansea Dual Degree (B.Sc. & B.Eng.)...",
    "programLink": "https://www.trentu.ca/futurestudents/program/chemical-engineering-trentswansea-dual-degree?target=undergraduate",
    "entranceGrade": "80 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering Technology -- Environmental",
    "programLink": "https://www.sheridancollege.ca/programs/chemical-engineering-technology-environmental",
    "entranceGrade": "65",
    "tutionValue": "$18,662",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Chemical Engineering - Mineral Processing Option",
    "programLink": "https://admissions.usask.ca/chemical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering Technology",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/CHY.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,738",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Chemical Engineering",
    "programLink": "https://www.unb.ca/academics/programs/engineering/chemical-engineering_f.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=5876",
    "entranceGrade": "85 - 100",
    "tutionValue": "$61,487",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Chemical Engineering",
    "programLink": "https://you.ubc.ca/ubc_programs/chemical-engineering/",
    "entranceGrade": "N/S",
    "tutionValue": "$54,987",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (Biotechnology) and Chemical Engineering (Biotechno...",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Chemical Engineering (Grad. Cert.)",
    "programLink": "https://www.concordia.ca/academics/graduate/chemical-engineering-certificate.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering Technology",
    "programLink": "https://www.sheridancollege.ca/programs/chemical-engineering-technology",
    "entranceGrade": "65",
    "tutionValue": "$18,662",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Chemical Engineering",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/chemical-engineering-faculty-engineering",
    "entranceGrade": "91",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Chemical Engineering - Mineral Processing Option",
    "programLink": "https://admissions.usask.ca/chemical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering - Environmental Engineering",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-chemical-engineering-environmental-engineering-option/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering and Computing Technology",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-chemical-engineering-bsc-computing-technology/#text",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering (BEng)",
    "programLink": "https://laurentian.ca/program/chemical-engineering/details",
    "entranceGrade": "70",
    "tutionValue": "$25,745",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "HBA/ Chemical Engineering",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Law/ Chemical Engineering",
    "programLink": "https://www.westerncalendar.uwo.ca/Modules.cfm?ModuleID=21444&SelectedCalendar=Live&ArchiveID=",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "6 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Chemical Engineering - Biochemical Option",
    "programLink": "https://admissions.usask.ca/chemical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering and Society",
    "programLink": "https://www.eng.mcmaster.ca/chemeng/programs/degree-options/bengsociety/society",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Chemical Engineering",
    "programLink": "https://www.unb.ca/academics/programs/engineering/chemical-engineering_f.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/chemeng/programs/degree-options/beng-0",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering and Management",
    "programLink": "https://www.eng.mcmaster.ca/chemeng/programs/degree-options/bengmanagement/management",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Chemical Engineering (PhD)",
    "programLink": "https://www.concordia.ca/academics/graduate/chemical-engineering-certificate.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering - Engineering Management and Entrepreneursh...",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-chemical-engineering-engineering-management-entrepreneurship-option/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering Technology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=4989",
    "entranceGrade": "N/S",
    "tutionValue": "$16,209",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Loyalist College",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering Technician",
    "programLink": "https://loyalistcollege.com/program/chemical-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Chemical Engineering",
    "programLink": "https://you.ubc.ca/ubc_programs/chemical-engineering/",
    "entranceGrade": "N/S",
    "tutionValue": "$54,987",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (Biotechnology) and Chemical Engineering (Biotechno...",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "6 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Chemical Engineering",
    "programLink": "https://www.ucalgary.ca/future-students/undergraduate/explore-programs/chemical-engineering",
    "entranceGrade": "85",
    "tutionValue": "$25,530",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "NAIT",
    "location": "Canada, Alberta",
    "programName": "Chemical Engineering Technology",
    "programLink": "https://www.nait.ca/programs/chemical-engineering-technology?term=2021-fall",
    "entranceGrade": "N/S",
    "tutionValue": "$15,910",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Chemical Engineering - Petroleum Option",
    "programLink": "https://admissions.usask.ca/chemical-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-chemical-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering Technician",
    "programLink": "https://catalog.cambriancollege.ca/chln/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,411",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering - Engineering Management and Entrepreneursh...",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-chemical-engineering-engineering-management-entrepreneurship-option/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Chemical Engineering (GrDip)",
    "programLink": "https://www.concordia.ca/academics/graduate/chemical-engineering-diploma.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering Technology - Lab and Process Control",
    "programLink": "https://catalog.cambriancollege.ca/chlp/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,411",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering - Environmental Engineering",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/basc-chemical-engineering-environmental-engineering-option/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering and Biomedical Engineering",
    "programLink": "https://www.eng.uwo.ca/biomed/undergraduate/program-options.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Aerospace Engineering (Five-Year Co-operative Internship Program...",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/aerospace-engineering/",
    "entranceGrade": "80 - 89",
    "tutionValue": "$38,472",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Aerospace Engineering (BEng)",
    "programLink": "https://admissions.carleton.ca/programs/aerospace-engineering/",
    "entranceGrade": "82 - 86",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Aerospace Engineering (BEng)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/aerospace-engineering.html",
    "entranceGrade": "90",
    "tutionValue": "$35,625",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Aerospace Engineering",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/aerospace-engineering/",
    "entranceGrade": "80 - 89",
    "tutionValue": "$38,472",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Aerospace Engineering (MASc)",
    "programLink": "https://graduate.carleton.ca/cu-programs/aerospace-engineering-masters/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Aerospace Engineering (MEng)",
    "programLink": "https://www.concordia.ca/academics/graduate/aerospace-engineering-meng.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Aerospace Engineering",
    "programLink": "https://graduate.carleton.ca/cu-programs/aerospace-engineering-phd/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering Technology",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/biomedical-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,484",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering Technology",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/biomedical-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,484",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/biomedical-engineering/",
    "entranceGrade": "80 - 85",
    "tutionValue": "$38,472",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering (Five-Year Co-operative Internship Progra...",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/biomedical-engineering/",
    "entranceGrade": "80 - 85",
    "tutionValue": "$38,472",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering Technology (Fast-Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/biomedical-engineering-technology-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,276",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering Technology (Fast-Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/biomedical-engineering-technology-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,276",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering",
    "programLink": "https://uwaterloo.ca/future-students/programs/biomedical-engineering?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "87 - 93",
    "tutionValue": "$73,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/biomedical-engineering-program-co-op-bmec/",
    "entranceGrade": "84 - 89",
    "tutionValue": "$46,268",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Dalhousie Medical School",
    "location": "Canada, Nova Scotia",
    "programName": "Biomedical Engineering (M.ASc.)",
    "programLink": "https://www.dal.ca/study/programs.html#level=Graduate-Professional",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Biomedical Engineering",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=104327",
    "entranceGrade": "N/S",
    "tutionValue": "$54,987",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Electrical and Biomedical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/ibiomed/programs/degree-options/bengbme/electrical-and-biomedical-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Materials and Biomedical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/ibiomed/programs/degree-options/bengbme/materials-and-biomedical-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Biomedical Engineering",
    "programLink": "https://you.ubc.ca/ubc_programs/biomedical-engineering/",
    "entranceGrade": "N/S",
    "tutionValue": "$54,987",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering and Biomedical Engineering",
    "programLink": "https://www.eng.uwo.ca/biomed/undergraduate/program-options.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Chemical and Biomedical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/ibiomed/programs/degree-options/bengbme/chemical-and-biomedical-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Engineering Physics and Biomedical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/ibiomed/programs/degree-options/bengbme/engineering-physics-and-biomedical-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Mechanical Engineering and Biomedical Engineering",
    "programLink": "https://www.eng.uwo.ca/biomed/undergraduate/program-options.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Biomedical Engineering (MSc)",
    "programLink": "https://umanitoba.ca/explore/programs-of-study/biomedical-engineering-msc",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/biomedical-engineering-program-bme/",
    "entranceGrade": "84 - 89",
    "tutionValue": "$46,268",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Mechatronic Systems Engineering and Biomedical Engineering",
    "programLink": "https://www.eng.uwo.ca/biomed/undergraduate/program-options.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "NAIT",
    "location": "Canada, Alberta",
    "programName": "Biomedical Engineering Technology",
    "programLink": "https://www.nait.ca/programs/biomedical-engineering-technology?term=2021-fall",
    "entranceGrade": "80",
    "tutionValue": "$21,417",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering and Biomedical Engineering",
    "programLink": "https://www.eng.uwo.ca/biomed/undergraduate/program-options.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "N/S"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering Technology - Equipment and Devices",
    "programLink": "https://www.stclaircollege.ca/programs/biomedical-engineering-technology-equipment-and-devices",
    "entranceGrade": "N/S",
    "tutionValue": "$15,772",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Biomedical Engineering (PhD)",
    "programLink": "https://umanitoba.ca/explore/programs-of-study/biomedical-engineering-phd",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering Technology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=12667",
    "entranceGrade": "N/S",
    "tutionValue": "$16,267",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Electrical Engineering and Biomedical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/ece/programs/degree-options/bengbme/electrical-engineering-and-biomedical-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Software and Biomedical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/ibiomed/programs/degree-options/bengbme/software-and-biomedical-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Biomedical Engineering Technology",
    "programLink": "https://www.bcit.ca/programs/biomedical-engineering-technology-diploma-full-time-5063dipma/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Mechanical and Biomedical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/ibiomed/programs/degree-options/bengbme/mechanical-and-biomedical-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Mechatronics and Biomedical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/ibiomed/programs/degree-options/bengbme/mechatronics-and-biomedical-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Civil and Biomedical Engineering",
    "programLink": "https://www.eng.mcmaster.ca/civil/programs/degree-options/bengbme/civil-and-biomedical-engineering-0",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Dalhousie Medical School",
    "location": "Canada, Nova Scotia",
    "programName": "Biomedical Engineering",
    "programLink": "https://www.dal.ca/study/programs.html#level=Graduate-Professional",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering",
    "programLink": "https://uwaterloo.ca/future-students/programs/computer-engineering?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "87 - 93",
    "tutionValue": "$73,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Computer Engineering",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-computer-engineering-computer-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Computer Engineering",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-computer-engineering-computer-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Computer Engineering (BEng)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/computer-engineering.html",
    "entranceGrade": "80",
    "tutionValue": "$35,625",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering Technology - Computing Science",
    "programLink": "https://www.algonquincollege.com/sat/program/computer-engineering-technology-computing-science/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,826",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Computer Engineering - Software",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-computer-engineering-computer-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering (BEng)",
    "programLink": "https://futurestudents.yorku.ca/program/computer-engineering",
    "entranceGrade": "80 - 100",
    "tutionValue": "$37,345",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Technology (Digital Health) - Pathway for Computer P...",
    "programLink": "https://www.algonquincollege.com/sat/program/bachelor-of-technology-digital-health/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering Technology - Computing Science",
    "programLink": "https://www.algonquincollege.com/sat/program/computer-engineering-technology-computing-science/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,826",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/computer-engineering/",
    "entranceGrade": "80 - 89",
    "tutionValue": "$38,472",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering Technology",
    "programLink": "https://www.conestogac.on.ca/fulltime/computer-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,570",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering Technology",
    "programLink": "https://www.conestogac.on.ca/fulltime/computer-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,570",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Fleming College",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering Technician",
    "programLink": "https://flemingcollege.ca/programs/computer-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$18,620",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Computer Engineering",
    "programLink": "https://admissions.usask.ca/computer-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Electrical and Computer Engineering - Computer Engineering Optio...",
    "programLink": "https://www.dal.ca/study/programs/undergraduate/engineering-beng.html",
    "entranceGrade": "70",
    "tutionValue": "$34,323",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering Technology - Mechatronics",
    "programLink": "https://www.mohawkcollege.ca/programs/technology/computer-engineering-technology-mechatronic-systems-562",
    "entranceGrade": "N/S",
    "tutionValue": "$17,950",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "NAIT",
    "location": "Canada, Alberta",
    "programName": "Computer Engineering Technology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=70974",
    "entranceGrade": "70",
    "tutionValue": "$16,632",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Electrical and Computer Engineering",
    "programLink": "https://uwaterloo.ca/academic-calendar/graduate-studies/catalog#/programs/rk-ox100oh",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Electrical and Computer Engineering Technology (Telecommunicatio...",
    "programLink": "https://www.bcit.ca/programs/electrical-and-computer-engineering-technology-telecommunications-and-networks-option-diploma-full-time-534cdipma/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering Technology",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/ECT.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,890",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering Technician - Mechatronic Systems",
    "programLink": "https://www.mohawkcollege.ca/programs/technology/computer-engineering-technician-mechatronic-systems-563",
    "entranceGrade": "N/S",
    "tutionValue": "$17,335",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Electronics and Computer Engineering Technology - Renewable Ener...",
    "programLink": "https://camosun.ca/programs-courses/find-program/electronics-computer-engineering-technology-renewable-energy-diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$40,030",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "83 - 86",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering",
    "programLink": "https://www.eng.mcmaster.ca/ece/programs/degree-options/beng/computer-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering Technician",
    "programLink": "https://www.sheridancollege.ca/programs/computer-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$19,028",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Electronics and Computer Engineering Technology - Renewable Ener...",
    "programLink": "https://camosun.ca/programs-courses/find-program/electronics-computer-engineering-technology-renewable-energy-diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$40,030",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Electrical and Computer Engineering",
    "programLink": "https://graduate.carleton.ca/cu-programs/electrical-computer-engineering-phd/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Engineering",
    "programLink": "https://www.mun.ca/undergrad/programs/engineering/computer-engineering/",
    "entranceGrade": "80",
    "tutionValue": "$20,790",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "NAIT",
    "location": "Canada, Alberta",
    "programName": "Computer Engineering Technology",
    "programLink": "https://www.nait.ca/programs/computer-engineering-technology?term=2021-fall",
    "entranceGrade": "70",
    "tutionValue": "$18,993",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/computer-engineering-program-co-op-cengc/",
    "entranceGrade": "84 - 89",
    "tutionValue": "$46,268",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Electrical and Computer Engineering (M.Eng.)",
    "programLink": "https://umanitoba.ca/ece/pros_students/grad/admissions.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Electrical and Computer Engineering Technology (Electrical Power...",
    "programLink": "https://www.bcit.ca/programs/electrical-and-computer-engineering-technology-electrical-power-and-industrial-control-option-diploma-full-time-534bdipma/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Electrical and Computer Engineering (MASc)",
    "programLink": "https://www.concordia.ca/academics/graduate/electrical-engineering-masc.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Electrical and Computer Engineering (MASc)",
    "programLink": "https://graduate.carleton.ca/cu-programs/electrical-computer-engineering-masters/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering and Society",
    "programLink": "https://www.eng.mcmaster.ca/ece/programs/degree-options/bengsociety/computer-engineering-and-society",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Electrical and Computer Engineering (PhD)",
    "programLink": "https://www.concordia.ca/academics/graduate/electrical-engineering-phd.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Computer Engineering",
    "programLink": "https://umanitoba.ca/student/admissions/programs/electrical-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$22,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Electrical and Computer Engineering (Ph.D.)",
    "programLink": "https://www.lakeheadu.ca/programs/graduate/programs/doctoral/electrical-computer-engineering/node/21234",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering - Engineering Management and Entrepreneursh...",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "83 - 86",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Electrical and Computer Engineering Technology (Automation and I...",
    "programLink": "https://www.bcit.ca/programs/electrical-and-computer-engineering-technology-automation-and-instrumentation-option-diploma-full-time-534adipma/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering and Management",
    "programLink": "https://www.eng.mcmaster.ca/ece/programs/degree-options/bengmanagement/computer-engineering-and-management",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Electrical and Computer Engineering (Pre-Master's)",
    "programLink": "https://umanitoba.ca/ece/pros_students/grad/admissions.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering Technology",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/ECT.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,890",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering Technology",
    "programLink": "https://www.sheridancollege.ca/programs/computer-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$19,028",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/computer-engineering-program-ceng/",
    "entranceGrade": "84 - 89",
    "tutionValue": "$46,268",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Electrical and Computer Engineering Bridge to UVic",
    "programLink": "https://camosun.ca/programs-courses/find-program/electrical-computer-engineering-bridge-uvic-advanced-diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$15,190",
    "length": "8 Month(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Computer Engineering",
    "programLink": "https://you.ubc.ca/ubc_programs/computer-engineering/",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Computer Engineering",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/computer-engineering",
    "entranceGrade": "94",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering - Engineering Management and Entrepreneursh...",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "83 - 86",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "83 - 86",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Saskatchewan Polytechnic (Moose Jaw Campus)",
    "location": "Canada, Saskatchewan",
    "programName": "Computer Engineering Technology",
    "programLink": "https://saskpolytech.ca/programs-and-courses/programs/Computer-Engineering-Technology.aspx",
    "entranceGrade": "60",
    "tutionValue": "$14,625",
    "length": "76 Week(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Computer Engineering",
    "programLink": "https://you.ubc.ca/ubc_programs/computer-engineering/",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saskatchewan Polytechnic",
    "location": "Canada, Saskatchewan",
    "programName": "Computer Engineering Technology",
    "programLink": "https://saskpolytech.ca/programs-and-courses/programs/Computer-Engineering-Technology.aspx",
    "entranceGrade": "60",
    "tutionValue": "$18,643",
    "length": "76 Week(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Computer Engineering",
    "programLink": "https://admissions.usask.ca/computer-engineering.php",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Electrical and Computer Engineering - Electrical Engineering Opt...",
    "programLink": "https://www.dal.ca/study/programs/undergraduate/engineering-beng.html",
    "entranceGrade": "70",
    "tutionValue": "$34,323",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Electrical and Computer Engineering (M.Sc Eng.)",
    "programLink": "https://www.lakeheadu.ca/programs/faculties/engineering/graduate-programs/electrical-and-computer",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Computer Engineering & Management",
    "programLink": "https://www.eng.mcmaster.ca/ece/programs/degree-options/bengmanagement/electrical-engineering-and-management",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Electrical and Computer Engineering",
    "programLink": "https://umanitoba.ca/explore/programs-of-study/electrical-and-computer-engineering-phd",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Computer Engineering",
    "programLink": "https://umanitoba.ca/student/admissions/programs/electrical-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$22,800",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering Technology",
    "programLink": "https://appliedtechnology.humber.ca/programs/computer-engineering-technology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,394",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Electrical and Computer Engineering (MEng)",
    "programLink": "https://www.concordia.ca/academics/graduate/electrical-engineering-meng.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Computer Engineering",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/computer-engineering.php",
    "entranceGrade": "85 - 100",
    "tutionValue": "$31,493",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Computer Engineering",
    "programLink": "https://smithengineering.queensu.ca/ece/",
    "entranceGrade": "80 - 100",
    "tutionValue": "$61,487",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Computer Science – Computation Arts (BCompSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/computer-science-comp-arts.html",
    "entranceGrade": "85",
    "tutionValue": "$35,625",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Computer Science (BCompSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/computer-science.html",
    "entranceGrade": "87",
    "tutionValue": "$35,625",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Computer Science (BA) Milton Campus",
    "programLink": "https://wlu.ca/programs/science/undergraduate/computer-science-ba-milton/index.html",
    "entranceGrade": "78 - 100",
    "tutionValue": "$35,796",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Computer Science (BCS)",
    "programLink": "https://admissions.carleton.ca/programs/computer-science/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Business Administration and Computer Science (BBA/BSc) Double De...",
    "programLink": "https://wlu.ca/programs/business-and-economics/undergraduate/business-bba-and-computer-science-bsc/index.html",
    "entranceGrade": "90 - 92",
    "tutionValue": "$40,209",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Business Administration and Computer Science Double Degree",
    "programLink": "https://uwaterloo.ca/future-students/programs/business-administration-computer-science-double-degree?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "90 - 94",
    "tutionValue": "$70,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://futurestudents.yorku.ca/program/computer-science",
    "entranceGrade": "75 - 100",
    "tutionValue": "$25,934",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Computer Science (BA) Waterloo or Brantford Campus",
    "programLink": "https://wlu.ca/programs/science/undergraduate/computer-science-ba/index.html",
    "entranceGrade": "78 - 100",
    "tutionValue": "$35,796",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Business Administration and Computer Science (BBA/BCS) Double De...",
    "programLink": "https://wlu.ca/programs/business-and-economics/undergraduate/business-bba-and-computer-science-bcs-uwaterloo/index.html",
    "entranceGrade": "95 - 100",
    "tutionValue": "$40,209",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Computer Science for Software Development",
    "programLink": "https://futurestudents.yorku.ca/program/computer-science-for-software-development",
    "entranceGrade": "50 - 100",
    "tutionValue": "$25,934",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Computer Science and Physics (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/computer-science-and-physics-bsc/index.html",
    "entranceGrade": "77 - 100",
    "tutionValue": "$35,796",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Technology (Business Systems Development) Path for C...",
    "programLink": "https://www.algonquincollege.com/sat/program/bachelor-of-technology-in-business-systems-development-honours-pathway-computer-programming-and-computer-engineering-technology-computing-science/",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Computer Science",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/ScienceTechnology/Departments/MathematicsComputing/Majors/Computer-Science/index.htm",
    "entranceGrade": "90 - 95",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Computation Arts – Computer Science (BFA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/comp-arts-computer-science.html",
    "entranceGrade": "85",
    "tutionValue": "$35,625",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Career and Technology Studies: Computer Science (BEd in Secondar...",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-career-and-technology-studies-computer-science.html",
    "entranceGrade": "75",
    "tutionValue": "$27,540",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Computer Science (BSc) Waterloo Campus",
    "programLink": "https://wlu.ca/programs/science/undergraduate/computer-science-bsc/index.html",
    "entranceGrade": "78 - 100",
    "tutionValue": "$35,796",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://uwaterloo.ca/future-students/programs/computer-science?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "90 - 94",
    "tutionValue": "$70,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Computer Science (BSc) Milton Campus",
    "programLink": "https://wlu.ca/programs/science/undergraduate/computer-science-bsc-milton/index.html",
    "entranceGrade": "78 - 100",
    "tutionValue": "$35,796",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/computer-science/",
    "entranceGrade": "90 - 96",
    "tutionValue": "$31,871",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Computer Science",
    "programLink": "https://www.unb.ca/academics/programs/computer-science/computer-science_sj.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Computer Science",
    "programLink": "https://www.unb.ca/academics/programs/computer-science/computer-science_sj.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Alexander College",
    "location": "Canada, British Columbia",
    "programName": "Associate of Science (Computer Science)",
    "programLink": "https://alexandercollege.ca/programs-and-courses/associate-of-science-computer-science-degree/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,300",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Advanced Entry",
    "programLink": "https://ontariotechu.ca/programs/science/computer-science-diploma-to-degree.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Integrated Mathematics and Computer Science",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/computers-and-technology/integrated-math-and-comp-sci/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://ontariotechu.ca/programs/science/computer-science-regular-and-co-op.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Integrated Mathematics and Computer Science",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/computers-and-technology/integrated-math-and-comp-sci/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science/Economics (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Liberal Program in Computer Science",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/computer-science-faculty-science",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Economics with Computer Science (Double Major)",
    "programLink": "https://www2.acadiau.ca/academics/undergraduate/economics.html",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Information Systems (HBSc)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/computer-science-%E2%80%94-information-systems-stream",
    "entranceGrade": "87 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Software Engineering (HBSc)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/computer-science-%E2%80%94-software-engineering-stream",
    "entranceGrade": "87 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science/Physics (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Mathematics and Computer Science (B.A.)",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/mathematics-and-computer-science-faculty-arts",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/computer-science-cs/#text",
    "entranceGrade": "85 - 90",
    "tutionValue": "$37,427",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Specialization in Software Engineering (B.Sc....",
    "programLink": "https://www.trentu.ca/cois/programs/computing-systems/co-op-option",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Computer Science (BA)",
    "programLink": "https://cmps.ok.ubc.ca/undergraduate/computer-science/",
    "entranceGrade": "67",
    "tutionValue": "$41,156",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://graduate.carleton.ca/cu-programs/computer-science-phd/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Computer Science and Statistics",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/computer-science-and-statistics.php",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Computer Science (BSc)",
    "programLink": "https://www2.acadiau.ca/prg_ug_comp.html",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science (Visual Computing and Games) (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science-visual-computing-and-games/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Computer Science (BCS)",
    "programLink": "https://www2.acadiau.ca/academics/undergraduate/computer-science.html",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Mathematics and Computer Science (B.A.)",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/mathematics-and-computer-science-faculty-arts",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Specialization in Theoretical Computer Scienc...",
    "programLink": "https://www.trentu.ca/futurestudents/program/computer-science/theoretical-computer-science?target=undergraduate",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Integrated Science with Computer Science",
    "programLink": "https://www.uwo.ca/sci/wisc/",
    "entranceGrade": "N/S",
    "tutionValue": "$48,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://uwaterloo.ca/academic-calendar/graduate-studies/catalog#/programs/HJybyC0ih",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Mathematics and Computer Science",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=32498",
    "entranceGrade": "70 - 100",
    "tutionValue": "$37,497",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Computer Science: Computer Games Option",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/computer-science-faculty-science",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Management | Computer Science",
    "programLink": "https://www.ulethbridge.ca/future-student/program/computer-science-management",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Computer Science (BSc)",
    "programLink": "https://you.ubc.ca/ubc_programs/computer-science-vancouver-bsc/",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://uwaterloo.ca/academic-calendar/graduate-studies/catalog#/programs/rJJbJRCsn",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Computer Science Diploma",
    "programLink": "https://www.uregina.ca/academics/programs/science/computer-science-diplomas.html",
    "entranceGrade": "N/S",
    "tutionValue": "$12,049",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Computer Science & Physics (B.Sc.)",
    "programLink": "https://www.trentu.ca/futurestudents/degree/computer-science-physics?target=undergraduate",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Computer Science",
    "programLink": "https://umanitoba.ca/student/admissions/programs/computer-science.html",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Computer Science",
    "programLink": "https://www.unbc.ca/programs/computer-science",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science/Pure Mathematics (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algoma University",
    "location": "Canada, Ontario",
    "programName": "Computer Science (BCOSC)",
    "programLink": "https://algomau.ca/academics/programs/computer-science/",
    "entranceGrade": "65 - 65",
    "tutionValue": "$20,438",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Science | Computer Science & GIS",
    "programLink": "https://www.ulethbridge.ca/future-student/program/computer-science-geographical-information-science",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Computer Science/Mathematics and Statistics Joint Major",
    "programLink": "https://www.unbc.ca/calendar/undergraduate/chemistry#chemistry_physics",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Specialization in Data Analytics (B.Sc.)",
    "programLink": "https://www.trentu.ca/futurestudents/program/computing-systems?target=undergraduate",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Data Science",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "83 - 86",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Applied Computer Science (BSc)",
    "programLink": "https://www.uwinnipeg.ca/applied-computer-science/",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Business Administration & Computer Science",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=114220",
    "entranceGrade": "73 - 100",
    "tutionValue": "$38,857",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Computer Science and Mathematics",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/computer-science-and-mathematics.php",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Computer Science (BA)",
    "programLink": "https://cmps.ok.ubc.ca/undergraduate/computer-science/",
    "entranceGrade": "70",
    "tutionValue": "$41,156",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "College of New Caledonia",
    "location": "Canada, British Columbia",
    "programName": "Computer Science",
    "programLink": "https://cnc.bc.ca/programs-courses/programs/Academic_Pathways.htm",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Mathematics and Computer Science (B.Sc.)",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/mathematics-and-computer-science-faculty-science",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Computer Science / Statistics",
    "programLink": "https://umanitoba.ca/student/admissions/programs/computer-science.html",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/computer-science.html",
    "entranceGrade": "N/S",
    "tutionValue": "$44,700",
    "length": "N/S"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Computer Science (HBSc)",
    "programLink": "https://future.utoronto.ca/undergraduate-programs/computer-science/",
    "entranceGrade": "87 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Computer Science",
    "programLink": "https://www.viu.ca/programs/science-and-technology/computer-science-bsc",
    "entranceGrade": "N/S",
    "tutionValue": "$27,002",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Computer Science (BSc)",
    "programLink": "https://you.ubc.ca/ubc_programs/computer-science-vancouver-bsc/",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=4611",
    "entranceGrade": "75 - 100",
    "tutionValue": "$39,947",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Computer Science (BSc)",
    "programLink": "https://www2.acadiau.ca/academics/undergraduate/computer-science.html",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Software Engineering (HBSc)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/computer-science-%E2%80%94-software-engineering-stream",
    "entranceGrade": "87 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://www.uwindsor.ca/graduate-studies/494/computer-science?elqTrackId=3c5d5459b62644498dc727a3fa1a8ee3&elqaid=414&elqat=2",
    "entranceGrade": "N/S",
    "tutionValue": "$9,318",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Computer Science and Biology",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/computer-science-and-biology",
    "entranceGrade": "93",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Computer Science (BA)",
    "programLink": "https://you.ubc.ca/ubc_programs/computer-science-vancouver-ba/",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science/Statistics (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Computer Science (B.A.)",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/computer-science-faculty-arts",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Specialization in Data Analytics (B.Sc.)",
    "programLink": "https://www.trentu.ca/futurestudents/program/computing-systems?target=undergraduate",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Computer Science - Software Engineering Option (B.Sc. Honours SE...",
    "programLink": "https://admissions.usask.ca/computer-science.php",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Science and Mathematics",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "87 - 89",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science (Smart Systems) (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science-smart-systems/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Computer Science - Networks and Distributed Computing",
    "programLink": "https://science.ucalgary.ca/computer-science/future-students/undergraduate/programs",
    "entranceGrade": "N/S",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Computer Science with Software Engineering Specialization",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=21711",
    "entranceGrade": "75 - 100",
    "tutionValue": "$39,947",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Applied Computer Science (BA Four Year)",
    "programLink": "https://www.uwinnipeg.ca/applied-computer-science/",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algoma University Brampton Campus",
    "location": "Canada, Ontario",
    "programName": "Computer Science BCOSC (Accelerated)",
    "programLink": "https://algomau.ca/academics/programs/computer-science/accelerated-degree/",
    "entranceGrade": "65",
    "tutionValue": "$20,438",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Applied Mathematics/Computer Science (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/applied-math.php",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Computer Science (MCompSc)",
    "programLink": "https://www.concordia.ca/academics/graduate/computer-science-mcompsc.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Computer Science / Mathematics",
    "programLink": "https://umanitoba.ca/student/admissions/programs/computer-science.html",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Integrated Computer Science",
    "programLink": "https://you.ubc.ca/ubc_programs/integrated-computer-science/",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "20 Month(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Computer Science - Computer Graphics",
    "programLink": "https://science.ucalgary.ca/computer-science/future-students/undergraduate/programs",
    "entranceGrade": "N/S",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science (BA)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Computer Science (BA)",
    "programLink": "https://laurentian.ca/program/computer-science/details",
    "entranceGrade": "70",
    "tutionValue": "$25,745",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Computer Science",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/computer-science.php",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Accelerated Stream",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "83 - 86",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Visual Arts & Computer Science",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/visual-arts-and-computer-science.php",
    "entranceGrade": "75 - 80",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/computer-science-co-op-csc/",
    "entranceGrade": "85 - 90",
    "tutionValue": "$37,427",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Computer Science and Statistics",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/computer-science-and-statistics.php",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Computer Science (B.A.)",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=9855",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Computer Science (BCS)",
    "programLink": "https://www2.acadiau.ca/academics/undergraduate/computer-science.html",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Vancouver Community College",
    "location": "Canada, British Columbia",
    "programName": "Associate of Science – Computer Science",
    "programLink": "https://www.vcc.ca/programs/associate-of-science--computer-science/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://www.lakeheadu.ca/programs/undergraduate-programs/computer-science/node/1110",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Management and Entrepreneurship",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "83 - 86",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Applied Computer Science (BSc Three Year)",
    "programLink": "https://www.uwinnipeg.ca/applied-computer-science/",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Computer Science (MApCompSc)",
    "programLink": "https://www.concordia.ca/academics/graduate/computer-science-mcompsci-applied.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science/Geography (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Computer Science",
    "programLink": "https://umanitoba.ca/explore/programs-of-study/computer-science-msc",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Computer Science / Mathematics",
    "programLink": "https://umanitoba.ca/student/admissions/programs/computer-science.html",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Computer Science",
    "programLink": "https://www.viu.ca/programs/science-and-technology/computer-science-bsc",
    "entranceGrade": "N/S",
    "tutionValue": "$27,002",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Computer Science",
    "programLink": "https://www.brandonu.ca/future-students/programs/degrees/science/math-comp-sci/",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Comprehensive (HBSc)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/computer-science-%E2%80%94-comprehensive-stream",
    "entranceGrade": "87 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Computer Science",
    "programLink": "https://www.viu.ca/programs/science-and-technology/computer-science",
    "entranceGrade": "N/S",
    "tutionValue": "$24,446",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Integrated Computer Science",
    "programLink": "https://you.ubc.ca/ubc_programs/integrated-computer-science/",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "20 Month(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://www.lakeheadu.ca/programs/undergraduate-programs/computer-science/node/1110",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://www.uwindsor.ca/graduate-studies/769/computer-science-phd-program",
    "entranceGrade": "80 - 100",
    "tutionValue": "$9,318",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Computer Science",
    "programLink": "https://www.brandonu.ca/future-students/programs/degrees/science/math-comp-sci/",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Computer Science (B.Sc.)",
    "programLink": "https://www.trentu.ca/futurestudents/program/computer-science?target=undergraduate",
    "entranceGrade": "80 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "83 - 86",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Business and Computer Science",
    "programLink": "https://you.ubc.ca/ubc_programs/business-computer-science/",
    "entranceGrade": "70",
    "tutionValue": "$55,385",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Computer Science",
    "programLink": "https://www.uregina.ca/academics/programs/science/computer-science.html",
    "entranceGrade": "65",
    "tutionValue": "$12,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Computer Science (BSc)",
    "programLink": "https://you.ubc.ca/ubc_programs/computer-science-vancouver-bsc/",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Computer Science - Human Computer Interactions",
    "programLink": "https://science.ucalgary.ca/computer-science/future-students/undergraduate/programs",
    "entranceGrade": "N/S",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Computer Science",
    "programLink": "https://www.ubishops.ca/academic-programs/faculty-of-arts-and-science/natural-sciences-and-mathematics/computer-science/",
    "entranceGrade": "70",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Computer Science and Geographic Information Systems",
    "programLink": "https://www.ulethbridge.ca/future-student/graduate-studies/master-science/computer-science-and-geographical-information-science",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Applied Computer Science (BA Three Year)",
    "programLink": "https://www.uwinnipeg.ca/applied-computer-science/",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Computer Science (BSc)",
    "programLink": "https://you.ubc.ca/ubc_programs/computer-science-vancouver-bsc/",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science/Pure Mathematics (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://uwaterloo.ca/academic-calendar/graduate-studies/catalog#/programs/BkgWyARjn",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Coquitlam College",
    "location": "Canada, British Columbia",
    "programName": "Science - Computer Science and Mathematics",
    "programLink": "https://www.coquitlamcollege.com/english/higherlearning/university/certificatediplomaprograms/coursesforcertificateprograms",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Algoma University Brampton Campus",
    "location": "Canada, Ontario",
    "programName": "Computer Science BCOSC (Three-year)",
    "programLink": "https://algomau.ca/academics/programs/computer-science/",
    "entranceGrade": "65",
    "tutionValue": "$20,438",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Applied Computer Science",
    "programLink": "https://www.dal.ca/study/programs/undergraduate/applied-computer-science-bacs.html",
    "entranceGrade": "70",
    "tutionValue": "$28,487",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Game Design (BCosc)",
    "programLink": "https://laurentian.ca/program/computer-science/details",
    "entranceGrade": "70",
    "tutionValue": "$25,745",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Computer Science (HBSc)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/computer-science",
    "entranceGrade": "87 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://www.sheridancollege.ca/programs/bachelor-computer-science",
    "entranceGrade": "65",
    "tutionValue": "$21,622",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Computer Science and Health Information Science",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/computer-science-and-health-information-science.php",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Specialization in Software Engineering (B.Sc....",
    "programLink": "https://www.trentu.ca/futurestudents/program/computer-science/software-engineering?target=undergraduate",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Computer Science (B.A.)",
    "programLink": "https://www.stfx.ca/programs-courses/programs/computer-science",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science (BA)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Computer Science",
    "programLink": "https://www.ubishops.ca/academic-programs/faculty-of-arts-and-science/natural-sciences-and-mathematics/computer-science/",
    "entranceGrade": "70",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Computer Science",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/computer-science.php",
    "entranceGrade": "80",
    "tutionValue": "$31,493",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Computer Science (M.Sc.)",
    "programLink": "https://www.lakeheadu.ca/programs/graduate/programs/masters/computer-science/node/7246",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Trent University - Durham Greater Toronto Area",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://www.trentu.ca/futurestudents/program/computer-science",
    "entranceGrade": "70",
    "tutionValue": "$28,285",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science (BA)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Computer Science (BCS)",
    "programLink": "https://www2.acadiau.ca/academics/undergraduate/computer-science.html",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Computer Science and Mathematics",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/computer-science-and-mathematics.php",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Physics and Computer Science",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/physics-and-computer-science",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Computer Science",
    "programLink": "https://selkirk.ca/search?search=program%20computer%20science",
    "entranceGrade": "N/S",
    "tutionValue": "$13,409",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science (Software Engineering) (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Computer Science - Theoretical Computer Science",
    "programLink": "https://science.ucalgary.ca/computer-science/future-students/undergraduate/programs",
    "entranceGrade": "N/S",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Computer Science",
    "programLink": "https://www.ubishops.ca/academic-programs/faculty-of-arts-and-science/natural-sciences-and-mathematics/computer-science/",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Computer Science (BA)",
    "programLink": "https://you.ubc.ca/ubc_programs/computer-science-vancouver-ba/",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "83 - 86",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Computer Science (BCS)",
    "programLink": "https://www2.acadiau.ca/academics/undergraduate/computer-science.html",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Computer Science (B.Sc.)",
    "programLink": "https://www.stfx.ca/programs-courses/programs/computer-science",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Comprehensive (HBSc)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/computer-science-%E2%80%94-comprehensive-stream",
    "entranceGrade": "87 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Specialization in Theoretical Computer Scienc...",
    "programLink": "https://www.trentu.ca/futurestudents/program/computer-science/theoretical-computer-science?target=undergraduate",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Computer Science (B.Sc. Three-year)",
    "programLink": "https://admissions.usask.ca/computer-science.php",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Computer Science - Computer Game Development",
    "programLink": "https://science.ucalgary.ca/computer-science/future-students/undergraduate/programs",
    "entranceGrade": "N/S",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Computer Science (MCS)",
    "programLink": "https://graduate.carleton.ca/cu-programs/computer-science-masters/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Computer Science (BSc)",
    "programLink": "https://www.nipissingu.ca/academics/faculty-arts-and-science/computer-science",
    "entranceGrade": "70 - 70",
    "tutionValue": "$21,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Science and Mathematics",
    "programLink": "https://www.uottawa.ca/faculty-science/",
    "entranceGrade": "87 - 89",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Chemistry/Computer Science Joint Major",
    "programLink": "https://www.unbc.ca/calendar/undergraduate/chemistry#chemistry_computer",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Computer Science",
    "programLink": "https://www.lakeheadu.ca/programs/undergraduate-programs/",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science/Physics (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Information Technology (Information Resource Managem...",
    "programLink": "https://www.algonquincollege.com/wellness-safety-community/program/bit-information-resource-management/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Information Technology (Optical Systems and Sensors)...",
    "programLink": "https://www.algonquincollege.com/sat/program/bachelor-of-information-technology-optical-systems-and-sensors/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Information Technology (BIT)",
    "programLink": "https://admissions.carleton.ca/programs/information-technology/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Information Technology Management",
    "programLink": "https://uwaterloo.ca/future-students/programs/information-technology-management?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "84 - 86",
    "tutionValue": "$59,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Information Technology (Interactive Media Design)",
    "programLink": "https://www.algonquincollege.com/mediaanddesign/program/bit-interactive-multimedia-and-design/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Information Technology (Computer and Communication N...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/bachelor-of-information-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$21,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Information Technology (Network Technology)",
    "programLink": "https://www.algonquincollege.com/sat/program/bit-network-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Information Technology (BA)",
    "programLink": "https://futurestudents.yorku.ca/program/information-technology",
    "entranceGrade": "75 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Technology Management (Bachelor of Information Technology)",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/computers-and-technology/technology-management-bit/index.php",
    "entranceGrade": "75 - 100",
    "tutionValue": "$35,345",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Information Technology - Networking and Information Technology S...",
    "programLink": "https://uoit.ca/programs/business-and-information-technology/information-technology-networking-and-information-technology-security-specialization.php",
    "entranceGrade": "75 - 100",
    "tutionValue": "$35,345",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Business Administration (Honours) - Accounting, Audit and Inform...",
    "programLink": "https://www.conestogac.on.ca/fulltime/bachelor-of-business-administration-honours-accounting-audit-and-information-technology",
    "entranceGrade": "65",
    "tutionValue": "$19,353",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Information Technology - Game Development and Entrepreneurship",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/computers-and-technology/information-technology-game-development-and-interactive-media/index.php",
    "entranceGrade": "75 - 100",
    "tutionValue": "$35,345",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Computer Systems Technician - Information Technology Infrastruct...",
    "programLink": "https://www.conestogac.on.ca/fulltime/computer-systems-technician-information-technology-infrastructure-and-services",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Information Technology - Game Development and Entrepreneurship -...",
    "programLink": "https://ontariotechu.ca/programs/business-and-information-technology/information-technology-pathways-program-game-development-and-entrepreneurship-specialization-bridge.php",
    "entranceGrade": "75 - 100",
    "tutionValue": "$35,345",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Networking and Information Technology Security - Advanced Entry",
    "programLink": "https://ontariotechu.ca/programs/business-and-information-technology/information-technology-pathways-program-networking-and-information-technology-security-direct-entry.php",
    "entranceGrade": "75 - 100",
    "tutionValue": "$35,345",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Information Technology Security - Bridge",
    "programLink": "https://ontariotechu.ca/programs/business-and-information-technology/information-technology-pathways-program-networking-and-information-technology-security-bridge.php",
    "entranceGrade": "75",
    "tutionValue": "$35,345",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Computer Systems Technician - Information Technology Infrastruct...",
    "programLink": "https://www.conestogac.on.ca/fulltime/computer-systems-technician-information-technology-infrastructure-and-services",
    "entranceGrade": "N/S",
    "tutionValue": "$16,092",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "triOS College (Kitchener Campus)",
    "location": "Canada, Ontario",
    "programName": "Information Technology Administrator",
    "programLink": "https://www.trios.com/programs-courses/technology/information-technology-administrator-cybersecurity/?section=InformationTechnologyAdministrator&campaignid=247",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "58 Week(s)"
  },
  {
    "schoolName": "ITD Canada",
    "location": "Canada, British Columbia",
    "programName": "Information Technology Diploma with Co-op - General Programming ...",
    "programLink": "https://itdcanada.ca/Default.aspx?ITGeneralProgramming",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "New Brunswick Community College",
    "location": "Canada, New Brunswick",
    "programName": "Information Technology: Cybersecurity",
    "programLink": "https://nbcc.ca/programs-courses/program-details?baseCurriculumId=9d27ef52-5c83-439f-b8c1-2de19bd6b259",
    "entranceGrade": "N/S",
    "tutionValue": "$9,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Information Technology and Applied Systems – Web and Mobile Deve...",
    "programLink": "https://www.viu.ca/programs/trades-applied-technology/information-technology-and-applied-systems-web-and-mobile",
    "entranceGrade": "N/S",
    "tutionValue": "$28,848",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Information Technology",
    "programLink": "https://www.ubishops.ca/academic-programs/faculty-of-arts-and-science/natural-sciences-and-mathematics/computer-science/courses-programs/bachelor-of-arts-in-information-technology-bait/",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Management & Information Technology (BBA)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/management-information-technology",
    "entranceGrade": "84 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "MacEwan University",
    "location": "Canada, Alberta",
    "programName": "Library and Information Technology",
    "programLink": "https://www.macewan.ca/academics/programs/library-and-information-technology/",
    "entranceGrade": "65 - 100",
    "tutionValue": "$24,572",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "CDI College (Longueuil Campus)",
    "location": "Canada, Québec",
    "programName": "Specialist in Applied Information Technology - LCE.3V Option: Me...",
    "programLink": "https://www.cdicollege.ca/study-on-campus/quebec/#gsc.tab=0",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "43 Week(s)"
  },
  {
    "schoolName": "ITD Canada",
    "location": "Canada, British Columbia",
    "programName": "Information Technology Diploma with Co-op - Embedded Programming...",
    "programLink": "https://itdcanada.ca/Default.aspx?ITEmbedded",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Bay River College",
    "location": "Canada, Alberta",
    "programName": "Information Technology and Network Administrator",
    "programLink": "https://bayrivercolleges.ca/program/information-technology-and-network-administrator/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "42 Week(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Computer Systems Technician – Information Technology Infrastruct...",
    "programLink": "https://appliedtechnology.humber.ca/programs/computer-systems-technician-information-technology-infrastructure-and-services.html",
    "entranceGrade": "N/S",
    "tutionValue": "$17,618",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "New Brunswick Community College",
    "location": "Canada, New Brunswick",
    "programName": "Information Technology: Business and Advanced Analytics",
    "programLink": "https://nbcc.ca/programs-courses/program-details?baseCurriculumId=c2ffe2e8-a927-4d0a-a64e-17e9b96aefaf",
    "entranceGrade": "N/S",
    "tutionValue": "$9,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "CDI College (Pointe Claire Campus)",
    "location": "Canada, Québec",
    "programName": "Specialist in Applied Information Technology - LCE.3V Option: Me...",
    "programLink": "https://www.cdicollege.ca/study-on-campus/quebec/#gsc.tab=0",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "ITD Canada",
    "location": "Canada, British Columbia",
    "programName": "Information Technology Diploma with Co-op - E-commerce Track",
    "programLink": "https://itdcanada.ca/Default.aspx?ITEcommerce",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Business Information Technology Management (Enterprise Systems M...",
    "programLink": "https://www.bcit.ca/programs/business-information-technology-management-enterprise-systems-management-option-diploma-full-time-623bdipma/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Campbellsville University - Windsor",
    "location": "Canada, Ontario",
    "programName": "Master of Science in Information Technology Management (MSITM)",
    "programLink": "https://campbellsville.ca/master-of-science-in-information-technology-management/",
    "entranceGrade": "60",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "New Brunswick Community College",
    "location": "Canada, New Brunswick",
    "programName": "Information Technology: Web and Mobile Application Development",
    "programLink": "https://nbcc.ca/programs-courses/program-details?baseCurriculumId=a3dc93ce-e29b-49e5-bf3c-d5374530e205",
    "entranceGrade": "N/S",
    "tutionValue": "$9,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "triOS College (Kitchener Campus)",
    "location": "Canada, Ontario",
    "programName": "Information Technology Professional",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=70598",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "74 Week(s)"
  },
  {
    "schoolName": "ITD Canada",
    "location": "Canada, British Columbia",
    "programName": "Information Technology Diploma with Co-op - Advanced Game Develo...",
    "programLink": "https://itdcanada.ca/Default.aspx?ITAdvancedGame",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "ITD Canada",
    "location": "Canada, British Columbia",
    "programName": "Information Technology Diploma with Co-op - Game Development Tra...",
    "programLink": "https://itdcanada.ca/Default.aspx?ITGame",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Library & Information Technology (Flexible Participation Option)...",
    "programLink": "https://langara.ca/programs-and-courses/programs/library-information-technology/index.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Computer Information Technology",
    "programLink": "https://www.bcit.ca/programs/computer-information-technology-diploma-full-time-5540dipma/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Fairleigh Dickinson University - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Bachelor of Science in Information Technology",
    "programLink": "https://www.fdu.edu/program/bs-information-technology-vancouver/",
    "entranceGrade": "N/S",
    "tutionValue": "$26,070",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Business Information Technology Management (Artificial Intellige...",
    "programLink": "https://www.bcit.ca/programs/business-information-technology-management-artificial-intelligence-management-option-diploma-full-time-623adipma/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Saskatchewan Polytechnic",
    "location": "Canada, Saskatchewan",
    "programName": "Library and Information Technology",
    "programLink": "https://saskpolytech.ca/programs-and-courses/programs/Library-and-Information-Technology.aspx",
    "entranceGrade": "60",
    "tutionValue": "N/S",
    "length": "72 Week(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Computer Systems Technician – Transfer to Ontario Tech Universit...",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=85157",
    "entranceGrade": "N/S",
    "tutionValue": "$16,609",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "triOS College (Kitchener Campus)",
    "location": "Canada, Ontario",
    "programName": "Information Technology Administrator",
    "programLink": "https://www.trios.com/programs-courses/technology/information-technology-administrator-cybersecurity/?section=InformationTechnologyAdministrator&campaignid=247",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "50 Week(s)"
  },
  {
    "schoolName": "Concordia University of Edmonton",
    "location": "Canada, Alberta",
    "programName": "Information Technology (MSc)",
    "programLink": "https://concordia.ab.ca/science/graduate/master-of-science-in-information-technology/",
    "entranceGrade": "80 - 80",
    "tutionValue": "$21,642",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Nova Scotia Community College",
    "location": "Canada, Nova Scotia",
    "programName": "Library and Information Technology",
    "programLink": "https://www.nscc.ca/programs-and-courses/programs/plandescr.aspx?prg=LBTN&pln=LIBINFTECH",
    "entranceGrade": "N/S",
    "tutionValue": "$11,690",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Information Technology Services",
    "programLink": "https://www.sait.ca/programs-and-courses/diplomas/information-technology-services",
    "entranceGrade": "50 - 60",
    "tutionValue": "$24,134",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "triOS College (Kitchener Campus)",
    "location": "Canada, Ontario",
    "programName": "Information Technology Professional",
    "programLink": "https://www.trios.com/programs-courses/technology/information-technology-professional-infrastructure-cloud-cybersecurity/?section=InformationTechnologyProfessional&campaignid=247",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "90 Week(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Library and Information Technology",
    "programLink": "https://langara.ca/programs-and-courses/programs/library-information-technology/index.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Medicine Hat College",
    "location": "Canada, Alberta",
    "programName": "Information Technology - Software and Internet Development",
    "programLink": "https://www.mhc.ab.ca/page-not-found?item=%2fprogramsandcourses%2facademic-programs%2fprograms-of-study%2finformation-technology&user=extranet%5cAnonymous&site=website",
    "entranceGrade": "N/S",
    "tutionValue": "$18,148",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "ITD Canada",
    "location": "Canada, British Columbia",
    "programName": "Information Technology Diploma with Co-op - Graphic and Web Deve...",
    "programLink": "https://itdcanada.ca/Default.aspx?ITGraphicWeb",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Library and Information Technology",
    "programLink": "https://www.ufv.ca/information-studies/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Management & Information Technology (BBA)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/management-information-technology",
    "entranceGrade": "84 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lethbridge College",
    "location": "Canada, Alberta",
    "programName": "Computer Information Technology",
    "programLink": "https://lethpolytech.ca/programs-and-courses/computer-information-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$13,092",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Bow Valley College",
    "location": "Canada, Alberta",
    "programName": "Information Technology Systems Diploma",
    "programLink": "https://bowvalleycollege.ca/programs-courses/technology/information-technology-systems-diploma",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "16 Month(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Information Technology (BTech)",
    "programLink": "https://www.kpu.ca/melville/bachelor-technology-information-technology",
    "entranceGrade": "70",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Information Technology and Applied Systems – Systems Administrat...",
    "programLink": "https://www.viu.ca/programs/trades-applied-technology/information-technology-and-applied-systems-systems",
    "entranceGrade": "N/S",
    "tutionValue": "$28,848",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Information Technology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=114229",
    "entranceGrade": "75 - 100",
    "tutionValue": "$39,947",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University of Edmonton",
    "location": "Canada, Alberta",
    "programName": "Information Technology (B.Sc. 4-year)",
    "programLink": "https://concordia.ab.ca/science/bachelor/bachelor-information-technology/",
    "entranceGrade": "60",
    "tutionValue": "$25,414",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Computer Systems Technician -- Information Technology Infrastruc...",
    "programLink": "https://www.sheridancollege.ca/programs/computer-systems-technician-information-technology-infrastructure-services",
    "entranceGrade": "65",
    "tutionValue": "$18,501",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "New Brunswick Community College",
    "location": "Canada, New Brunswick",
    "programName": "Information Technology: Network Administration",
    "programLink": "https://nbcc.ca/programs-courses/program-details?baseCurriculumId=3f485930-f502-45ff-9ad1-ed4b3913e48a",
    "entranceGrade": "N/S",
    "tutionValue": "$9,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Information Technology (BTech)",
    "programLink": "https://www.kpu.ca/co-op/information-technology",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Library Information Technology",
    "programLink": "https://www.sait.ca/programs-and-courses/diplomas/library-information-technology",
    "entranceGrade": "60",
    "tutionValue": "$18,124",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "ITD Canada",
    "location": "Canada, British Columbia",
    "programName": "Information Technology Diploma with Co-op - Network Administrati...",
    "programLink": "https://itdcanada.ca/Default.aspx?ITNetwork",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Technology (Fast-Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/software-engineering-technology-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,044",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Software Engineering",
    "programLink": "https://uwaterloo.ca/future-students/programs/software-engineering?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "87 - 93",
    "tutionValue": "$73,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Technology (Fast-Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/software-engineering-technology-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,044",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Software Engineering (BCS)",
    "programLink": "https://admissions.carleton.ca/programs/software-engineering-computer-science/",
    "entranceGrade": "80 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Software Engineering (BEng)",
    "programLink": "https://admissions.carleton.ca/programs/software-engineering-beng/",
    "entranceGrade": "75 - 85",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Software Engineering (BEng)",
    "programLink": "https://futurestudents.yorku.ca/program/software-engineering",
    "entranceGrade": "80 - 100",
    "tutionValue": "$37,345",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence - Software Engineering Technology (Optio...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/artificial-intelligence/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,064",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Technology",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/software-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,152",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Software Engineering (BCS)",
    "programLink": "https://admissions.carleton.ca/programs/software-engineering-computer-science/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Technician",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/software-engineering-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,152",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Technology",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/software-engineering-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,152",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Software Engineering (BEng)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/software-engineering.html",
    "entranceGrade": "85",
    "tutionValue": "$35,625",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Technician (Fast-Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/software-engineering-technician-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,044",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Software Engineering",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/engineering/software-engineering/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$39,808",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Technology",
    "programLink": "https://www.conestogac.on.ca/fulltime/software-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,361",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Technician",
    "programLink": "https://www.conestogac.on.ca/fulltime/software-engineering-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$16,361",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Software Engineering",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/engineering/software-engineering/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$39,808",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Technology",
    "programLink": "https://www.conestogac.on.ca/fulltime/software-engineering-technology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,113",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Software Engineering: Bachelor of Engineering in Software Engine...",
    "programLink": "https://www.tru.ca/programs/catalogue/bachelor-software-engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Technology",
    "programLink": "https://www.eng.mcmaster.ca/sept/programs/degree-options/btech/software-engineering-technology#overview",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Software Engineering - Engineering Management and Entrepreneursh...",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "87 - 89",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Software Engineering & Society",
    "programLink": "https://www.eng.mcmaster.ca/cas/programs/degree-options/bengsociety/software-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Canadian Business College - Toronto",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Diploma (SE)",
    "programLink": "https://canadianbusinesscollege.com/information-technology/software-engineering/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Software Engineering (MEng)",
    "programLink": "https://edgeip.com/admin/index.asp",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Software Engineering (B.A.)",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/software-engineering-faculty-arts",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Software Engineering",
    "programLink": "https://www.ucalgary.ca/future-students/undergraduate/explore-programs/software-engineering",
    "entranceGrade": "85 - 89",
    "tutionValue": "$20,172",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Specialization in Software Engineering (B.Sc....",
    "programLink": "https://www.trentu.ca/cois/programs/computing-systems/co-op-option",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Software Engineering (HBSc)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/computer-science-%E2%80%94-software-engineering-stream",
    "entranceGrade": "87 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Software Engineering",
    "programLink": "https://www.eng.mcmaster.ca/cas/programs/degree-options/beng/software-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Software Engineering",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Software Engineering",
    "programLink": "https://www.unb.ca/academics/programs/software-engineering/software_eng.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Software Engineering",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/software-engineering-co-op-sengc/",
    "entranceGrade": "85 - 90",
    "tutionValue": "$37,427",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Software Engineering (PhD)",
    "programLink": "https://www.concordia.ca/academics/graduate/software-engineering-phd.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence - Software Engineering Technology (Fast ...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/artificial-intelligence-fast-track-online",
    "entranceGrade": "N/S",
    "tutionValue": "$17,405",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Co-op",
    "programLink": "https://www.trentu.ca/futurestudents/program/software-engineering-co-op?target=undergraduate",
    "entranceGrade": "70 - 80",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Liberal Program in Software Engineering",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/software-engineering-faculty-science",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Specialization in Software Engineering (B.Sc....",
    "programLink": "https://www.trentu.ca/futurestudents/program/computer-science/software-engineering?target=undergraduate",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Software Engineering",
    "programLink": "https://www.unb.ca/academics/programs/software-engineering/software_eng.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Software Engineering (B.Sc.)",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/software-engineering-faculty-science",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Software Engineering",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/software-engineering-seng/",
    "entranceGrade": "85 - 90",
    "tutionValue": "$37,427",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "HBA/ Software Engineering",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science (Software Engineering) (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Software Engineering (B.Sc.)",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/software-engineering-faculty-science",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Computer Science - Software Engineering Option (B.Sc. Honours SE...",
    "programLink": "https://admissions.usask.ca/computer-science.php",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Law/ Software Engineering",
    "programLink": "https://www.westerncalendar.uwo.ca/Modules.cfm?ModuleID=21450&SelectedCalendar=Live&ArchiveID=",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "6 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence - Software Engineering Technology (Fast ...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/artificial-intelligence-fast-track",
    "entranceGrade": "N/S",
    "tutionValue": "$17,405",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Canadian Business College - Mississauga",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Diploma (SE)",
    "programLink": "https://canadianbusinesscollege.com/information-technology/software-engineering/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "40 Week(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence - Software Engineering Technology (Fast ...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/artificial-intelligence-fast-track-online",
    "entranceGrade": "N/S",
    "tutionValue": "$17,405",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence - Software Engineering Technology (Fast ...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/artificial-intelligence-fast-track",
    "entranceGrade": "N/S",
    "tutionValue": "$17,405",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Software Engineering (MASc)",
    "programLink": "https://www.concordia.ca/academics/graduate/software-engineering-masc.html",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science (Software Engineering) (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/computer-science/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Software Engineering (B.S.E.)",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/software-engineering-faculty-engineering",
    "entranceGrade": "96",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Computer Science with Software Engineering Specialization",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=21711",
    "entranceGrade": "75 - 100",
    "tutionValue": "$39,947",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Computer Science - Software Engineering",
    "programLink": "https://science.ucalgary.ca/computer-science/future-students/undergraduate/programs",
    "entranceGrade": "N/S",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Software Engineering (B.Eng.Management)",
    "programLink": "https://www.eng.mcmaster.ca/cas/programs/degree-options/bengmanagement/software-engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Canadian Business College - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Software Engineering Diploma (SE)",
    "programLink": "https://canadianbusinesscollege.com/information-technology/software-engineering/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "40 Week(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Software Engineering (HBSc)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/computer-science-%E2%80%94-software-engineering-stream",
    "entranceGrade": "87 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Software Engineering",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/software-engineering.php",
    "entranceGrade": "85 - 100",
    "tutionValue": "$31,493",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Software Engineering",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "87 - 89",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Data Science",
    "programLink": "https://futurestudents.yorku.ca/program/data-science",
    "entranceGrade": "75 - 100",
    "tutionValue": "$24,587",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Data Science (BA, BSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/arts-science-data-science.html",
    "entranceGrade": "70",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Data Science",
    "programLink": "https://uwaterloo.ca/future-students/programs/data-science?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "87 - 89",
    "tutionValue": "$59,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Data Science (BCompSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/computer-science-data-science.html",
    "entranceGrade": "70",
    "tutionValue": "$35,625",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Data Science (BDS)",
    "programLink": "https://admissions.carleton.ca/programs/data-science/",
    "entranceGrade": "85 - 88",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Data Science (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/data-science-bsc/index.html",
    "entranceGrade": "80 - 100",
    "tutionValue": "$35,796",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Data Science",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/ScienceTechnology/Departments/MathematicsComputing/Majors/Data-Science/index.htm",
    "entranceGrade": "85 - 90",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Data Science",
    "programLink": "https://ontariotechu.ca/programs/science/data-science.php",
    "entranceGrade": "82",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cornerstone Community College",
    "location": "Canada, British Columbia",
    "programName": "Data Science",
    "programLink": "https://ciccc.ca/programs/data-science/",
    "entranceGrade": "N/S",
    "tutionValue": "$12,995",
    "length": "18 Month(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver - Faculty of Graduate Studies",
    "location": "Canada, British Columbia",
    "programName": "Computational Linguistics (MDS) - Master of Data Science",
    "programLink": "https://www.grad.ubc.ca/prospective-students/graduate-degree-programs/master-of-data-science-computational-linguistics",
    "entranceGrade": "76",
    "tutionValue": "$49,644",
    "length": "10 Month(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Data Science",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/science.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "N/S"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science - Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Data Science and Analytics",
    "programLink": "https://science.ucalgary.ca/data-science/programs/professional-programs/mdsa",
    "entranceGrade": "83",
    "tutionValue": "$8,408",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Brock University",
    "location": "Canada, Ontario",
    "programName": "Data Sciences and Analytics (B.Sc)",
    "programLink": "https://brocku.ca/programs/undergraduate/data-sciences-and-analytics/",
    "entranceGrade": "N/S",
    "tutionValue": "$33,059",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Data Science",
    "programLink": "https://cmps.ok.ubc.ca/undergraduate/data-science/",
    "entranceGrade": "75",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver - Faculty of Graduate Studies",
    "location": "Canada, British Columbia",
    "programName": "Data Science (MDS) - Master of Data Science",
    "programLink": "https://www.grad.ubc.ca/prospective-students/graduate-degree-programs/master-of-data-science",
    "entranceGrade": "76",
    "tutionValue": "$49,644",
    "length": "10 Month(s)"
  },
  {
    "schoolName": "Thompson Rivers University - Graduate Programs",
    "location": "Canada, British Columbia",
    "programName": "Master of Science in Data Science",
    "programLink": "https://www.tru.ca/programs/catalogue/master-of-science-in-data-science.html#highlights",
    "entranceGrade": "N/S",
    "tutionValue": "$24,080",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Data Science",
    "programLink": "https://cmps.ok.ubc.ca/undergraduate/data-science/",
    "entranceGrade": "75",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Data Science",
    "programLink": "https://cmps.ok.ubc.ca/undergraduate/data-science/",
    "entranceGrade": "67",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Data Science",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "83 - 86",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Data Science (B.Sc.)",
    "programLink": "https://www.trentu.ca/futurestudents/program/data-science-bsc?target=undergraduate",
    "entranceGrade": "75",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Data Science",
    "programLink": "https://www.nipissingu.ca/academics/faculty-arts-and-science/data-science",
    "entranceGrade": "70 - 70",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Data Science (HBSc)",
    "programLink": "https://future.utoronto.ca/undergraduate-programs/data-science/",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Mathematics and Computer Science (Data Science)",
    "programLink": "https://catalogue.uottawa.ca/en/undergrad/honours-bsc-mathematics-honours-bsc-computer-science/",
    "entranceGrade": "90 - 92",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Data Science",
    "programLink": "https://cmps.ok.ubc.ca/undergraduate/data-science/",
    "entranceGrade": "70",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Computer Science - Data Science",
    "programLink": "https://www.uottawa.ca/faculty-engineering/",
    "entranceGrade": "83 - 86",
    "tutionValue": "$52,707",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Red River College Polytechnic",
    "location": "Canada, Manitoba",
    "programName": "Data Science and Machine Learning",
    "programLink": "https://catalogue.rrc.ca/Programs/WPG/FullTime/DATSF-DP",
    "entranceGrade": "N/S",
    "tutionValue": "$20,660",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Cybersecurity (BCS)",
    "programLink": "https://admissions.carleton.ca/programs/cybersecurity/",
    "entranceGrade": "85 - 88",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Cyber Security Analysis",
    "programLink": "https://www.algonquincollege.com/sat/program/cyber-security-analysis/",
    "entranceGrade": "65",
    "tutionValue": "$16,822",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Cybersecurity (BCyber)",
    "programLink": "https://admissions.carleton.ca/programs/bachelor-of-cybersecurity/",
    "entranceGrade": "85 - 88",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Computer Science for Software Development",
    "programLink": "https://futurestudents.yorku.ca/program/computer-science-for-software-development",
    "entranceGrade": "50 - 100",
    "tutionValue": "$25,934",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Cyber Security Analysis",
    "programLink": "https://www.algonquincollege.com/sat/program/cyber-security-analysis/",
    "entranceGrade": "65",
    "tutionValue": "$16,822",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Cybersecurity (BCS)",
    "programLink": "https://admissions.carleton.ca/programs/cybersecurity/",
    "entranceGrade": "85 - 88",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Industrial Network Cybersecurity",
    "programLink": "https://www.bcit.ca/programs/industrial-network-cybersecurity-diploma-full-time-5265dipma/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "CDI College (Calgary South Campus)",
    "location": "Canada, Alberta",
    "programName": "Cybersecurity Specialist",
    "programLink": "https://www.cdicollege.ca/study-on-campus/alberta/programs-and-courses/technology/cybersecurity-specialist/#gsc.tab=0",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "74 Week(s)"
  },
  {
    "schoolName": "New Brunswick Community College",
    "location": "Canada, New Brunswick",
    "programName": "Information Technology: Cybersecurity",
    "programLink": "https://nbcc.ca/programs-courses/program-details?baseCurriculumId=9d27ef52-5c83-439f-b8c1-2de19bd6b259",
    "entranceGrade": "N/S",
    "tutionValue": "$9,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Cybersecurity",
    "programLink": "https://cambriancollege.ca/programs/cybersecurity",
    "entranceGrade": "N/S",
    "tutionValue": "$17,608",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Continuing Education",
    "location": "Canada, Ontario",
    "programName": "Cybersecurity",
    "programLink": "https://continue.uwindsor.ca/524/cybersecurity",
    "entranceGrade": "N/S",
    "tutionValue": "$1,170",
    "length": "4 Month(s)"
  },
  {
    "schoolName": "Bow Valley College",
    "location": "Canada, Alberta",
    "programName": "Cybersecurity Post-Diploma Certificate",
    "programLink": "https://bowvalleycollege.ca/programs-courses/technology/cybersecurity-post-diploma-certificate",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "8 Month(s)"
  },
  {
    "schoolName": "Canadian College of Technology and Business",
    "location": "Canada, British Columbia",
    "programName": "Cybersecurity Risk Management with Co-op",
    "programLink": "https://gus.cvtr.io/lp/cctb-live-lp",
    "entranceGrade": "N/S",
    "tutionValue": "$36,000",
    "length": "146 Week(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Forensic Investigation (Digital Forensics and Cybersecurity Opti...",
    "programLink": "https://www.bcit.ca/programs/forensic-investigation-digital-forensics-and-cybersecurity-option-bachelor-of-technology-full-time-part-time-847cbtech/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Concordia University of Edmonton",
    "location": "Canada, Alberta",
    "programName": "Management - Cybersecurity Emphasis",
    "programLink": "https://concordia.ab.ca/management/bachelor/",
    "entranceGrade": "65",
    "tutionValue": "$25,414",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Futures Canadian College of Business, Health & Technology",
    "location": "Canada, Ontario",
    "programName": "Cybersecurity Boot Camp",
    "programLink": "https://www.futurescollege.ca/programs/technology/cybersecurity-boot-camp/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "12 Week(s)"
  },
  {
    "schoolName": "Bay River College",
    "location": "Canada, Alberta",
    "programName": "Cyber Security and Cloud Computing",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=106875",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "91 Week(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Cybersecurity",
    "programLink": "https://www.canadorecollege.ca/programs/cybersecurity",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Toronto School of Management",
    "location": "Canada, Ontario",
    "programName": "Diploma in Cybersecurity Specialist Co-op",
    "programLink": "https://www.torontosom.ca/programs/technology/diploma-in-cybersecurity-specialist-co-op",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Forensic Investigation (Digital Forensics and Cybersecurity Opti...",
    "programLink": "https://www.bcit.ca/programs/forensic-investigation-digital-forensics-and-cybersecurity-option-advanced-certificate-part-time-528cadcert/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science - Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Information Security and Privacy",
    "programLink": "https://science.ucalgary.ca/information-security/programs/professional-programs/misp",
    "entranceGrade": "83",
    "tutionValue": "$8,408",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence Software Development",
    "programLink": "https://www.algonquincollege.com/sat/program/artificial-intelligence-software-development/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,722",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence and Machine Learning (BCS)",
    "programLink": "https://admissions.carleton.ca/programs/ai-and-machine-learning/",
    "entranceGrade": "85 - 88",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence - Fast Track (Optional Co-op)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/artificial-intelligence-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,956",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence - Software Engineering Technology (Optio...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/artificial-intelligence/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,064",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Fredericton - Graduate Programs",
    "location": "Canada, New Brunswick",
    "programName": "Master of Business Administration (MBA) - Artificial Intelligenc...",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=115628",
    "entranceGrade": "70",
    "tutionValue": "$25,993",
    "length": "42 Month(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence - Software Engineering Technology (Fast ...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/artificial-intelligence-fast-track",
    "entranceGrade": "N/S",
    "tutionValue": "$17,405",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence Systems Engineering",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/engineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$63,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=112983",
    "entranceGrade": "65",
    "tutionValue": "$19,733",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence - Software Engineering Technology (Fast ...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/artificial-intelligence-fast-track-online",
    "entranceGrade": "N/S",
    "tutionValue": "$17,405",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Torbram College",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=107022",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "40 Hour(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence - Software Engineering Technology (Fast ...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/artificial-intelligence-fast-track-online",
    "entranceGrade": "N/S",
    "tutionValue": "$17,405",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Business Information Technology Management (Artificial Intellige...",
    "programLink": "https://www.bcit.ca/programs/business-information-technology-management-artificial-intelligence-management-option-diploma-full-time-623adipma/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Artificial Intelligence - Software Engineering Technology (Fast ...",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/artificial-intelligence-fast-track",
    "entranceGrade": "N/S",
    "tutionValue": "$17,405",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biology (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/biology-bsc/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Cell Biology",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-cell-biology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Climate and Environmental Change",
    "programLink": "https://uwaterloo.ca/future-students/programs/climate-environmental-change?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$48,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biology (BA)",
    "programLink": "https://admissions.carleton.ca/programs/biology-ba/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Molecular, Cellular and Developmental Biology",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-molecular-cellular-and-developmental-biology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/biomedical-engineering/",
    "entranceGrade": "80 - 85",
    "tutionValue": "$38,472",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Biotechnology - Advanced",
    "programLink": "https://www.algonquincollege.com/sat/program/biotechnology-advanced/",
    "entranceGrade": "N/S",
    "tutionValue": "$15,022",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biology (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/biology-bsc/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Cell and Molecular Biology (BSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/cell-molecular-biology.html",
    "entranceGrade": "85",
    "tutionValue": "$32,525",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Biology",
    "programLink": "https://uwaterloo.ca/future-students/programs/biology?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$50,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Earth Sciences and Biology (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/earth-sciences/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Honours Science (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/science-bsc-waterloo/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Biology / Biologie",
    "programLink": "https://futurestudents.yorku.ca/program/biology/glendon",
    "entranceGrade": "75 - 100",
    "tutionValue": "$24,587",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Ecology, Evolution and Environmental Biology",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-ecology-evolution-and-environmental-biology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Neuroscience and Biology (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/neuroscience-and-mental-health/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Earth Sciences and Biology (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/earth-sciences/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biotechnology (BSc with Biochemistry OR Biology)",
    "programLink": "https://admissions.carleton.ca/programs/biotechnology/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics and Biology (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Biomedical Sciences",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/biomedical-sciences/",
    "entranceGrade": "88 - 94",
    "tutionValue": "$31,811",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry/Biotechnology (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/biochemistry-biotechnology-bsc/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Immunology and Infection (BSc with Specialization)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-immunology-and-infection.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Neuroscience and Biology (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/neuroscience-and-mental-health/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Molecular, Cellular and Developmental Biology (BSc with Speciali...",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-molecular-cellular-and-developmental-biology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Nursing (BScN)",
    "programLink": "https://admissions.carleton.ca/programs/nursing/",
    "entranceGrade": "85 - 88",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics and Biology (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Immunology and Infection",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-immunology-and-infection.html",
    "entranceGrade": "80",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Ecology, Evolution and Environmental Biology (BSc with Specializ...",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-ecology-evolution-and-environmental-biology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Environmental Biology (BSc)",
    "programLink": "https://futurestudents.yorku.ca/program/environmental-biology",
    "entranceGrade": "80 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biology (BA)",
    "programLink": "https://admissions.carleton.ca/programs/biology-ba/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Biology",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/ScienceTechnology/Departments/Biology/Majors/Biology/index.htm",
    "entranceGrade": "85 - 90",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Biology (Honours)",
    "programLink": "https://futurestudents.yorku.ca/program/biology/science",
    "entranceGrade": "80 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Cell Biology",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-cell-biology.html",
    "entranceGrade": "80 - 85",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Conservation Biology",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-environmental-and-conservation-sciences-conservation-biology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Biomedical Engineering (Five-Year Co-operative Internship Progra...",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/biomedical-engineering/",
    "entranceGrade": "80 - 85",
    "tutionValue": "$38,472",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Biology (BA)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/biology-ba/index.html",
    "entranceGrade": "75",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Biology (BSc/BEd - Secondary) (Augustana Faculty)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-elementary-education-combined-degree.html",
    "entranceGrade": "N/S",
    "tutionValue": "$27,540",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biology (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/biology-bsc/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biotechnology (BSc with Biochemistry OR Biology)",
    "programLink": "https://admissions.carleton.ca/programs/biotechnology/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Biology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/biology/",
    "entranceGrade": "76 - 85",
    "tutionValue": "$31,811",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Biology (BSc) (Augustana Faculty)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-integrative-biology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$27,540",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Science Foundations (Cert)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/science-foundations.html",
    "entranceGrade": "65",
    "tutionValue": "$32,450",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Systems and Information Biology (BSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/systems-information-biology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Biology (BSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/biology.html",
    "entranceGrade": "85",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Biotechnology - Advanced",
    "programLink": "https://www.algonquincollege.com/sat/program/biotechnology-advanced/",
    "entranceGrade": "N/S",
    "tutionValue": "$15,022",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Biology (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/biology-bsc/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Mathematical Biology (BSc)",
    "programLink": "https://futurestudents.yorku.ca/program/mathematical-biology",
    "entranceGrade": "75 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Humanities and Biology (BHum Combined Honours)",
    "programLink": "https://admissions.carleton.ca/programs/humanities/",
    "entranceGrade": "80 - 84",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Biology (B.Sc.)",
    "programLink": "https://www.unb.ca/academics/programs/science/biology-sj.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Alexander College",
    "location": "Canada, British Columbia",
    "programName": "Associate of Science (Mathematics)",
    "programLink": "https://alexandercollege.ca/programs-and-courses/associate-of-science-mathematics-degree/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,300",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Biology-Psychology",
    "programLink": "https://www.unb.ca/academics/programs/science/biology-psychology.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Biology (B.A.)",
    "programLink": "https://www.unb.ca/academics/programs/arts/biology.html",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Alexander College",
    "location": "Canada, British Columbia",
    "programName": "Associate of Science",
    "programLink": "https://alexandercollege.ca/programs-and-courses/associate-of-science/?utm_campaign=study-in-canada-partner&utm_source=study-in-canada-website",
    "entranceGrade": "N/S",
    "tutionValue": "$18,300",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Marine Biology",
    "programLink": "https://www.unb.ca/academics/programs/science/marine-biology.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Environmental Biology",
    "programLink": "https://www.unb.ca/academics/programs/science/environmental-biology.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Biology-Psychology",
    "programLink": "https://www.unb.ca/academics/programs/science/biology-psychology.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Biological Science",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/biological-science/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Chemical Biology",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/chemical-biology/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Fleming College",
    "location": "Canada, Ontario",
    "programName": "Conservation Biology",
    "programLink": "https://flemingcollege.ca/programs/conservation-biology",
    "entranceGrade": "N/S",
    "tutionValue": "$19,288",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Biological Science",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/biological-science/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Life Sciences",
    "programLink": "https://uoit.ca/programs/science/life-sciences-regular-and-co-op.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Biological Science - Advanced Entry",
    "programLink": "https://ontariotechu.ca/programs/science/biological-science-advanced-entry.php",
    "entranceGrade": "75 - 100",
    "tutionValue": "$29,196",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Chemical Biology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=100708",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Environmental Biology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=100713",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Marine Biology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=111106",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Forensic Science",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=21388",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Life Sciences",
    "programLink": "https://uoit.ca/programs/science/life-sciences-regular-and-co-op.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Environmental Biology",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/environmental-biology/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Biology (MSc)",
    "programLink": "https://graduate.carleton.ca/cu-programs/biology-masters/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Biology",
    "programLink": "https://www.nipissingu.ca/academics/faculty-arts-and-science/studies-biology-and-chemistry/biology",
    "entranceGrade": "70 - 70",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Biological Sciences",
    "programLink": "https://www.ulethbridge.ca/future-student/graduate-studies/master-science/biological-sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Developmental Biology (HBSc)",
    "programLink": "https://future.utoronto.ca/undergraduate-programs/developmental-biology/",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Microbiology and Immunology (BSc)",
    "programLink": "https://www.dal.ca/study/programs/undergraduate/microbiology-immunology-bsc.html",
    "entranceGrade": "75",
    "tutionValue": "$28,473",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Neuroscience",
    "programLink": "https://www.ulethbridge.ca/future-student/graduate-studies/master-science/neuroscience",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Cellular, Molecular and Microbial Biology",
    "programLink": "https://www.ucalgary.ca/future-students/undergraduate/explore-programs/cellular-molecular-microbial-biology",
    "entranceGrade": "93",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Human Biology (HBSc)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/human-biology",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Biochemistry",
    "programLink": "https://www.ucalgary.ca/future-students/undergraduate/explore-programs/biochemistry",
    "entranceGrade": "90",
    "tutionValue": "$25,530",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (HBSc)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/biochemistry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Biology - Biomedical Science Stream (3 Year)",
    "programLink": "https://www.brandonu.ca/future-students/programs/degrees/science/biology/",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Life Sciences",
    "programLink": "https://healthsci.queensu.ca/liscbchm/life_sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biology - Cellular / Molecular",
    "programLink": "https://www.uottawa.ca/faculty-science/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biology - Cellular / Molecular (Research Focus)",
    "programLink": "https://www.uottawa.ca/faculty-science/",
    "entranceGrade": "N/S",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Cellular, Anatomical, & Physiological Sciences",
    "programLink": "https://you.ubc.ca/ubc_programs/cellular-anatomical-physiological-sciences/",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry and Molecular Biology",
    "programLink": "https://www.unbc.ca/programs/biochemistry-and-molecular-biology",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Bioinformatics",
    "programLink": "https://www.lakeheadu.ca/programs/undergraduate-programs/bioinformatics/node/3364",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Medical Laboratory Technology",
    "programLink": "https://catalog.cambriancollege.ca/mdly/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Agricultural Biotechnology",
    "programLink": "https://www.ulethbridge.ca/future-student/graduate-studies/master-science/agricultural-biotechnology",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Biological Sciences",
    "programLink": "https://umanitoba.ca/student/admissions/programs/biological-sciences.html",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Chemistry (Biological)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/chemistry-biological/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Biomedical Engineering",
    "programLink": "https://you.ubc.ca/ubc_programs/biomedical-engineering/",
    "entranceGrade": "N/S",
    "tutionValue": "$54,987",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Biology",
    "programLink": "https://www2.acadiau.ca/academics/undergraduate/biology.html",
    "entranceGrade": "75",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Biomedical Engineering Technology",
    "programLink": "https://www.bcit.ca/programs/biomedical-engineering-technology-diploma-full-time-5063dipma/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Biology - Biological Science Stream",
    "programLink": "https://www.brandonu.ca/future-students/programs/degrees/science/biology/",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cape Breton University",
    "location": "Canada, Nova Scotia",
    "programName": "Biology",
    "programLink": "https://www.cbu.ca/academics/programs/biology/",
    "entranceGrade": "65",
    "tutionValue": "$19,579",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "College of the Rockies",
    "location": "Canada, British Columbia",
    "programName": "Biology and Medicine Pre-Major",
    "programLink": "https://cotr.bc.ca/programs/biology-and-medicine-pre-major/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Agricultural Biology (B.S.A.)",
    "programLink": "https://admissions.usask.ca/agricultural-biology.php",
    "entranceGrade": "70",
    "tutionValue": "$18,391",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://healthsci.queensu.ca/liscbchm/biochemistry",
    "entranceGrade": "85 - 100",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Bioinformatics (B.Sc. Four-year)",
    "programLink": "https://admissions.usask.ca/bioinformatics.php",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Molecular Biology (B.Sc.)",
    "programLink": "https://www.trentu.ca/futurestudents/program/biochemistry-molecular-biology?target=undergraduate",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biology (Research Focus)",
    "programLink": "https://www.uottawa.ca/faculty-science/",
    "entranceGrade": "N/S",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biology - Ecology / Evolution / Behaviour",
    "programLink": "https://www.uottawa.ca/faculty-science/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Biology - Biomedical Science Stream",
    "programLink": "https://www.brandonu.ca/future-students/programs/degrees/science/biology/",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Biology",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/biology",
    "entranceGrade": "95",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Biological Sciences",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=4590",
    "entranceGrade": "70 - 100",
    "tutionValue": "$37,497",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Molecular Biology and Genetics",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/molecular-biology-genetics-co-op-mbg-c/#text",
    "entranceGrade": "80 - 83",
    "tutionValue": "$32,599",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Biochemistry",
    "programLink": "https://www.ulethbridge.ca/future-student/graduate-studies/master-science/biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Microbiology",
    "programLink": "https://www.uvic.ca/undergraduate/programs/undergraduate-programs/pages/microbiology.php",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry with Option in Microbiology and Immunology",
    "programLink": "https://www.uottawa.ca/faculty-science/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Biochemistry",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/biochemistry",
    "entranceGrade": "95",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Biology",
    "programLink": "https://www.unb.ca/academics/programs/science/biology.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Biologie - Biomédicale",
    "programLink": "https://laurentienne.ca/programme/biologie-biomedicale/details",
    "entranceGrade": "70",
    "tutionValue": "$25,745",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Biochemistry",
    "programLink": "https://www.uregina.ca/academics/programs/science/biochemistry.html",
    "entranceGrade": "70 - 100",
    "tutionValue": "$12,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biology - Physiology",
    "programLink": "https://www.uottawa.ca/faculty-science/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biology",
    "programLink": "https://www.mun.ca/undergrad/programs/science/biology/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Microbiology",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/microbiology-micr/#text",
    "entranceGrade": "78 - 84",
    "tutionValue": "$32,599",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://selkirk.ca/search?search=program%20biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$13,409",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "York University - Glendon Campus",
    "location": "Canada, Ontario",
    "programName": "Biology (iBSc)",
    "programLink": "https://www.yorku.ca/glendon/biology/",
    "entranceGrade": "80",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Neuroscience",
    "programLink": "https://www.ulethbridge.ca/future-student/graduate-studies/master-science/neuroscience",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Marine and Freshwater Biology",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/marine-freshwater-biology-mfb/",
    "entranceGrade": "78 - 84",
    "tutionValue": "$32,599",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Biology (M.Sc.)",
    "programLink": "https://www.lakeheadu.ca/programs/graduate/programs/masters/biology/node/7277",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Medical Microbiology and Infectious Diseases (PhD)",
    "programLink": "https://umanitoba.ca/explore/programs-of-study/medical-microbiology-and-infectious-diseases-phd",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Trinity Western University",
    "location": "Canada, British Columbia",
    "programName": "Biology",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=2160",
    "entranceGrade": "67 - 100",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Biology",
    "programLink": "https://uwaterloo.ca/academic-calendar/graduate-studies/catalog#/programs/rk4gbyAAi2",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Biology and Psychology",
    "programLink": "https://biology.queensu.ca/prospective-students/undergraduate-students/degree-plans/biology-and-psychology-ssp",
    "entranceGrade": "80 - 100",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Associate of Science Degree",
    "programLink": "https://www.tru.ca/science/programs/associate.html",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biology/Earth Sciences (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/biology/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biomedical Science (B.Sc.)",
    "programLink": "https://www.trentu.ca/futurestudents/program/biomedical-science?target=undergraduate",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Microbiology & Immunology",
    "programLink": "https://you.ubc.ca/ubc_programs/microbiology-immunology/",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences with Biology",
    "programLink": "https://welcome.uwo.ca/what-can-i-study/undergraduate-programs/health-sciences.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biology - Physiology",
    "programLink": "https://www.uottawa.ca/faculty-science/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences (Biomedical Sciences Specialization)",
    "programLink": "https://future.mcmaster.ca/programs/health-sciences/",
    "entranceGrade": "90",
    "tutionValue": "$48,996",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University of Edmonton",
    "location": "Canada, Alberta",
    "programName": "Biology (B.Sc. 4-year)",
    "programLink": "https://concordia.ab.ca/science/bachelor/biology/",
    "entranceGrade": "60",
    "tutionValue": "$25,414",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biology",
    "programLink": "https://www.uottawa.ca/faculty-science/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Applied Life Sciences",
    "programLink": "https://www.lakeheadu.ca/programs/undergraduate-programs/applied-life-sciences/node/18",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry with Option in Synthetic Biology",
    "programLink": "https://www.uottawa.ca/faculty-science/",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Environmental Biology",
    "programLink": "https://www.uregina.ca/academics/programs/science/environmental-biology.html",
    "entranceGrade": "65",
    "tutionValue": "$12,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Biochemistry, Microbiology and Immunology [B.Sc. (BMSC) Four-yea...",
    "programLink": "https://admissions.usask.ca/biochemistry-microbiology-and-immunology.php",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Life Sciences: Plant Biology",
    "programLink": "https://www.mcgill.ca/undergraduate-admissions/program/plant-biology",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Biology (HBSc)",
    "programLink": "https://www.utsc.utoronto.ca/admissions/programs/biology",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biology",
    "programLink": "https://www.mun.ca/undergrad/programs/science/biology/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Biological Sciences",
    "programLink": "https://www.ucalgary.ca/future-students/undergraduate/explore-programs/biological-sciences",
    "entranceGrade": "90",
    "tutionValue": "$25,530",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Cellular, Anatomical, & Physiological Sciences",
    "programLink": "https://you.ubc.ca/ubc_programs/cellular-anatomical-physiological-sciences/",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Biology",
    "programLink": "https://www.smu.ca/future-students/bsc-programs/biology-program.html",
    "entranceGrade": "70 - 100",
    "tutionValue": "$22,060",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Faculty of Medicine at the University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Biochemistry and Molecular Biology",
    "programLink": "https://cumming.ucalgary.ca/gse/about/programs/biochemistry-and-molecular-biology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,389",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "MacEwan University",
    "location": "Canada, Alberta",
    "programName": "Biological Sciences",
    "programLink": "https://www.macewan.ca/academics/academic-departments/biological-sciences/academics/biological-sciences-major/",
    "entranceGrade": "67 - 76",
    "tutionValue": "$24,378",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biochemistry/Cell Biology (BSc)",
    "programLink": "https://www.mun.ca/undergrad/programs/science/biochemistry.php",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Marine Biology",
    "programLink": "https://www.mun.ca/undergrad/programs/science/marine-biology/",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Chemical Laboratory Technician",
    "programLink": "https://www.senecapolytechnic.ca/programs/fulltime/CLT.html",
    "entranceGrade": "N/S",
    "tutionValue": "$16,738",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Biotechnology - Advanced",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=17010",
    "entranceGrade": "N/S",
    "tutionValue": "$16,249",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Integrated Science with Biology",
    "programLink": "https://www.uwo.ca/sci/wisc/",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Biology",
    "programLink": "https://www2.acadiau.ca/academics/undergraduate/biology.html",
    "entranceGrade": "75",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biology - Specialization in Conservation Biology (B.Sc.)",
    "programLink": "https://www.trentu.ca/futurestudents/program/biology?target=undergraduate",
    "entranceGrade": "N/S",
    "tutionValue": "$28,285",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Biological Sciences",
    "programLink": "https://www.uwindsor.ca/graduate-studies/309/biological-sciences?elqTrackId=c609190339184621b152b9b4ce9d8538&elqaid=414&elqat=2",
    "entranceGrade": "70 - 100",
    "tutionValue": "$8,863",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Bioinformatics",
    "programLink": "https://www.ucalgary.ca/future-students/undergraduate/explore-programs/bioinformatics",
    "entranceGrade": "86",
    "tutionValue": "$25,530",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Science | Biological Sciences",
    "programLink": "https://www.ulethbridge.ca/future-student/program/biological-sciences",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Biology",
    "programLink": "https://www.unb.ca/academics/programs/science/biology.html",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Biology",
    "programLink": "https://www.ufv.ca/biology/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Biology",
    "programLink": "https://www2.acadiau.ca/academics/undergraduate/biology.html",
    "entranceGrade": "75",
    "tutionValue": "$21,620",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Biology: Bachelor of Science Degree - Major or Honours",
    "programLink": "https://www.tru.ca/programs/catalogue/biology-major.html",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Biology - Biomedical Science Stream",
    "programLink": "https://www.brandonu.ca/future-students/programs/degrees/science/biology/",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Microbiology",
    "programLink": "https://www.studyincanada.com/Programs/13502/University-of-Manitoba-Faculty-of-Graduate-Studies/Microbiology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Microbiology",
    "programLink": "https://www.studyincanada.com/Programs/13498/University-of-Manitoba/Microbiology",
    "entranceGrade": "85 - 85",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/4293/McMaster-University/Biochemistry",
    "entranceGrade": "80 - 86",
    "tutionValue": "$48,996",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "MacEwan University",
    "location": "Canada, Alberta",
    "programName": "Biological Sciences",
    "programLink": "https://www.studyincanada.com/Programs/109497/MacEwan-University/Biological-Sciences",
    "entranceGrade": "67 - 76",
    "tutionValue": "$24,378",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Biology (B.Sc.) - Biodiversity and Ecology",
    "programLink": "https://www.studyincanada.com/Programs/70745/Bishop-s-University/Biology-(B.Sc.)-Biodiversity-and-Ecology",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Biotechnology (Health)",
    "programLink": "https://www.mohawkcollege.ca/programs/technology/biotechnology-370",
    "entranceGrade": "N/S",
    "tutionValue": "$17,335",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/77534/The-University-of-British-Columbia-Vancouver/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Biology",
    "programLink": "https://www.studyincanada.com/Programs/33472/Saint-Mary-s-University/Biology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$22,060",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Biology",
    "programLink": "https://www.studyincanada.com/Programs/2485/Lakehead-University-Thunder-Bay/Biology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (BSc)",
    "programLink": "https://futurestudents.yorku.ca/program/biochemistry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/biochemistry/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biotechnology (BSc with Biochemistry OR Biology)",
    "programLink": "https://admissions.carleton.ca/programs/biotechnology/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Biochemistry",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-biochemistry.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biotechnology (BSc with Biochemistry OR Biology)",
    "programLink": "https://admissions.carleton.ca/programs/biotechnology/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry/Biotechnology (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/biochemistry-biotechnology-bsc/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://uwaterloo.ca/future-students/programs/biochemistry?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$50,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Computational Biochemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/computational-biochemistry/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/biochemistry/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Computational Biochemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/computational-biochemistry/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/biochemistry/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Biochemistry (BSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/biochemistry.html",
    "entranceGrade": "85",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Biochemistry",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-biochemistry.html",
    "entranceGrade": "80",
    "tutionValue": "$30,090",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Chemical Biology",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/chemical-biology/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Chemical Biology",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/chemical-biology/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Molecular Biology - Specialization in Health Sc...",
    "programLink": "https://www.studyincanada.com/Programs/105836/Trent-University/Biochemistry-and-Molecular-Biology-Specialization-in-Health-Sc...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/78460/University-of-Northern-British-Columbia/Chemistry",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry and Molecular Biology",
    "programLink": "https://www.studyincanada.com/Programs/29898/The-University-of-British-Columbia-Okanagan/Biochemistry-and-Molecular-Biology",
    "entranceGrade": "67",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biochemistry/Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/81219/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Biochemistry/Physics-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biochemistry/Behavioural Neuroscience (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/106435/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Biochemistry/Behavioural-Neuroscience-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/12326/University-of-Victoria/Biochemistry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Chemical Laboratory Technician",
    "programLink": "https://www.studyincanada.com/Programs/4914/Seneca-Polytechnic/Chemical-Laboratory-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$16,738",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Biochemistry (Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/27077/The-University-of-Winnipeg/Biochemistry-(Three-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/77532/The-University-of-British-Columbia-Vancouver/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Liberal Program in Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/65657/McGill-University/Liberal-Program-in-Biochemistry",
    "entranceGrade": "95",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Biology (B.Sc. Double Honours) with Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/69474/University-of-Saskatchewan/Biology-(B.Sc.-Double-Honours)-with-Biochemistry",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (Biotechnology) and Chemical Engineering (Biotechno...",
    "programLink": "https://www.studyincanada.com/Programs/96557/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biochemistry-(Biotechnology)-and-Chemical-Engineering-(Biotechno...",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "6 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry and Molecular Biology",
    "programLink": "https://www.studyincanada.com/Programs/78459/University-of-Northern-British-Columbia/Biochemistry-and-Molecular-Biology",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/77534/The-University-of-British-Columbia-Vancouver/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/biochemistry-co-op-biocc/",
    "entranceGrade": "80 - 83",
    "tutionValue": "$32,599",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/59938/University-of-Manitoba/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (Biomedical Research Specialization)",
    "programLink": "https://www.studyincanada.com/Programs/81641/McMaster-University/Biochemistry-(Biomedical-Research-Specialization)",
    "entranceGrade": "80 - 86",
    "tutionValue": "$48,996",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/4788/Laurentian-University/Universite-Laurentienne/Biochemistry-(HBSc)",
    "entranceGrade": "70",
    "tutionValue": "$25,745",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/37367/University-of-Manitoba/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/100105/Laurentian-University/Universite-Laurentienne/Biochemistry-(BSc)",
    "entranceGrade": "70",
    "tutionValue": "$25,745",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Biochemistry and Medical Genetics (PhD)",
    "programLink": "https://www.studyincanada.com/Programs/111601/University-of-Manitoba-Faculty-of-Graduate-Studies/Biochemistry-and-Medical-Genetics-(PhD)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (Biomedical Research Specialization)",
    "programLink": "https://www.studyincanada.com/Programs/81640/McMaster-University/Biochemistry-(Biomedical-Research-Specialization)",
    "entranceGrade": "80 - 86",
    "tutionValue": "$48,996",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Biochemistry and Medical Genetics (MSc)",
    "programLink": "https://www.studyincanada.com/Programs/111600/University-of-Manitoba-Faculty-of-Graduate-Studies/Biochemistry-and-Medical-Genetics-(MSc)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/6998/University-of-Regina/Biochemistry",
    "entranceGrade": "70 - 100",
    "tutionValue": "$12,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biochemistry/Chemistry (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/81181/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Biochemistry/Chemistry-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa - Faculty of Medicine",
    "location": "Canada, Ontario",
    "programName": "MD / PhD - Medicine and Philosophy",
    "programLink": "https://www.studyincanada.com/Programs/28880/University-of-Ottawa/Universit%c3%a9-d-Ottawa-Faculty-of-Medicine/MD-/-PhD-Medicine-and-Philosophy",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "7 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Bioinformatics (B.Sc. Four-year)",
    "programLink": "https://www.studyincanada.com/Programs/27128/University-of-Saskatchewan/Bioinformatics-(B.Sc.-Four-year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/55655/Bishop-s-University/Biochemistry",
    "entranceGrade": "75",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Biochemistry, Microbiology and Immunology [B.Sc. (BMSC) Four-yea...",
    "programLink": "https://www.studyincanada.com/Programs/106125/University-of-Saskatchewan/Biochemistry,-Microbiology-and-Immunology-[B.Sc.-(BMSC)-Four-yea...",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.ufv.ca/chemistry/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Molecular Biology - Specialization in Conservat...",
    "programLink": "https://www.studyincanada.com/Programs/105838/Trent-University/Biochemistry-and-Molecular-Biology-Specialization-in-Conservat...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/4293/McMaster-University/Biochemistry",
    "entranceGrade": "80 - 86",
    "tutionValue": "$48,996",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Molecular Biology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/103/Trent-University/Biochemistry-and-Molecular-Biology-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (Biotechnology) and Chemical Engineering (Biotechno...",
    "programLink": "https://www.studyincanada.com/Programs/59181/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biochemistry-(Biotechnology)-and-Chemical-Engineering-(Biotechno...",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Biological Chemistry (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/101912/University-of-Toronto-Scarborough/Biological-Chemistry-(HBSc)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry with Option in Chemical Biology",
    "programLink": "https://www.studyincanada.com/Programs/87301/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biochemistry-with-Option-in-Chemical-Biology",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Genetics and Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/32028/Western-University/Genetics-and-Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/99373/University-of-Calgary/Biochemistry",
    "entranceGrade": "90",
    "tutionValue": "$25,530",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Pathology of Human Disease",
    "programLink": "https://www.studyincanada.com/Programs/85086/Western-University/Biochemistry-and-Pathology-of-Human-Disease",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/7351/McGill-University/Biochemistry",
    "entranceGrade": "95",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Nutrition - Health and Disease",
    "programLink": "https://www.studyincanada.com/Programs/78140/McGill-University/Nutrition-Health-and-Disease",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Redeemer University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/89405/Redeemer-University/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$19,494",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry with Option in Microbiology and Immunology",
    "programLink": "https://www.studyincanada.com/Programs/66386/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biochemistry-with-Option-in-Microbiology-and-Immunology",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/10860/Selkirk-College/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$13,409",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Biochemistry & Molecular Biology",
    "programLink": "https://www.studyincanada.com/Programs/2721/Dalhousie-University/Biochemistry-&-Molecular-Biology",
    "entranceGrade": "75",
    "tutionValue": "$23,891",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/biochemistry-bioc/",
    "entranceGrade": "78 - 84",
    "tutionValue": "$32,599",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/27156/University-of-Victoria/Biochemistry",
    "entranceGrade": "80",
    "tutionValue": "$27,949",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Integrated Science (Biochemistry Concentration)",
    "programLink": "https://www.studyincanada.com/Programs/81669/McMaster-University/Integrated-Science-(Biochemistry-Concentration)",
    "entranceGrade": "87 - 89",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Faculty of Medicine at the University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Biochemistry and Molecular Biology",
    "programLink": "https://www.studyincanada.com/Programs/15874/Faculty-of-Medicine-at-the-University-of-Calgary/Biochemistry-and-Molecular-Biology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,389",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8220/University-of-Toronto-St.-George/Biochemistry-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Faculty of Medicine at the University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Biochemistry and Molecular Biology",
    "programLink": "https://www.studyincanada.com/Programs/15852/Faculty-of-Medicine-at-the-University-of-Calgary/Biochemistry-and-Molecular-Biology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,389",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Science | Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/2944/University-of-Lethbridge/Bachelor-of-Science-|-Biochemistry",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/1759/The-University-of-British-Columbia-Vancouver/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/77533/The-University-of-British-Columbia-Vancouver/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/49597/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biochemistry",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/5834/Queen-s-University/Biochemistry",
    "entranceGrade": "85 - 100",
    "tutionValue": "$55,567",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Physics (B.Sc. Double Honours) with Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/69564/University-of-Saskatchewan/Physics-(B.Sc.-Double-Honours)-with-Biochemistry",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's School of Medicine",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Cell Biology (PhD)",
    "programLink": "https://www.studyincanada.com/Programs/105333/Queen-s-School-of-Medicine/Biochemistry-and-Cell-Biology-(PhD)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry and Molecular Biology",
    "programLink": "https://www.studyincanada.com/Programs/81545/The-University-of-British-Columbia-Okanagan/Biochemistry-and-Molecular-Biology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Chemistry & Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/19133/University-of-Windsor-Faculty-of-Graduate-Studies/Chemistry-&-Biochemistry",
    "entranceGrade": "70 - 100",
    "tutionValue": "$8,863",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Chemistry & Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/19137/University-of-Windsor-Faculty-of-Graduate-Studies/Chemistry-&-Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/44618/University-of-Toronto-Scarborough/Biochemistry-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's School of Medicine",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Cell Biology (MSc)",
    "programLink": "https://www.studyincanada.com/Programs/105332/Queen-s-School-of-Medicine/Biochemistry-and-Cell-Biology-(MSc)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Cancer Biology",
    "programLink": "https://www.studyincanada.com/Programs/91950/Western-University/Biochemistry-and-Cancer-Biology",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/7486/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Biochemistry",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Nutrition - Nutritional Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/65595/McGill-University/Nutrition-Nutritional-Biochemistry",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "HBA/ Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/107691/Western-University/HBA/-Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry and Molecular Biology",
    "programLink": "https://www.studyincanada.com/Programs/3195/University-of-Northern-British-Columbia/Biochemistry-and-Molecular-Biology",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry with Option in Synthetic Biology",
    "programLink": "https://www.studyincanada.com/Programs/87304/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biochemistry-with-Option-in-Synthetic-Biology",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/3640/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biochemistry",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/113868/University-of-Calgary-Faculty-of-Science/Biochemistry",
    "entranceGrade": "93",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry with Option in Chemical Biology",
    "programLink": "https://www.studyincanada.com/Programs/87302/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biochemistry-with-Option-in-Chemical-Biology",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Allison University",
    "location": "Canada, New Brunswick",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/9648/Mount-Allison-University/Biochemistry",
    "entranceGrade": "65",
    "tutionValue": "$20,885",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Medical Laboratory Technology",
    "programLink": "https://www.studyincanada.com/Programs/27214/Cambrian-College/Medical-Laboratory-Technology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/37416/University-of-Manitoba/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Molecular Biology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/102/Trent-University/Biochemistry-and-Molecular-Biology-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry and Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/107527/University-of-Victoria/Biochemistry-and-Chemistry",
    "entranceGrade": "80",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Physics and Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/90543/University-of-Manitoba/Physics-and-Biochemistry",
    "entranceGrade": "85 - 85",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/3042/Western-University/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Biochemistry (Four-Year)",
    "programLink": "https://www.studyincanada.com/Programs/41105/The-University-of-Winnipeg/Biochemistry-(Four-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/16887/The-University-of-Winnipeg/Biochemistry",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/65673/McGill-University/Biochemistry",
    "entranceGrade": "95",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biochemistry/Cell Biology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/95871/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Biochemistry/Cell-Biology-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/49595/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biochemistry",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Biochemistry, Microbiology and Immunology [B.Sc. (BMSC) Honours]...",
    "programLink": "https://www.studyincanada.com/Programs/106131/University-of-Saskatchewan/Biochemistry,-Microbiology-and-Immunology-[B.Sc.-(BMSC)-Honours]...",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Chemistry (Biological)",
    "programLink": "https://www.studyincanada.com/Programs/106418/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Chemistry-(Biological)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Health Sciences",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/88031/McMaster-University-Faculty-of-Health-Sciences/Biochemistry",
    "entranceGrade": "77",
    "tutionValue": "$17,096",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/2945/University-of-Lethbridge-School-of-Graduate-Studies/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/55661/Bishop-s-University/Biochemistry",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry of Infection and Immunity",
    "programLink": "https://www.studyincanada.com/Programs/56368/Western-University/Biochemistry-of-Infection-and-Immunity",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/12212/University-of-Victoria/Biochemistry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/101910/University-of-Toronto-Scarborough/Biochemistry-(HBSc)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Bioinformatics",
    "programLink": "https://www.studyincanada.com/Programs/32215/Western-University/Bioinformatics",
    "entranceGrade": "N/S",
    "tutionValue": "$54,700",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/3222/University-of-Northern-British-Columbia/Chemistry",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Biochimie (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/78348/Laurentian-University/Universite-Laurentienne/Biochimie-(BSc)",
    "entranceGrade": "70",
    "tutionValue": "$25,745",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Bioinformatics",
    "programLink": "https://www.studyincanada.com/Programs/58938/University-of-Saskatchewan/Bioinformatics",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/37414/University-of-Manitoba/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/56375/Western-University/Biochemistry-and-Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/36607/Queen-s-University/Biochemistry",
    "entranceGrade": "85 - 100",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Health Sciences",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/88037/McMaster-University-Faculty-of-Health-Sciences/Biochemistry",
    "entranceGrade": "77",
    "tutionValue": "$6,307",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/81226/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Biochemistry",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Medical Biophysics and Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/85083/Western-University/Medical-Biophysics-and-Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/104963/Queen-s-University/Biochemistry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$55,567",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Biological Chemistry (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/44619/University-of-Toronto-Scarborough/Biological-Chemistry-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry and Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/107528/University-of-Victoria/Biochemistry-and-Chemistry",
    "entranceGrade": "80",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Cell Biology",
    "programLink": "https://www.studyincanada.com/Programs/56357/Western-University/Biochemistry-and-Cell-Biology",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/64916/University-of-Windsor/Biochemistry",
    "entranceGrade": "70 - 100",
    "tutionValue": "$37,497",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/2504/Lakehead-University-Thunder-Bay/Chemistry",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry with Option in Synthetic Biology",
    "programLink": "https://www.studyincanada.com/Programs/87303/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biochemistry-with-Option-in-Synthetic-Biology",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/112476/University-of-Lethbridge-School-of-Graduate-Studies/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Computational Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/85085/Western-University/Computational-Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Nursing - Part-time",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/nursing-post-diploma/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Nursing",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/nursing-post-diploma/",
    "entranceGrade": "N/S",
    "tutionValue": "$32,938",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.algonquincollege.com/health-studies/program/practical-nursing/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,922",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Nursing Collaborative",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/nursing-collaborative/",
    "entranceGrade": "90 - 96",
    "tutionValue": "$32,938",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Nursing (Bilingual BSc in Nursing/Baccalauréat bilingue ès scien...",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bilingual-bachelor-of-science-in-nursing-nursing.html",
    "entranceGrade": "78 - 78",
    "tutionValue": "$31,089",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Nursing - Direct Entry",
    "programLink": "https://futurestudents.yorku.ca/program/nursing",
    "entranceGrade": "85",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing Pathway for Personal Support Worker",
    "programLink": "https://www.algonquincollege.com/health-studies/program/practical-nursing-pathway-for-personal-support-worker/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Advanced Nursing Leadership and Management",
    "programLink": "https://continuing.torontomu.ca/public/category/courseCategoryCertificateProfile.do?method=load&certificateId=197053",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Nursing: Post-RN for Internationally Educated Nurses",
    "programLink": "https://futurestudents.yorku.ca/program/nursing-ien",
    "entranceGrade": "80 - 100",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Nursing",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/HealthCommunityEducation/Programs/BachelorofNursing/index.htm",
    "entranceGrade": "90 - 100",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Science in Nursing",
    "programLink": "https://www.algonquincollege.com/health-studies/program/bachelor-of-science-in-nursing/",
    "entranceGrade": "70",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Nursing",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-nursing-nursing.html",
    "entranceGrade": "80",
    "tutionValue": "$31,089",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing for Internationally Educated Nurses",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/practical-nursing-internationally-educated-nurses/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,378",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Academic Pathway for Movement from Ontario College Diploma in Pr...",
    "programLink": "https://www.algonquincollege.com/health-studies/program/academic-pathway-for-movement-from-ontario-college-diploma-in-practical-nursing-to-bscn/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "13 Week(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Nursing: Second Entry",
    "programLink": "https://futurestudents.yorku.ca/program/nursing-second-entry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$32,502",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Nursing (BScN)",
    "programLink": "https://admissions.carleton.ca/programs/nursing/",
    "entranceGrade": "85 - 88",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/15523/University-of-New-Brunswick-Saint-John/Nursing",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Nursing - Post RPN (Oshawa)",
    "programLink": "https://ontariotechu.ca/programs/health-sciences/nursing-post-rpn.php",
    "entranceGrade": "85",
    "tutionValue": "$29,771",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.conestogac.on.ca/fulltime/practical-nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$19,834",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Nursing - Collaborative",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/health-sciences-and-medicine/nursing-collaborative/index.php",
    "entranceGrade": "70",
    "tutionValue": "$29,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Fleming College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=591",
    "entranceGrade": "N/S",
    "tutionValue": "$9,977",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Nursing - Post RPN (Barrie)",
    "programLink": "https://ontariotechu.ca/programs/health-sciences/nursing-post-rpn-barrie.php",
    "entranceGrade": "85",
    "tutionValue": "$29,771",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Pre-Nursing Certificate",
    "programLink": "https://www.studyincanada.com/Programs/107501/University-of-Regina/Pre-Nursing-Certificate",
    "entranceGrade": "N/S",
    "tutionValue": "$24,508",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Assiniboine Community College",
    "location": "Canada, Manitoba",
    "programName": "Practical Nursing Bridging",
    "programLink": "https://www.studyincanada.com/Programs/95715/Assiniboine-Community-College/Practical-Nursing-Bridging",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/5051/Durham-College/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$17,587",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Nursing (MSc)",
    "programLink": "https://www.studyincanada.com/Programs/112520/University-of-Lethbridge-School-of-Graduate-Studies/Nursing-(MSc)",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Nursing",
    "location": "Canada, Alberta",
    "programName": "MN - Nurse Practitioner",
    "programLink": "https://www.studyincanada.com/Programs/77764/University-of-Calgary-Faculty-of-Nursing/MN-Nurse-Practitioner",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "George Brown College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Science in Nursing (B.Sc.N.)",
    "programLink": "https://www.studyincanada.com/Programs/1397/George-Brown-College/Bachelor-of-Science-in-Nursing-(B.Sc.N.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$18,190",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Athabasca University",
    "location": "Canada, Alberta",
    "programName": "Nursing (Post L.P.N.)",
    "programLink": "https://www.studyincanada.com/Programs/29689/Athabasca-University/Nursing-(Post-L.P.N.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Nursing (Collaborative)",
    "programLink": "https://www.studyincanada.com/Programs/7588/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Nursing-(Collaborative)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Canadian Mennonite University",
    "location": "Canada, Manitoba",
    "programName": "Nursing (U Manitoba) (Pre-Professional)",
    "programLink": "https://www.studyincanada.com/Programs/9406/Canadian-Mennonite-University/Nursing-(U-Manitoba)-(Pre-Professional)",
    "entranceGrade": "N/S",
    "tutionValue": "$10,208",
    "length": "N/S"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/50756/Langara-College/Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Red Deer Polytechnic",
    "location": "Canada, Alberta",
    "programName": "Nursing - Collaboration with University of Alberta",
    "programLink": "https://www.studyincanada.com/Programs/65960/Red-Deer-Polytechnic/Nursing-Collaboration-with-University-of-Alberta",
    "entranceGrade": "65 - 100",
    "tutionValue": "$27,711",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Honours Bachelor of Science in Nursing",
    "programLink": "https://www.studyincanada.com/Programs/37551/Cambrian-College/Honours-Bachelor-of-Science-in-Nursing",
    "entranceGrade": "75",
    "tutionValue": "$26,215",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Columbia College (Closed)",
    "location": "Canada, Alberta",
    "programName": "Practical Nurse",
    "programLink": "https://www.studyincanada.com/Programs/34459/Columbia-College-(Closed)/Practical-Nurse",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "65 Week(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Nursing (BScN)",
    "programLink": "https://www.studyincanada.com/Programs/4868/Laurentian-University/Universite-Laurentienne/Nursing-(BScN)",
    "entranceGrade": "75",
    "tutionValue": "$23,747",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Nursing (MN)",
    "programLink": "https://www.studyincanada.com/Programs/79432/University-of-Manitoba-Faculty-of-Graduate-Studies/Nursing-(MN)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trinity Western University",
    "location": "Canada, British Columbia",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/2239/Trinity-Western-University/Nursing",
    "entranceGrade": "67 - 100",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/3544/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Nursing",
    "entranceGrade": "87 - 89",
    "tutionValue": "$42,941",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Honours Bachelor of Nursing",
    "programLink": "https://www.studyincanada.com/Programs/107795/Canadore-College/Honours-Bachelor-of-Nursing",
    "entranceGrade": "70",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Science in Nursing (BScN) - Kingston",
    "programLink": "https://www.studyincanada.com/Programs/19876/St.-Lawrence-College/Bachelor-of-Science-in-Nursing-(BScN)-Kingston",
    "entranceGrade": "83",
    "tutionValue": "$22,849",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Nursing",
    "location": "Canada, Alberta",
    "programName": "MN/MBA",
    "programLink": "https://www.studyincanada.com/Programs/100584/University-of-Calgary-Faculty-of-Nursing/MN/MBA",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/68545/University-of-Regina/Nursing",
    "entranceGrade": "70 - 70",
    "tutionValue": "$12,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Emergency Nursing Specialty (Combined Emergency/Critical Care Op...",
    "programLink": "https://www.studyincanada.com/Programs/28229/British-Columbia-Institute-of-Technology/Emergency-Nursing-Specialty-(Combined-Emergency/Critical-Care-Op...",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "12 Week(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Nursing (MScN)",
    "programLink": "https://www.studyincanada.com/Programs/4713/University-of-Windsor-Faculty-of-Graduate-Studies/Nursing-(MScN)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$8,878",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Nursing (BScN)",
    "programLink": "https://www.studyincanada.com/Programs/45525/Kwantlen-Polytechnic-University/Nursing-(BScN)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Canadian Mennonite University",
    "location": "Canada, Manitoba",
    "programName": "Nursing (Red River College) (Pre-Professional)",
    "programLink": "https://www.studyincanada.com/Programs/97487/Canadian-Mennonite-University/Nursing-(Red-River-College)-(Pre-Professional)",
    "entranceGrade": "N/S",
    "tutionValue": "$10,208",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "College of New Caledonia",
    "location": "Canada, British Columbia",
    "programName": "Practical Nurse Diploma",
    "programLink": "https://www.studyincanada.com/Programs/15741/College-of-New-Caledonia/Practical-Nurse-Diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$29,000",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "MacEwan University",
    "location": "Canada, Alberta",
    "programName": "Psychiatric Nursing",
    "programLink": "https://www.studyincanada.com/Programs/6274/MacEwan-University/Psychiatric-Nursing",
    "entranceGrade": "75 - 80",
    "tutionValue": "$32,437",
    "length": "28 Month(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/65672/McGill-University/Nursing",
    "entranceGrade": "82",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Queen's School of Medicine",
    "location": "Canada, Ontario",
    "programName": "Nursing (MNSc Course)",
    "programLink": "https://www.studyincanada.com/Programs/109790/Queen-s-School-of-Medicine/Nursing-(MNSc-Course)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Lethbridge College",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Nursing",
    "programLink": "https://www.studyincanada.com/Programs/8176/Lethbridge-College/Bachelor-of-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$16,355",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/17337/Seneca-Polytechnic/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Honours Bachelor of Science – Nursing",
    "programLink": "https://www.studyincanada.com/Programs/18705/Georgian-College/Honours-Bachelor-of-Science-%e2%80%93-Nursing",
    "entranceGrade": "75",
    "tutionValue": "$16,408",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/22854/Sheridan-College/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$24,274",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/12096/Mohawk-College/Practical-Nursing",
    "entranceGrade": "85",
    "tutionValue": "$27,700",
    "length": "16 Month(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Nursing (Compressed) (B.Sc.N.)",
    "programLink": "https://www.studyincanada.com/Programs/91067/Trent-University/Nursing-(Compressed)-(B.Sc.N.)",
    "entranceGrade": "75 - 100",
    "tutionValue": "$28,285",
    "length": "28 Month(s)"
  },
  {
    "schoolName": "George Brown College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/1415/George-Brown-College/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$15,190",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Cape Breton University",
    "location": "Canada, Nova Scotia",
    "programName": "Bachelor of Science in Nursing",
    "programLink": "https://www.studyincanada.com/Programs/105147/Cape-Breton-University/Bachelor-of-Science-in-Nursing",
    "entranceGrade": "65",
    "tutionValue": "$20,714",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Nursing (B.N.)",
    "programLink": "https://www.studyincanada.com/Programs/32676/Brandon-University/Nursing-(B.N.)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,912",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/7631/The-University-of-British-Columbia-Vancouver/Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$42,884",
    "length": "20 Month(s)"
  },
  {
    "schoolName": "North Island College (Comox Valley Campus)",
    "location": "Canada, British Columbia",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/21465/North-Island-College-(Comox-Valley-Campus)/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Nursing - RPN to BScN (E) Stream",
    "programLink": "https://www.studyincanada.com/Programs/91576/McMaster-University/Nursing-RPN-to-BScN-(E)-Stream",
    "entranceGrade": "N/S",
    "tutionValue": "$46,044",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Critical Care Nursing Specialty (Combined Critical Care/Emergenc...",
    "programLink": "https://www.studyincanada.com/Programs/28186/British-Columbia-Institute-of-Technology/Critical-Care-Nursing-Specialty-(Combined-Critical-Care/Emergenc...",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Windsor - Continuing Education",
    "location": "Canada, Ontario",
    "programName": "Competency-Bridging Program of Study for Internationally Educate...",
    "programLink": "https://www.studyincanada.com/Programs/115513/University-of-Windsor-Continuing-Education/Competency-Bridging-Program-of-Study-for-Internationally-Educate...",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Nursing (BSN Four-year)",
    "programLink": "https://www.studyincanada.com/Programs/11533/University-of-Saskatchewan/Nursing-(BSN-Four-year)",
    "entranceGrade": "75",
    "tutionValue": "$22,954",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/69507/Vancouver-Island-University/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Nursing - Nurse Practitioner Stream (MN)",
    "programLink": "https://www.studyincanada.com/Programs/111577/University-of-Manitoba-Faculty-of-Graduate-Studies/Nursing-Nurse-Practitioner-Stream-(MN)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Specialty Nursing (Pediatric - Standard Option)",
    "programLink": "https://www.studyincanada.com/Programs/28297/British-Columbia-Institute-of-Technology/Specialty-Nursing-(Pediatric-Standard-Option)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Nursing (BScN) (Advanced Standing Entry)",
    "programLink": "https://www.studyincanada.com/Programs/96628/Dalhousie-University/Nursing-(BScN)-(Advanced-Standing-Entry)",
    "entranceGrade": "N/S",
    "tutionValue": "$43,442",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/3942/Fanshawe-College/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$20,305",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/13105/Nipissing-University/Nursing",
    "entranceGrade": "70 - 85",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - English Language Program",
    "location": "Canada, Ontario",
    "programName": "Part-time: ENGLISH FOR HEALTH PROFESSIONALS",
    "programLink": "https://www.studyincanada.com/Programs/49765/University-of-Toronto-English-Language-Program/Part-time-ENGLISH-FOR-HEALTH-PROFESSIONALS",
    "entranceGrade": "N/S",
    "tutionValue": "$570",
    "length": "6 Week(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing - Kingston",
    "programLink": "https://www.studyincanada.com/Programs/8863/St.-Lawrence-College/Practical-Nursing-Kingston",
    "entranceGrade": "75",
    "tutionValue": "$17,692",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Nursing (Collaborative) (B.Sc.N.)",
    "programLink": "https://www.studyincanada.com/Programs/22202/Trent-University/Nursing-(Collaborative)-(B.Sc.N.)",
    "entranceGrade": "75 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "NorQuest College",
    "location": "Canada, Alberta",
    "programName": "Practical Nurse",
    "programLink": "https://www.studyincanada.com/Programs/335/NorQuest-College/Practical-Nurse",
    "entranceGrade": "N/S",
    "tutionValue": "$38,098",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Specialty Nursing (Neonatal)",
    "programLink": "https://www.studyincanada.com/Programs/28223/British-Columbia-Institute-of-Technology/Specialty-Nursing-(Neonatal)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Bow Valley College",
    "location": "Canada, Alberta",
    "programName": "Practical Nurse Diploma - 5 Term Program",
    "programLink": "https://www.studyincanada.com/Programs/46089/Bow-Valley-College/Practical-Nurse-Diploma-5-Term-Program",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "20 Month(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/11339/Selkirk-College/Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Prince Edward Island",
    "location": "Canada, Prince Edward Island",
    "programName": "Bachelor of Science in Nursing",
    "programLink": "https://www.studyincanada.com/Programs/10380/University-of-Prince-Edward-Island/Bachelor-of-Science-in-Nursing",
    "entranceGrade": "70 - 100",
    "tutionValue": "$14,274",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Nursing (Licensed Practical Nurse (LPN) Bridging option)",
    "programLink": "https://www.studyincanada.com/Programs/81178/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Nursing-(Licensed-Practical-Nurse-(LPN)-Bridging-option)",
    "entranceGrade": "N/S",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Northern College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/14750/Northern-College/Practical-Nursing",
    "entranceGrade": "60",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing Bridging Program for Internationally-Educated ...",
    "programLink": "https://www.studyincanada.com/Programs/82159/Centennial-College-Graduate-Programs/Practical-Nursing-Bridging-Program-for-Internationally-Educated-...",
    "entranceGrade": "N/S",
    "tutionValue": "$17,080",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "PN to BScN Bridging Honours Bachelor of Science – Nursing",
    "programLink": "https://www.studyincanada.com/Programs/115588/Canadore-College/PN-to-BScN-Bridging-Honours-Bachelor-of-Science-%e2%80%93-Nursing",
    "entranceGrade": "73",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/7931/Canadore-College/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$21,538",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/92297/University-of-Windsor-Faculty-of-Graduate-Studies/Nursing",
    "entranceGrade": "77 - 100",
    "tutionValue": "$8,878",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Practical Nursing Diploma",
    "programLink": "https://www.studyincanada.com/Programs/99948/Thompson-Rivers-University/Practical-Nursing-Diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Nursing (MN)",
    "programLink": "https://www.studyincanada.com/Programs/28579/University-of-Lethbridge-School-of-Graduate-Studies/Nursing-(MN)",
    "entranceGrade": "N/S",
    "tutionValue": "$23,621",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "Grenfell Campus, Memorial University",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Nursing (BN)",
    "programLink": "https://www.studyincanada.com/Programs/13693/Grenfell-Campus,-Memorial-University/Nursing-(BN)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$11,460",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Nursing (Collaborative)",
    "programLink": "https://www.studyincanada.com/Programs/19388/Lakehead-University-Thunder-Bay/Nursing-(Collaborative)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,616",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/1592/Humber-College-Institute-of-Technology-&-Advanced-Learning/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$16,394",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Pediatric Nursing Specialty (Standard Option)",
    "programLink": "https://www.studyincanada.com/Programs/28216/British-Columbia-Institute-of-Technology/Pediatric-Nursing-Specialty-(Standard-Option)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "New Brunswick Community College",
    "location": "Canada, New Brunswick",
    "programName": "Practical Nurse",
    "programLink": "https://www.studyincanada.com/Programs/14105/New-Brunswick-Community-College/Practical-Nurse",
    "entranceGrade": "N/S",
    "tutionValue": "$9,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Perioperative Nursing Specialty",
    "programLink": "https://www.studyincanada.com/Programs/33676/British-Columbia-Institute-of-Technology/Perioperative-Nursing-Specialty",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Queen's School of Medicine",
    "location": "Canada, Ontario",
    "programName": "Nursing (MNSc Thesis)",
    "programLink": "https://www.studyincanada.com/Programs/23166/Queen-s-School-of-Medicine/Nursing-(MNSc-Thesis)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Confederation College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/251/Confederation-College/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Nursing Preparation Program",
    "programLink": "https://www.studyincanada.com/Programs/112552/University-of-Lethbridge/Nursing-Preparation-Program",
    "entranceGrade": "N/S",
    "tutionValue": "$20,784",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Specialty Nursing (Occupational Health)",
    "programLink": "https://www.studyincanada.com/Programs/28226/British-Columbia-Institute-of-Technology/Specialty-Nursing-(Occupational-Health)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Nursing - Registered Practical Nurse Bridge (Algonquin College -...",
    "programLink": "https://www.studyincanada.com/Programs/96559/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Nursing-Registered-Practical-Nurse-Bridge-(Algonquin-College-...",
    "entranceGrade": "80 - 86",
    "tutionValue": "$42,941",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Nursing | After Degree",
    "programLink": "https://www.studyincanada.com/Programs/91090/University-of-Lethbridge/Bachelor-of-Nursing-|-After-Degree",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing - Flex Program (weekend delivery)",
    "programLink": "https://www.studyincanada.com/Programs/85161/Durham-College/Practical-Nursing-Flex-Program-(weekend-delivery)",
    "entranceGrade": "N/S",
    "tutionValue": "$17,587",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Perinatal Nursing Specialty (Perinatal - Perioperative Option)",
    "programLink": "https://www.studyincanada.com/Programs/28205/British-Columbia-Institute-of-Technology/Perinatal-Nursing-Specialty-(Perinatal-Perioperative-Option)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Emergency Nursing Specialty (Standard Option)",
    "programLink": "https://www.studyincanada.com/Programs/28189/British-Columbia-Institute-of-Technology/Emergency-Nursing-Specialty-(Standard-Option)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "12 Week(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/29925/The-University-of-British-Columbia-Okanagan/Nursing",
    "entranceGrade": "67",
    "tutionValue": "$49,462",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Specialty Nursing (Nephrology)",
    "programLink": "https://www.studyincanada.com/Programs/28225/British-Columbia-Institute-of-Technology/Specialty-Nursing-(Nephrology)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "College of the Rockies",
    "location": "Canada, British Columbia",
    "programName": "Bachelor of Science Nursing",
    "programLink": "https://www.studyincanada.com/Programs/15895/College-of-the-Rockies/Bachelor-of-Science-Nursing",
    "entranceGrade": "70",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Continuing Education",
    "location": "Canada, Ontario",
    "programName": "Introduction to Critical Care",
    "programLink": "https://www.studyincanada.com/Programs/115507/University-of-Windsor-Continuing-Education/Introduction-to-Critical-Care",
    "entranceGrade": "N/S",
    "tutionValue": "$899",
    "length": "3 Month(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/2576/Dalhousie-University/Nursing",
    "entranceGrade": "75",
    "tutionValue": "$43,442",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/4728/Cambrian-College/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$21,292",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Nursing Practice in Canada",
    "programLink": "https://www.studyincanada.com/Programs/85333/Langara-College/Nursing-Practice-in-Canada",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Nursing",
    "programLink": "https://www.studyincanada.com/Programs/10335/University-of-Lethbridge/Bachelor-of-Nursing",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing - Cornwall",
    "programLink": "https://www.studyincanada.com/Programs/108894/St.-Lawrence-College/Practical-Nursing-Cornwall",
    "entranceGrade": "75",
    "tutionValue": "$17,056",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Nursing - Collaborative Bachelor of Science in Nursing (BScN) (H...",
    "programLink": "https://www.studyincanada.com/Programs/5041/Durham-College/Nursing-Collaborative-Bachelor-of-Science-in-Nursing-(BScN)-(H...",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing to Registered Nursing Bridge",
    "programLink": "https://www.studyincanada.com/Programs/115553/Cambrian-College/Practical-Nursing-to-Registered-Nursing-Bridge",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Month(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Specialty Nursing (Critical Care - Combined Critical Care/Emerge...",
    "programLink": "https://www.studyincanada.com/Programs/28218/British-Columbia-Institute-of-Technology/Specialty-Nursing-(Critical-Care-Combined-Critical-Care/Emerge...",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing (Chatham)",
    "programLink": "https://www.studyincanada.com/Programs/85186/St.-Clair-College/Practical-Nursing-(Chatham)",
    "entranceGrade": "N/S",
    "tutionValue": "$17,219",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/79429/University-of-Manitoba-Faculty-of-Graduate-Studies/Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Red River College Polytechnic",
    "location": "Canada, Manitoba",
    "programName": "Bridging Program for Internationally Educated Nurses",
    "programLink": "https://www.studyincanada.com/Programs/90381/Red-River-College-Polytechnic/Bridging-Program-for-Internationally-Educated-Nurses",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/15423/University-of-New-Brunswick-Fredericton/Nursing",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Nursing (Collaborative Fast-track)",
    "programLink": "https://www.studyincanada.com/Programs/58450/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Nursing-(Collaborative-Fast-track)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Nursing: Bachelor of Science in Nursing Degree",
    "programLink": "https://www.studyincanada.com/Programs/95437/Thompson-Rivers-University/Nursing-Bachelor-of-Science-in-Nursing-Degree",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Psychiatric Nursing",
    "programLink": "https://www.studyincanada.com/Programs/62887/The-University-of-Winnipeg/Psychiatric-Nursing",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Medicine Hat College",
    "location": "Canada, Alberta",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/16689/Medicine-Hat-College/Practical-Nursing",
    "entranceGrade": "60 - 70",
    "tutionValue": "$20,955",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing (PSW-PN bridge)",
    "programLink": "https://www.studyincanada.com/Programs/115904/Durham-College/Practical-Nursing-(PSW-PN-bridge)",
    "entranceGrade": "N/S",
    "tutionValue": "$16,146",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Sciences infirmiéres (BScInf)",
    "programLink": "https://www.studyincanada.com/Programs/78376/Laurentian-University/Universite-Laurentienne/Sciences-infirmi%c3%a9res-(BScInf)",
    "entranceGrade": "75",
    "tutionValue": "$23,747",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Nursing (Accelerated)",
    "programLink": "https://www.studyincanada.com/Programs/91564/McMaster-University/Nursing-(Accelerated)",
    "entranceGrade": "N/S",
    "tutionValue": "$46,044",
    "length": "20 Month(s)"
  },
  {
    "schoolName": "Lambton College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Science - Nursing",
    "programLink": "https://www.studyincanada.com/Programs/46750/Lambton-College/Bachelor-of-Science-Nursing",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "MacEwan University",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Psychiatric Nursing",
    "programLink": "https://www.studyincanada.com/Programs/81376/MacEwan-University/Bachelor-of-Psychiatric-Nursing",
    "entranceGrade": "65 - 100",
    "tutionValue": "$24,472",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Nursing, Advanced Entry (BScN)",
    "programLink": "https://www.studyincanada.com/Programs/71957/Kwantlen-Polytechnic-University/Nursing,-Advanced-Entry-(BScN)",
    "entranceGrade": "68 - 71",
    "tutionValue": "N/S",
    "length": "21 Month(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/28199/British-Columbia-Institute-of-Technology/Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Nephrology Nursing Specialty",
    "programLink": "https://www.studyincanada.com/Programs/28196/British-Columbia-Institute-of-Technology/Nephrology-Nursing-Specialty",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "12 Week(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Northern Collaborative Baccalaureate Nursing Program",
    "programLink": "https://www.studyincanada.com/Programs/3313/University-of-Northern-British-Columbia/Northern-Collaborative-Baccalaureate-Nursing-Program",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Health Sciences",
    "location": "Canada, Ontario",
    "programName": "Nursing (Thesis-based)",
    "programLink": "https://www.studyincanada.com/Programs/27787/McMaster-University-Faculty-of-Health-Sciences/Nursing-(Thesis-based)",
    "entranceGrade": "77",
    "tutionValue": "$17,096",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/13508/University-of-Manitoba/Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$19,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Nursing",
    "programLink": "https://www.ufv.ca/nursing/programs/bachelor-of-science-in-nursing/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Nursing (MSc)",
    "programLink": "https://www.studyincanada.com/Programs/112519/University-of-Lethbridge-School-of-Graduate-Studies/Nursing-(MSc)",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Science in Nursing (BScN) - Brockville",
    "programLink": "https://www.studyincanada.com/Programs/108901/St.-Lawrence-College/Bachelor-of-Science-in-Nursing-(BScN)-Brockville",
    "entranceGrade": "83",
    "tutionValue": "$22,487",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Okanagan College",
    "location": "Canada, British Columbia",
    "programName": "Bachelor of Science in Nursing (Years 1 and 2)",
    "programLink": "https://www.studyincanada.com/Programs/81075/Okanagan-College/Bachelor-of-Science-in-Nursing-(Years-1-and-2)",
    "entranceGrade": "50 - 70",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Nursing",
    "location": "Canada, Alberta",
    "programName": "Nursing - Degree Holders",
    "programLink": "https://www.studyincanada.com/Programs/100583/University-of-Calgary-Faculty-of-Nursing/Nursing-Degree-Holders",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Nursing - Transfer",
    "programLink": "https://www.studyincanada.com/Programs/99450/University-of-Calgary/Nursing-Transfer",
    "entranceGrade": "86 - 89",
    "tutionValue": "$20,172",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Neonatal Nursing Specialty",
    "programLink": "https://www.studyincanada.com/Programs/28194/British-Columbia-Institute-of-Technology/Neonatal-Nursing-Specialty",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "12 Week(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Nursing",
    "location": "Canada, Alberta",
    "programName": "Nursing - Four Year",
    "programLink": "https://www.studyincanada.com/Programs/71889/University-of-Calgary-Faculty-of-Nursing/Nursing-Four-Year",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Specialty Nursing (Perinatal - Standard Option)",
    "programLink": "https://www.studyincanada.com/Programs/28232/British-Columbia-Institute-of-Technology/Specialty-Nursing-(Perinatal-Standard-Option)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Collaborative Nursing (B.Sc.N.)",
    "programLink": "https://www.studyincanada.com/Programs/3912/Fanshawe-College/Collaborative-Nursing-(B.Sc.N.)",
    "entranceGrade": "86 - 89",
    "tutionValue": "$20,367",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lambton College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/12581/Lambton-College/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Pediatric Nursing Specialty (Critical Care Option)",
    "programLink": "https://www.studyincanada.com/Programs/28215/British-Columbia-Institute-of-Technology/Pediatric-Nursing-Specialty-(Critical-Care-Option)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Public Health: Nursing Specialization (M.P.H.)",
    "programLink": "https://www.studyincanada.com/Programs/71275/Lakehead-University-Faculty-of-Graduate-Studies/Public-Health-Nursing-Specialization-(M.P.H.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Gerontological Nursing",
    "programLink": "https://www.studyincanada.com/Programs/81398/Selkirk-College/Gerontological-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$17,609",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/3521/Georgian-College/Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$14,359",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Lambton College",
    "location": "Canada, Ontario",
    "programName": "Pre-Health Sciences Pathway to Advanced Diplomas & Degrees",
    "programLink": "https://www.studyincanada.com/Programs/28953/Lambton-College/Pre-Health-Sciences-Pathway-to-Advanced-Diplomas-&-Degrees",
    "entranceGrade": "55 - 55",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Access to Practical Nursing",
    "programLink": "https://www.studyincanada.com/Programs/56432/Selkirk-College/Access-to-Practical-Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "9 Month(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Practical Nursing",
    "programLink": "https://www.ufv.ca/nursing/programs/practical-nursing-diploma/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "19 Month(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Science in Nursing (BScN) - Cornwall",
    "programLink": "https://www.studyincanada.com/Programs/108902/St.-Lawrence-College/Bachelor-of-Science-in-Nursing-(BScN)-Cornwall",
    "entranceGrade": "83",
    "tutionValue": "$22,412",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saskatchewan Polytechnic",
    "location": "Canada, Saskatchewan",
    "programName": "Nursing (Saskatchewan Collaborative Bachelor of Science in Nursi...",
    "programLink": "https://www.studyincanada.com/Programs/65967/Saskatchewan-Polytechnic/Nursing-(Saskatchewan-Collaborative-Bachelor-of-Science-in-Nursi...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$11,939",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lethbridge College",
    "location": "Canada, Alberta",
    "programName": "Practical Nurse",
    "programLink": "https://www.studyincanada.com/Programs/13077/Lethbridge-College/Practical-Nurse",
    "entranceGrade": "N/S",
    "tutionValue": "$16,480",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Nursing (Integrated)",
    "programLink": "https://www.studyincanada.com/Programs/65662/McGill-University/Nursing-(Integrated)",
    "entranceGrade": "N/S",
    "tutionValue": "$21,786",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "College of the Rockies",
    "location": "Canada, British Columbia",
    "programName": "Practical Nurse",
    "programLink": "https://www.studyincanada.com/Programs/15896/College-of-the-Rockies/Practical-Nurse",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Nursing Science",
    "programLink": "https://www.studyincanada.com/Programs/23112/Queen-s-University/Nursing-Science",
    "entranceGrade": "85 - 100",
    "tutionValue": "$55,591",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/91573/McMaster-University/Nursing",
    "entranceGrade": "85",
    "tutionValue": "$46,044",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Health Sciences",
    "location": "Canada, Ontario",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/27780/McMaster-University-Faculty-of-Health-Sciences/Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$6,307",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Loyalist College",
    "location": "Canada, Ontario",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/7876/Loyalist-College/Nursing",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Health & Behaviour (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/107796/Trent-University/Health-&-Behaviour-(B.Sc.)",
    "entranceGrade": "75",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Nursing",
    "location": "Canada, Alberta",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/71884/University-of-Calgary-Faculty-of-Nursing/Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Red Deer Polytechnic",
    "location": "Canada, Alberta",
    "programName": "Practical Nurse",
    "programLink": "https://www.studyincanada.com/Programs/8808/Red-Deer-Polytechnic/Practical-Nurse",
    "entranceGrade": "60 - 100",
    "tutionValue": "$13,311",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Specialty Nursing (Pediatric - Critical Care Option)",
    "programLink": "https://www.studyincanada.com/Programs/28280/British-Columbia-Institute-of-Technology/Specialty-Nursing-(Pediatric-Critical-Care-Option)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Nursing - BSCN (Chatham)",
    "programLink": "https://www.studyincanada.com/Programs/85184/St.-Clair-College/Nursing-BSCN-(Chatham)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Nursing",
    "programLink": "https://www.studyincanada.com/Programs/12307/University-of-Victoria/Nursing",
    "entranceGrade": "N/S",
    "tutionValue": "$27,949",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Confederation College",
    "location": "Canada, Ontario",
    "programName": "Collaborative Bachelor of Science in Nursing (BScN)",
    "programLink": "https://www.studyincanada.com/Programs/245/Confederation-College/Collaborative-Bachelor-of-Science-in-Nursing-(BScN)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Pre-Health Sciences Pathways to Advanced Diplomas and Degrees",
    "programLink": "https://www.algonquincollege.com/health-studies/program/pre-health-sciences-pathway-advanced-diplomas-degrees/",
    "entranceGrade": "N/S",
    "tutionValue": "$15,022",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Pre-Health Sciences Pathway to Certificates and Diplomas",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/pre-health/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,396",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences (BHSc)",
    "programLink": "https://admissions.carleton.ca/programs/health-sciences/",
    "entranceGrade": "85 - 88",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Health Sciences",
    "programLink": "https://uwaterloo.ca/future-students/programs/health-sciences?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "83 - 89",
    "tutionValue": "$48,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Applied Health Sciences (BASc)",
    "programLink": "https://wlu.ca/programs/human-and-social-sciences/undergraduate/applied-health-sciences-basc/index.html",
    "entranceGrade": "70",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/health-sciences-bsc/index.html",
    "entranceGrade": "84 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Pre-Health Sciences Pathway to Certificates and Diplomas",
    "programLink": "https://www.algonquincollege.com/health-studies/program/pre-health-sciences-pathway-certificates-diplomas/",
    "entranceGrade": "N/S",
    "tutionValue": "$15,022",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Allied Health Sciences - Bridge",
    "programLink": "https://ontariotechu.ca/programs/health-sciences/allied-health-sciences.php",
    "entranceGrade": "70",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences",
    "programLink": "https://ontariotechu.ca/programs/health-sciences/health-science.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Health Sciences - Community and Population Health - Aboriginal a...",
    "programLink": "https://www.studyincanada.com/Programs/40252/University-of-Northern-British-Columbia/Health-Sciences-Community-and-Population-Health-Aboriginal-a...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Health Sciences | Aboriginal Health",
    "programLink": "https://www.studyincanada.com/Programs/82276/University-of-Lethbridge/Bachelor-of-Health-Sciences-|-Aboriginal-Health",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Tyndale University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences Pre-Medicine with a Minor in Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/115972/Tyndale-University/Health-Sciences-Pre-Medicine-with-a-Minor-in-Chemistry",
    "entranceGrade": "65 - 100",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences (Biomedical Sciences Specialization)",
    "programLink": "https://www.studyincanada.com/Programs/91553/McMaster-University/Health-Sciences-(Biomedical-Sciences-Specialization)",
    "entranceGrade": "90",
    "tutionValue": "$48,996",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Interdisciplinary Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/114226/University-of-Windsor/Interdisciplinary-Health-Sciences",
    "entranceGrade": "70 - 100",
    "tutionValue": "$37,497",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Red Deer Polytechnic",
    "location": "Canada, Alberta",
    "programName": "Pre-Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/96162/Red-Deer-Polytechnic/Pre-Health-Sciences",
    "entranceGrade": "50",
    "tutionValue": "$21,527",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Health Sciences – Workplace Health and Wellness",
    "programLink": "https://www.studyincanada.com/Programs/90728/Humber-College-Institute-of-Technology-&-Advanced-Learning/Bachelor-of-Health-Sciences-%e2%80%93-Workplace-Health-and-Wellness",
    "entranceGrade": "65",
    "tutionValue": "$19,615",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences - Health and Aging",
    "programLink": "https://www.studyincanada.com/Programs/107565/Western-University/Health-Sciences-Health-and-Aging",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "N/S"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Health Sciences - Diagnostic Medical Ultrasound (BHSc)",
    "programLink": "https://www.studyincanada.com/Programs/14843/Dalhousie-University/Health-Sciences-Diagnostic-Medical-Ultrasound-(BHSc)",
    "entranceGrade": "75",
    "tutionValue": "$30,970",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences (Child Health Specialization)",
    "programLink": "https://www.studyincanada.com/Programs/91508/McMaster-University/Health-Sciences-(Child-Health-Specialization)",
    "entranceGrade": "90",
    "tutionValue": "$48,996",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Pre-Professional Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/45650/Thompson-Rivers-University/Pre-Professional-Health-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Health Sciences (Arts)",
    "programLink": "https://www.studyincanada.com/Programs/66017/Langara-College/Health-Sciences-(Arts)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Health Sciences (M.H.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/71276/Lakehead-University-Faculty-of-Graduate-Studies/Health-Sciences-(M.H.Sc.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Redeemer University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/84629/Redeemer-University/Health-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$19,494",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Molecular Biology - Specialization in Health Sc...",
    "programLink": "https://www.studyincanada.com/Programs/105836/Trent-University/Biochemistry-and-Molecular-Biology-Specialization-in-Health-Sc...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Pre-Health Sciences Pathway to Certificates and Diplomas",
    "programLink": "https://www.studyincanada.com/Programs/31312/Canadore-College/Pre-Health-Sciences-Pathway-to-Certificates-and-Diplomas",
    "entranceGrade": "N/S",
    "tutionValue": "$17,490",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Community Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/79435/University-of-Manitoba-Faculty-of-Graduate-Studies/Community-Health-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Community Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/79433/University-of-Manitoba-Faculty-of-Graduate-Studies/Community-Health-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Faculty of Medicine at the University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Community Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/15877/Faculty-of-Medicine-at-the-University-of-Calgary/Community-Health-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$16,389",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/16632/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Health-Sciences",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences- Health Promotion",
    "programLink": "https://www.studyincanada.com/Programs/65504/Western-University/Health-Sciences-Health-Promotion",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/60026/University-of-Manitoba/Health-Sciences",
    "entranceGrade": "85 - 85",
    "tutionValue": "$18,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Health Sciences (Science)",
    "programLink": "https://www.studyincanada.com/Programs/67668/Langara-College/Health-Sciences-(Science)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/3921/Western-University/Health-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "N/S"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Pre-Health Sciences Pathway to Advanced Diplomas and Degrees",
    "programLink": "https://www.studyincanada.com/Programs/112988/Durham-College/Pre-Health-Sciences-Pathway-to-Advanced-Diplomas-and-Degrees",
    "entranceGrade": "N/S",
    "tutionValue": "$14,884",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Pre-Health Sciences Pathway to Certificates and Diplomas",
    "programLink": "https://www.studyincanada.com/Programs/106062/St.-Lawrence-College/Pre-Health-Sciences-Pathway-to-Certificates-and-Diplomas",
    "entranceGrade": "N/S",
    "tutionValue": "$14,600",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Tyndale University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences Professional with a Minor in Psychology",
    "programLink": "https://www.studyincanada.com/Programs/81840/Tyndale-University/Health-Sciences-Professional-with-a-Minor-in-Psychology",
    "entranceGrade": "65 - 100",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Health Sciences - Biomedical Studies",
    "programLink": "https://www.studyincanada.com/Programs/76020/University-of-Northern-British-Columbia/Health-Sciences-Biomedical-Studies",
    "entranceGrade": "70 - 100",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Health Sciences - Community and Population Health - Environmenta...",
    "programLink": "https://www.studyincanada.com/Programs/76022/University-of-Northern-British-Columbia/Health-Sciences-Community-and-Population-Health-Environmenta...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lambton College",
    "location": "Canada, Ontario",
    "programName": "Pre-Health Sciences Pathway to Advanced Diplomas & Degrees",
    "programLink": "https://www.studyincanada.com/Programs/28953/Lambton-College/Pre-Health-Sciences-Pathway-to-Advanced-Diplomas-&-Degrees",
    "entranceGrade": "55 - 55",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "HBA/ Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/107598/Western-University/HBA/-Health-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/106446/Lakehead-University-Faculty-of-Graduate-Studies/Health-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Health Sciences | Public Health",
    "programLink": "https://www.studyincanada.com/Programs/85808/University-of-Lethbridge/Bachelor-of-Health-Sciences-|-Public-Health",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences (Core)",
    "programLink": "https://www.studyincanada.com/Programs/4504/McMaster-University/Health-Sciences-(Core)",
    "entranceGrade": "90",
    "tutionValue": "$48,996",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Health Sciences - Integrative Health Biosciences",
    "programLink": "https://www.studyincanada.com/Programs/104954/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Health-Sciences-Integrative-Health-Biosciences",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Kinesiology and Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/10408/Graduate-Studies-at-University-of-Waterloo/Kinesiology-and-Health-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/109468/Queen-s-University/Health-Sciences",
    "entranceGrade": "90 - 100",
    "tutionValue": "$35,846",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Pre-Health Sciences Pathway to Advanced Diplomas and Degrees",
    "programLink": "https://www.studyincanada.com/Programs/12868/Cambrian-College/Pre-Health-Sciences-Pathway-to-Advanced-Diplomas-and-Degrees",
    "entranceGrade": "N/S",
    "tutionValue": "$17,411",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Redeemer University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences - Professional",
    "programLink": "https://www.studyincanada.com/Programs/88523/Redeemer-University/Health-Sciences-Professional",
    "entranceGrade": "N/S",
    "tutionValue": "$19,494",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Faculty of Medicine at the University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Community Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/15876/Faculty-of-Medicine-at-the-University-of-Calgary/Community-Health-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$16,389",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Community Health Sciences (MPH)",
    "programLink": "https://www.studyincanada.com/Programs/111605/University-of-Manitoba-Faculty-of-Graduate-Studies/Community-Health-Sciences-(MPH)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biology - Specialization in Health Sciences (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/78477/Trent-University/Biology-Specialization-in-Health-Sciences-(B.Sc.)",
    "entranceGrade": "70",
    "tutionValue": "$28,285",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Health Sciences - Biomedical Studies",
    "programLink": "https://www.studyincanada.com/Programs/40248/University-of-Northern-British-Columbia/Health-Sciences-Biomedical-Studies",
    "entranceGrade": "70 - 100",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Health Sciences | Addictions Counselling",
    "programLink": "https://www.studyincanada.com/Programs/12516/University-of-Lethbridge/Bachelor-of-Health-Sciences-|-Addictions-Counselling",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Translational Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/113799/University-of-Windsor-Faculty-of-Graduate-Studies/Translational-Health-Sciences",
    "entranceGrade": "70 - 100",
    "tutionValue": "$9,360",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Health Sciences (Arts)",
    "programLink": "https://www.studyincanada.com/Programs/66021/Langara-College/Health-Sciences-(Arts)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Pre-Health Sciences Pathway to Certificates and Diplomas",
    "programLink": "https://www.studyincanada.com/Programs/112989/Durham-College/Pre-Health-Sciences-Pathway-to-Certificates-and-Diplomas",
    "entranceGrade": "N/S",
    "tutionValue": "$16,089",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Canadian Mennonite University",
    "location": "Canada, Manitoba",
    "programName": "Health Sciences (Pre-Professional)",
    "programLink": "https://www.studyincanada.com/Programs/97485/Canadian-Mennonite-University/Health-Sciences-(Pre-Professional)",
    "entranceGrade": "N/S",
    "tutionValue": "$10,208",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Applied Health Sciences -- Athletic Therapy",
    "programLink": "https://www.studyincanada.com/Programs/115862/Sheridan-College/Applied-Health-Sciences-Athletic-Therapy",
    "entranceGrade": "65",
    "tutionValue": "$27,415",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Pre-Health Sciences Pathway to Advanced Diplomas and Degrees",
    "programLink": "https://www.studyincanada.com/Programs/115883/Sheridan-College/Pre-Health-Sciences-Pathway-to-Advanced-Diplomas-and-Degrees",
    "entranceGrade": "N/S",
    "tutionValue": "$18,566",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Health Sciences - Population and Public Health",
    "programLink": "https://www.studyincanada.com/Programs/104952/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Health-Sciences-Population-and-Public-Health",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cape Breton University",
    "location": "Canada, Nova Scotia",
    "programName": "Bachelor of Health Sciences (Public Health)",
    "programLink": "https://www.studyincanada.com/Programs/105170/Cape-Breton-University/Bachelor-of-Health-Sciences-(Public-Health)",
    "entranceGrade": "65",
    "tutionValue": "$19,579",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Health Sciences - Community and Population Health - Environmenta...",
    "programLink": "https://www.studyincanada.com/Programs/40253/University-of-Northern-British-Columbia/Health-Sciences-Community-and-Population-Health-Environmenta...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Forensic Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/28099/British-Columbia-Institute-of-Technology/Forensic-Health-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Health Sciences - Technologies and Innovation in Healthcare",
    "programLink": "https://www.studyincanada.com/Programs/104953/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Health-Sciences-Technologies-and-Innovation-in-Healthcare",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/104981/Queen-s-University/Health-Sciences",
    "entranceGrade": "90 - 100",
    "tutionValue": "$26,626",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences with Biology",
    "programLink": "https://www.studyincanada.com/Programs/65505/Western-University/Health-Sciences-with-Biology",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Health Sciences - Community and Population Health - Aboriginal a...",
    "programLink": "https://www.studyincanada.com/Programs/76021/University-of-Northern-British-Columbia/Health-Sciences-Community-and-Population-Health-Aboriginal-a...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Health Sciences (Science)",
    "programLink": "https://www.studyincanada.com/Programs/67666/Langara-College/Health-Sciences-(Science)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Health Sciences - Kinesiology and Health Promotion",
    "programLink": "https://www.studyincanada.com/Programs/56533/Sheridan-College/Health-Sciences-Kinesiology-and-Health-Promotion",
    "entranceGrade": "65 - 65",
    "tutionValue": "$27,121",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biology - Internship in Health Sciences (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/91070/Trent-University/Biology-Internship-in-Health-Sciences-(B.Sc.)",
    "entranceGrade": "N/S",
    "tutionValue": "$28,285",
    "length": "N/S"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Occupational and Public Health - Occupational Health and Safety ...",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/occupational-health-safety/",
    "entranceGrade": "N/S",
    "tutionValue": "$31,746",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Public Health",
    "programLink": "https://uwaterloo.ca/future-students/programs/public-health?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 87",
    "tutionValue": "$48,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Public Health (Two-Year)",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/public-health/",
    "entranceGrade": "76 - 78",
    "tutionValue": "$31,746",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Occupational and Public Health - Public Health and Safety",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/occupational-health-safety/",
    "entranceGrade": "N/S",
    "tutionValue": "$31,746",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Public Health",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/public-health/",
    "entranceGrade": "76 - 78",
    "tutionValue": "$31,746",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Health Studies (BA) + Public Health / Global Health (MSc), Brigh...",
    "programLink": "https://wlu.ca/programs/human-and-social-sciences/undergraduate/health-studies-ba-msc/index.html",
    "entranceGrade": "75",
    "tutionValue": "$33,220",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Public Health",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/health-sciences-and-medicine/public-health/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Public Health and Health Systems (Aging, Health, and Well-Being)...",
    "programLink": "https://www.studyincanada.com/Programs/33322/Graduate-Studies-at-University-of-Waterloo/Public-Health-and-Health-Systems-(Aging,-Health,-and-Well-Being)...",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Public Health and Health Systems",
    "programLink": "https://www.studyincanada.com/Programs/615/Graduate-Studies-at-University-of-Waterloo/Public-Health-and-Health-Systems",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Public Health: Nursing Specialization (M.P.H.)",
    "programLink": "https://www.studyincanada.com/Programs/71275/Lakehead-University-Faculty-of-Graduate-Studies/Public-Health-Nursing-Specialization-(M.P.H.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Health Sciences",
    "location": "Canada, Ontario",
    "programName": "Public Health (Thesis-based)",
    "programLink": "https://www.studyincanada.com/Programs/88044/McMaster-University-Faculty-of-Health-Sciences/Public-Health-(Thesis-based)",
    "entranceGrade": "77",
    "tutionValue": "$51,047",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa - Faculty of Medicine",
    "location": "Canada, Ontario",
    "programName": "Public Health",
    "programLink": "https://www.studyincanada.com/Programs/109654/University-of-Ottawa/Universit%c3%a9-d-Ottawa-Faculty-of-Medicine/Public-Health",
    "entranceGrade": "75 - 75",
    "tutionValue": "$30,000",
    "length": "16 Month(s)"
  },
  {
    "schoolName": "Cape Breton University",
    "location": "Canada, Nova Scotia",
    "programName": "Bachelor of Health Sciences (Public Health)",
    "programLink": "https://www.studyincanada.com/Programs/105170/Cape-Breton-University/Bachelor-of-Health-Sciences-(Public-Health)",
    "entranceGrade": "65",
    "tutionValue": "$19,579",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Health Sciences - Population and Public Health",
    "programLink": "https://www.studyincanada.com/Programs/104952/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Health-Sciences-Population-and-Public-Health",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Public Health and Preventive Medicine Residency",
    "programLink": "https://www.studyincanada.com/Programs/111582/University-of-Manitoba-Faculty-of-Graduate-Studies/Public-Health-and-Preventive-Medicine-Residency",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Health Sciences | Public Health",
    "programLink": "https://www.studyincanada.com/Programs/85808/University-of-Lethbridge/Bachelor-of-Health-Sciences-|-Public-Health",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Health Sciences",
    "location": "Canada, Ontario",
    "programName": "Public Health (Practicum)",
    "programLink": "https://www.studyincanada.com/Programs/88051/McMaster-University-Faculty-of-Health-Sciences/Public-Health-(Practicum)",
    "entranceGrade": "77",
    "tutionValue": "$51,047",
    "length": "16 Month(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Public Health",
    "programLink": "https://www.studyincanada.com/Programs/33334/Graduate-Studies-at-University-of-Waterloo/Public-Health",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Environmental Health (Public Health Inspection)",
    "programLink": "https://www.studyincanada.com/Programs/28150/British-Columbia-Institute-of-Technology/Environmental-Health-(Public-Health-Inspection)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Post-Diploma | Public Health in Health Leadership",
    "programLink": "https://www.studyincanada.com/Programs/91087/University-of-Lethbridge/Post-Diploma-|-Public-Health-in-Health-Leadership",
    "entranceGrade": "65",
    "tutionValue": "$20,784",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Public Health (M.P.H.)",
    "programLink": "https://www.studyincanada.com/Programs/19422/Lakehead-University-Faculty-of-Graduate-Studies/Public-Health-(M.P.H.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Pharmacy",
    "programLink": "https://uwaterloo.ca/future-students/programs/pharmacy?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Pharmaceutical Chemistry",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/pharmaceutical-chemistry/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Fleming College",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Technician",
    "programLink": "https://flemingcollege.ca/programs/pharmacy-technician",
    "entranceGrade": "N/S",
    "tutionValue": "$18,086",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Pharmaceutical Chemistry",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/pharmaceutical-chemistry/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie Medical School",
    "location": "Canada, Nova Scotia",
    "programName": "Pharmacy Residency Program",
    "programLink": "https://www.studyincanada.com/Programs/2580/Dalhousie-Medical-School/Pharmacy-Residency-Program",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "12 Month(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Technician",
    "programLink": "https://www.studyincanada.com/Programs/30150/Sheridan-College/Pharmacy-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$18,732",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Bow Valley College",
    "location": "Canada, Alberta",
    "programName": "Pharmacy Technician Diploma",
    "programLink": "https://www.studyincanada.com/Programs/50584/Bow-Valley-College/Pharmacy-Technician-Diploma",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "16 Month(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Pharmacology",
    "programLink": "https://www.studyincanada.com/Programs/65664/McGill-University/Pharmacology",
    "entranceGrade": "95",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Technician",
    "programLink": "https://www.studyincanada.com/Programs/6608/St.-Clair-College/Pharmacy-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$15,685",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Pre-Pharmacy",
    "programLink": "https://www.studyincanada.com/Programs/71834/University-of-Regina/Pre-Pharmacy",
    "entranceGrade": "65 - 70",
    "tutionValue": "$12,049",
    "length": "N/S"
  },
  {
    "schoolName": "CDI College (Scarborough Campus)",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Assistant",
    "programLink": "https://www.studyincanada.com/Programs/71669/CDI-College-(Scarborough-Campus)/Pharmacy-Assistant",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "45 Week(s)"
  },
  {
    "schoolName": "Bay River College",
    "location": "Canada, Alberta",
    "programName": "Pharmacy Assistant",
    "programLink": "https://www.studyincanada.com/Programs/106868/Bay-River-College/Pharmacy-Assistant",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "34 Week(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Community Pharmacy Technician",
    "programLink": "https://www.ufv.ca/calendar/2008_09/ProgramsC-E/CS_PHARM.htm",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "triOS College (London Campus)",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Assistant",
    "programLink": "https://www.studyincanada.com/Programs/26314/triOS-College-(London-Campus)/Pharmacy-Assistant",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "32 Week(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Pharmacology",
    "programLink": "https://www.studyincanada.com/Programs/77492/The-University-of-British-Columbia-Vancouver/Pharmacology",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lambton College",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Technician",
    "programLink": "https://www.studyincanada.com/Programs/28955/Lambton-College/Pharmacy-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "triOS College (Kitchener Campus)",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Assistant",
    "programLink": "https://www.studyincanada.com/Programs/26320/triOS-College-(Kitchener-Campus)/Pharmacy-Assistant",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "32 Week(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Pharmacy (PhD)",
    "programLink": "https://www.studyincanada.com/Programs/111615/University-of-Manitoba-Faculty-of-Graduate-Studies/Pharmacy-(PhD)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "triOS College (Mississauga Campus)",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Assistant",
    "programLink": "https://www.studyincanada.com/Programs/26348/triOS-College-(Mississauga-Campus)/Pharmacy-Assistant",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "32 Week(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Pharmacology",
    "programLink": "https://www.studyincanada.com/Programs/65658/McGill-University/Pharmacology",
    "entranceGrade": "95",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Pharmaceutical Sciences",
    "programLink": "https://www.studyincanada.com/Programs/104478/The-University-of-British-Columbia-Vancouver/Pharmaceutical-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Technician",
    "programLink": "https://www.studyincanada.com/Programs/23261/Mohawk-College/Pharmacy-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$18,473",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "CDI College (Mississauga Campus)",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Assistant",
    "programLink": "https://www.studyincanada.com/Programs/71635/CDI-College-(Mississauga-Campus)/Pharmacy-Assistant",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "45 Week(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Technician",
    "programLink": "https://www.studyincanada.com/Programs/69801/Georgian-College/Pharmacy-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$14,329",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Pharmacology",
    "programLink": "https://www.studyincanada.com/Programs/77619/The-University-of-British-Columbia-Vancouver/Pharmacology",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Pharmacy (Entry-to-Practice)",
    "programLink": "https://www.studyincanada.com/Programs/7636/The-University-of-British-Columbia-Vancouver/Pharmacy-(Entry-to-Practice)",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Technician",
    "programLink": "https://www.studyincanada.com/Programs/1587/Humber-College-Institute-of-Technology-&-Advanced-Learning/Pharmacy-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$16,394",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Red Deer Polytechnic",
    "location": "Canada, Alberta",
    "programName": "Pharmacy Technician",
    "programLink": "https://www.studyincanada.com/Programs/8807/Red-Deer-Polytechnic/Pharmacy-Technician",
    "entranceGrade": "60 - 100",
    "tutionValue": "$22,109",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Pharmacy",
    "programLink": "https://www.studyincanada.com/Programs/11544/University-of-Saskatchewan/Pharmacy",
    "entranceGrade": "70",
    "tutionValue": "$17,687",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saskatchewan Polytechnic",
    "location": "Canada, Saskatchewan",
    "programName": "Pharmacy Technician",
    "programLink": "https://www.studyincanada.com/Programs/100091/Saskatchewan-Polytechnic/Pharmacy-Technician",
    "entranceGrade": "65 - 100",
    "tutionValue": "N/S",
    "length": "73 Week(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Pharmacology",
    "programLink": "https://www.studyincanada.com/Programs/77493/The-University-of-British-Columbia-Vancouver/Pharmacology",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Pharmacy Assistant",
    "programLink": "https://www.studyincanada.com/Programs/16644/SAIT/Pharmacy-Assistant",
    "entranceGrade": "60 - 60",
    "tutionValue": "N/S",
    "length": "5 Month(s)"
  },
  {
    "schoolName": "triOS College (Windsor Campus)",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Assistant",
    "programLink": "https://www.studyincanada.com/Programs/26335/triOS-College-(Windsor-Campus)/Pharmacy-Assistant",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "32 Week(s)"
  },
  {
    "schoolName": "Herzing College - Ottawa",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Assistance",
    "programLink": "https://www.studyincanada.com/Programs/17962/Herzing-College-Ottawa/Pharmacy-Assistance",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1400 Hour(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Pharmacy",
    "programLink": "https://www.studyincanada.com/Programs/13516/University-of-Manitoba/Pharmacy",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "triOS College",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Assistant",
    "programLink": "https://www.studyincanada.com/Programs/26341/triOS-College/Pharmacy-Assistant",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "32 Week(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Entry to Pharmacy",
    "programLink": "https://www.studyincanada.com/Programs/81368/Selkirk-College/Entry-to-Pharmacy",
    "entranceGrade": "N/S",
    "tutionValue": "$13,409",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Pharmacy Technician Bridging National Education Program",
    "programLink": "https://www.studyincanada.com/Programs/81395/Selkirk-College/Pharmacy-Technician-Bridging-National-Education-Program",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Medix School - Brampton",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Assistant",
    "programLink": "https://www.studyincanada.com/Programs/95314/Medix-School-Brampton/Pharmacy-Assistant",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Technician",
    "programLink": "https://www.studyincanada.com/Programs/4170/Fanshawe-College/Pharmacy-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$15,629",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Pharmacology",
    "programLink": "https://www.studyincanada.com/Programs/53050/The-University-of-British-Columbia-Vancouver/Pharmacology",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "New Brunswick Community College",
    "location": "Canada, New Brunswick",
    "programName": "Pharmacy Technician",
    "programLink": "https://www.studyincanada.com/Programs/14109/New-Brunswick-Community-College/Pharmacy-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$9,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Pharmacy (MSc)",
    "programLink": "https://www.studyincanada.com/Programs/111614/University-of-Manitoba-Faculty-of-Graduate-Studies/Pharmacy-(MSc)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "triOS College (Hamilton Campus)",
    "location": "Canada, Ontario",
    "programName": "Pharmacy Assistant",
    "programLink": "https://www.studyincanada.com/Programs/26326/triOS-College-(Hamilton-Campus)/Pharmacy-Assistant",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "32 Week(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Pre-Pharmacy",
    "programLink": "https://www.studyincanada.com/Programs/68598/The-University-of-Winnipeg/Pre-Pharmacy",
    "entranceGrade": "N/S",
    "tutionValue": "$15,810",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Pre-Physical Therapy",
    "programLink": "https://www.studyincanada.com/Programs/105187/The-University-of-Winnipeg/Pre-Physical-Therapy",
    "entranceGrade": "N/S",
    "tutionValue": "$15,810",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Physical Therapy",
    "programLink": "https://www.studyincanada.com/Programs/81039/McGill-University/Physical-Therapy",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Canadian Mennonite University",
    "location": "Canada, Manitoba",
    "programName": "Physical Therapy (Pre-Professional)",
    "programLink": "https://www.studyincanada.com/Programs/97489/Canadian-Mennonite-University/Physical-Therapy-(Pre-Professional)",
    "entranceGrade": "N/S",
    "tutionValue": "$10,208",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Pre-Physical Therapy",
    "programLink": "https://www.studyincanada.com/Programs/71829/University-of-Regina/Pre-Physical-Therapy",
    "entranceGrade": "65 - 70",
    "tutionValue": "$12,049",
    "length": "N/S"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Physical Therapy (MPT)",
    "programLink": "https://www.studyincanada.com/Programs/111616/University-of-Manitoba-Faculty-of-Graduate-Studies/Physical-Therapy-(MPT)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Medical Laboratory Science",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/index.html#sort=relevancy",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Medical Laboratory Science",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-science-medical-laboratory-science.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Medical Laboratory Science",
    "programLink": "https://www.studyincanada.com/Programs/28255/British-Columbia-Institute-of-Technology/Medical-Laboratory-Science",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "30 Month(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Medical Laboratory Science",
    "programLink": "https://www.studyincanada.com/Programs/1732/The-University-of-British-Columbia-Vancouver/Medical-Laboratory-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$55,849",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Medical Laboratory Science",
    "programLink": "https://www.studyincanada.com/Programs/6598/St.-Clair-College/Medical-Laboratory-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$15,685",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Red River College Polytechnic",
    "location": "Canada, Manitoba",
    "programName": "Medical Laboratory Sciences",
    "programLink": "https://www.studyincanada.com/Programs/11714/Red-River-College-Polytechnic/Medical-Laboratory-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "22 Month(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Medical Laboratory Science",
    "programLink": "https://www.studyincanada.com/Programs/8859/St.-Lawrence-College/Medical-Laboratory-Science",
    "entranceGrade": "85",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Huron University College",
    "location": "Canada, Ontario",
    "programName": "Psychology (Specialization)",
    "programLink": "https://huronu.ca/programs/arts-social-science/psychology/",
    "entranceGrade": "N/S",
    "tutionValue": "$46,079",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Neuroscience (BSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/neuroscience.html",
    "entranceGrade": "85",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Psychology (BA)",
    "programLink": "https://admissions.carleton.ca/programs/psychology-ba/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Psychology (Bachelor of Science)",
    "programLink": "https://uwaterloo.ca/future-students/programs/psychology-science?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$50,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Psychology (BSc - Business Minor)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-psychology.html",
    "entranceGrade": "67 - 69",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Psychologie Sciences",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/baccalaureat-es-sciences-psychologie-sciences.html",
    "entranceGrade": "70 - 70",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Psychology (Bachelor of Arts)",
    "programLink": "https://uwaterloo.ca/future-students/programs/psychology-arts?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$55,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Psychology (BA)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-psychology.html",
    "entranceGrade": "72 - 72",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Psychology (BSc) Brantford Campus",
    "programLink": "https://wlu.ca/programs/science/undergraduate/psychology-bsc-brantford/index.html",
    "entranceGrade": "72 - 76",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Psychology (BSc)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-psychology.html",
    "entranceGrade": "73 - 75",
    "tutionValue": "$33,968",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Psychology/Psychologie",
    "programLink": "https://futurestudents.yorku.ca/program/psychology/glendon",
    "entranceGrade": "75 - 100",
    "tutionValue": "$24,587",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "King's University College",
    "location": "Canada, Ontario",
    "programName": "Psychology",
    "programLink": "https://www.kings.uwo.ca/academics/psychology/",
    "entranceGrade": "80",
    "tutionValue": "$37,476",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Psychology (BA)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-psychology.html",
    "entranceGrade": "73 - 75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Psychology (BSc) Waterloo Campus",
    "programLink": "https://wlu.ca/programs/science/undergraduate/psychology-bsc/index.html",
    "entranceGrade": "72 - 76",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Psychology (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/psychology-bsc/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Psychology",
    "programLink": "https://futurestudents.yorku.ca/program/psychology/health",
    "entranceGrade": "75 - 100",
    "tutionValue": "$24,587",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Huron University College",
    "location": "Canada, Ontario",
    "programName": "Psychology (Major)",
    "programLink": "https://huronu.ca/programs/arts-social-science/psychology/",
    "entranceGrade": "N/S",
    "tutionValue": "$46,079",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Psychology (BA)",
    "programLink": "https://admissions.carleton.ca/programs/psychology-ba/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Psychology and Neuroscience (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/psychology-and-neuroscience-bsc/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Psychology (BA, BSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/psychology.html",
    "entranceGrade": "88",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Psychologie (BA)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/baccalaureat-es-arts-psychologie.html",
    "entranceGrade": "70 - 70",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Psychology (BSc)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-psychology.html",
    "entranceGrade": "80",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Psychology (BA) Milton Campus",
    "programLink": "https://wlu.ca/programs/science/undergraduate/psychology-ba-milton/index.html",
    "entranceGrade": "72 - 76",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Psychology (BA)",
    "programLink": "https://admissions.carleton.ca/programs/psychology-ba/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Psychology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/psychology/",
    "entranceGrade": "86 - 90",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Psychology",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/Arts/Departments/Psychology/PsychologyMajor/index.htm",
    "entranceGrade": "85 - 90",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Psychology (BA) Brantford Campus",
    "programLink": "https://www.wlu.ca/programs/science/undergraduate/psychology-ba-brantford/index.html?ref=programs%2Fhuman-and-social-sciences%2Fundergraduate%2Fpsychology-ba%2Findex.html",
    "entranceGrade": "72 - 76",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "King's University College",
    "location": "Canada, Ontario",
    "programName": "Psychology",
    "programLink": "https://www.kings.uwo.ca/academics/psychology/",
    "entranceGrade": "80",
    "tutionValue": "$37,476",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Psychology (BSc with Specialization)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-psychology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Psychology (BA) Waterloo Campus",
    "programLink": "https://wlu.ca/programs/science/undergraduate/psychology-ba/index.html",
    "entranceGrade": "72 - 76",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Biology-Psychology",
    "programLink": "https://www.studyincanada.com/Programs/15525/University-of-New-Brunswick-Saint-John/Biology-Psychology",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Alexander College",
    "location": "Canada, British Columbia",
    "programName": "Associate of Arts (Business and Psychology)",
    "programLink": "https://www.studyincanada.com/Programs/112955/Alexander-College/Associate-of-Arts-(Business-and-Psychology)",
    "entranceGrade": "N/S",
    "tutionValue": "$18,300",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Alexander College",
    "location": "Canada, British Columbia",
    "programName": "Associate of Arts (Psychology)",
    "programLink": "https://www.studyincanada.com/Programs/85659/Alexander-College/Associate-of-Arts-(Psychology)",
    "entranceGrade": "N/S",
    "tutionValue": "$18,300",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Biology-Psychology",
    "programLink": "https://www.studyincanada.com/Programs/22945/University-of-New-Brunswick-Saint-John/Biology-Psychology",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/22915/University-of-New-Brunswick-Saint-John/Psychology-(B.A.)",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph-Humber",
    "location": "Canada, Ontario",
    "programName": "Psychology",
    "programLink": "https://www.guelphhumber.ca/futurestudents/psychology",
    "entranceGrade": "75 - 80",
    "tutionValue": "$31,833",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Forensic Psychology",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/social-science-and-humanities/forensic-psychology/index.php",
    "entranceGrade": "70",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Forensic Psychology - GAS Transfer",
    "programLink": "https://ontariotechu.ca/programs/social-science-and-humanities/forensic-psychology-gas-transfer.php",
    "entranceGrade": "75",
    "tutionValue": "$29,196",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Psychology (Bachelor of Science)",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/social-science-and-humanities/psychology-bachelor-of-science/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Psychology (Bachelor of Arts)",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/social-science-and-humanities/psychology-bachelor-of-arts/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Forensic Psychology - Advanced Entry",
    "programLink": "https://ontariotechu.ca/programs/social-science-and-humanities/forensic-psychology-advanced-entry.php",
    "entranceGrade": "70",
    "tutionValue": "$29,196",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Psychology - Advanced Entry",
    "programLink": "https://ontariotechu.ca/programs/college-to-university-transfer/social-science-and-humanities/psychology-advanced-entry/index.php",
    "entranceGrade": "75 - 100",
    "tutionValue": "$29,196",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Psychology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/108678/Lakehead-University-Orillia/Psychology-(BSc)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Psychology (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8509/University-of-Toronto-St.-George/Psychology-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Psychology and School Psychology (PhD)",
    "programLink": "https://www.studyincanada.com/Programs/13528/University-of-Manitoba-Faculty-of-Graduate-Studies/Psychology-and-School-Psychology-(PhD)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trinity Western University",
    "location": "Canada, British Columbia",
    "programName": "Psychology",
    "programLink": "https://www.studyincanada.com/Programs/2248/Trinity-Western-University/Psychology",
    "entranceGrade": "67 - 100",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Psychology",
    "programLink": "https://www.studyincanada.com/Programs/4727/University-of-Windsor/Psychology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$34,641",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Red Deer Polytechnic",
    "location": "Canada, Alberta",
    "programName": "Psychology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/46182/Red-Deer-Polytechnic/Psychology-(B.Sc.)",
    "entranceGrade": "60 - 60",
    "tutionValue": "$29,962",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Adler University (Vancouver Campus)",
    "location": "Canada, British Columbia",
    "programName": "Counselling Psychology (M.A.C.)",
    "programLink": "https://www.studyincanada.com/Programs/28899/Adler-University-(Vancouver-Campus)/Counselling-Psychology-(M.A.C.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Psychology (BA or BSc)",
    "programLink": "https://www.studyincanada.com/Programs/25136/Acadia-University/Psychology-(BA-or-BSc)",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University - Glendon Campus",
    "location": "Canada, Ontario",
    "programName": "Psychology (iBSc)",
    "programLink": "https://www.studyincanada.com/Programs/109391/York-University-Glendon-Campus/Psychology-(iBSc)",
    "entranceGrade": "75 - 79",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Psychology: Clinical",
    "programLink": "https://www.studyincanada.com/Programs/2714/Lakehead-University-Faculty-of-Graduate-Studies/Psychology-Clinical",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Psychology (BAA)",
    "programLink": "https://www.studyincanada.com/Programs/64418/Kwantlen-Polytechnic-University/Psychology-(BAA)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto: Ontario Institute for Studies in Education",
    "location": "Canada, Ontario",
    "programName": "School and Clinical Child Psychology (MA)",
    "programLink": "https://www.studyincanada.com/Programs/59885/University-of-Toronto-Ontario-Institute-for-Studies-in-Education/School-and-Clinical-Child-Psychology-(MA)",
    "entranceGrade": "N/S",
    "tutionValue": "$27,520",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Psychology (B.A)",
    "programLink": "https://www.studyincanada.com/Programs/9825/Saint-Mary-s-University/Psychology-(B.A)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Psychologie du sport",
    "programLink": "https://www.studyincanada.com/Programs/78362/Laurentian-University/Universite-Laurentienne/Psychologie-du-sport",
    "entranceGrade": "75",
    "tutionValue": "$23,747",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Tyndale University",
    "location": "Canada, Ontario",
    "programName": "Psychology",
    "programLink": "https://www.studyincanada.com/Programs/21027/Tyndale-University/Psychology",
    "entranceGrade": "65 - 100",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Psychology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/96445/Kwantlen-Polytechnic-University/Psychology-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Psychology",
    "programLink": "https://www.ufv.ca/psychology/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Psychology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/107432/Brandon-University/Psychology-(B.Sc.)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Psychology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/102025/St.-Francis-Xavier-University/Psychology-(B.Sc.)",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Public Health and Preventive Medicine Residency",
    "programLink": "https://www.studyincanada.com/Programs/111582/University-of-Manitoba-Faculty-of-Graduate-Studies/Public-Health-and-Preventive-Medicine-Residency",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Psychology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/7585/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Psychology-(BA)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Psychology (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/58309/University-of-Toronto-Scarborough/Psychology-(HBSc)",
    "entranceGrade": "84 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Grenfell Campus, Memorial University",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Psychology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/96677/Grenfell-Campus,-Memorial-University/Psychology-(BA)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$11,460",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Applied Social Psychology",
    "programLink": "https://www.studyincanada.com/Programs/4729/University-of-Windsor-Faculty-of-Graduate-Studies/Applied-Social-Psychology",
    "entranceGrade": "N/S",
    "tutionValue": "$8,863",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Psychology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/29929/The-University-of-British-Columbia-Okanagan/Psychology-(BSc)",
    "entranceGrade": "76",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Psychology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/12299/University-of-Victoria/Psychology-(BA)",
    "entranceGrade": "78 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Psychology (B.A.) - Applied",
    "programLink": "https://www.studyincanada.com/Programs/70755/Bishop-s-University/Psychology-(B.A.)-Applied",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/107425/Brandon-University/Psychology-(B.A.)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Honours Bachelor of Counselling Psychology",
    "programLink": "https://www.studyincanada.com/Programs/104959/Georgian-College/Honours-Bachelor-of-Counselling-Psychology",
    "entranceGrade": "65 - 100",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Psychology and Computer Science",
    "programLink": "https://www.studyincanada.com/Programs/30677/University-of-Victoria/Psychology-and-Computer-Science",
    "entranceGrade": "75 - 100",
    "tutionValue": "$27,965",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Psychology (M.A.)",
    "programLink": "https://www.studyincanada.com/Programs/10322/University-of-Lethbridge-School-of-Graduate-Studies/Psychology-(M.A.)",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Psychology (BA)/Concurrent Education (BEd)/Early Childhood Educa...",
    "programLink": "https://www.studyincanada.com/Programs/114213/University-of-Windsor/Psychology-(BA)/Concurrent-Education-(BEd)/Early-Childhood-Educa...",
    "entranceGrade": "75 - 100",
    "tutionValue": "$34,617",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/107433/Brandon-University/Psychology-(B.A.)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University of Edmonton",
    "location": "Canada, Alberta",
    "programName": "Psychology (B.A. 4-year)",
    "programLink": "https://www.studyincanada.com/Programs/85861/Concordia-University-of-Edmonton/Psychology-(B.A.-4-year)",
    "entranceGrade": "60",
    "tutionValue": "$25,414",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "College of the Rockies",
    "location": "Canada, British Columbia",
    "programName": "Applied Psychology",
    "programLink": "https://www.studyincanada.com/Programs/15892/College-of-the-Rockies/Applied-Psychology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "HBA/ Psychology",
    "programLink": "https://www.studyincanada.com/Programs/107570/Western-University/HBA/-Psychology",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/46238/Vancouver-Island-University/Psychology-(B.A.)",
    "entranceGrade": "N/S",
    "tutionValue": "$24,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Psychology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/27398/Brandon-University/Psychology-(B.Sc.)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Psychology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/109471/Queen-s-University/Psychology-(BA)",
    "entranceGrade": "85",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/65655/McGill-University/Psychology-(B.A.)",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/161/Trent-University/Psychology-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Allison University",
    "location": "Canada, New Brunswick",
    "programName": "Psychology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/85407/Mount-Allison-University/Psychology-(BA)",
    "entranceGrade": "65",
    "tutionValue": "$20,885",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Psychology",
    "programLink": "https://www.studyincanada.com/Programs/87554/Lakehead-University-Thunder-Bay/Psychology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Psychology (Arts)",
    "programLink": "https://www.studyincanada.com/Programs/11784/Camosun-College/Psychology-(Arts)",
    "entranceGrade": "N/S",
    "tutionValue": "$14,000",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/96736/Vancouver-Island-University/Psychology-(B.A.)",
    "entranceGrade": "N/S",
    "tutionValue": "$24,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Mental Health and Addiction Worker",
    "programLink": "https://www.studyincanada.com/Programs/27263/Canadore-College/Mental-Health-and-Addiction-Worker",
    "entranceGrade": "N/S",
    "tutionValue": "$17,528",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Grenfell Campus, Memorial University",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Psychology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/96679/Grenfell-Campus,-Memorial-University/Psychology-(BSc)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$11,460",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Human Kinetics - Sport Psychology (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/9509/Laurentian-University/Universite-Laurentienne/Human-Kinetics-Sport-Psychology-(HBA)",
    "entranceGrade": "75",
    "tutionValue": "$23,747",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Psychology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/51280/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Psychology-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Biology and Psychology",
    "programLink": "https://www.studyincanada.com/Programs/85195/Queen-s-University/Biology-and-Psychology",
    "entranceGrade": "80 - 100",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan - College of Graduate Studies",
    "location": "Canada, British Columbia",
    "programName": "Psychology - Psychological Science",
    "programLink": "https://www.studyincanada.com/Programs/102723/The-University-of-British-Columbia-Okanagan-College-of-Graduate-Studies/Psychology-Psychological-Science",
    "entranceGrade": "76",
    "tutionValue": "$9,314",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University of Edmonton",
    "location": "Canada, Alberta",
    "programName": "Psychology (B.A. 3-year)",
    "programLink": "https://www.studyincanada.com/Programs/56874/Concordia-University-of-Edmonton/Psychology-(B.A.-3-year)",
    "entranceGrade": "60",
    "tutionValue": "$25,414",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Behavioural Neuroscience",
    "programLink": "https://www.studyincanada.com/Programs/81197/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Behavioural-Neuroscience",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Behavioural Psychology",
    "programLink": "https://www.studyincanada.com/Programs/113301/Fanshawe-College/Behavioural-Psychology",
    "entranceGrade": "65 - 65",
    "tutionValue": "$18,585",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Psychology (BSc or BA)",
    "programLink": "https://www.studyincanada.com/Programs/14874/Dalhousie-University/Psychology-(BSc-or-BA)",
    "entranceGrade": "75",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Clinical Psychology",
    "programLink": "https://www.studyincanada.com/Programs/4731/University-of-Windsor-Faculty-of-Graduate-Studies/Clinical-Psychology",
    "entranceGrade": "N/S",
    "tutionValue": "$8,863",
    "length": "6 Year(s)"
  },
  {
    "schoolName": "Mount Allison University",
    "location": "Canada, New Brunswick",
    "programName": "Psychology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/109461/Mount-Allison-University/Psychology-(BA)",
    "entranceGrade": "65",
    "tutionValue": "$20,885",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Capilano University",
    "location": "Canada, British Columbia",
    "programName": "Bachelor of Arts with a Major in Psychology",
    "programLink": "https://www.studyincanada.com/Programs/107792/Capilano-University/Bachelor-of-Arts-with-a-Major-in-Psychology",
    "entranceGrade": "N/S",
    "tutionValue": "$21,499",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "MA Clinical Psychology: Gender and Women's Studies Specializatio...",
    "programLink": "https://www.studyincanada.com/Programs/2712/Lakehead-University-Faculty-of-Graduate-Studies/MA-Clinical-Psychology-Gender-and-Women-s-Studies-Specializatio...",
    "entranceGrade": "70",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Forensic Health Sciences",
    "programLink": "https://www.studyincanada.com/Programs/28099/British-Columbia-Institute-of-Technology/Forensic-Health-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Psychology",
    "programLink": "https://www.studyincanada.com/Programs/77404/The-University-of-British-Columbia-Vancouver/Psychology",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Psychology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/65670/McGill-University/Psychology-(B.Sc.)",
    "entranceGrade": "95",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Psychology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/56226/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Psychology-(BA)",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Health & Behaviour (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/107796/Trent-University/Health-&-Behaviour-(B.Sc.)",
    "entranceGrade": "75",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Psychology, Neuroscience and Behaviour (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/5486/McMaster-University/Psychology,-Neuroscience-and-Behaviour-(BSc)",
    "entranceGrade": "87 - 92",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Prince Edward Island",
    "location": "Canada, Prince Edward Island",
    "programName": "Psychology (B.Sc)",
    "programLink": "https://www.studyincanada.com/Programs/45719/University-of-Prince-Edward-Island/Psychology-(B.Sc)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$14,274",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Psychology",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/psychology-co-op-psyc-c/",
    "entranceGrade": "80 - 83",
    "tutionValue": "$32,594",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Applied Social Psychology",
    "programLink": "https://www.studyincanada.com/Programs/92296/University-of-Windsor-Faculty-of-Graduate-Studies/Applied-Social-Psychology",
    "entranceGrade": "N/S",
    "tutionValue": "$8,863",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Psychology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/64228/Trent-University/Psychology-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Alberta - Augustana Campus",
    "location": "Canada, Alberta",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/21927/University-of-Alberta-Augustana-Campus/Psychology-(B.A.)",
    "entranceGrade": "70 - 70",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "MacEwan University",
    "location": "Canada, Alberta",
    "programName": "Psychology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/109506/MacEwan-University/Psychology-(BSc)",
    "entranceGrade": "67 - 76",
    "tutionValue": "$24,378",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/22994/University-of-New-Brunswick-Fredericton/Psychology-(B.A.)",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Capilano University",
    "location": "Canada, British Columbia",
    "programName": "Psychology - Associate",
    "programLink": "https://www.studyincanada.com/Programs/30733/Capilano-University/Psychology-Associate",
    "entranceGrade": "N/S",
    "tutionValue": "$21,499",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Toronto: Ontario Institute for Studies in Education",
    "location": "Canada, Ontario",
    "programName": "Developmental Psychology and Education (PhD)",
    "programLink": "https://www.studyincanada.com/Programs/59884/University-of-Toronto-Ontario-Institute-for-Studies-in-Education/Developmental-Psychology-and-Education-(PhD)",
    "entranceGrade": "N/S",
    "tutionValue": "$6,210",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/4402/Bishop-s-University/Psychology-(B.A.)",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Applied Forensic Psychology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/106976/St.-Francis-Xavier-University/Applied-Forensic-Psychology-(B.Sc.)",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Psychology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/12300/University-of-Victoria/Psychology-(BSc)",
    "entranceGrade": "78 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto: Ontario Institute for Studies in Education",
    "location": "Canada, Ontario",
    "programName": "Developmental Psychology and Education (MEd)",
    "programLink": "https://www.studyincanada.com/Programs/20980/University-of-Toronto-Ontario-Institute-for-Studies-in-Education/Developmental-Psychology-and-Education-(MEd)",
    "entranceGrade": "73",
    "tutionValue": "$41,600",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Psychology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/96269/Saint-Mary-s-University/Psychology-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$22,060",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Adler University (Vancouver Campus)",
    "location": "Canada, British Columbia",
    "programName": "Counselling Psychology (M.C.P. non-thesis)",
    "programLink": "https://www.studyincanada.com/Programs/69042/Adler-University-(Vancouver-Campus)/Counselling-Psychology-(M.C.P.-non-thesis)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Psychology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/68422/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Psychology-(BA)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "General Arts & Science - Behavioural Psychology Stream",
    "programLink": "https://www.studyincanada.com/Programs/83057/St.-Lawrence-College/General-Arts-&-Science-Behavioural-Psychology-Stream",
    "entranceGrade": "N/S",
    "tutionValue": "$14,600",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Psychology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/39828/McGill-University/Psychology-(B.Sc.)",
    "entranceGrade": "95",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/7385/McGill-University/Psychology-(B.A.)",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Psychology (B.A.) - concentration in Forensic Psychology",
    "programLink": "https://www.studyincanada.com/Programs/106993/St.-Francis-Xavier-University/Psychology-(B.A.)-concentration-in-Forensic-Psychology",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Biology and Psychology, Neuroscience & Behaviour",
    "programLink": "https://www.studyincanada.com/Programs/100320/McMaster-University/Biology-and-Psychology,-Neuroscience-&-Behaviour",
    "entranceGrade": "87 - 92",
    "tutionValue": "$48,996",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Psychology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/31203/Nipissing-University/Psychology-(BA)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Psychology - Specialization in Health & Well-Being (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/109895/Trent-University/Psychology-Specialization-in-Health-&-Well-Being-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "General Arts: Bachelor of Arts Degree - Major",
    "programLink": "https://www.studyincanada.com/Programs/107882/Thompson-Rivers-University/General-Arts-Bachelor-of-Arts-Degree-Major",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "N/S"
  },
  {
    "schoolName": "University of Toronto: Ontario Institute for Studies in Education",
    "location": "Canada, Ontario",
    "programName": "Counselling Psychology (MEd)",
    "programLink": "https://www.studyincanada.com/Programs/114383/University-of-Toronto-Ontario-Institute-for-Studies-in-Education/Counselling-Psychology-(MEd)",
    "entranceGrade": "N/S",
    "tutionValue": "$41,600",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Psychology (Arts) (Internship Designation)",
    "programLink": "https://www.studyincanada.com/Programs/90824/Camosun-College/Psychology-(Arts)-(Internship-Designation)",
    "entranceGrade": "N/S",
    "tutionValue": "$14,000",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Psychology (B.A. Advanced Major)",
    "programLink": "https://www.studyincanada.com/Programs/59960/University-of-Manitoba/Psychology-(B.A.-Advanced-Major)",
    "entranceGrade": "85 - 85",
    "tutionValue": "$16,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Prince Edward Island",
    "location": "Canada, Prince Edward Island",
    "programName": "Psychology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/10361/University-of-Prince-Edward-Island/Psychology-(BA)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$14,274",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Psychology (B.A)",
    "programLink": "https://www.studyincanada.com/Programs/9824/Saint-Mary-s-University/Psychology-(B.A)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/160/Trent-University/Psychology-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/96270/Saint-Mary-s-University/Psychology-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Forensic Investigation (Crime and Intelligence Analysis Option)",
    "programLink": "https://www.studyincanada.com/Programs/33673/British-Columbia-Institute-of-Technology/Forensic-Investigation-(Crime-and-Intelligence-Analysis-Option)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biology/Psychology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/68447/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Biology/Psychology-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "MacEwan University",
    "location": "Canada, Alberta",
    "programName": "Psychology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/109505/MacEwan-University/Psychology-(BSc)",
    "entranceGrade": "67 - 76",
    "tutionValue": "$24,378",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Neuroscience (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/55683/Bishop-s-University/Neuroscience-(B.Sc.)",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brock University",
    "location": "Canada, Ontario",
    "programName": "Psychology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/111412/Brock-University/Psychology-(BSc)",
    "entranceGrade": "N/S",
    "tutionValue": "$31,622",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Tyndale University",
    "location": "Canada, Ontario",
    "programName": "Health Sciences Professional with a Minor in Psychology",
    "programLink": "https://www.studyincanada.com/Programs/81840/Tyndale-University/Health-Sciences-Professional-with-a-Minor-in-Psychology",
    "entranceGrade": "65 - 100",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Behavioural Sciences",
    "programLink": "https://www.studyincanada.com/Programs/73168/Seneca-Polytechnic/Behavioural-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$16,043",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Honours Bachelor of Behavioural Psychology",
    "programLink": "https://www.studyincanada.com/Programs/90780/Seneca-Polytechnic/Honours-Bachelor-of-Behavioural-Psychology",
    "entranceGrade": "65",
    "tutionValue": "$19,487",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Psychology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/108677/Lakehead-University-Orillia/Psychology-(BSc)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/37387/University-of-Manitoba/Psychology-(B.A.)",
    "entranceGrade": "85 - 85",
    "tutionValue": "$16,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/90592/Brandon-University/Psychology-(B.A.)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Psychology (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/86523/Laurentian-University/Universite-Laurentienne/Psychology-(HBA)",
    "entranceGrade": "N/S",
    "tutionValue": "$23,747",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Psychology",
    "programLink": "https://www.studyincanada.com/Programs/48498/Douglas-College/Psychology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Prince Edward Island",
    "location": "Canada, Prince Edward Island",
    "programName": "Psychology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/45718/University-of-Prince-Edward-Island/Psychology-(BA)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$14,274",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Psychology",
    "programLink": "https://www.studyincanada.com/Programs/45530/Kwantlen-Polytechnic-University/Psychology",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Psychology (BA or BSc)",
    "programLink": "https://www.studyincanada.com/Programs/1354/Acadia-University/Psychology-(BA-or-BSc)",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Psychology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/32679/Brandon-University/Psychology-(B.A.)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Psychology (B.Sc. Double Honours)",
    "programLink": "https://www.studyincanada.com/Programs/73056/University-of-Saskatchewan/Psychology-(B.Sc.-Double-Honours)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Athabasca University",
    "location": "Canada, Alberta",
    "programName": "Psychology (Three Year)",
    "programLink": "https://www.studyincanada.com/Programs/9071/Athabasca-University/Psychology-(Three-Year)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto: Ontario Institute for Studies in Education",
    "location": "Canada, Ontario",
    "programName": "Counselling Psychology (EdD)",
    "programLink": "https://www.studyincanada.com/Programs/114382/University-of-Toronto-Ontario-Institute-for-Studies-in-Education/Counselling-Psychology-(EdD)",
    "entranceGrade": "N/S",
    "tutionValue": "$40,260",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The King's University",
    "location": "Canada, Alberta",
    "programName": "Social Sciences",
    "programLink": "https://www.studyincanada.com/Programs/6182/The-King-s-University/Social-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$11,778",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Psychology",
    "programLink": "https://www.studyincanada.com/Programs/55604/University-of-Northern-British-Columbia/Psychology",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Redeemer University",
    "location": "Canada, Ontario",
    "programName": "Psychology",
    "programLink": "https://www.studyincanada.com/Programs/739/Redeemer-University/Psychology",
    "entranceGrade": "N/S",
    "tutionValue": "$19,494",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Psychology",
    "programLink": "https://www.studyincanada.com/Programs/77403/The-University-of-British-Columbia-Vancouver/Psychology",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University of Edmonton",
    "location": "Canada, Alberta",
    "programName": "Psychology - Applied Emphasis (B.A. 4-year)",
    "programLink": "https://www.studyincanada.com/Programs/62954/Concordia-University-of-Edmonton/Psychology-Applied-Emphasis-(B.A.-4-year)",
    "entranceGrade": "60",
    "tutionValue": "$25,414",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Yorkville University - Graduate School",
    "location": "Canada, New Brunswick",
    "programName": "Master of Arts in Counselling Psychology (MACP)",
    "programLink": "https://www.studyincanada.com/Programs/110296/Yorkville-University-Graduate-School/Master-of-Arts-in-Counselling-Psychology-(MACP)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Arts | Psychology",
    "programLink": "https://www.studyincanada.com/Programs/109244/University-of-Lethbridge/Bachelor-of-Arts-|-Psychology",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Psychological Science: Gender and Women's Studies Specialization...",
    "programLink": "https://www.studyincanada.com/Programs/22031/Lakehead-University-Faculty-of-Graduate-Studies/Psychological-Science-Gender-and-Women-s-Studies-Specialization...",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Psychology (B.A. Double Honours)",
    "programLink": "https://www.studyincanada.com/Programs/69559/University-of-Saskatchewan/Psychology-(B.A.-Double-Honours)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Psychology",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/psychology-psyc/",
    "entranceGrade": "80 - 85",
    "tutionValue": "$32,594",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Politics and Governance and Sociology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/politics-governance/",
    "entranceGrade": "75 - 80",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/sociology/",
    "entranceGrade": "74 - 77",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Sociology (BA)",
    "programLink": "https://admissions.carleton.ca/programs/sociology/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Sociology",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-sociology.html",
    "entranceGrade": "72 - 72",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Sociology (BA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/sociology.html",
    "entranceGrade": "85",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Sociologie",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/baccalaureat-es-arts-sociologie.html",
    "entranceGrade": "70 - 70",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Criminology and Sociology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/criminology/",
    "entranceGrade": "80 - 88",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Sociology/Sociologie",
    "programLink": "https://futurestudents.yorku.ca/program/sociology/laps",
    "entranceGrade": "74 - 76",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "King's University College",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.kings.uwo.ca/academics/sociology/",
    "entranceGrade": "80",
    "tutionValue": "$37,476",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://futurestudents.yorku.ca/program/sociology/laps",
    "entranceGrade": "75 - 100",
    "tutionValue": "$24,587",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Sociology and Politics and Governance",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/sociology/",
    "entranceGrade": "74 - 77",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Anthropology and Sociology (BA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/anthropology-and-sociology.html",
    "entranceGrade": "72",
    "tutionValue": "$32,450",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Sociology (BA)",
    "programLink": "https://admissions.carleton.ca/programs/sociology/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Sociology",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-with-honors-sociology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Sociology",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/Arts/Departments/SociologyAnthropology/SociologyProgram/index.htm",
    "entranceGrade": "65 - 70",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Sociology (BA)",
    "programLink": "https://wlu.ca/programs/arts/undergraduate/sociology-ba/index.html",
    "entranceGrade": "75 - 76",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://uwaterloo.ca/future-students/programs/sociology?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$55,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "History and Sociology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/history/",
    "entranceGrade": "70 - 72",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "King's University College",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.kings.uwo.ca/academics/sociology/",
    "entranceGrade": "80",
    "tutionValue": "$37,476",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Sociology and History",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/sociology/",
    "entranceGrade": "74 - 77",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Sociology (BA)",
    "programLink": "https://admissions.carleton.ca/programs/sociology/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Sociology and Criminology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/sociology/",
    "entranceGrade": "74 - 77",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Alexander College",
    "location": "Canada, British Columbia",
    "programName": "Associate of Arts (Sociology)",
    "programLink": "https://www.studyincanada.com/Programs/104197/Alexander-College/Associate-of-Arts-(Sociology)",
    "entranceGrade": "N/S",
    "tutionValue": "$18,300",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/22916/University-of-New-Brunswick-Saint-John/Sociology",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/22926/University-of-New-Brunswick-Saint-John/Sociology",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/1695/The-University-of-British-Columbia-Vancouver/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/23008/University-of-New-Brunswick-Fredericton/Sociology",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Athabasca University",
    "location": "Canada, Alberta",
    "programName": "Sociology (Four Year)",
    "programLink": "https://www.studyincanada.com/Programs/45581/Athabasca-University/Sociology-(Four-Year)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "St. Thomas University",
    "location": "Canada, New Brunswick",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/13277/St.-Thomas-University/Sociology",
    "entranceGrade": "70",
    "tutionValue": "$17,208",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Sociologie (BA)",
    "programLink": "https://www.studyincanada.com/Programs/78384/Laurentian-University/Universite-Laurentienne/Sociologie-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$23,747",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/7154/Nipissing-University/Sociology",
    "entranceGrade": "70 - 70",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Grenfell Campus, Memorial University",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Social Cultural Studies (BA)",
    "programLink": "https://www.studyincanada.com/Programs/13695/Grenfell-Campus,-Memorial-University/Social-Cultural-Studies-(BA)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$11,460",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Sociology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/164/Trent-University/Sociology-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algoma University",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/12411/Algoma-University/Sociology",
    "entranceGrade": "65 - 65",
    "tutionValue": "$20,438",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Sociology: Gender and Women's Studies Specialization (M.A.)",
    "programLink": "https://www.studyincanada.com/Programs/2742/Lakehead-University-Faculty-of-Graduate-Studies/Sociology-Gender-and-Women-s-Studies-Specialization-(M.A.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology - Indigenous Justice and Criminology (IJC)",
    "programLink": "https://www.studyincanada.com/Programs/56596/University-of-Saskatchewan/Sociology-Indigenous-Justice-and-Criminology-(IJC)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/33410/Acadia-University/Sociology",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Feminist and Gender Studies and Sociology",
    "programLink": "https://www.studyincanada.com/Programs/49730/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Feminist-and-Gender-Studies-and-Sociology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Industrial and Labour Relations",
    "programLink": "https://www.studyincanada.com/Programs/16159/McGill-University/Industrial-and-Labour-Relations",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Cultural and Social Explorations Certificate",
    "programLink": "https://www.studyincanada.com/Programs/45651/Thompson-Rivers-University/Cultural-and-Social-Explorations-Certificate",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology (B.A. Four-Year)",
    "programLink": "https://www.studyincanada.com/Programs/11585/University-of-Saskatchewan/Sociology-(B.A.-Four-Year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/11586/University-of-Saskatchewan/Sociology",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/81169/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Sociology",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/4218/Western-University/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cape Breton University",
    "location": "Canada, Nova Scotia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/12629/Cape-Breton-University/Sociology",
    "entranceGrade": "65",
    "tutionValue": "$19,579",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/15388/University-of-New-Brunswick-Fredericton/Sociology",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University - Durham Greater Toronto Area",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/95559/Trent-University-Durham-Greater-Toronto-Area/Sociology",
    "entranceGrade": "70",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/5499/McMaster-University/Sociology",
    "entranceGrade": "78 - 82",
    "tutionValue": "$40,960",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "NorQuest College",
    "location": "Canada, Alberta",
    "programName": "Social Work",
    "programLink": "https://www.studyincanada.com/Programs/366/NorQuest-College/Social-Work",
    "entranceGrade": "N/S",
    "tutionValue": "$36,270",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology - Indigenous Justice and Criminology (IJC) (B.A. Four-...",
    "programLink": "https://www.studyincanada.com/Programs/37215/University-of-Saskatchewan/Sociology-Indigenous-Justice-and-Criminology-(IJC)-(B.A.-Four-...",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University - Glendon Campus",
    "location": "Canada, Ontario",
    "programName": "Canadian Studies (iBA)",
    "programLink": "https://www.studyincanada.com/Programs/68564/York-University-Glendon-Campus/Canadian-Studies-(iBA)",
    "entranceGrade": "75 - 79",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Sociology (MA)",
    "programLink": "https://www.studyincanada.com/Programs/6676/Carleton-University-Faculty-of-Graduate-Studies-and-Research/Sociology-(MA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/13176/Carleton-University-Faculty-of-Graduate-Studies-and-Research/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Sociology - Specialization in Social Justice & Equity Studies (B...",
    "programLink": "https://www.studyincanada.com/Programs/109898/Trent-University/Sociology-Specialization-in-Social-Justice-&-Equity-Studies-(B...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/78389/Lakehead-University-Orillia/Sociology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Redeemer University",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/748/Redeemer-University/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "$19,494",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/9849/St.-Francis-Xavier-University/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Criminology and Socio-Legal Studies (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8344/University-of-Toronto-St.-George/Criminology-and-Socio-Legal-Studies-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/27395/Brandon-University/Sociology",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/39945/McGill-University/Sociology",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Law, Justice and Society (BA)",
    "programLink": "https://www.studyincanada.com/Programs/96605/Dalhousie-University/Law,-Justice-and-Society-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/81405/Selkirk-College/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "$13,409",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Sociology (MA)",
    "programLink": "https://www.studyincanada.com/Programs/39686/Concordia-University-School-of-Graduate-Studies/Sociology-(MA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/51096/Brandon-University/Sociology",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Prince Edward Island",
    "location": "Canada, Prince Edward Island",
    "programName": "Sociology/Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/10364/University-of-Prince-Edward-Island/Sociology/Anthropology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$14,274",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/112515/University-of-Lethbridge-School-of-Graduate-Studies/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Crandall University",
    "location": "Canada, New Brunswick",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/37810/Crandall-University/Sociology",
    "entranceGrade": "70",
    "tutionValue": "$17,900",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Social Work (Pre-Social Work)",
    "programLink": "https://www.studyincanada.com/Programs/84636/University-of-Regina/Social-Work-(Pre-Social-Work)",
    "entranceGrade": "65 - 65",
    "tutionValue": "$11,712",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Sociology (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/44543/University-of-Toronto-Scarborough/Sociology-(HBA)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Sociology: Bachelor of Arts Degree - Major",
    "programLink": "https://www.studyincanada.com/Programs/107871/Thompson-Rivers-University/Sociology-Bachelor-of-Arts-Degree-Major",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/32295/University-of-Windsor/Sociology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$34,641",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/1372/Acadia-University/Sociology",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Disability Studies",
    "programLink": "https://www.studyincanada.com/Programs/64154/The-University-of-Winnipeg/Disability-Studies",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Sociology (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/78365/Laurentian-University/Universite-Laurentienne/Sociology-(HBA)",
    "entranceGrade": "70",
    "tutionValue": "$23,747",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University - Glendon Campus",
    "location": "Canada, Ontario",
    "programName": "Canadian Studies (BA)",
    "programLink": "https://www.studyincanada.com/Programs/15031/York-University-Glendon-Campus/Canadian-Studies-(BA)",
    "entranceGrade": "75 - 79",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Prince Edward Island",
    "location": "Canada, Prince Edward Island",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/10367/University-of-Prince-Edward-Island/Sociology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$14,274",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Allison University",
    "location": "Canada, New Brunswick",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/19813/Mount-Allison-University/Sociology",
    "entranceGrade": "65",
    "tutionValue": "$20,885",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Urban and Regional Studies",
    "programLink": "https://www.studyincanada.com/Programs/112495/University-of-Lethbridge-School-of-Graduate-Studies/Urban-and-Regional-Studies",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "General Arts: Bachelor of Arts Degree - Major",
    "programLink": "https://www.studyincanada.com/Programs/83624/Thompson-Rivers-University/General-Arts-Bachelor-of-Arts-Degree-Major",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/9850/St.-Francis-Xavier-University/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/23162/Queen-s-University/Sociology",
    "entranceGrade": "80 - 100",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Communication and Sociology",
    "programLink": "https://www.studyincanada.com/Programs/49630/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Communication-and-Sociology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/107420/Brandon-University/Sociology",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "General Arts: Bachelor of Arts Degree - Major",
    "programLink": "https://www.studyincanada.com/Programs/107882/Thompson-Rivers-University/General-Arts-Bachelor-of-Arts-Degree-Major",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "N/S"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/1871/Graduate-Studies-at-University-of-Waterloo/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Sociology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/163/Trent-University/Sociology-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Sociology (General Major)",
    "programLink": "https://www.studyincanada.com/Programs/37370/University-of-Manitoba/Sociology-(General-Major)",
    "entranceGrade": "85 - 85",
    "tutionValue": "$16,600",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Sociology - Crime and Community (3 Year)",
    "programLink": "https://www.studyincanada.com/Programs/107437/Brandon-University/Sociology-Crime-and-Community-(3-Year)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/1868/Graduate-Studies-at-University-of-Waterloo/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/4746/University-of-Windsor-Faculty-of-Graduate-Studies/Sociology",
    "entranceGrade": "77 - 100",
    "tutionValue": "$8,863",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Disability Studies (Four-Year)",
    "programLink": "https://www.studyincanada.com/Programs/65685/The-University-of-Winnipeg/Disability-Studies-(Four-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/1375/Acadia-University/Sociology",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Sociology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/71976/Kwantlen-Polytechnic-University/Sociology-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/96737/Vancouver-Island-University/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "$24,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/29922/The-University-of-British-Columbia-Okanagan/Sociology",
    "entranceGrade": "67",
    "tutionValue": "$41,156",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta - Augustana Campus",
    "location": "Canada, Alberta",
    "programName": "Sociology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/21935/University-of-Alberta-Augustana-Campus/Sociology-(B.A.)",
    "entranceGrade": "70 - 70",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University - Durham Greater Toronto Area",
    "location": "Canada, Ontario",
    "programName": "Social Justice and Equity Studies (Specialization)",
    "programLink": "https://www.studyincanada.com/Programs/105225/Trent-University-Durham-Greater-Toronto-Area/Social-Justice-and-Equity-Studies-(Specialization)",
    "entranceGrade": "N/S",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Sociology (Four-Year)",
    "programLink": "https://www.studyincanada.com/Programs/62870/The-University-of-Winnipeg/Sociology-(Four-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Tyndale University",
    "location": "Canada, Ontario",
    "programName": "Sociology (Minor)",
    "programLink": "https://www.studyincanada.com/Programs/115981/Tyndale-University/Sociology-(Minor)",
    "entranceGrade": "65 - 100",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/5810/Queen-s-University/Sociology",
    "entranceGrade": "80 - 100",
    "tutionValue": "$55,567",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/7206/McGill-University/Sociology",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/48500/Douglas-College/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The King's University",
    "location": "Canada, Alberta",
    "programName": "Social Sciences",
    "programLink": "https://www.studyincanada.com/Programs/6182/The-King-s-University/Social-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$11,778",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Sociology & Criminology",
    "programLink": "https://www.studyincanada.com/Programs/114219/University-of-Windsor/Sociology-&-Criminology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$34,641",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/11281/Kwantlen-Polytechnic-University/Sociology",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/9756/Saint-Mary-s-University/Sociology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/27193/University-of-Victoria/Sociology",
    "entranceGrade": "78",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University - Glendon Campus",
    "location": "Canada, Ontario",
    "programName": "Sociology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/31231/York-University-Glendon-Campus/Sociology-(BA)",
    "entranceGrade": "75 - 79",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Sociology and Social Anthropology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/85343/Dalhousie-University/Sociology-and-Social-Anthropology-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology - Indigenous Justice and Criminology (IJC) (B.A. Three...",
    "programLink": "https://www.studyincanada.com/Programs/106231/University-of-Saskatchewan/Sociology-Indigenous-Justice-and-Criminology-(IJC)-(B.A.-Three...",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Social and Cultural Analysis (PhD)",
    "programLink": "https://www.studyincanada.com/Programs/68874/Concordia-University-School-of-Graduate-Studies/Social-and-Cultural-Analysis-(PhD)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/4782/University-of-Lethbridge-School-of-Graduate-Studies/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Okanagan College",
    "location": "Canada, British Columbia",
    "programName": "Diploma in Criminal and Social Justice",
    "programLink": "https://www.studyincanada.com/Programs/46746/Okanagan-College/Diploma-in-Criminal-and-Social-Justice",
    "entranceGrade": "60",
    "tutionValue": "$13,750",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The King's University",
    "location": "Canada, Alberta",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/6183/The-King-s-University/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "$11,778",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/77454/The-University-of-British-Columbia-Vancouver/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/77453/The-University-of-British-Columbia-Vancouver/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Anthropology and Sociology",
    "programLink": "https://www.studyincanada.com/Programs/49593/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Anthropology-and-Sociology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/7428/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Sociology",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology (Double Honours)",
    "programLink": "https://www.studyincanada.com/Programs/73057/University-of-Saskatchewan/Sociology-(Double-Honours)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Communication and Sociology",
    "programLink": "https://www.studyincanada.com/Programs/49634/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Communication-and-Sociology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/8016/The-University-of-Winnipeg/Sociology",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Sociology: Social Justice Studies Specialization (M.A.)",
    "programLink": "https://www.studyincanada.com/Programs/111559/Lakehead-University-Faculty-of-Graduate-Studies/Sociology-Social-Justice-Studies-Specialization-(M.A.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/56571/Western-University/Criminology",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "N/S"
  },
  {
    "schoolName": "University of Toronto: Ontario Institute for Studies in Education",
    "location": "Canada, Ontario",
    "programName": "Social Justice Education (PhD)",
    "programLink": "https://www.studyincanada.com/Programs/59886/University-of-Toronto-Ontario-Institute-for-Studies-in-Education/Social-Justice-Education-(PhD)",
    "entranceGrade": "N/S",
    "tutionValue": "$6,210",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Sociology and Another Subject (BA) (Combined Honours)",
    "programLink": "https://www.studyincanada.com/Programs/91544/McMaster-University/Sociology-and-Another-Subject-(BA)-(Combined-Honours)",
    "entranceGrade": "78 - 82",
    "tutionValue": "$40,960",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/13563/University-of-Manitoba-Faculty-of-Graduate-Studies/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Women's & Gender Studies (B.A. Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/106229/University-of-Saskatchewan/Women-s-&-Gender-Studies-(B.A.-Three-Year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Concordia University of Edmonton",
    "location": "Canada, Alberta",
    "programName": "Sociology (B.A. 4-year)",
    "programLink": "https://www.studyincanada.com/Programs/78284/Concordia-University-of-Edmonton/Sociology-(B.A.-4-year)",
    "entranceGrade": "60",
    "tutionValue": "$25,414",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Sociology (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/8517/University-of-Toronto-St.-George/Sociology-(HBA)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/sociology-soc/",
    "entranceGrade": "75 - 80",
    "tutionValue": "$32,594",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/37674/University-of-Manitoba-Faculty-of-Graduate-Studies/Sociology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Sociology - Crime and Community",
    "programLink": "https://www.studyincanada.com/Programs/107436/Brandon-University/Sociology-Crime-and-Community",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/12311/University-of-Victoria/Sociology",
    "entranceGrade": "78 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Sociology (Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/27066/The-University-of-Winnipeg/Sociology-(Three-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Arts | Sociology",
    "programLink": "https://www.studyincanada.com/Programs/109235/University-of-Lethbridge/Bachelor-of-Arts-|-Sociology",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Arts | Urban and Regional Studies",
    "programLink": "https://www.studyincanada.com/Programs/109215/University-of-Lethbridge/Bachelor-of-Arts-|-Urban-and-Regional-Studies",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Sociology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/109896/Trent-University/Sociology-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology (B.A. Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/106230/University-of-Saskatchewan/Sociology-(B.A.-Three-Year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Toronto: Ontario Institute for Studies in Education",
    "location": "Canada, Ontario",
    "programName": "Social Justice Education (EdD)",
    "programLink": "https://www.studyincanada.com/Programs/65491/University-of-Toronto-Ontario-Institute-for-Studies-in-Education/Social-Justice-Education-(EdD)",
    "entranceGrade": "N/S",
    "tutionValue": "$40,260",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Athabasca University",
    "location": "Canada, Alberta",
    "programName": "Sociology (Three Year)",
    "programLink": "https://www.studyincanada.com/Programs/9074/Athabasca-University/Sociology-(Three-Year)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/87311/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Sociology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "York University - Glendon Campus",
    "location": "Canada, Ontario",
    "programName": "Sociology (iBA)",
    "programLink": "https://www.studyincanada.com/Programs/64866/York-University-Glendon-Campus/Sociology-(iBA)",
    "entranceGrade": "75 - 79",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Sociology (3 Year)",
    "programLink": "https://www.studyincanada.com/Programs/107421/Brandon-University/Sociology-(3-Year)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/37335/University-of-Manitoba/Sociology",
    "entranceGrade": "85 - 85",
    "tutionValue": "$16,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Sociology - Crime and Community",
    "programLink": "https://www.studyincanada.com/Programs/107423/Brandon-University/Sociology-Crime-and-Community",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Nipissing University - Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/90181/Nipissing-University-Graduate-Studies/Sociology",
    "entranceGrade": "75 - 75",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/55690/Bishop-s-University/Sociology",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Sociology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/109897/Trent-University/Sociology-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Sociology: Gerontology Specialization (M.A.)",
    "programLink": "https://www.studyincanada.com/Programs/111558/Lakehead-University-Faculty-of-Graduate-Studies/Sociology-Gerontology-Specialization-(M.A.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/88607/The-University-of-British-Columbia-Okanagan/Sociology",
    "entranceGrade": "70",
    "tutionValue": "$41,156",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Women's & Gender Studies (B.A. Four-Year)",
    "programLink": "https://www.studyincanada.com/Programs/11621/University-of-Saskatchewan/Women-s-&-Gender-Studies-(B.A.-Four-Year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Prince Edward Island",
    "location": "Canada, Prince Edward Island",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/45504/University-of-Prince-Edward-Island/Anthropology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$14,274",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Certificate in Arts and Science | Sociology",
    "programLink": "https://www.studyincanada.com/Programs/112562/University-of-Lethbridge/Certificate-in-Arts-and-Science-|-Sociology",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Women's & Gender Studies (Double Honours)",
    "programLink": "https://www.studyincanada.com/Programs/106234/University-of-Saskatchewan/Women-s-&-Gender-Studies-(Double-Honours)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.ufv.ca/scms/programs/anthropology-ba/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Sociology/Anthropology",
    "programLink": "https://www.ufv.ca/scms/programs/anthropology-ba/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Anthropology and Sociology",
    "programLink": "https://www.studyincanada.com/Programs/49592/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Anthropology-and-Sociology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Sociology (Specialist Option)",
    "programLink": "https://www.studyincanada.com/Programs/91537/McMaster-University/Sociology-(Specialist-Option)",
    "entranceGrade": "78 - 82",
    "tutionValue": "$38,778",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/90517/University-of-Manitoba/Criminology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/49729/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Sociology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Sociology - Crime and Community",
    "programLink": "https://www.studyincanada.com/Programs/51132/Brandon-University/Sociology-Crime-and-Community",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/2740/Lakehead-University-Thunder-Bay/Sociology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Sociology - Specialization in Health Studies (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/95525/Trent-University/Sociology-Specialization-in-Health-Studies-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Urban and Regional Studies",
    "programLink": "https://www.studyincanada.com/Programs/4823/University-of-Lethbridge-School-of-Graduate-Studies/Urban-and-Regional-Studies",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Sociology (Specialist Option) and Another Subject (BA) (Combined...",
    "programLink": "https://www.studyincanada.com/Programs/91543/McMaster-University/Sociology-(Specialist-Option)-and-Another-Subject-(BA)-(Combined...",
    "entranceGrade": "78 - 82",
    "tutionValue": "$40,960",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Sociology (Pre-Master's)",
    "programLink": "https://www.studyincanada.com/Programs/111597/University-of-Manitoba-Faculty-of-Graduate-Studies/Sociology-(Pre-Master-s)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Recreation (Therapeutic) (BScR-TR)",
    "programLink": "https://www.studyincanada.com/Programs/96608/Dalhousie-University/Recreation-(Therapeutic)-(BScR-TR)",
    "entranceGrade": "70",
    "tutionValue": "$30,966",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Sociology - Crime and Community",
    "programLink": "https://www.studyincanada.com/Programs/107424/Brandon-University/Sociology-Crime-and-Community",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Political Science (BA)",
    "programLink": "https://admissions.carleton.ca/programs/political-science/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "King's University College",
    "location": "Canada, Ontario",
    "programName": "Political Science and International Relations",
    "programLink": "https://www.kings.uwo.ca/academics/politics-international-relations/",
    "entranceGrade": "80",
    "tutionValue": "$37,476",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Political Science (iBA)",
    "programLink": "https://futurestudents.yorku.ca/program/political-science/glendon",
    "entranceGrade": "75 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Political Science",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-with-honors-political-science.html",
    "entranceGrade": "73 - 75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Huron University College",
    "location": "Canada, Ontario",
    "programName": "Political Science (Major)",
    "programLink": "https://huronu.ca/programs/arts-social-science/political-science/",
    "entranceGrade": "N/S",
    "tutionValue": "$46,079",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Political Science (BA)",
    "programLink": "https://admissions.carleton.ca/programs/political-science/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://uwaterloo.ca/future-students/programs/political-science?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$55,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Political Science",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-political-science.html",
    "entranceGrade": "72 - 72",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Political Science (BA)",
    "programLink": "https://futurestudents.yorku.ca/program/political-science/laps",
    "entranceGrade": "75 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "King's University College",
    "location": "Canada, Ontario",
    "programName": "Political Science and International Relations",
    "programLink": "https://www.kings.uwo.ca/academics/politics-international-relations/",
    "entranceGrade": "80",
    "tutionValue": "$37,476",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Political Science (BA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/political-science.html",
    "entranceGrade": "65",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Political Science (BA)",
    "programLink": "https://admissions.carleton.ca/programs/political-science/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Huron University College",
    "location": "Canada, Ontario",
    "programName": "Political Science (Specialization)",
    "programLink": "https://huronu.ca/programs/arts-social-science/political-science/",
    "entranceGrade": "N/S",
    "tutionValue": "$46,079",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Political Science (BA)",
    "programLink": "https://wlu.ca/programs/arts/undergraduate/political-science-ba/index.html",
    "entranceGrade": "72 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Political Science - GAS Transfer",
    "programLink": "https://ontariotechu.ca/programs/social-science-and-humanities/political-science-gas-transfer.php",
    "entranceGrade": "77 - 100",
    "tutionValue": "$29,196",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/social-science-and-humanities/political-science/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Political Science - Advanced Entry",
    "programLink": "https://ontariotechu.ca/programs/social-science-and-humanities/political-science-advanced-entry.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Feminist and Gender Studies and in Political Science",
    "programLink": "https://www.studyincanada.com/Programs/49722/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Feminist-and-Gender-Studies-and-in-Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/81231/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Political-Science",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Allison University",
    "location": "Canada, New Brunswick",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/9680/Mount-Allison-University/Political-Science",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/109017/Saint-Mary-s-University/Political-Science",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/77565/The-University-of-British-Columbia-Vancouver/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Public Administration and Political Science",
    "programLink": "https://www.studyincanada.com/Programs/49714/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Public-Administration-and-Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/2700/Lakehead-University-Thunder-Bay/Political-Science",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Political Science (MA)",
    "programLink": "https://www.studyincanada.com/Programs/90204/Concordia-University-School-of-Graduate-Studies/Political-Science-(MA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/8013/The-University-of-Winnipeg/Political-Science",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/81230/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Political-Science",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University - Glendon Campus",
    "location": "Canada, Ontario",
    "programName": "Political Science (iBA)",
    "programLink": "https://www.studyincanada.com/Programs/64863/York-University-Glendon-Campus/Political-Science-(iBA)",
    "entranceGrade": "75 - 79",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/6935/University-of-Regina/Political-Science",
    "entranceGrade": "65",
    "tutionValue": "$11,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/9877/St.-Francis-Xavier-University/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/9777/Saint-Mary-s-University/Political-Science",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/88605/The-University-of-British-Columbia-Okanagan/Political-Science",
    "entranceGrade": "70",
    "tutionValue": "$41,156",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Global and International Studies/Political Science Joint Major",
    "programLink": "https://www.studyincanada.com/Programs/13083/University-of-Northern-British-Columbia/Global-and-International-Studies/Political-Science-Joint-Major",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Geography/Political Science Joint Major",
    "programLink": "https://www.studyincanada.com/Programs/108398/University-of-Northern-British-Columbia/Geography/Political-Science-Joint-Major",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/81232/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Political-Science",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.ufv.ca/politicalscience/programs/political-science--bachelor-of-arts/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The King's University",
    "location": "Canada, Alberta",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/108844/The-King-s-University/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$11,778",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/1683/The-University-of-British-Columbia-Vancouver/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Philosophy and Political Science",
    "programLink": "https://www.studyincanada.com/Programs/49711/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Philosophy-and-Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/4724/University-of-Windsor-Faculty-of-Graduate-Studies/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$8,863",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Cape Breton University",
    "location": "Canada, Nova Scotia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/62393/Cape-Breton-University/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$19,579",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Political Science (Specialization in Global Citizenship)",
    "programLink": "https://www.studyincanada.com/Programs/100319/McMaster-University/Political-Science-(Specialization-in-Global-Citizenship)",
    "entranceGrade": "78 - 82",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Political Science (Specialization in Public Law and Judicial Stu...",
    "programLink": "https://www.studyincanada.com/Programs/91529/McMaster-University/Political-Science-(Specialization-in-Public-Law-and-Judicial-Stu...",
    "entranceGrade": "78 - 82",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/77563/The-University-of-British-Columbia-Vancouver/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/4161/Western-University/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Political Science (BA)",
    "programLink": "https://www.studyincanada.com/Programs/71972/Kwantlen-Polytechnic-University/Political-Science-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/77564/The-University-of-British-Columbia-Vancouver/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/3844/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/16905/Kwantlen-Polytechnic-University/Political-Science",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Economics and Political Science",
    "programLink": "https://www.studyincanada.com/Programs/49642/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Economics-and-Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/12296/University-of-Victoria/Political-Science",
    "entranceGrade": "75 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/107410/Brandon-University/Political-Science",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Political Science (BA)",
    "programLink": "https://www.studyincanada.com/Programs/14873/Dalhousie-University/Political-Science-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/political-science-pols/",
    "entranceGrade": "75 - 80",
    "tutionValue": "$32,594",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/2699/Lakehead-University-Thunder-Bay/Political-Science",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "English/Political Science Joint Major",
    "programLink": "https://www.studyincanada.com/Programs/55602/University-of-Northern-British-Columbia/English/Political-Science-Joint-Major",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/112509/University-of-Lethbridge-School-of-Graduate-Studies/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/87561/Lakehead-University-Orillia/Political-Science",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/23007/University-of-New-Brunswick-Fredericton/Political-Science",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "History and Political Science",
    "programLink": "https://www.studyincanada.com/Programs/49684/University-of-Ottawa/Universit%c3%a9-d-Ottawa/History-and-Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Business Administration & Political Science",
    "programLink": "https://www.studyincanada.com/Programs/114210/University-of-Windsor/Business-Administration-&-Political-Science",
    "entranceGrade": "73 - 100",
    "tutionValue": "$38,857",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/87298/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Economics/Political Science Joint Major",
    "programLink": "https://www.studyincanada.com/Programs/27928/University-of-Northern-British-Columbia/Economics/Political-Science-Joint-Major",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Management | Political Science",
    "programLink": "https://www.studyincanada.com/Programs/91088/University-of-Lethbridge/Bachelor-of-Management-|-Political-Science",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/68972/University-of-Windsor/Political-Science",
    "entranceGrade": "70 - 100",
    "tutionValue": "$34,641",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/39959/McGill-University/Political-Science",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/1849/Graduate-Studies-at-University-of-Waterloo/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/12297/University-of-Victoria/Political-Science",
    "entranceGrade": "75 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/1850/Graduate-Studies-at-University-of-Waterloo/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Political Science (PhD)",
    "programLink": "https://www.studyincanada.com/Programs/39684/Concordia-University-School-of-Graduate-Studies/Political-Science-(PhD)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Political Science (BA)/Concurrent Education (BEd)",
    "programLink": "https://www.studyincanada.com/Programs/114224/University-of-Windsor/Political-Science-(BA)/Concurrent-Education-(BEd)",
    "entranceGrade": "75 - 100",
    "tutionValue": "$34,617",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Algoma University",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/12407/Algoma-University/Political-Science",
    "entranceGrade": "65 - 65",
    "tutionValue": "$20,438",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/12696/Carleton-University-Faculty-of-Graduate-Studies-and-Research/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/29920/The-University-of-British-Columbia-Okanagan/Political-Science",
    "entranceGrade": "67",
    "tutionValue": "$41,156",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/76797/Langara-College/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "HBA/ Political Science",
    "programLink": "https://www.studyincanada.com/Programs/107560/Western-University/HBA/-Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/5482/McMaster-University/Political-Science",
    "entranceGrade": "78 - 82",
    "tutionValue": "$40,960",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/107411/Brandon-University/Political-Science",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Communication and Political Science",
    "programLink": "https://www.studyincanada.com/Programs/49625/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Communication-and-Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/3323/University-of-Northern-British-Columbia/Political-Science",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/7185/McGill-University/Political-Science",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Public Administration and Political Science",
    "programLink": "https://www.studyincanada.com/Programs/49715/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Public-Administration-and-Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "St. Thomas University",
    "location": "Canada, New Brunswick",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/13279/St.-Thomas-University/Political-Science",
    "entranceGrade": "70",
    "tutionValue": "$17,208",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/9876/St.-Francis-Xavier-University/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Feminist and Gender Studies and in Political Science",
    "programLink": "https://www.studyincanada.com/Programs/105137/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Feminist-and-Gender-Studies-and-in-Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/27394/Brandon-University/Political-Science",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Prince Edward Island",
    "location": "Canada, Prince Edward Island",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/10360/University-of-Prince-Edward-Island/Political-Science",
    "entranceGrade": "70 - 100",
    "tutionValue": "$14,274",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/7426/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Political-Science",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/49723/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/9776/Saint-Mary-s-University/Political-Science",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Environmental and Sustainability Studies/Political Science Joint...",
    "programLink": "https://www.studyincanada.com/Programs/100823/University-of-Northern-British-Columbia/Environmental-and-Sustainability-Studies/Political-Science-Joint...",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Political Science (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/44540/University-of-Toronto-Scarborough/Political-Science-(HBA)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/51131/Brandon-University/Political-Science",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Economics and Political Science",
    "programLink": "https://www.studyincanada.com/Programs/49648/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Economics-and-Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Thomas University",
    "location": "Canada, New Brunswick",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/13281/St.-Thomas-University/Political-Science",
    "entranceGrade": "70",
    "tutionValue": "$17,208",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University - Glendon Campus",
    "location": "Canada, Ontario",
    "programName": "Political Science (BA)",
    "programLink": "https://www.studyincanada.com/Programs/31229/York-University-Glendon-Campus/Political-Science-(BA)",
    "entranceGrade": "75 - 79",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/27191/University-of-Victoria/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Political Science (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/8502/University-of-Toronto-St.-George/Political-Science-(HBA)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/49713/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Political Science/Women's Studies Joint Major",
    "programLink": "https://www.studyincanada.com/Programs/27911/University-of-Northern-British-Columbia/Political-Science/Women-s-Studies-Joint-Major",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/political-science-co-op-pols-c/",
    "entranceGrade": "80 - 83",
    "tutionValue": "$32,594",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Political Science (3 Year)",
    "programLink": "https://www.studyincanada.com/Programs/107414/Brandon-University/Political-Science-(3-Year)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Political Science (Four-Year)",
    "programLink": "https://www.studyincanada.com/Programs/62894/The-University-of-Winnipeg/Political-Science-(Four-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/62813/Nipissing-University/Political-Science",
    "entranceGrade": "70 - 70",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Political Science (MA)",
    "programLink": "https://www.studyincanada.com/Programs/6673/Carleton-University-Faculty-of-Graduate-Studies-and-Research/Political-Science-(MA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "History and Political Science",
    "programLink": "https://www.studyincanada.com/Programs/49689/University-of-Ottawa/Universit%c3%a9-d-Ottawa/History-and-Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Political Science (Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/27068/The-University-of-Winnipeg/Political-Science-(Three-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/3022/University-of-Lethbridge-School-of-Graduate-Studies/Political-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Certificate in Arts and Science | Political Science",
    "programLink": "https://www.studyincanada.com/Programs/112561/University-of-Lethbridge/Certificate-in-Arts-and-Science-|-Political-Science",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Communication and Political Science",
    "programLink": "https://www.studyincanada.com/Programs/49624/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Communication-and-Political-Science",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Political Science",
    "programLink": "https://www.studyincanada.com/Programs/23001/University-of-New-Brunswick-Fredericton/Political-Science",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "History/Political Science Joint Major",
    "programLink": "https://www.studyincanada.com/Programs/27909/University-of-Northern-British-Columbia/History/Political-Science-Joint-Major",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "History and English",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/history/",
    "entranceGrade": "70 - 72",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Ancient and Medieval History",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-ancient-and-medieval-studies.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Medieval Studies",
    "programLink": "https://uwaterloo.ca/future-students/programs/medieval-studies?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$55,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "History and Politics and Governance",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/history/",
    "entranceGrade": "70 - 72",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://uwaterloo.ca/future-students/programs/history?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$55,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Visual Arts - Art History",
    "programLink": "https://futurestudents.yorku.ca/program/art-history",
    "entranceGrade": "75 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "History (BA) - Brantford Campus",
    "programLink": "https://wlu.ca/programs/liberal-arts/undergraduate/history-ba/index.html",
    "entranceGrade": "70 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Sociology and History",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/sociology/",
    "entranceGrade": "74 - 77",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "History",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-history.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "English and History (BA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/english-and-history.html",
    "entranceGrade": "73",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "History and Sociology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/history/",
    "entranceGrade": "70 - 72",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "History (BA) - Waterloo Campus",
    "programLink": "https://wlu.ca/programs/arts/undergraduate/history-ba/index.html",
    "entranceGrade": "70 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "History and Criminology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/history/",
    "entranceGrade": "70 - 72",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "History",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/Arts/Departments/Humanities/program-areas/history/index.htm",
    "entranceGrade": "65 - 70",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "History and Theory of Architecture (BA)",
    "programLink": "https://admissions.carleton.ca/programs/history-and-theory-of-architecture/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "History (BA)",
    "programLink": "https://admissions.carleton.ca/programs/history/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "History (BA)",
    "programLink": "https://futurestudents.yorku.ca/program/history/laps",
    "entranceGrade": "75 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Art History and Film Studies (BFA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/art-history-filmstudiesbfa.html",
    "entranceGrade": "65",
    "tutionValue": "$30,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Classics",
    "programLink": "https://uwaterloo.ca/future-students/programs/classical-studies?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$50,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "History of Art, Design, and Visual Culture",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-history-of-art-design-and-visual-culture.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Cross-Disciplinary Certificate in Public History",
    "programLink": "https://futurestudents.yorku.ca/program/certificates/public-history",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Criminology and History",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/criminology/",
    "entranceGrade": "80 - 88",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Art History (BFA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/art-history.html",
    "entranceGrade": "65",
    "tutionValue": "$30,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "History and Theory of Architecture (BA)",
    "programLink": "https://admissions.carleton.ca/programs/history-and-theory-of-architecture/",
    "entranceGrade": "73 - 76",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Art History and Studio Art (BFA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/art-history-studio-art.html",
    "entranceGrade": "65",
    "tutionValue": "$30,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "History / Histoire (iBA)",
    "programLink": "https://futurestudents.yorku.ca/program/history/glendon",
    "entranceGrade": "75 - 100",
    "tutionValue": "$24,587",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "History and Philosophy",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/history/",
    "entranceGrade": "70 - 72",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Music (BMus)",
    "programLink": "https://wlu.ca/programs/music/undergraduate/music-bmus/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Politics and Governance and History",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/politics-governance/",
    "entranceGrade": "75 - 78",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "English and History",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/english/",
    "entranceGrade": "70 - 72",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Music (BA)",
    "programLink": "https://admissions.carleton.ca/programs/music-ba/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "History (BA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/history.html",
    "entranceGrade": "67",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Huron University College",
    "location": "Canada, Ontario",
    "programName": "History (Specialization)",
    "programLink": "https://huronu.ca/programs/arts-social-science/history/",
    "entranceGrade": "N/S",
    "tutionValue": "$46,079",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "History (BA)",
    "programLink": "https://admissions.carleton.ca/programs/history/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Global and Transnational History (BGInS)",
    "programLink": "https://admissions.carleton.ca/programs/global-transnational-history/",
    "entranceGrade": "73 - 76",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Art History (BA)",
    "programLink": "https://admissions.carleton.ca/programs/art-history/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Huron University College",
    "location": "Canada, Ontario",
    "programName": "History (Specialization)",
    "programLink": "https://huronu.ca/programs/arts-social-science/history/",
    "entranceGrade": "N/S",
    "tutionValue": "$46,079",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "King's University College",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.kings.uwo.ca/academics/history/",
    "entranceGrade": "80",
    "tutionValue": "$37,476",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Fine Arts - Visual Culture",
    "programLink": "https://uwaterloo.ca/future-students/programs/fine-arts?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$55,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Arts Degree (BA) and Master's Degree (MA or MAP)",
    "programLink": "https://wlu.ca/programs/arts/undergraduate/ba-mba/index.html",
    "entranceGrade": "80 - 89",
    "tutionValue": "$33,220",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "History of Art, Design, and Visual Culture",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-history-of-art-design-and-visual-culture.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Histoire",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/baccalaureat-es-arts-histoire.html",
    "entranceGrade": "70 - 70",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Huron University College",
    "location": "Canada, Ontario",
    "programName": "History (Major)",
    "programLink": "https://huronu.ca/programs/arts-social-science/history/",
    "entranceGrade": "N/S",
    "tutionValue": "$46,079",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Classical Studies",
    "programLink": "https://uwaterloo.ca/future-students/programs/classical-studies?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$55,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/history/",
    "entranceGrade": "70 - 72",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "History (BA)",
    "programLink": "https://admissions.carleton.ca/programs/history/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "History",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-history.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Art History (BA)",
    "programLink": "https://admissions.carleton.ca/programs/art-history/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Philosophy and History",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/philosophy/",
    "entranceGrade": "70 - 72",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "King's University College",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.kings.uwo.ca/academics/history/",
    "entranceGrade": "80",
    "tutionValue": "$37,476",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/22925/University-of-New-Brunswick-Saint-John/History",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/15541/University-of-New-Brunswick-Saint-John/History",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "OCAD University",
    "location": "Canada, Ontario",
    "programName": "Criticism and Curatorial Practice (MFA)",
    "programLink": "https://gradadmissions.ocadu.ca/program/CCP",
    "entranceGrade": "N/S",
    "tutionValue": "$31,107",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "OCAD University",
    "location": "Canada, Ontario",
    "programName": "Drawing & Painting",
    "programLink": "https://admissions.ocadu.ca/program/drawing-painting",
    "entranceGrade": "70 - 70",
    "tutionValue": "$29,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "History (MA)",
    "programLink": "https://www.wlu.ca/programs/arts/graduate/history-ma/index.html",
    "entranceGrade": "77",
    "tutionValue": "$21,810",
    "length": "12 Month(s)"
  },
  {
    "schoolName": "OCAD University",
    "location": "Canada, Ontario",
    "programName": "Contemporary Art, Design and New Media Art Histories (MA)",
    "programLink": "https://gradadmissions.ocadu.ca/program/CADN",
    "entranceGrade": "N/S",
    "tutionValue": "$31,107",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Tyndale University",
    "location": "Canada, Ontario",
    "programName": "History and Global Studies",
    "programLink": "https://www.studyincanada.com/Programs/89987/Tyndale-University/History-and-Global-Studies",
    "entranceGrade": "65 - 100",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/37373/University-of-Manitoba/History",
    "entranceGrade": "85 - 85",
    "tutionValue": "$16,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "History (BA)/Concurrent Education (BEd)",
    "programLink": "https://www.studyincanada.com/Programs/114222/University-of-Windsor/History-(BA)/Concurrent-Education-(BEd)",
    "entranceGrade": "75 - 100",
    "tutionValue": "$34,617",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "History (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/44529/University-of-Toronto-Scarborough/History-(HBA)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Early Modern Studies",
    "programLink": "https://www.studyincanada.com/Programs/14844/Dalhousie-University/Early-Modern-Studies",
    "entranceGrade": "70",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "History (Advanced Major)",
    "programLink": "https://www.studyincanada.com/Programs/59992/University-of-Manitoba/History-(Advanced-Major)",
    "entranceGrade": "85 - 85",
    "tutionValue": "$16,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/27178/University-of-Victoria/History",
    "entranceGrade": "75",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/77541/The-University-of-British-Columbia-Vancouver/History",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Russian Studies (BA)",
    "programLink": "https://www.studyincanada.com/Programs/14826/Dalhousie-University/Russian-Studies-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Art History (B.A. Honours)",
    "programLink": "https://www.studyincanada.com/Programs/11094/University-of-Saskatchewan/Art-History-(B.A.-Honours)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/10872/Selkirk-College/History",
    "entranceGrade": "N/S",
    "tutionValue": "$13,409",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Classics",
    "programLink": "https://www.studyincanada.com/Programs/2240/McGill-University/Classics",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "College of New Caledonia",
    "location": "Canada, British Columbia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/49178/College-of-New-Caledonia/History",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "History/Political Science Joint Major",
    "programLink": "https://www.studyincanada.com/Programs/27909/University-of-Northern-British-Columbia/History/Political-Science-Joint-Major",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Music (ArtDipMus)",
    "programLink": "https://www.studyincanada.com/Programs/96766/University-of-Toronto-St.-George/Music-(ArtDipMus)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$37,680",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/27396/Brandon-University/History",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/6915/University-of-Regina/History",
    "entranceGrade": "65",
    "tutionValue": "$11,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Spanish",
    "programLink": "https://www.studyincanada.com/Programs/95848/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Spanish",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Law, Justice and Society (BA)",
    "programLink": "https://www.studyincanada.com/Programs/96605/Dalhousie-University/Law,-Justice-and-Society-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The King's University",
    "location": "Canada, Alberta",
    "programName": "Interdisciplinary Science",
    "programLink": "https://www.studyincanada.com/Programs/108847/The-King-s-University/Interdisciplinary-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$11,778",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "History (BA)",
    "programLink": "https://www.studyincanada.com/Programs/85355/Dalhousie-University/History-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/2603/Lakehead-University-Thunder-Bay/History",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "History of Art",
    "programLink": "https://www.studyincanada.com/Programs/85119/The-University-of-Winnipeg/History-of-Art",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Ancient Greek and Roman Studies - Specialization in Greek & Lati...",
    "programLink": "https://www.studyincanada.com/Programs/95524/Trent-University/Ancient-Greek-and-Roman-Studies-Specialization-in-Greek-&-Lati...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "History (MA)",
    "programLink": "https://www.studyincanada.com/Programs/39678/Concordia-University-School-of-Graduate-Studies/History-(MA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/2988/University-of-Lethbridge-School-of-Graduate-Studies/History",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/23155/Queen-s-University/History",
    "entranceGrade": "80 - 100",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/1829/Graduate-Studies-at-University-of-Waterloo/History",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Tyndale University",
    "location": "Canada, Ontario",
    "programName": "History and Global Studies",
    "programLink": "https://www.studyincanada.com/Programs/89988/Tyndale-University/History-and-Global-Studies",
    "entranceGrade": "65 - 100",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Allison University",
    "location": "Canada, New Brunswick",
    "programName": "Art History",
    "programLink": "https://www.studyincanada.com/Programs/19811/Mount-Allison-University/Art-History",
    "entranceGrade": "65",
    "tutionValue": "$20,885",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Art History",
    "programLink": "https://www.studyincanada.com/Programs/32209/Western-University/Art-History",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "N/S"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/23003/University-of-New-Brunswick-Fredericton/History",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "History (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/4834/Laurentian-University/Universite-Laurentienne/History-(HBA)",
    "entranceGrade": "70",
    "tutionValue": "$23,747",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Classics and Another Subject (BA) (Combined Honours)",
    "programLink": "https://www.studyincanada.com/Programs/91545/McMaster-University/Classics-and-Another-Subject-(BA)-(Combined-Honours)",
    "entranceGrade": "77 - 79",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "History (B.A. Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/106198/University-of-Saskatchewan/History-(B.A.-Three-Year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/history-co-op-hist-c/",
    "entranceGrade": "80 - 83",
    "tutionValue": "$32,598",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "History (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/101930/University-of-Toronto-Scarborough/History-(HBA)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "NSCAD University",
    "location": "Canada, Nova Scotia",
    "programName": "Art History",
    "programLink": "https://www.studyincanada.com/Programs/8149/NSCAD-University/Art-History",
    "entranceGrade": "70",
    "tutionValue": "$22,069",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The King's University",
    "location": "Canada, Alberta",
    "programName": "Politics, History, & Economics",
    "programLink": "https://www.studyincanada.com/Programs/34382/The-King-s-University/Politics,-History,-&-Economics",
    "entranceGrade": "N/S",
    "tutionValue": "$11,778",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/2482/McGill-University/History",
    "entranceGrade": "87",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "English Literature - Specialization in Literary History (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/105851/Trent-University/English-Literature-Specialization-in-Literary-History-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/4288/Bishop-s-University/History",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/3927/Western-University/History",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Medieval Studies",
    "programLink": "https://www.studyincanada.com/Programs/95909/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Medieval-Studies",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Classics (Greek and Latin) (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/8264/University-of-Toronto-St.-George/Classics-(Greek-and-Latin)-(HBA)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/112487/University-of-Lethbridge-School-of-Graduate-Studies/History",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Contemporary Studies",
    "programLink": "https://www.studyincanada.com/Programs/34088/Dalhousie-University/Contemporary-Studies",
    "entranceGrade": "70",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/2600/Lakehead-University-Thunder-Bay/History",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Drama - Acting (B.F.A. Honours)",
    "programLink": "https://www.studyincanada.com/Programs/69498/University-of-Saskatchewan/Drama-Acting-(B.F.A.-Honours)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Ancient Worlds",
    "programLink": "https://www.studyincanada.com/Programs/95861/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Ancient-Worlds",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Arts One - First Nations",
    "programLink": "https://www.studyincanada.com/Programs/30824/Vancouver-Island-University/Arts-One-First-Nations",
    "entranceGrade": "N/S",
    "tutionValue": "$24,646",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Athabasca University",
    "location": "Canada, Alberta",
    "programName": "History (Three Year)",
    "programLink": "https://www.studyincanada.com/Programs/9051/Athabasca-University/History-(Three-Year)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Women's & Gender Studies (B.A. Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/106229/University-of-Saskatchewan/Women-s-&-Gender-Studies-(B.A.-Three-Year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Art History",
    "programLink": "https://www.studyincanada.com/Programs/77406/The-University-of-British-Columbia-Vancouver/Art-History",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Art History",
    "programLink": "https://www.studyincanada.com/Programs/99371/University-of-Calgary/Art-History",
    "entranceGrade": "70",
    "tutionValue": "$25,530",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta - Augustana Campus",
    "location": "Canada, Alberta",
    "programName": "Ethics and Global Studies - Interdisciplinary (B.A)",
    "programLink": "https://www.studyincanada.com/Programs/103752/University-of-Alberta-Augustana-Campus/Ethics-and-Global-Studies-Interdisciplinary-(B.A)",
    "entranceGrade": "70 - 70",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Spanish",
    "programLink": "https://www.studyincanada.com/Programs/7416/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Spanish",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Renaissance Studies (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/8451/University-of-Toronto-St.-George/Renaissance-Studies-(HBA)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Music: Scholarship",
    "programLink": "https://www.studyincanada.com/Programs/77626/The-University-of-British-Columbia-Vancouver/Music-Scholarship",
    "entranceGrade": "N/S",
    "tutionValue": "$40,517",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Mediaeval Studies (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/8466/University-of-Toronto-St.-George/Mediaeval-Studies-(HBA)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/87537/Lakehead-University-Thunder-Bay/History",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Cape Breton University",
    "location": "Canada, Nova Scotia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/12623/Cape-Breton-University/History",
    "entranceGrade": "65",
    "tutionValue": "$19,579",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "International Relations (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/8434/University-of-Toronto-St.-George/International-Relations-(HBA)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/96734/Vancouver-Island-University/History",
    "entranceGrade": "N/S",
    "tutionValue": "$24,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Education - Secondary Social Sciences",
    "programLink": "https://www.studyincanada.com/Programs/105287/McGill-University/Education-Secondary-Social-Sciences",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Art History (BA)",
    "programLink": "https://www.studyincanada.com/Programs/37330/University-of-Manitoba/Art-History-(BA)",
    "entranceGrade": "N/S",
    "tutionValue": "$16,600",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Women's & Gender Studies",
    "programLink": "https://www.studyincanada.com/Programs/27101/University-of-Saskatchewan/Women-s-&-Gender-Studies",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "History/Women's Studies Joint Major",
    "programLink": "https://www.studyincanada.com/Programs/27910/University-of-Northern-British-Columbia/History/Women-s-Studies-Joint-Major",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Art History",
    "programLink": "https://www.studyincanada.com/Programs/77520/The-University-of-British-Columbia-Vancouver/Art-History",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Classics",
    "programLink": "https://www.studyincanada.com/Programs/4375/McMaster-University/Classics",
    "entranceGrade": "77 - 79",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Art History",
    "programLink": "https://www.studyincanada.com/Programs/21210/University-of-Regina/Art-History",
    "entranceGrade": "65 - 100",
    "tutionValue": "$12,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/25128/Acadia-University/History",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto: Ontario Institute for Studies in Education",
    "location": "Canada, Ontario",
    "programName": "Social Justice Education (MEd)",
    "programLink": "https://www.studyincanada.com/Programs/59879/University-of-Toronto-Ontario-Institute-for-Studies-in-Education/Social-Justice-Education-(MEd)",
    "entranceGrade": "N/S",
    "tutionValue": "$41,600",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Drama - Design (B.F.A. Honours)",
    "programLink": "https://www.studyincanada.com/Programs/85321/University-of-Saskatchewan/Drama-Design-(B.F.A.-Honours)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Prince Edward Island",
    "location": "Canada, Prince Edward Island",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/10356/University-of-Prince-Edward-Island/History",
    "entranceGrade": "70 - 100",
    "tutionValue": "$14,274",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/3234/University-of-Ottawa/Universit%c3%a9-d-Ottawa/History",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/4292/Bishop-s-University/History",
    "entranceGrade": "70",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "History (M.A.)",
    "programLink": "https://www.studyincanada.com/Programs/29710/Lakehead-University-Faculty-of-Graduate-Studies/History-(M.A.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "York University - Glendon Campus",
    "location": "Canada, Ontario",
    "programName": "Canadian Studies (BA)",
    "programLink": "https://www.studyincanada.com/Programs/15031/York-University-Glendon-Campus/Canadian-Studies-(BA)",
    "entranceGrade": "75 - 79",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Art History and Visual Culture (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/44495/University-of-Toronto-Scarborough/Art-History-and-Visual-Culture-(HBA)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cape Breton University",
    "location": "Canada, Nova Scotia",
    "programName": "Celtic Culture (Concentration)",
    "programLink": "https://www.studyincanada.com/Programs/55776/Cape-Breton-University/Celtic-Culture-(Concentration)",
    "entranceGrade": "65",
    "tutionValue": "$19,579",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/88593/The-University-of-British-Columbia-Okanagan/History",
    "entranceGrade": "70",
    "tutionValue": "$41,156",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "African Studies (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/62652/University-of-Toronto-Scarborough/African-Studies-(HBA)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/49705/University-of-Ottawa/Universit%c3%a9-d-Ottawa/History",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "History of Art (Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/7991/The-University-of-Winnipeg/History-of-Art-(Three-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/91271/Lakehead-University-Orillia/History",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/4674/University-of-Windsor-Faculty-of-Graduate-Studies/History",
    "entranceGrade": "75 - 100",
    "tutionValue": "$8,863",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Social Work (Pre-Social Work)",
    "programLink": "https://www.studyincanada.com/Programs/84636/University-of-Regina/Social-Work-(Pre-Social-Work)",
    "entranceGrade": "65 - 65",
    "tutionValue": "$11,712",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/77542/The-University-of-British-Columbia-Vancouver/History",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Classical Studies",
    "programLink": "https://www.studyincanada.com/Programs/1202/Acadia-University/Classical-Studies",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/12269/University-of-Victoria/History",
    "entranceGrade": "75 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/5779/Queen-s-University/History",
    "entranceGrade": "80 - 100",
    "tutionValue": "$55,567",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Art History",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/art-history-arth/",
    "entranceGrade": "75 - 80",
    "tutionValue": "$32,598",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Women's & Gender Studies (B.A. Four-Year)",
    "programLink": "https://www.studyincanada.com/Programs/11621/University-of-Saskatchewan/Women-s-&-Gender-Studies-(B.A.-Four-Year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Ancient Greek and Roman Studies (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/59752/Trent-University/Ancient-Greek-and-Roman-Studies-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Museum and Curatorial Studies",
    "programLink": "https://www.studyincanada.com/Programs/85091/Western-University/Museum-and-Curatorial-Studies",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/14367/Vancouver-Island-University/History",
    "entranceGrade": "N/S",
    "tutionValue": "$24,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/9772/Saint-Mary-s-University/History",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Crandall University",
    "location": "Canada, New Brunswick",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/37815/Crandall-University/History",
    "entranceGrade": "70 - 70",
    "tutionValue": "$17,900",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Redeemer University",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/717/Redeemer-University/History",
    "entranceGrade": "N/S",
    "tutionValue": "$19,494",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Caribbean Studies (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/8248/University-of-Toronto-St.-George/Caribbean-Studies-(HBA)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Indigenous Studies",
    "programLink": "https://www.studyincanada.com/Programs/91527/McMaster-University/Indigenous-Studies",
    "entranceGrade": "78 - 82",
    "tutionValue": "$40,960",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "History (Pre-Masters's)",
    "programLink": "https://www.studyincanada.com/Programs/13449/University-of-Manitoba-Faculty-of-Graduate-Studies/History-(Pre-Masters-s)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Arts | History",
    "programLink": "https://www.studyincanada.com/Programs/109170/University-of-Lethbridge/Bachelor-of-Arts-|-History",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "American Cultural Studies",
    "programLink": "https://www.studyincanada.com/Programs/65917/Western-University/American-Cultural-Studies",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "N/S"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/64612/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/History",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/9865/St.-Francis-Xavier-University/History",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algoma University",
    "location": "Canada, Ontario",
    "programName": "History",
    "programLink": "https://www.studyincanada.com/Programs/46252/Algoma-University/History",
    "entranceGrade": "65 - 65",
    "tutionValue": "$20,438",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto: Ontario Institute for Studies in Education",
    "location": "Canada, Ontario",
    "programName": "Social Justice Education (EdD)",
    "programLink": "https://www.studyincanada.com/Programs/65491/University-of-Toronto-Ontario-Institute-for-Studies-in-Education/Social-Justice-Education-(EdD)",
    "entranceGrade": "N/S",
    "tutionValue": "$40,260",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Art History and Studio Art",
    "programLink": "https://www.studyincanada.com/Programs/85078/Western-University/Art-History-and-Studio-Art",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "History of Science and Technology",
    "programLink": "https://www.studyincanada.com/Programs/56683/Dalhousie-University/History-of-Science-and-Technology",
    "entranceGrade": "70",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Art History",
    "programLink": "https://www.studyincanada.com/Programs/49286/The-University-of-British-Columbia-Vancouver/Art-History",
    "entranceGrade": "70",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Anthropology",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-anthropology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Anthropology (BA)",
    "programLink": "https://admissions.carleton.ca/programs/anthropology/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Anthropology",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-with-honors-anthropology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$29,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Archaeology and Heritage Studies (BA)",
    "programLink": "https://wlu.ca/programs/arts/undergraduate/archaeology-and-heritage-studies-ba/index.html",
    "entranceGrade": "70",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://futurestudents.yorku.ca/program/anthropology",
    "entranceGrade": "75 - 100",
    "tutionValue": "$24,587",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Anthropology and Sociology (BA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/anthropology-and-sociology.html",
    "entranceGrade": "72",
    "tutionValue": "$32,450",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Anthropology (BA in Combination)",
    "programLink": "https://wlu.ca/programs/arts/undergraduate/anthropology-ba/index.html",
    "entranceGrade": "70",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Anthropology",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/Arts/Departments/SociologyAnthropology/AnthropologyProgram/index.htm",
    "entranceGrade": "65 - 75",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://uwaterloo.ca/future-students/programs/anthropology?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$55,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Anthropology (BA)",
    "programLink": "https://admissions.carleton.ca/programs/anthropology/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Anthropology (BA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/anthropology.html",
    "entranceGrade": "72",
    "tutionValue": "$32,450",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Anthropology (BA)",
    "programLink": "https://admissions.carleton.ca/programs/anthropology/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Bioanthropology (Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/30171/The-University-of-Winnipeg/Bioanthropology-(Three-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Anthropology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/71938/Kwantlen-Polytechnic-University/Anthropology-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/48469/Douglas-College/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Archaeology - Specialization in Mediterranean Archaeology (B.A.)...",
    "programLink": "https://www.studyincanada.com/Programs/95529/Trent-University/Archaeology-Specialization-in-Mediterranean-Archaeology-(B.A.)...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Archaeology - Specialization in Environmental Archaeology (B.A.)...",
    "programLink": "https://www.studyincanada.com/Programs/105837/Trent-University/Archaeology-Specialization-in-Environmental-Archaeology-(B.A.)...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Athabasca University",
    "location": "Canada, Alberta",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/14806/Athabasca-University/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Anthropology and Sociology",
    "programLink": "https://www.studyincanada.com/Programs/49592/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Anthropology-and-Sociology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Anthropology (MA)",
    "programLink": "https://www.studyincanada.com/Programs/6648/Carleton-University-Faculty-of-Graduate-Studies-and-Research/Anthropology-(MA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/27406/Brandon-University/Anthropology",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/47087/St.-Francis-Xavier-University/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Anthropology (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/55819/University-of-Toronto-Scarborough/Anthropology-(HBSc)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/77411/The-University-of-British-Columbia-Vancouver/Anthropology",
    "entranceGrade": "70",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Anthropology and Sociology",
    "programLink": "https://www.studyincanada.com/Programs/49593/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Anthropology-and-Sociology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Anthropology (Three Year)",
    "programLink": "https://www.studyincanada.com/Programs/27074/The-University-of-Winnipeg/Anthropology-(Three-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/65563/McGill-University/Anthropology",
    "entranceGrade": "86",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/108710/Vancouver-Island-University/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "$24,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Certificate in Arts and Science | Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/112557/University-of-Lethbridge/Certificate-in-Arts-and-Science-|-Anthropology",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Archaeology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/72229/Trent-University/Archaeology-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Archaeology - Specialization in Mediterranean Archaeology (B.Sc....",
    "programLink": "https://www.studyincanada.com/Programs/95530/Trent-University/Archaeology-Specialization-in-Mediterranean-Archaeology-(B.Sc....",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/87307/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Anthropology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Archaeology and Anthropology (B.A. Three-year)",
    "programLink": "https://www.studyincanada.com/Programs/106133/University-of-Saskatchewan/Archaeology-and-Anthropology-(B.A.-Three-year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Arts | Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/2920/University-of-Lethbridge/Bachelor-of-Arts-|-Anthropology",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Archaeology",
    "programLink": "https://www.studyincanada.com/Programs/77442/The-University-of-British-Columbia-Vancouver/Archaeology",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Archaeology and Anthropology (B.A. Honours)",
    "programLink": "https://www.studyincanada.com/Programs/11086/University-of-Saskatchewan/Archaeology-and-Anthropology-(B.A.-Honours)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Anthropology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/74741/Lakehead-University-Orillia/Anthropology-(BA)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Applied Life Sciences",
    "programLink": "https://www.studyincanada.com/Programs/91272/Lakehead-University-Orillia/Applied-Life-Sciences",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Archaeology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/85216/Trent-University/Archaeology-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Anthropology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/12223/University-of-Victoria/Anthropology-(BA)",
    "entranceGrade": "75 - 80",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Prince Edward Island",
    "location": "Canada, Prince Edward Island",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/45504/University-of-Prince-Edward-Island/Anthropology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$14,274",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/77425/The-University-of-British-Columbia-Vancouver/Anthropology",
    "entranceGrade": "70",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Thomas University",
    "location": "Canada, New Brunswick",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/8579/St.-Thomas-University/Anthropology",
    "entranceGrade": "70",
    "tutionValue": "$17,208",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Anthropology (Advanced Major)",
    "programLink": "https://www.studyincanada.com/Programs/59975/University-of-Manitoba/Anthropology-(Advanced-Major)",
    "entranceGrade": "N/S",
    "tutionValue": "$16,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/81176/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Anthropology",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/112473/University-of-Lethbridge-School-of-Graduate-Studies/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Bioanthropology (Four-Year)",
    "programLink": "https://www.studyincanada.com/Programs/41106/The-University-of-Winnipeg/Bioanthropology-(Four-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/90277/Nipissing-University/Anthropology",
    "entranceGrade": "70 - 70",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Anthropology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/96413/University-of-Victoria/Anthropology-(BA)",
    "entranceGrade": "75 - 80",
    "tutionValue": "$27,949",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/2180/McGill-University/Anthropology",
    "entranceGrade": "86",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/9746/Saint-Mary-s-University/Anthropology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/2481/Lakehead-University-Thunder-Bay/Anthropology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Arts & Science and Anthropology (Combined Honours)",
    "programLink": "https://www.studyincanada.com/Programs/4290/McMaster-University/Arts-&-Science-and-Anthropology-(Combined-Honours)",
    "entranceGrade": "90",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Social and Cultural Anthropology (MA)",
    "programLink": "https://www.studyincanada.com/Programs/39659/Concordia-University-School-of-Graduate-Studies/Social-and-Cultural-Anthropology-(MA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Anthropology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/96399/University-of-Victoria/Anthropology-(BSc)",
    "entranceGrade": "80 - 85",
    "tutionValue": "$27,949",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/8581/University-of-New-Brunswick-Fredericton/Anthropology",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Sociology/Anthropology",
    "programLink": "https://www.ufv.ca/scms/programs/anthropology-ba/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/5953/The-University-of-Winnipeg/Anthropology",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Anthropology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/96403/University-of-Victoria/Anthropology-(BSc)",
    "entranceGrade": "80 - 85",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Sociocultural Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/65906/Western-University/Sociocultural-Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/32098/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Anthropology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/2922/University-of-Lethbridge-School-of-Graduate-Studies/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Archaeology and Anthropology (B.A. Four-year)",
    "programLink": "https://www.studyincanada.com/Programs/19044/University-of-Saskatchewan/Archaeology-and-Anthropology-(B.A.-Four-year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/49594/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Anthropology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Archaeology - Specialization in Environmental Archaeology (B.Sc....",
    "programLink": "https://www.studyincanada.com/Programs/105835/Trent-University/Archaeology-Specialization-in-Environmental-Archaeology-(B.Sc....",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Anthropology (Bachelor of Arts)",
    "programLink": "https://www.studyincanada.com/Programs/99369/University-of-Calgary/Anthropology-(Bachelor-of-Arts)",
    "entranceGrade": "70",
    "tutionValue": "$19,563",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Anthropology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/96398/University-of-Victoria/Anthropology-(BSc)",
    "entranceGrade": "80 - 85",
    "tutionValue": "$27,949",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/13324/Vancouver-Island-University/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "$24,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.ufv.ca/scms/programs/anthropology-ba/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/55980/University-of-Northern-British-Columbia/Anthropology",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Anthropology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/108681/Lakehead-University-Orillia/Anthropology-(BSc)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/107616/Saint-Mary-s-University/Anthropology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/6889/University-of-Regina/Anthropology",
    "entranceGrade": "65 - 100",
    "tutionValue": "$11,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/6414/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Anthropology",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/3031/Western-University/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/90512/University-of-Manitoba/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Anthropology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/96402/University-of-Victoria/Anthropology-(BSc)",
    "entranceGrade": "80 - 85",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Anthropology (General Major)",
    "programLink": "https://www.studyincanada.com/Programs/13358/University-of-Manitoba/Anthropology-(General-Major)",
    "entranceGrade": "N/S",
    "tutionValue": "$16,600",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Grenfell Campus, Memorial University",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Social Cultural Studies (BA)",
    "programLink": "https://www.studyincanada.com/Programs/13695/Grenfell-Campus,-Memorial-University/Social-Cultural-Studies-(BA)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$11,460",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Geoarchaeology",
    "programLink": "https://www.studyincanada.com/Programs/2568/Lakehead-University-Thunder-Bay/Geoarchaeology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/59583/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Anthropology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Anthropology (3 Year)",
    "programLink": "https://www.studyincanada.com/Programs/107315/Brandon-University/Anthropology-(3-Year)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/47088/St.-Francis-Xavier-University/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Archaeology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/85217/Trent-University/Archaeology-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Anthropology/Geography Joint Major",
    "programLink": "https://www.studyincanada.com/Programs/40097/University-of-Northern-British-Columbia/Anthropology/Geography-Joint-Major",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Prince Edward Island",
    "location": "Canada, Prince Edward Island",
    "programName": "Sociology/Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/10364/University-of-Prince-Edward-Island/Sociology/Anthropology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$14,274",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Linguistic Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/65904/Western-University/Linguistic-Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "N/S"
  },
  {
    "schoolName": "Cape Breton University",
    "location": "Canada, Nova Scotia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/5364/Cape-Breton-University/Anthropology",
    "entranceGrade": "65",
    "tutionValue": "$19,579",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/4229/McMaster-University/Anthropology",
    "entranceGrade": "78 - 82",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/77417/The-University-of-British-Columbia-Vancouver/Anthropology",
    "entranceGrade": "70",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Anthropology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/108680/Lakehead-University-Orillia/Anthropology-(BA)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/13360/University-of-Manitoba-Faculty-of-Graduate-Studies/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/23006/University-of-New-Brunswick-Fredericton/Anthropology",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Anthropology (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/44531/University-of-Toronto-Scarborough/Anthropology-(HBA)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Anthropology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/108682/Lakehead-University-Orillia/Anthropology-(BSc)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/51915/The-University-of-British-Columbia-Vancouver/Anthropology",
    "entranceGrade": "70",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/109024/Saint-Mary-s-University/Anthropology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Prince Edward Island",
    "location": "Canada, Prince Edward Island",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/10366/University-of-Prince-Edward-Island/Anthropology",
    "entranceGrade": "70",
    "tutionValue": "$14,274",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Anthropology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/12224/University-of-Victoria/Anthropology-(BA)",
    "entranceGrade": "75 - 80",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Anthropology and Another Subject (BA) (Combined Honours)",
    "programLink": "https://www.studyincanada.com/Programs/91524/McMaster-University/Anthropology-and-Another-Subject-(BA)-(Combined-Honours)",
    "entranceGrade": "78 - 82",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/19390/Lakehead-University-Thunder-Bay/Anthropology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Trent University - Durham Greater Toronto Area",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/95573/Trent-University-Durham-Greater-Toronto-Area/Anthropology",
    "entranceGrade": "70",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Archaeology and Biological Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/65909/Western-University/Archaeology-and-Biological-Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "N/S"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/13361/University-of-Manitoba-Faculty-of-Graduate-Studies/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/29897/The-University-of-British-Columbia-Okanagan/Anthropology",
    "entranceGrade": "67 - 100",
    "tutionValue": "$39,574",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Applied Life Sciences",
    "programLink": "https://www.studyincanada.com/Programs/2497/Lakehead-University-Thunder-Bay/Applied-Life-Sciences",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Geoarchaeology",
    "programLink": "https://www.studyincanada.com/Programs/2569/Lakehead-University-Thunder-Bay/Geoarchaeology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Sociology and Social Anthropology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/85343/Dalhousie-University/Sociology-and-Social-Anthropology-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Archaeology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/69847/Trent-University/Archaeology-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/anthropology-co-op-anth-c/",
    "entranceGrade": "80 - 83",
    "tutionValue": "$32,594",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Social Work (Pre-Social Work)",
    "programLink": "https://www.studyincanada.com/Programs/84636/University-of-Regina/Social-Work-(Pre-Social-Work)",
    "entranceGrade": "65 - 65",
    "tutionValue": "$11,712",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/11270/Kwantlen-Polytechnic-University/Anthropology",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Anthropology",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/anthropology-anth/",
    "entranceGrade": "76 - 80",
    "tutionValue": "$32,594",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Law, Justice and Society (BA)",
    "programLink": "https://www.studyincanada.com/Programs/96605/Dalhousie-University/Law,-Justice-and-Society-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Anthropology (Four Year)",
    "programLink": "https://www.studyincanada.com/Programs/85122/The-University-of-Winnipeg/Anthropology-(Four-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/81367/Selkirk-College/Anthropology",
    "entranceGrade": "N/S",
    "tutionValue": "$13,409",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Social and Cultural Analysis (PhD)",
    "programLink": "https://www.studyincanada.com/Programs/68874/Concordia-University-School-of-Graduate-Studies/Social-and-Cultural-Analysis-(PhD)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Anthropology",
    "programLink": "https://www.studyincanada.com/Programs/51101/Brandon-University/Anthropology",
    "entranceGrade": "60 - 100",
    "tutionValue": "$17,331",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "King's University College",
    "location": "Canada, Ontario",
    "programName": "Political Science and International Relations",
    "programLink": "https://www.kings.uwo.ca/academics/politics-international-relations/",
    "entranceGrade": "80",
    "tutionValue": "$37,476",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "King's University College",
    "location": "Canada, Ontario",
    "programName": "Political Science and International Relations",
    "programLink": "https://www.kings.uwo.ca/academics/politics-international-relations/",
    "entranceGrade": "80",
    "tutionValue": "$37,476",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Alexander College",
    "location": "Canada, British Columbia",
    "programName": "Associate of Arts (International Relations)",
    "programLink": "https://www.studyincanada.com/Programs/112957/Alexander-College/Associate-of-Arts-(International-Relations)",
    "entranceGrade": "N/S",
    "tutionValue": "$18,300",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "International Relations",
    "programLink": "https://www.studyincanada.com/Programs/88598/The-University-of-British-Columbia-Okanagan/International-Relations",
    "entranceGrade": "70",
    "tutionValue": "$41,156",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "International Relations",
    "programLink": "https://www.studyincanada.com/Programs/32214/Western-University/International-Relations",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "International Relations",
    "programLink": "https://www.studyincanada.com/Programs/99426/University-of-Calgary/International-Relations",
    "entranceGrade": "75",
    "tutionValue": "$20,172",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "International Relations",
    "programLink": "https://www.studyincanada.com/Programs/96302/The-University-of-British-Columbia-Vancouver/International-Relations",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Thomas University",
    "location": "Canada, New Brunswick",
    "programName": "International Relations",
    "programLink": "https://www.studyincanada.com/Programs/84948/St.-Thomas-University/International-Relations",
    "entranceGrade": "70",
    "tutionValue": "$17,208",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Redeemer University",
    "location": "Canada, Ontario",
    "programName": "International Relations",
    "programLink": "https://www.studyincanada.com/Programs/108768/Redeemer-University/International-Relations",
    "entranceGrade": "N/S",
    "tutionValue": "$19,494",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "International Relations",
    "programLink": "https://www.studyincanada.com/Programs/29917/The-University-of-British-Columbia-Okanagan/International-Relations",
    "entranceGrade": "67",
    "tutionValue": "$41,156",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Thomas University",
    "location": "Canada, New Brunswick",
    "programName": "International Relations",
    "programLink": "https://www.studyincanada.com/Programs/84949/St.-Thomas-University/International-Relations",
    "entranceGrade": "70",
    "tutionValue": "$17,208",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "International Relations and Development Studies",
    "programLink": "https://www.studyincanada.com/Programs/4684/University-of-Windsor/International-Relations-and-Development-Studies",
    "entranceGrade": "70 - 100",
    "tutionValue": "$34,641",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "HBA/ International Relations",
    "programLink": "https://www.studyincanada.com/Programs/107578/Western-University/HBA/-International-Relations",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Mount Allison University",
    "location": "Canada, New Brunswick",
    "programName": "International Relations",
    "programLink": "https://www.studyincanada.com/Programs/9683/Mount-Allison-University/International-Relations",
    "entranceGrade": "65",
    "tutionValue": "$20,885",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "International Relations",
    "programLink": "https://www.studyincanada.com/Programs/1668/The-University-of-British-Columbia-Vancouver/International-Relations",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "International Relations (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/8434/University-of-Toronto-St.-George/International-Relations-(HBA)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Criminology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/criminology/",
    "entranceGrade": "80 - 88",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "History and Criminology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/history/",
    "entranceGrade": "70 - 72",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Policing (BA in Combination)",
    "programLink": "https://wlu.ca/programs/human-and-social-sciences/undergraduate/policing-ba-combination/index.html",
    "entranceGrade": "72 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Criminology (BA)",
    "programLink": "https://wlu.ca/programs/human-and-social-sciences/undergraduate/criminology-ba/index.html",
    "entranceGrade": "70",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Criminology and Sociology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/criminology/",
    "entranceGrade": "80 - 88",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "King's University College",
    "location": "Canada, Ontario",
    "programName": "Criminology",
    "programLink": "https://www.kings.uwo.ca/academics/sociology/sociology-programs-offered/",
    "entranceGrade": "80",
    "tutionValue": "$37,476",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Criminal Justice",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/Arts/Departments/EconomicsJusticePolicyStudies/DegreePrograms/BachelorofArts-CriminalJustice/index.htm",
    "entranceGrade": "85 - 90",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "King's University College",
    "location": "Canada, Ontario",
    "programName": "Criminology",
    "programLink": "https://www.kings.uwo.ca/academics/sociology/",
    "entranceGrade": "80",
    "tutionValue": "$37,476",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Criminology and Criminal Justice (BA)",
    "programLink": "https://admissions.carleton.ca/programs/criminology-and-criminal-justice/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Politics and Governance and Criminology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/politics-governance/",
    "entranceGrade": "75 - 78",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Criminology and Policing (BA)",
    "programLink": "https://wlu.ca/programs/human-and-social-sciences/undergraduate/criminology-and-policing-ba/index.html",
    "entranceGrade": "70",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Sociology and Criminology",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/sociology/",
    "entranceGrade": "74 - 77",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Criminology and History",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/criminology/",
    "entranceGrade": "80 - 88",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Criminology (BA)",
    "programLink": "https://futurestudents.yorku.ca/program/criminology",
    "entranceGrade": "80 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Criminology",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-in-criminology-criminology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Criminology and Politics and Governance",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/criminology/",
    "entranceGrade": "80 - 88",
    "tutionValue": "$31,814",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Criminology (MA)",
    "programLink": "https://www.wlu.ca/programs/human-and-social-sciences/graduate/criminology-ma/index.html",
    "entranceGrade": "77 - 100",
    "tutionValue": "$14,540",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Criminology and Justice - Advanced Entry",
    "programLink": "https://ontariotechu.ca/programs/social-science-and-humanities/criminology-and-justice-advanced-entry.php",
    "entranceGrade": "70",
    "tutionValue": "$29,196",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Criminology and Justice - GAS Transfer",
    "programLink": "https://ontariotechu.ca/programs/social-science-and-humanities/criminology-and-justice-gas-transfer.php",
    "entranceGrade": "75",
    "tutionValue": "$29,196",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Criminology and Justice",
    "programLink": "https://ontariotechu.ca/programs/social-science-and-humanities/criminology-and-justice.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Athabasca University",
    "location": "Canada, Alberta",
    "programName": "Criminal Justice",
    "programLink": "https://www.studyincanada.com/Programs/9029/Athabasca-University/Criminal-Justice",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Criminology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/96450/Kwantlen-Polytechnic-University/Criminology-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Sociology & Criminology",
    "programLink": "https://www.studyincanada.com/Programs/114219/University-of-Windsor/Sociology-&-Criminology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$34,641",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology (B.A. Four-Year)",
    "programLink": "https://www.studyincanada.com/Programs/11585/University-of-Saskatchewan/Sociology-(B.A.-Four-Year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Criminology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/105844/Trent-University/Criminology-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Okanagan College",
    "location": "Canada, British Columbia",
    "programName": "Diploma in Criminal and Social Justice",
    "programLink": "https://www.studyincanada.com/Programs/46746/Okanagan-College/Diploma-in-Criminal-and-Social-Justice",
    "entranceGrade": "60",
    "tutionValue": "$13,750",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/59941/University-of-Manitoba/Criminology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,600",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Criminal Justice (Criminal Justice Studies)",
    "programLink": "https://www.studyincanada.com/Programs/90352/Nipissing-University/Criminal-Justice-(Criminal-Justice-Studies)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology (Double Honours)",
    "programLink": "https://www.studyincanada.com/Programs/73057/University-of-Saskatchewan/Sociology-(Double-Honours)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Criminology (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/109911/Trent-University/Criminology-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/3798/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Criminology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/109053/Langara-College/Criminology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/49656/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Criminology",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology - Indigenous Justice and Criminology (IJC)",
    "programLink": "https://www.studyincanada.com/Programs/56596/University-of-Saskatchewan/Sociology-Indigenous-Justice-and-Criminology-(IJC)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Criminology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/71945/Kwantlen-Polytechnic-University/Criminology-(BA)",
    "entranceGrade": "71",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/74742/Lakehead-University-Orillia/Criminology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Criminology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/11274/Kwantlen-Polytechnic-University/Criminology-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "College of New Caledonia",
    "location": "Canada, British Columbia",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/15713/College-of-New-Caledonia/Criminology",
    "entranceGrade": "N/S",
    "tutionValue": "$24,680",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Criminology and Socio-Legal Studies (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8344/University-of-Toronto-St.-George/Criminology-and-Socio-Legal-Studies-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Criminal Justice (Criminology)",
    "programLink": "https://www.studyincanada.com/Programs/62817/Nipissing-University/Criminal-Justice-(Criminology)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/45509/Kwantlen-Polytechnic-University/Criminology",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/56571/Western-University/Criminology",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "N/S"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology - Indigenous Justice and Criminology (IJC) (B.A. Three...",
    "programLink": "https://www.studyincanada.com/Programs/106231/University-of-Saskatchewan/Sociology-Indigenous-Justice-and-Criminology-(IJC)-(B.A.-Three...",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Criminology and Feminist and Gender Studies",
    "programLink": "https://www.studyincanada.com/Programs/49640/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Criminology-and-Feminist-and-Gender-Studies",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology (B.A. Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/106230/University-of-Saskatchewan/Sociology-(B.A.-Three-Year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/13317/Vancouver-Island-University/Criminology",
    "entranceGrade": "N/S",
    "tutionValue": "$24,476",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/45508/Kwantlen-Polytechnic-University/Criminology",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/46235/Vancouver-Island-University/Criminology",
    "entranceGrade": "N/S",
    "tutionValue": "$24,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/17146/Douglas-College/Criminology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Alberta - Augustana Campus",
    "location": "Canada, Alberta",
    "programName": "Law, Crime and Justice Studies - Interdisciplinary (B.A)",
    "programLink": "https://www.studyincanada.com/Programs/103754/University-of-Alberta-Augustana-Campus/Law,-Crime-and-Justice-Studies-Interdisciplinary-(B.A)",
    "entranceGrade": "70 - 70",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "North Island College (Comox Valley Campus)",
    "location": "Canada, British Columbia",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/68996/North-Island-College-(Comox-Valley-Campus)/Criminology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/9759/Saint-Mary-s-University/Criminology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Thomas University",
    "location": "Canada, New Brunswick",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/84947/St.-Thomas-University/Criminology",
    "entranceGrade": "70",
    "tutionValue": "$17,208",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/90517/University-of-Manitoba/Criminology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology - Indigenous Justice and Criminology (IJC) (B.A. Four-...",
    "programLink": "https://www.studyincanada.com/Programs/37215/University-of-Saskatchewan/Sociology-Indigenous-Justice-and-Criminology-(IJC)-(B.A.-Four-...",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Criminal Justice",
    "programLink": "https://www.studyincanada.com/Programs/8611/Langara-College/Criminal-Justice",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Sociology",
    "programLink": "https://www.studyincanada.com/Programs/11586/University-of-Saskatchewan/Sociology",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Protection, Security and Investigation",
    "programLink": "https://www.studyincanada.com/Programs/84942/Cambrian-College/Protection,-Security-and-Investigation",
    "entranceGrade": "N/S",
    "tutionValue": "$17,471",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Forensic Science",
    "programLink": "https://www.studyincanada.com/Programs/105358/Laurentian-University/Universite-Laurentienne/Forensic-Science",
    "entranceGrade": "80",
    "tutionValue": "$25,745",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Human Justice",
    "programLink": "https://www.studyincanada.com/Programs/6917/University-of-Regina/Human-Justice",
    "entranceGrade": "65 - 65",
    "tutionValue": "$11,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Criminology (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/100094/Laurentian-University/Universite-Laurentienne/Criminology-(HBA)",
    "entranceGrade": "70",
    "tutionValue": "$23,747",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Criminology (BA)",
    "programLink": "https://www.studyincanada.com/Programs/109547/Kwantlen-Polytechnic-University/Criminology-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/87556/Saint-Mary-s-University/Criminology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Trent University - Durham Greater Toronto Area",
    "location": "Canada, Ontario",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/108521/Trent-University-Durham-Greater-Toronto-Area/Criminology",
    "entranceGrade": "75",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Thomas University",
    "location": "Canada, New Brunswick",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/27287/St.-Thomas-University/Criminology",
    "entranceGrade": "70",
    "tutionValue": "$17,208",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/48485/Douglas-College/Criminology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Criminology",
    "programLink": "https://www.studyincanada.com/Programs/9758/Saint-Mary-s-University/Criminology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$20,440",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Criminal Psychology and Behaviour",
    "programLink": "https://www.studyincanada.com/Programs/106036/St.-Lawrence-College/Criminal-Psychology-and-Behaviour",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biotechnology (BSc with Biochemistry OR Biology)",
    "programLink": "https://admissions.carleton.ca/programs/biotechnology/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Honours Science (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/science-bsc-waterloo/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Chemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/chemistry/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Chemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/chemistry/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Chemistry",
    "programLink": "https://www.ualberta.ca/en/chemistry/undergraduate-program/index.html",
    "entranceGrade": "80",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Chemistry (BSc with Specialization)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-chemistry.html",
    "entranceGrade": "80",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Computational Biochemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/computational-biochemistry/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (BSc)",
    "programLink": "https://futurestudents.yorku.ca/program/biochemistry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/biochemistry/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Earth Sciences and Chemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/earth-sciences/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Chemistry (BSc - Business Minor)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-chemistry.html",
    "entranceGrade": "67 - 69",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Earth Sciences and Chemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/earth-sciences/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Chemistry",
    "programLink": "https://uwaterloo.ca/future-students/programs/chemistry?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$50,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Chemistry (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/chemistry-bsc/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Chemistry (BSc Honours)",
    "programLink": "https://futurestudents.yorku.ca/program/chemistry",
    "entranceGrade": "75 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Chemistry and Physics (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/chemistry-and-physics-bsc/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Biochemistry",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-biochemistry.html",
    "entranceGrade": "80",
    "tutionValue": "$30,090",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Science Foundations (Cert)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/science-foundations.html",
    "entranceGrade": "65",
    "tutionValue": "$32,450",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Computational Biochemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/computational-biochemistry/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Climate and Environmental Change",
    "programLink": "https://uwaterloo.ca/future-students/programs/climate-environmental-change?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$48,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biotechnology (BSc with Biochemistry OR Biology)",
    "programLink": "https://admissions.carleton.ca/programs/biotechnology/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics and Chemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Chemistry (BSc General)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-chemistry.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/biochemistry/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Chemistry",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/ScienceTechnology/Departments/ChemistryPhysics/Majors/Chemistry/index.htm",
    "entranceGrade": "85 - 90",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Biochemistry (BSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/biochemistry.html",
    "entranceGrade": "85",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Biochemistry",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-biochemistry.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Chemistry (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/augustana/programs/degree/bed-bsc/index.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Chemistry",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/chemistry/",
    "entranceGrade": "70 - 72",
    "tutionValue": "$31,811",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/biochemistry/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry/Biotechnology (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/biochemistry-biotechnology-bsc/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Chemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/chemistry/",
    "entranceGrade": "74 - 76",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://uwaterloo.ca/future-students/programs/biochemistry?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$50,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics and Chemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Chemistry (BSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/chemistry.html",
    "entranceGrade": "85",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Medicinal Chemistry",
    "programLink": "https://uwaterloo.ca/future-students/programs/medicinal-chemistry?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$50,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Alexander College",
    "location": "Canada, British Columbia",
    "programName": "Associate of Science (Mathematics)",
    "programLink": "https://www.studyincanada.com/Programs/107472/Alexander-College/Associate-of-Science-(Mathematics)",
    "entranceGrade": "N/S",
    "tutionValue": "$18,300",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/22936/University-of-New-Brunswick-Saint-John/Chemistry",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/22937/University-of-New-Brunswick-Saint-John/Chemistry",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Alexander College",
    "location": "Canada, British Columbia",
    "programName": "Associate of Science",
    "programLink": "https://www.studyincanada.com/Programs/70355/Alexander-College/Associate-of-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$18,300",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/22921/University-of-New-Brunswick-Saint-John/Chemistry",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Chemical Biology",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/chemical-biology/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Nuclear Engineering",
    "programLink": "https://ontariotechu.ca/programs/energy-systems-and-nuclear-science/nuclear-engineering.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$39,808",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Pharmaceutical Chemistry",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/pharmaceutical-chemistry/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Nuclear Engineering",
    "programLink": "https://ontariotechu.ca/programs/energy-systems-and-nuclear-science/nuclear-engineering.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$39,808",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Pharmaceutical Chemistry",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/pharmaceutical-chemistry/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Forensic Science",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/forensic-science/",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Bruce Power Women in Nuclear Engineering Internship Program",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/engineering/bruce-power-women-in-nuclear-engineering-co-op-program/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$39,808",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Chemistry",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/chemistry/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Chemistry",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/chemistry/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Chemical Biology",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/chemical-biology/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/27409/Brandon-University/Chemistry",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Chemistry - EcoChemistry",
    "programLink": "https://www.studyincanada.com/Programs/69128/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Chemistry-EcoChemistry",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Associate of Science Degree",
    "programLink": "https://www.studyincanada.com/Programs/107890/Thompson-Rivers-University/Associate-of-Science-Degree",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Materials Chemistry and Engineering",
    "programLink": "https://www.studyincanada.com/Programs/113800/University-of-Windsor-Faculty-of-Graduate-Studies/Materials-Chemistry-and-Engineering",
    "entranceGrade": "78 - 100",
    "tutionValue": "$9,573",
    "length": "16 Month(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Molecular Biology - Specialization in Conservat...",
    "programLink": "https://www.studyincanada.com/Programs/105838/Trent-University/Biochemistry-and-Molecular-Biology-Specialization-in-Conservat...",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Molecular Biology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/103/Trent-University/Biochemistry-and-Molecular-Biology-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Biological Sciences",
    "programLink": "https://www.studyincanada.com/Programs/99375/University-of-Calgary/Biological-Sciences",
    "entranceGrade": "90",
    "tutionValue": "$25,530",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/37381/University-of-Manitoba/Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trinity Western University",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/45558/Trinity-Western-University/Chemistry",
    "entranceGrade": "67 - 100",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Chemistry - Pharmaceutical Chemistry (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/37504/Laurentian-University/Universite-Laurentienne/Chemistry-Pharmaceutical-Chemistry-(BSc)",
    "entranceGrade": "70",
    "tutionValue": "$25,745",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Applied Life Sciences",
    "programLink": "https://www.studyincanada.com/Programs/2497/Lakehead-University-Thunder-Bay/Applied-Life-Sciences",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/23095/University-of-New-Brunswick-Fredericton/Chemistry",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Chemistry/Earth Sciences (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/68444/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Chemistry/Earth-Sciences-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Chemical Engineering",
    "programLink": "https://www.studyincanada.com/Programs/23090/University-of-New-Brunswick-Fredericton/Chemical-Engineering",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Grenfell Campus, Memorial University",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "General Science (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/21381/Grenfell-Campus,-Memorial-University/General-Science-(BSc)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$11,460",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/96596/Dalhousie-University/Chemistry",
    "entranceGrade": "75",
    "tutionValue": "$28,473",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry of Infection and Immunity",
    "programLink": "https://www.studyincanada.com/Programs/56368/Western-University/Biochemistry-of-Infection-and-Immunity",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/78460/University-of-Northern-British-Columbia/Chemistry",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/6998/University-of-Regina/Biochemistry",
    "entranceGrade": "70 - 100",
    "tutionValue": "$12,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University of Edmonton",
    "location": "Canada, Alberta",
    "programName": "Chemistry (B.Sc. 3-year)",
    "programLink": "https://www.studyincanada.com/Programs/56878/Concordia-University-of-Edmonton/Chemistry-(B.Sc.-3-year)",
    "entranceGrade": "60",
    "tutionValue": "$25,414",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/3603/Western-University/Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Allison University",
    "location": "Canada, New Brunswick",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/9648/Mount-Allison-University/Biochemistry",
    "entranceGrade": "65",
    "tutionValue": "$20,885",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Molecular Biology (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/102/Trent-University/Biochemistry-and-Molecular-Biology-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/99382/University-of-Calgary/Chemistry",
    "entranceGrade": "80",
    "tutionValue": "$25,530",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Environmental Geochemistry",
    "programLink": "https://www.studyincanada.com/Programs/23040/University-of-New-Brunswick-Fredericton/Environmental-Geochemistry",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/104963/Queen-s-University/Biochemistry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$55,567",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Nutrition - Health and Disease",
    "programLink": "https://www.studyincanada.com/Programs/78140/McGill-University/Nutrition-Health-and-Disease",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/15428/University-of-New-Brunswick-Fredericton/Chemistry",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/77533/The-University-of-British-Columbia-Vancouver/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Forensic Chemistry (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/109890/Trent-University/Forensic-Chemistry-(B.Sc.)",
    "entranceGrade": "75 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Biology (B.Sc. Double Honours) with Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/69474/University-of-Saskatchewan/Biology-(B.Sc.-Double-Honours)-with-Biochemistry",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Chemical Engineering",
    "programLink": "https://www.studyincanada.com/Programs/7253/McGill-University/Chemical-Engineering",
    "entranceGrade": "91",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/27156/University-of-Victoria/Biochemistry",
    "entranceGrade": "80",
    "tutionValue": "$27,949",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Chemistry (B.Sc. Four-year)",
    "programLink": "https://www.studyincanada.com/Programs/19056/University-of-Saskatchewan/Chemistry-(B.Sc.-Four-year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/109095/Douglas-College/Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry with Option in Microbiology and Immunology",
    "programLink": "https://www.studyincanada.com/Programs/66386/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biochemistry-with-Option-in-Microbiology-and-Immunology",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/81182/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Chemistry",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biomedical Science - Medicinal Chemistry (Research Focus)",
    "programLink": "https://www.studyincanada.com/Programs/96572/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biomedical-Science-Medicinal-Chemistry-(Research-Focus)",
    "entranceGrade": "N/S",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/4340/McMaster-University/Chemistry",
    "entranceGrade": "82 - 85",
    "tutionValue": "$43,116",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Medical Biophysics and Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/85083/Western-University/Medical-Biophysics-and-Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Health Sciences",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/88031/McMaster-University-Faculty-of-Health-Sciences/Biochemistry",
    "entranceGrade": "77",
    "tutionValue": "$17,096",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Medical Laboratory Technology",
    "programLink": "https://www.studyincanada.com/Programs/27214/Cambrian-College/Medical-Laboratory-Technology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/3677/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Chemistry",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Applied Mathematics/Chemistry (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/95915/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Applied-Mathematics/Chemistry-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/37416/University-of-Manitoba/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta - Augustana Campus",
    "location": "Canada, Alberta",
    "programName": "Chemistry (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/12011/University-of-Alberta-Augustana-Campus/Chemistry-(B.A.)",
    "entranceGrade": "70 - 70",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/113852/University-of-Calgary-Faculty-of-Science/Chemistry",
    "entranceGrade": "83",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Chemistry (Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/27078/The-University-of-Winnipeg/Chemistry-(Three-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Integrated Science (Chemistry Concentration)",
    "programLink": "https://www.studyincanada.com/Programs/91588/McMaster-University/Integrated-Science-(Chemistry-Concentration)",
    "entranceGrade": "87 - 89",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/7351/McGill-University/Biochemistry",
    "entranceGrade": "95",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Chemistry (MSc)",
    "programLink": "https://www.studyincanada.com/Programs/96174/Concordia-University-School-of-Graduate-Studies/Chemistry-(MSc)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/12212/University-of-Victoria/Biochemistry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Engineering - Chemical",
    "programLink": "https://www.studyincanada.com/Programs/19366/Lakehead-University-Thunder-Bay/Engineering-Chemical",
    "entranceGrade": "70 - 100",
    "tutionValue": "$37,395",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biochemistry/Behavioural Neuroscience (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/106435/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Biochemistry/Behavioural-Neuroscience-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Pharmaceutical Chemistry (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/100102/Laurentian-University/Universite-Laurentienne/Pharmaceutical-Chemistry-(HBSc)",
    "entranceGrade": "70",
    "tutionValue": "$25,745",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/25138/Acadia-University/Chemistry",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/1976/Graduate-Studies-at-University-of-Waterloo/Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Redeemer University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/89405/Redeemer-University/Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$19,494",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/33448/Saint-Mary-s-University/Chemistry",
    "entranceGrade": "70 - 100",
    "tutionValue": "$22,060",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/4359/Bishop-s-University/Chemistry",
    "entranceGrade": "75",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/104964/Queen-s-University/Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$55,567",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Chemistry: Bachelor of Science Degree - Major",
    "programLink": "https://www.studyincanada.com/Programs/107898/Thompson-Rivers-University/Chemistry-Bachelor-of-Science-Degree-Major",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/4788/Laurentian-University/Universite-Laurentienne/Biochemistry-(HBSc)",
    "entranceGrade": "70",
    "tutionValue": "$25,745",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa - Faculty of Medicine",
    "location": "Canada, Ontario",
    "programName": "MD / PhD - Medicine and Philosophy",
    "programLink": "https://www.studyincanada.com/Programs/28880/University-of-Ottawa/Universit%c3%a9-d-Ottawa-Faculty-of-Medicine/MD-/-PhD-Medicine-and-Philosophy",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "7 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/1977/Graduate-Studies-at-University-of-Waterloo/Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Biochimie (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/78348/Laurentian-University/Universite-Laurentienne/Biochimie-(BSc)",
    "entranceGrade": "70",
    "tutionValue": "$25,745",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Chemistry (3 Year)",
    "programLink": "https://www.studyincanada.com/Programs/107355/Brandon-University/Chemistry-(3-Year)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/81369/Selkirk-College/Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$13,409",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Chemical Engineering (Grad. Cert.)",
    "programLink": "https://www.studyincanada.com/Programs/113176/Concordia-University-School-of-Graduate-Studies/Chemical-Engineering-(Grad.-Cert.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Cancer Biology",
    "programLink": "https://www.studyincanada.com/Programs/91950/Western-University/Biochemistry-and-Cancer-Biology",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Chemical Engineering",
    "programLink": "https://www.studyincanada.com/Programs/15392/University-of-New-Brunswick-Fredericton/Chemical-Engineering",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Cape Breton University",
    "location": "Canada, Nova Scotia",
    "programName": "Chemical (Advanced)",
    "programLink": "https://www.studyincanada.com/Programs/105166/Cape-Breton-University/Chemical-(Advanced)",
    "entranceGrade": "65",
    "tutionValue": "$19,579",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/90734/Langara-College/Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/37377/University-of-Manitoba/Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8220/University-of-Toronto-St.-George/Biochemistry-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Biochemistry/Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/81219/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Biochemistry/Physics-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Chemical Laboratory Technology",
    "programLink": "https://www.studyincanada.com/Programs/12980/SAIT/Chemical-Laboratory-Technology",
    "entranceGrade": "50 - 50",
    "tutionValue": "$22,125",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/49618/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Chemistry",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Microbiology and Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/96421/University-of-Victoria/Microbiology-and-Chemistry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/12226/University-of-Victoria/Chemistry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/56375/Western-University/Biochemistry-and-Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/81727/The-University-of-British-Columbia-Vancouver/Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/3222/University-of-Northern-British-Columbia/Chemistry",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Chemical and Physical Sciences",
    "programLink": "https://www.studyincanada.com/Programs/81666/McMaster-University/Chemical-and-Physical-Sciences",
    "entranceGrade": "82 - 85",
    "tutionValue": "$43,116",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Environmental Technology",
    "programLink": "https://www.studyincanada.com/Programs/16668/SAIT/Environmental-Technology",
    "entranceGrade": "50 - 60",
    "tutionValue": "$20,048",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University of Edmonton",
    "location": "Canada, Alberta",
    "programName": "Chemistry (B.Sc. 4-year)",
    "programLink": "https://www.studyincanada.com/Programs/56876/Concordia-University-of-Edmonton/Chemistry-(B.Sc.-4-year)",
    "entranceGrade": "60",
    "tutionValue": "$25,414",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Food Science",
    "programLink": "https://www.studyincanada.com/Programs/19050/University-of-Saskatchewan/Food-Science",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry and Cell Biology",
    "programLink": "https://www.studyincanada.com/Programs/56357/Western-University/Biochemistry-and-Cell-Biology",
    "entranceGrade": "N/S",
    "tutionValue": "$47,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering Technology - Lab and Process Control",
    "programLink": "https://www.studyincanada.com/Programs/4625/Cambrian-College/Chemical-Engineering-Technology-Lab-and-Process-Control",
    "entranceGrade": "N/S",
    "tutionValue": "$17,411",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Environmental Chemistry (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8361/University-of-Toronto-St.-George/Environmental-Chemistry-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Faculty of Medicine at the University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Biochemistry and Molecular Biology",
    "programLink": "https://www.studyincanada.com/Programs/15874/Faculty-of-Medicine-at-the-University-of-Calgary/Biochemistry-and-Molecular-Biology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,389",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry and Molecular Biology",
    "programLink": "https://www.studyincanada.com/Programs/78459/University-of-Northern-British-Columbia/Biochemistry-and-Molecular-Biology",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Biochemistry (Four-Year)",
    "programLink": "https://www.studyincanada.com/Programs/41105/The-University-of-Winnipeg/Biochemistry-(Four-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Environmental Chemistry (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/131/Trent-University/Environmental-Chemistry-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/biochemistry-bioc/",
    "entranceGrade": "78 - 84",
    "tutionValue": "$32,599",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Food Science",
    "programLink": "https://www.studyincanada.com/Programs/105282/McGill-University/Food-Science",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/12326/University-of-Victoria/Biochemistry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/1769/The-University-of-British-Columbia-Vancouver/Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Biological and Pharmaceutical Chemistry",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/biological-pharmaceutical-chemistry-bpch/",
    "entranceGrade": "78 - 84",
    "tutionValue": "$32,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/29906/The-University-of-British-Columbia-Okanagan/Chemistry",
    "entranceGrade": "67 - 100",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/64916/University-of-Windsor/Biochemistry",
    "entranceGrade": "70 - 100",
    "tutionValue": "$37,497",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/36607/Queen-s-University/Biochemistry",
    "entranceGrade": "85 - 100",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Food Science",
    "programLink": "https://www.studyincanada.com/Programs/95683/McGill-University/Food-Science",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/55655/Bishop-s-University/Biochemistry",
    "entranceGrade": "75",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Chemistry and Mathematics",
    "programLink": "https://www.studyincanada.com/Programs/96415/University-of-Victoria/Chemistry-and-Mathematics",
    "entranceGrade": "80",
    "tutionValue": "$27,949",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/12227/University-of-Victoria/Chemistry",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Chemistry (B.Sc. Three-year)",
    "programLink": "https://www.studyincanada.com/Programs/106141/University-of-Saskatchewan/Chemistry-(B.Sc.-Three-year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Chemical Physics (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/111/Trent-University/Chemical-Physics-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/49595/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Biochemistry",
    "entranceGrade": "80 - 82",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Biochemistry (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/44618/University-of-Toronto-Scarborough/Biochemistry-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Chemistry/Mathematics and Statistics Joint Major",
    "programLink": "https://www.studyincanada.com/Programs/3199/University-of-Northern-British-Columbia/Chemistry/Mathematics-and-Statistics-Joint-Major",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "College of New Caledonia",
    "location": "Canada, British Columbia",
    "programName": "Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/49120/College-of-New-Caledonia/Chemistry",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Chemistry (B.Sc. Honours)",
    "programLink": "https://www.studyincanada.com/Programs/11114/University-of-Saskatchewan/Chemistry-(B.Sc.-Honours)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Faculty of Medicine at the University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Biochemistry and Molecular Biology",
    "programLink": "https://www.studyincanada.com/Programs/15852/Faculty-of-Medicine-at-the-University-of-Calgary/Biochemistry-and-Molecular-Biology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,389",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Chemical Engineering",
    "programLink": "https://www.studyincanada.com/Programs/5876/Queen-s-University/Chemical-Engineering",
    "entranceGrade": "85 - 100",
    "tutionValue": "$61,487",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Capilano University",
    "location": "Canada, British Columbia",
    "programName": "Science",
    "programLink": "https://www.studyincanada.com/Programs/108199/Capilano-University/Science",
    "entranceGrade": "N/S",
    "tutionValue": "$21,066",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Chemistry (B.Sc. Double Honours)",
    "programLink": "https://www.studyincanada.com/Programs/69467/University-of-Saskatchewan/Chemistry-(B.Sc.-Double-Honours)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Physics (BSc with Specialization)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/index.html#sort=relevancy",
    "entranceGrade": "N/S",
    "tutionValue": "$30,090",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Astrophysics",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-astrophysics.html",
    "entranceGrade": "80",
    "tutionValue": "$30,090",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Physics",
    "programLink": "https://uwaterloo.ca/future-students/programs/physics?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$50,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Medical Physics",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/medical-physics/",
    "entranceGrade": "70 - 72",
    "tutionValue": "$31,811",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Honours Science (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/science-bsc-waterloo/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Mathematical Physics",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-mathematical-physics.html",
    "entranceGrade": "80",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Physics",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-physics.html",
    "entranceGrade": "82",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Biophysics (BSc)",
    "programLink": "https://futurestudents.yorku.ca/program/biomedical-physics",
    "entranceGrade": "75 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Nanoengineering (BSc in Engineering Physics)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-engineering-physics-nanoengineering-option-nanoengineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Mathematics and Physics (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/mathematics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Applied Physics (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/applied-physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Physics (BSc)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/physics.html",
    "entranceGrade": "70",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Engineering Physics (Co-op)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-engineering-physics-engineering-physics.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Science Foundations (Cert)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/science-foundations.html",
    "entranceGrade": "65",
    "tutionValue": "$32,450",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Physics",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-physics.html",
    "entranceGrade": "80",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Applied Physics (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/applied-physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics (Experimental) (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics (Experimental) (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Computer Science and Physics (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/computer-science-and-physics-bsc/index.html",
    "entranceGrade": "77 - 100",
    "tutionValue": "$35,796",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics and Biology (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Engineering Physics (BEng)",
    "programLink": "https://admissions.carleton.ca/programs/engineering-physics/",
    "entranceGrade": "75 - 85",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Mathematical Physics (BSc)",
    "programLink": "https://uwaterloo.ca/future-students/programs/mathematical-physics?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$59,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics (Theory) (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Physics (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/index.html#sort=relevancy",
    "entranceGrade": "75",
    "tutionValue": "$27,540",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Chemistry and Physics (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/chemistry-and-physics-bsc/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Physics and Astronomy",
    "programLink": "https://uwaterloo.ca/future-students/programs/physics-and-astronomy?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$50,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics and Chemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Astrophysics",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-astrophysics.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Geophysics",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-geophysics.html",
    "entranceGrade": "80",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Mathematical Physics (BMath)",
    "programLink": "https://uwaterloo.ca/future-students/programs/mathematical-physics?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "84 - 86",
    "tutionValue": "$59,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics and Biology (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics (Theory) (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Physics and Chemistry (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/physics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Mathematics and Physics (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/mathematics/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Physics & Astronomy (BSc)",
    "programLink": "https://futurestudents.yorku.ca/program/physics-astronomy",
    "entranceGrade": "75 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Biological and Medical Physics",
    "programLink": "https://uwaterloo.ca/future-students/programs/biological-and-medical-physics",
    "entranceGrade": "80 - 83",
    "tutionValue": "$50,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Engineering Physics",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-engineering-physics-engineering-physics.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Geophysics (BSc with Specialization)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-geophysics.html",
    "entranceGrade": "80 - 82",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Nanoengineering (BSc in Engineering Physics)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-in-engineering-physics-nanoengineering-option-co-operative-nanoengineering.html",
    "entranceGrade": "N/S",
    "tutionValue": "$45,482",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Physics (BSc - Business Minor)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/index.html#sort=relevancy",
    "entranceGrade": "67 - 69",
    "tutionValue": "$30,090",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/22943/University-of-New-Brunswick-Saint-John/Physics",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/22944/University-of-New-Brunswick-Saint-John/Physics",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Alexander College",
    "location": "Canada, British Columbia",
    "programName": "Associate of Science",
    "programLink": "https://www.studyincanada.com/Programs/70355/Alexander-College/Associate-of-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$18,300",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Nuclear Engineering",
    "programLink": "https://ontariotechu.ca/programs/energy-systems-and-nuclear-science/nuclear-engineering.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$39,808",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Health Physics and Radiation Science",
    "programLink": "https://ontariotechu.ca/programs/energy-systems-and-nuclear-science/health-physics-and-radiation-science.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Bruce Power Women in Nuclear Engineering Internship Program",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/engineering/bruce-power-women-in-nuclear-engineering-co-op-program/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$39,808",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Forensic Science",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/forensic-science/",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Physics",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/physics/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Nuclear Engineering",
    "programLink": "https://ontariotechu.ca/programs/energy-systems-and-nuclear-science/nuclear-engineering.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$39,808",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Physics",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/science/physics/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Physics and Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/13537/University-of-Manitoba-Faculty-of-Graduate-Studies/Physics-and-Astronomy",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Meteorology (Diploma)",
    "programLink": "https://www.studyincanada.com/Programs/108800/Dalhousie-University/Meteorology-(Diploma)",
    "entranceGrade": "75",
    "tutionValue": "$28,473",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Grenfell Campus, Memorial University",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "General Science (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/21381/Grenfell-Campus,-Memorial-University/General-Science-(BSc)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$11,460",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Physics (B.Sc. Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/106208/University-of-Saskatchewan/Physics-(B.Sc.-Three-Year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Medical Biophysics and Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/85083/Western-University/Medical-Biophysics-and-Biochemistry",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Environmental Physics",
    "programLink": "https://www.studyincanada.com/Programs/27428/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Environmental-Physics",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/109470/Queen-s-University/Physics-(BSc)",
    "entranceGrade": "80",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Physics -  Biological Physics",
    "programLink": "https://www.studyincanada.com/Programs/96567/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Physics-Biological-Physics",
    "entranceGrade": "80 - 82",
    "tutionValue": "$48,353",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Associate of Science Degree",
    "programLink": "https://www.studyincanada.com/Programs/107890/Thompson-Rivers-University/Associate-of-Science-Degree",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Geophysics (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8392/University-of-Toronto-St.-George/Geophysics-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Geophysics",
    "programLink": "https://www.studyincanada.com/Programs/77507/The-University-of-British-Columbia-Vancouver/Geophysics",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Biological and Medical Physics",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/biological-medical-physics-co-op-bmphc/",
    "entranceGrade": "80 - 83",
    "tutionValue": "$32,600",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Physics: Bachelor of Science Degree - Major",
    "programLink": "https://www.studyincanada.com/Programs/107907/Thompson-Rivers-University/Physics-Bachelor-of-Science-Degree-Major",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Earth Sciences/Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/68446/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Earth-Sciences/Physics-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Engineering Physics",
    "programLink": "https://www.studyincanada.com/Programs/108537/McMaster-University-Faculty-of-Engineering/Engineering-Physics",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Biophysics",
    "programLink": "https://www.studyincanada.com/Programs/77472/The-University-of-British-Columbia-Vancouver/Biophysics",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Applied Life Sciences",
    "programLink": "https://www.studyincanada.com/Programs/91272/Lakehead-University-Orillia/Applied-Life-Sciences",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Geophysics and Physical Oceanography",
    "programLink": "https://www.studyincanada.com/Programs/68463/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Geophysics-and-Physical-Oceanography",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/3318/University-of-Northern-British-Columbia/Physics",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Earth Sciences/Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/81185/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Earth-Sciences/Physics-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Geophysics",
    "programLink": "https://www.studyincanada.com/Programs/77506/The-University-of-British-Columbia-Vancouver/Geophysics",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Biological and Medical Physics",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/biological-medical-physics-bmph/#text",
    "entranceGrade": "78 - 84",
    "tutionValue": "$32,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/29926/The-University-of-British-Columbia-Okanagan/Physics",
    "entranceGrade": "67",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Physics for Modern Technology (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/78325/Kwantlen-Polytechnic-University/Physics-for-Modern-Technology-(BSc)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Science | Physics",
    "programLink": "https://www.studyincanada.com/Programs/109176/University-of-Lethbridge/Bachelor-of-Science-|-Physics",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/9801/Saint-Mary-s-University/Physics",
    "entranceGrade": "70 - 100",
    "tutionValue": "$22,060",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Ocean Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/106426/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Ocean-Physics-(BSc)",
    "entranceGrade": "70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Physics - Mathematics",
    "programLink": "https://www.studyincanada.com/Programs/49720/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Physics-Mathematics",
    "entranceGrade": "80 - 82",
    "tutionValue": "$48,353",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/1992/Graduate-Studies-at-University-of-Waterloo/Physics",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Engineering Physics",
    "programLink": "https://www.studyincanada.com/Programs/96720/University-of-Saskatchewan/Engineering-Physics",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Physics and Atmospheric Sciences (BSc or BA)",
    "programLink": "https://www.studyincanada.com/Programs/46119/Dalhousie-University/Physics-and-Atmospheric-Sciences-(BSc-or-BA)",
    "entranceGrade": "75",
    "tutionValue": "$28,473",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Biophysics",
    "programLink": "https://www.studyincanada.com/Programs/1785/The-University-of-British-Columbia-Vancouver/Biophysics",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Astrophysics",
    "programLink": "https://www.studyincanada.com/Programs/113865/University-of-Calgary-Faculty-of-Science/Astrophysics",
    "entranceGrade": "77",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Mathematical Physics",
    "programLink": "https://www.studyincanada.com/Programs/11515/University-of-Saskatchewan/Mathematical-Physics",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/19123/Carleton-University-Faculty-of-Graduate-Studies-and-Research/Physics",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Physics",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/physics-phys/",
    "entranceGrade": "78 - 83",
    "tutionValue": "$32,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Engineering Physics",
    "programLink": "https://www.studyincanada.com/Programs/1629/The-University-of-British-Columbia-Vancouver/Engineering-Physics",
    "entranceGrade": "N/S",
    "tutionValue": "$54,987",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Applied Mathematics/Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/68441/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Applied-Mathematics/Physics-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Physics and Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/13538/University-of-Manitoba-Faculty-of-Graduate-Studies/Physics-and-Astronomy",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Coquitlam College",
    "location": "Canada, British Columbia",
    "programName": "Science - Chemistry, Physics or Engineering",
    "programLink": "https://www.studyincanada.com/Programs/30549/Coquitlam-College/Science-Chemistry,-Physics-or-Engineering",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/5479/McMaster-University/Physics",
    "entranceGrade": "82 - 85",
    "tutionValue": "$40,960",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Mathematical Physics (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/55895/Trent-University/Mathematical-Physics-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Integrated Science (Biophysics Concentration)",
    "programLink": "https://www.studyincanada.com/Programs/81638/McMaster-University/Integrated-Science-(Biophysics-Concentration)",
    "entranceGrade": "87 - 89",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Physics (M.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/71273/Lakehead-University-Faculty-of-Graduate-Studies/Physics-(M.Sc.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/29927/The-University-of-British-Columbia-Okanagan/Physics",
    "entranceGrade": "76",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Environmental Physics",
    "programLink": "https://www.studyincanada.com/Programs/81192/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Environmental-Physics",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Physics (PhD)",
    "programLink": "https://www.studyincanada.com/Programs/67148/Concordia-University-School-of-Graduate-Studies/Physics-(PhD)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/1339/Acadia-University/Physics-(BSc)",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Grenfell Campus, Memorial University",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/69512/Grenfell-Campus,-Memorial-University/Physics-(BSc)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$11,460",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Chemical Engineering",
    "programLink": "https://www.studyincanada.com/Programs/15392/University-of-New-Brunswick-Fredericton/Chemical-Engineering",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Theoretical Physics",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/theoretical-physics-thpy/#text",
    "entranceGrade": "78 - 84",
    "tutionValue": "$32,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Astronomy and Physics (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8496/University-of-Toronto-St.-George/Astronomy-and-Physics-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Ocean Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/106431/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Ocean-Physics-(BSc)",
    "entranceGrade": "70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Physics (BA)",
    "programLink": "https://www.studyincanada.com/Programs/81415/Queen-s-University/Physics-(BA)",
    "entranceGrade": "85",
    "tutionValue": "$55,567",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/23005/University-of-New-Brunswick-Fredericton/Physics",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Engineering Physics & Management",
    "programLink": "https://www.studyincanada.com/Programs/108560/McMaster-University-Faculty-of-Engineering/Bachelor-of-Engineering-Physics-&-Management",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science/Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/81183/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Computer-Science/Physics-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Astronomy & Astrophysics (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/81697/University-of-Toronto-Scarborough/Astronomy-&-Astrophysics-(HBSc)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Physics and Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/90553/University-of-Manitoba/Physics-and-Astronomy",
    "entranceGrade": "85 - 85",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/23097/University-of-New-Brunswick-Fredericton/Physics",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Geophysics - For Professional Registration",
    "programLink": "https://www.studyincanada.com/Programs/3882/Western-University/Geophysics-For-Professional-Registration",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Physics and Geophysics",
    "programLink": "https://www.studyincanada.com/Programs/39825/McGill-University/Physics-and-Geophysics",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Physics (Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/27067/The-University-of-Winnipeg/Physics-(Three-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Physics (B.Sc. Four-Year)",
    "programLink": "https://www.studyincanada.com/Programs/11551/University-of-Saskatchewan/Physics-(B.Sc.-Four-Year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Engineering Physics",
    "programLink": "https://www.studyincanada.com/Programs/58924/University-of-Saskatchewan/Engineering-Physics",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/1342/Acadia-University/Physics-(BSc)",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Environmental Physics (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/101942/University-of-Toronto-Scarborough/Environmental-Physics-(HBSc)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/51130/Brandon-University/Physics",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/77488/The-University-of-British-Columbia-Vancouver/Physics",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Astrophysics",
    "programLink": "https://www.studyincanada.com/Programs/23159/Queen-s-University/Astrophysics",
    "entranceGrade": "80 - 100",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Chemistry/Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/68448/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Chemistry/Physics-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Physics and Electrical Engineering",
    "programLink": "https://www.studyincanada.com/Programs/87305/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Physics-and-Electrical-Engineering",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/25911/Acadia-University/Physics-(BSc)",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/27172/University-of-Victoria/Physics",
    "entranceGrade": "80",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/8620/Langara-College/Physics",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/1784/The-University-of-British-Columbia-Vancouver/Physics",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Mathematics and Physics (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8458/University-of-Toronto-St.-George/Mathematics-and-Physics-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Physics - Mathematics",
    "programLink": "https://www.studyincanada.com/Programs/49738/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Physics-Mathematics",
    "entranceGrade": "80 - 82",
    "tutionValue": "$48,353",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Physics and Computer Science",
    "programLink": "https://www.studyincanada.com/Programs/30672/University-of-Victoria/Physics-and-Computer-Science",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Physics -  Biological Physics",
    "programLink": "https://www.studyincanada.com/Programs/96568/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Physics-Biological-Physics",
    "entranceGrade": "80 - 82",
    "tutionValue": "$48,353",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Physics and Electrical Engineering",
    "programLink": "https://www.studyincanada.com/Programs/87306/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Physics-and-Electrical-Engineering",
    "entranceGrade": "80 - 82",
    "tutionValue": "$52,707",
    "length": "6 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/9897/St.-Francis-Xavier-University/Physics",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/49718/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Physics",
    "entranceGrade": "80 - 82",
    "tutionValue": "$48,353",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science - Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Quantum Computing",
    "programLink": "https://www.studyincanada.com/Programs/113898/University-of-Calgary-Faculty-of-Science-Graduate-Studies/Quantum-Computing",
    "entranceGrade": "83",
    "tutionValue": "$8,408",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/81193/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Physics",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University - Faculty of Engineering",
    "location": "Canada, Ontario",
    "programName": "Engineering Physics and Biomedical Engineering",
    "programLink": "https://www.studyincanada.com/Programs/108572/McMaster-University-Faculty-of-Engineering/Engineering-Physics-and-Biomedical-Engineering",
    "entranceGrade": "N/S",
    "tutionValue": "$63,397",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Chemical Physics (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/112/Trent-University/Chemical-Physics-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Atmospheric Science and Physics",
    "programLink": "https://www.studyincanada.com/Programs/39836/McGill-University/Atmospheric-Science-and-Physics",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Physics and Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/49511/McGill-University/Physics-and-Chemistry",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Liberal Program in Earth and Planetary Sciences",
    "programLink": "https://www.studyincanada.com/Programs/78200/McGill-University/Liberal-Program-in-Earth-and-Planetary-Sciences",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Physics (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/11552/University-of-Saskatchewan/Physics-(B.Sc.)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Geology - Physics",
    "programLink": "https://www.studyincanada.com/Programs/49683/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Geology-Physics",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Integrated Science with Astrophysics",
    "programLink": "https://www.studyincanada.com/Programs/107577/Western-University/Integrated-Science-with-Astrophysics",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Physics (M.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/29712/Lakehead-University-Faculty-of-Graduate-Studies/Physics-(M.Sc.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/27405/Brandon-University/Physics",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/77487/The-University-of-British-Columbia-Vancouver/Physics",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Geophysics",
    "programLink": "https://www.studyincanada.com/Programs/1776/The-University-of-British-Columbia-Vancouver/Geophysics",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Liberal Program in Physics",
    "programLink": "https://www.studyincanada.com/Programs/65675/McGill-University/Liberal-Program-in-Physics",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Geophysics",
    "programLink": "https://www.studyincanada.com/Programs/11487/University-of-Saskatchewan/Geophysics",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/39824/McGill-University/Physics",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/88615/The-University-of-British-Columbia-Okanagan/Physics",
    "entranceGrade": "70",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Engineering Physics - Mechatronics",
    "programLink": "https://www.ufv.ca/engineering/engineering-physics-diploma-in-mechatronics/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Law/ Geology or Geophysics - MSc",
    "programLink": "https://www.studyincanada.com/Programs/107588/Western-University/Law/-Geology-or-Geophysics-MSc",
    "entranceGrade": "N/S",
    "tutionValue": "$48,910",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/2677/Lakehead-University-Thunder-Bay/Physics",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Physics - Photonics",
    "programLink": "https://www.studyincanada.com/Programs/49719/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Physics-Photonics",
    "entranceGrade": "80 - 82",
    "tutionValue": "$48,353",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/2680/Lakehead-University-Thunder-Bay/Physics",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Geophysics",
    "programLink": "https://www.studyincanada.com/Programs/113863/University-of-Calgary-Faculty-of-Science/Geophysics",
    "entranceGrade": "N/S",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Physics (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/96715/University-of-Saskatchewan/Physics-(B.Sc.)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Allison University",
    "location": "Canada, New Brunswick",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/27081/Mount-Allison-University/Physics",
    "entranceGrade": "65",
    "tutionValue": "$20,885",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/7527/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Physics",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Chemical Engineering",
    "programLink": "https://www.studyincanada.com/Programs/23090/University-of-New-Brunswick-Fredericton/Chemical-Engineering",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Medical Biophysics (Physical Science Concentration)",
    "programLink": "https://www.studyincanada.com/Programs/107650/Western-University/Medical-Biophysics-(Physical-Science-Concentration)",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "General Science: Bachelor of Science Degree - Major",
    "programLink": "https://www.studyincanada.com/Programs/95453/Thompson-Rivers-University/General-Science-Bachelor-of-Science-Degree-Major",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "N/S"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Physics",
    "programLink": "https://www.studyincanada.com/Programs/3766/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Physics",
    "entranceGrade": "80 - 82",
    "tutionValue": "$48,353",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Computer Science/Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/68451/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Computer-Science/Physics-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Environmental Studies (Faculty of Agricultural, Life and Environ...",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-in-environmental-studies-environmental-studies-ales.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Environmental Science (BSc)",
    "programLink": "https://futurestudents.yorku.ca/program/environmental-science",
    "entranceGrade": "75 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Environmental Science (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/environmental-science/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Environmental Sciences",
    "programLink": "https://uwaterloo.ca/future-students/programs/environmental-sciences?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$50,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Environmental Science (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/environmental-science/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Environmental Science (BSc)",
    "programLink": "https://admissions.carleton.ca/programs/environmental-science/",
    "entranceGrade": "74 - 76",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Environmental Science",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/ScienceTechnology/Departments/EarthEnvironmentalSciences/Majors/EnvironmentalScience/index.htm",
    "entranceGrade": "80 - 85",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Environmental Science (BSc)",
    "programLink": "https://wlu.ca/programs/science/undergraduate/environmental-science-bsc/index.html",
    "entranceGrade": "75 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Environmental Science (Interdisciplinary) (BSc) (Augustana Facul...",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-environmental-science.html",
    "entranceGrade": "78 - 78",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Integrated Science (Environmental Sciences Concentration)",
    "programLink": "https://www.studyincanada.com/Programs/91522/McMaster-University/Integrated-Science-(Environmental-Sciences-Concentration)",
    "entranceGrade": "87 - 89",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Environmental Science (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/101943/University-of-Toronto-Scarborough/Environmental-Science-(HBSc)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/17123/Douglas-College/Environmental-Science",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/55598/University-of-Northern-British-Columbia/Environmental-Science",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/60039/University-of-Manitoba/Environmental-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$19,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Allison University",
    "location": "Canada, New Brunswick",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/19812/Mount-Allison-University/Environmental-Science",
    "entranceGrade": "65",
    "tutionValue": "$20,885",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Earth & Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/4488/McMaster-University/Earth-&-Environmental-Sciences",
    "entranceGrade": "80 - 86",
    "tutionValue": "$43,116",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/109096/Douglas-College/Environmental-Science",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Lakeland College",
    "location": "Canada, Alberta",
    "programName": "Environmental Sciences General",
    "programLink": "https://www.studyincanada.com/Programs/109058/Lakeland-College/Environmental-Sciences-General",
    "entranceGrade": "N/S",
    "tutionValue": "$17,655",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Environmental Science - Resource Management Stream",
    "programLink": "https://www.studyincanada.com/Programs/107372/Brandon-University/Environmental-Science-Resource-Management-Stream",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Environmental Science (General)",
    "programLink": "https://www.studyincanada.com/Programs/90520/University-of-Manitoba/Environmental-Science-(General)",
    "entranceGrade": "N/S",
    "tutionValue": "$19,500",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Environmental Science - Physical Sciences Stream",
    "programLink": "https://www.studyincanada.com/Programs/107371/Brandon-University/Environmental-Science-Physical-Sciences-Stream",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Earth and Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/29907/The-University-of-British-Columbia-Okanagan/Earth-and-Environmental-Sciences",
    "entranceGrade": "67",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Environmental Science (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/44621/University-of-Toronto-Scarborough/Environmental-Science-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta - Augustana Campus",
    "location": "Canada, Alberta",
    "programName": "Environmental Science (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/12017/University-of-Alberta-Augustana-Campus/Environmental-Science-(B.Sc.)",
    "entranceGrade": "70 - 70",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/113866/University-of-Calgary-Faculty-of-Science/Environmental-Science",
    "entranceGrade": "77",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Northern British Columbia",
    "location": "Canada, British Columbia",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/10346/University-of-Northern-British-Columbia/Environmental-Science",
    "entranceGrade": "65",
    "tutionValue": "$26,750",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Environmental Science - Biodiversity Stream",
    "programLink": "https://www.studyincanada.com/Programs/51135/Brandon-University/Environmental-Science-Biodiversity-Stream",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Environmental Science - Conservation and Biodiversity",
    "programLink": "https://www.studyincanada.com/Programs/49672/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Environmental-Science-Conservation-and-Biodiversity",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Earth and Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/29891/The-University-of-British-Columbia-Okanagan/Earth-and-Environmental-Sciences",
    "entranceGrade": "75",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Environmental Science - Global Change",
    "programLink": "https://www.studyincanada.com/Programs/105130/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Environmental-Science-Global-Change",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/60022/University-of-Manitoba/Environmental-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$19,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/1262/Acadia-University/Environmental-Science",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Capilano University",
    "location": "Canada, British Columbia",
    "programName": "Bachelor of Environment and Society (Environmental Sciences)",
    "programLink": "https://www.studyincanada.com/Programs/116329/Capilano-University/Bachelor-of-Environment-and-Society-(Environmental-Sciences)",
    "entranceGrade": "N/S",
    "tutionValue": "$20,012",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Grenfell Campus, Memorial University",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Environmental Science (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/33951/Grenfell-Campus,-Memorial-University/Environmental-Science-(BSc)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$11,460",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Environmental Science - Biodiversity Stream",
    "programLink": "https://www.studyincanada.com/Programs/107369/Brandon-University/Environmental-Science-Biodiversity-Stream",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Okanagan",
    "location": "Canada, British Columbia",
    "programName": "Earth and Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/88587/The-University-of-British-Columbia-Okanagan/Earth-and-Environmental-Sciences",
    "entranceGrade": "70",
    "tutionValue": "$42,396",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Redeemer University",
    "location": "Canada, Ontario",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/64518/Redeemer-University/Environmental-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$19,494",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University of Edmonton",
    "location": "Canada, Alberta",
    "programName": "Environmental Science (B.Sc. 3-year)",
    "programLink": "https://www.studyincanada.com/Programs/56870/Concordia-University-of-Edmonton/Environmental-Science-(B.Sc.-3-year)",
    "entranceGrade": "60",
    "tutionValue": "$25,414",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/81768/The-University-of-British-Columbia-Vancouver/Environmental-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/85117/The-University-of-Winnipeg/Environmental-Sciences",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/68969/Bishop-s-University/Environmental-Science",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Applied Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/62880/The-University-of-Winnipeg/Applied-Environmental-Science",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Environmental Sciences",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/environmental-sciences-envsc/",
    "entranceGrade": "80 - 83",
    "tutionValue": "$32,652",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Agro-Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/49398/McGill-University/Agro-Environmental-Sciences",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Environmental Science/Studies - Specialization in Climate Change...",
    "programLink": "https://www.studyincanada.com/Programs/105854/Trent-University/Environmental-Science/Studies-Specialization-in-Climate-Change...",
    "entranceGrade": "75 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/1263/Acadia-University/Environmental-Science",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algoma University Brampton Campus",
    "location": "Canada, Ontario",
    "programName": "Environmental Science (Anticipated Fall 2023)",
    "programLink": "https://www.studyincanada.com/Programs/105319/Algoma-University-Brampton-Campus/Environmental-Science-(Anticipated-Fall-2023)",
    "entranceGrade": "65",
    "tutionValue": "$20,438",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Integrated Science with Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/85062/Western-University/Integrated-Science-with-Environmental-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/33451/Saint-Mary-s-University/Environmental-Science",
    "entranceGrade": "70 - 100",
    "tutionValue": "$22,060",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Environmental Science - Global Change",
    "programLink": "https://www.studyincanada.com/Programs/105133/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Environmental-Science-Global-Change",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Nipissing University - Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/107477/Nipissing-University-Graduate-Studies/Environmental-Science",
    "entranceGrade": "75 - 75",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/60038/University-of-Manitoba/Environmental-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$19,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Environmental Science - Conservation and Biodiversity",
    "programLink": "https://www.studyincanada.com/Programs/3687/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Environmental-Science-Conservation-and-Biodiversity",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/1260/Acadia-University/Environmental-Science",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/1772/The-University-of-British-Columbia-Vancouver/Environmental-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/32510/University-of-Windsor-Faculty-of-Graduate-Studies/Environmental-Science",
    "entranceGrade": "77 - 100",
    "tutionValue": "$8,863",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/8001/The-University-of-Winnipeg/Environmental-Sciences",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Environmental Science - Environmental Geochemistry and Ecotoxico...",
    "programLink": "https://www.studyincanada.com/Programs/105128/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Environmental-Science-Environmental-Geochemistry-and-Ecotoxico...",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/33450/Saint-Mary-s-University/Environmental-Science",
    "entranceGrade": "70 - 100",
    "tutionValue": "$22,060",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Agro-Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/78159/McGill-University/Agro-Environmental-Sciences",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/9813/Saint-Mary-s-University/Environmental-Science",
    "entranceGrade": "70 - 100",
    "tutionValue": "$22,060",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/21731/University-of-Windsor-Faculty-of-Graduate-Studies/Environmental-Science",
    "entranceGrade": "77 - 100",
    "tutionValue": "$8,863",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/4659/University-of-Windsor/Environmental-Science",
    "entranceGrade": "70 - 100",
    "tutionValue": "$37,497",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Royal Roads University",
    "location": "Canada, British Columbia",
    "programName": "Environmental Science (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/11828/Royal-Roads-University/Environmental-Science-(BSc)",
    "entranceGrade": "73 - 100",
    "tutionValue": "$43,260",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "College of the Rockies",
    "location": "Canada, British Columbia",
    "programName": "Associate of Science (ASC) - Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/15898/College-of-the-Rockies/Associate-of-Science-(ASC)-Environmental-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/90519/University-of-Manitoba/Environmental-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$19,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Environmental Science/Studies (B.E.S.S.)",
    "programLink": "https://www.studyincanada.com/Programs/129/Trent-University/Environmental-Science/Studies-(B.E.S.S.)",
    "entranceGrade": "75 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Earth & Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/81644/McMaster-University/Earth-&-Environmental-Sciences",
    "entranceGrade": "80 - 86",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/25908/Acadia-University/Environmental-Science",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/96618/Dalhousie-University/Environmental-Sciences",
    "entranceGrade": "75",
    "tutionValue": "$28,473",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/68970/Bishop-s-University/Environmental-Science",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University - Graduate Programs",
    "location": "Canada, British Columbia",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/45485/Thompson-Rivers-University-Graduate-Programs/Environmental-Science",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Environmental Sciences",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/environmental-sciences-envs/",
    "entranceGrade": "75 - 80",
    "tutionValue": "$32,652",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algoma University",
    "location": "Canada, Ontario",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/88510/Algoma-University/Environmental-Science",
    "entranceGrade": "65 - 65",
    "tutionValue": "$20,438",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Science | Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/109275/University-of-Lethbridge/Bachelor-of-Science-|-Environmental-Science",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Environmental Science - Resource Management Stream",
    "programLink": "https://www.studyincanada.com/Programs/51137/Brandon-University/Environmental-Science-Resource-Management-Stream",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Environmental Science - Land and Water Management Stream",
    "programLink": "https://www.studyincanada.com/Programs/51121/Brandon-University/Environmental-Science-Land-and-Water-Management-Stream",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/81769/The-University-of-British-Columbia-Vancouver/Environmental-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Allison University",
    "location": "Canada, New Brunswick",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/81828/Mount-Allison-University/Environmental-Science",
    "entranceGrade": "65",
    "tutionValue": "$20,885",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Environmental Science - Environmental Geochemistry and Ecotoxico...",
    "programLink": "https://www.studyincanada.com/Programs/105132/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Environmental-Science-Environmental-Geochemistry-and-Ecotoxico...",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/99407/University-of-Calgary/Environmental-Science",
    "entranceGrade": "80 - 85",
    "tutionValue": "$20,172",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Indigenous Environmental Science and Practice",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/degree-programs/bachelor-indigenous-environmental-science-and-practice-biesp/#text",
    "entranceGrade": "75 - 80",
    "tutionValue": "$32,652",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Environmental Science (B.S.A.)",
    "programLink": "https://www.studyincanada.com/Programs/58931/University-of-Saskatchewan/Environmental-Science-(B.S.A.)",
    "entranceGrade": "70",
    "tutionValue": "$18,391",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University of Edmonton",
    "location": "Canada, Alberta",
    "programName": "Environmental Science (B.Sc. 4-year)",
    "programLink": "https://www.studyincanada.com/Programs/56877/Concordia-University-of-Edmonton/Environmental-Science-(B.Sc.-4-year)",
    "entranceGrade": "60",
    "tutionValue": "$25,414",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Environmental Science",
    "programLink": "https://www.studyincanada.com/Programs/31867/Western-University/Environmental-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "N/S"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Integrated Science (Earth and Environmental Sciences Concentrati...",
    "programLink": "https://www.studyincanada.com/Programs/91603/McMaster-University/Integrated-Science-(Earth-and-Environmental-Sciences-Concentrati...",
    "entranceGrade": "87 - 89",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Environmental Sciences",
    "programLink": "https://www.studyincanada.com/Programs/77611/The-University-of-British-Columbia-Vancouver/Environmental-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Environmental Science - Physical Sciences Stream",
    "programLink": "https://www.studyincanada.com/Programs/51114/Brandon-University/Environmental-Science-Physical-Sciences-Stream",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Geology",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/ScienceTechnology/Departments/EarthEnvironmentalSciences/Majors/Geology/index.htm",
    "entranceGrade": "75 - 85",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Science Foundations (Cert)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/science-foundations.html",
    "entranceGrade": "65",
    "tutionValue": "$32,450",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Geology (BSc with Specialization)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-geology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Geology",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-major-geology.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Geological Engineering",
    "programLink": "https://www.studyincanada.com/Programs/15556/University-of-New-Brunswick-Saint-John/Geological-Engineering",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/22938/University-of-New-Brunswick-Saint-John/Geology",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/22920/University-of-New-Brunswick-Saint-John/Geology",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/7217/McGill-University/Geology",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Geographical Sciences",
    "programLink": "https://www.studyincanada.com/Programs/77480/The-University-of-British-Columbia-Vancouver/Geographical-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Geological Sciences",
    "programLink": "https://www.studyincanada.com/Programs/23153/Queen-s-University/Geological-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/60028/University-of-Manitoba/Geology",
    "entranceGrade": "N/S",
    "tutionValue": "$19,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Geology (B.A. Three-year)",
    "programLink": "https://www.studyincanada.com/Programs/106170/University-of-Saskatchewan/Geology-(B.A.-Three-year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/2586/Lakehead-University-Thunder-Bay/Geology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Geological Resources",
    "programLink": "https://www.studyincanada.com/Programs/91914/Douglas-College/Geological-Resources",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/77399/The-University-of-British-Columbia-Vancouver/Geology",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Chemistry/Earth Sciences (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/68444/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Chemistry/Earth-Sciences-(BSc)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$20,790",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Geology (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8389/University-of-Toronto-St.-George/Geology-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Law/ Geology or Geophysics - MSc",
    "programLink": "https://www.studyincanada.com/Programs/107588/Western-University/Law/-Geology-or-Geophysics-MSc",
    "entranceGrade": "N/S",
    "tutionValue": "$48,910",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Earth, Space, and Physical Science",
    "programLink": "https://www.studyincanada.com/Programs/33529/University-of-Lethbridge-School-of-Graduate-Studies/Earth,-Space,-and-Physical-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "48 Month(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/56814/University-of-Saskatchewan/Geology",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Geophysics (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8392/University-of-Toronto-St.-George/Geophysics-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Earth Science",
    "programLink": "https://www.studyincanada.com/Programs/99393/University-of-Calgary/Earth-Science",
    "entranceGrade": "80",
    "tutionValue": "$25,530",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/77617/The-University-of-British-Columbia-Vancouver/Geology",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/33452/Saint-Mary-s-University/Geology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$22,060",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/49695/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Geology",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/9816/Saint-Mary-s-University/Geology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$22,060",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/1282/Acadia-University/Geology",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/81396/Selkirk-College/Geology",
    "entranceGrade": "N/S",
    "tutionValue": "$13,409",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/7006/University-of-Regina/Geology",
    "entranceGrade": "65 - 70",
    "tutionValue": "$12,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/107377/Brandon-University/Geology",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Geology and Biology",
    "programLink": "https://www.studyincanada.com/Programs/31876/Western-University/Geology-and-Biology",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "N/S"
  },
  {
    "schoolName": "Algoma University",
    "location": "Canada, Ontario",
    "programName": "Geography, Geology and Land Stewardship",
    "programLink": "https://www.studyincanada.com/Programs/12402/Algoma-University/Geography,-Geology-and-Land-Stewardship",
    "entranceGrade": "65 - 65",
    "tutionValue": "$20,438",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Earth Sciences",
    "programLink": "https://www.studyincanada.com/Programs/15426/University-of-New-Brunswick-Fredericton/Earth-Sciences",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/113861/University-of-Calgary-Faculty-of-Science/Geology",
    "entranceGrade": "N/S",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/3696/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Geology",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/49687/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Geology",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Geological Studies",
    "programLink": "https://www.studyincanada.com/Programs/106050/St.-Lawrence-College/Geological-Studies",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/77402/The-University-of-British-Columbia-Vancouver/Geology",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Geology - For Professional Registration",
    "programLink": "https://www.studyincanada.com/Programs/31877/Western-University/Geology-For-Professional-Registration",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/60027/University-of-Manitoba/Geology",
    "entranceGrade": "N/S",
    "tutionValue": "$19,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Geological Engineering",
    "programLink": "https://www.studyincanada.com/Programs/5900/Queen-s-University/Geological-Engineering",
    "entranceGrade": "80 - 100",
    "tutionValue": "$61,487",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Earth Sciences",
    "programLink": "https://www.studyincanada.com/Programs/22983/University-of-New-Brunswick-Fredericton/Earth-Sciences",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Geology (3 Year)",
    "programLink": "https://www.studyincanada.com/Programs/107384/Brandon-University/Geology-(3-Year)",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Geographical Sciences",
    "programLink": "https://www.studyincanada.com/Programs/67938/The-University-of-British-Columbia-Vancouver/Geographical-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/109085/Douglas-College/Geology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Earth Sciences",
    "programLink": "https://www.studyincanada.com/Programs/9891/St.-Francis-Xavier-University/Earth-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/1278/Acadia-University/Geology",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Saint Mary's University",
    "location": "Canada, Nova Scotia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/33453/Saint-Mary-s-University/Geology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$22,060",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/51110/Brandon-University/Geology",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/27404/Brandon-University/Geology",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Fredericton",
    "location": "Canada, New Brunswick",
    "programName": "Earth Sciences",
    "programLink": "https://www.studyincanada.com/Programs/23096/University-of-New-Brunswick-Fredericton/Earth-Sciences",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/25131/Acadia-University/Geology",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Geology - Physics",
    "programLink": "https://www.studyincanada.com/Programs/49683/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Geology-Physics",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Geology - Physics",
    "programLink": "https://www.studyincanada.com/Programs/49703/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Geology-Physics",
    "entranceGrade": "80 - 82",
    "tutionValue": "$38,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Environmental Geoscience",
    "programLink": "https://www.studyincanada.com/Programs/84634/University-of-Regina/Environmental-Geoscience",
    "entranceGrade": "65",
    "tutionValue": "$12,049",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Acadia University",
    "location": "Canada, Nova Scotia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/25909/Acadia-University/Geology",
    "entranceGrade": "70",
    "tutionValue": "$21,620",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Brandon University",
    "location": "Canada, Manitoba",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/107385/Brandon-University/Geology",
    "entranceGrade": "60 - 100",
    "tutionValue": "$18,613",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Geoarchaeology",
    "programLink": "https://www.studyincanada.com/Programs/2569/Lakehead-University-Thunder-Bay/Geoarchaeology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Geological Sciences",
    "programLink": "https://www.studyincanada.com/Programs/37415/University-of-Manitoba/Geological-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$18,800",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Earth Science",
    "programLink": "https://www.studyincanada.com/Programs/106154/Vancouver-Island-University/Earth-Science",
    "entranceGrade": "60",
    "tutionValue": "$24,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/78167/McGill-University/Geology",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Geoarchaeology",
    "programLink": "https://www.studyincanada.com/Programs/2568/Lakehead-University-Thunder-Bay/Geoarchaeology",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Geological Engineering",
    "programLink": "https://www.studyincanada.com/Programs/56861/University-of-Saskatchewan/Geological-Engineering",
    "entranceGrade": "75",
    "tutionValue": "$22,023",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Saskatchewan",
    "location": "Canada, Saskatchewan",
    "programName": "Geology (B.A. Four-year)",
    "programLink": "https://www.studyincanada.com/Programs/56809/University-of-Saskatchewan/Geology-(B.A.-Four-year)",
    "entranceGrade": "70",
    "tutionValue": "$18,040",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Geological Sciences",
    "programLink": "https://www.studyincanada.com/Programs/81414/Queen-s-University/Geological-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$55,567",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Faculty of Science",
    "location": "Canada, Alberta",
    "programName": "Geology - Petroleum Geology Concentration",
    "programLink": "https://www.studyincanada.com/Programs/113862/University-of-Calgary-Faculty-of-Science/Geology-Petroleum-Geology-Concentration",
    "entranceGrade": "N/S",
    "tutionValue": "$28,257",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/77596/The-University-of-British-Columbia-Vancouver/Geology",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Geology (M.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/2594/Lakehead-University-Faculty-of-Graduate-Studies/Geology-(M.Sc.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/31878/Western-University/Geology",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Geology",
    "programLink": "https://www.studyincanada.com/Programs/91911/Douglas-College/Geology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "St. Francis Xavier University",
    "location": "Canada, Nova Scotia",
    "programName": "Earth Sciences",
    "programLink": "https://www.studyincanada.com/Programs/9890/St.-Francis-Xavier-University/Earth-Sciences",
    "entranceGrade": "N/S",
    "tutionValue": "$9,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Physics and Astronomy",
    "programLink": "https://uwaterloo.ca/future-students/programs/physics-and-astronomy?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$50,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Physics & Astronomy (BSc)",
    "programLink": "https://futurestudents.yorku.ca/program/physics-astronomy",
    "entranceGrade": "75 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Physics and Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/13538/University-of-Manitoba-Faculty-of-Graduate-Studies/Physics-and-Astronomy",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Planetary Science (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8497/University-of-Toronto-St.-George/Planetary-Science-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Astronomy & Astrophysics (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/81697/University-of-Toronto-Scarborough/Astronomy-&-Astrophysics-(HBSc)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Astronomy and Physics (HBSc)",
    "programLink": "https://www.studyincanada.com/Programs/8496/University-of-Toronto-St.-George/Astronomy-and-Physics-(HBSc)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$57,020",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/109470/Queen-s-University/Physics-(BSc)",
    "entranceGrade": "80",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/12232/University-of-Victoria/Astronomy",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Physics (BSc)",
    "programLink": "https://www.studyincanada.com/Programs/5792/Queen-s-University/Physics-(BSc)",
    "entranceGrade": "80",
    "tutionValue": "$55,567",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Liberal Program in Earth and Planetary Sciences",
    "programLink": "https://www.studyincanada.com/Programs/78200/McGill-University/Liberal-Program-in-Earth-and-Planetary-Sciences",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Physics and Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/13537/University-of-Manitoba-Faculty-of-Graduate-Studies/Physics-and-Astronomy",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Physics and Astronomy (General Major)",
    "programLink": "https://www.studyincanada.com/Programs/37412/University-of-Manitoba/Physics-and-Astronomy-(General-Major)",
    "entranceGrade": "85 - 85",
    "tutionValue": "$19,800",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Computer Science / Physics & Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/60034/University-of-Manitoba/Computer-Science-/-Physics-&-Astronomy",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/30612/University-of-Victoria/Astronomy",
    "entranceGrade": "80 - 85",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Physics (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/156/Trent-University/Physics-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/81829/The-University-of-British-Columbia-Vancouver/Astronomy",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Physics and Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/37353/University-of-Manitoba/Physics-and-Astronomy",
    "entranceGrade": "85 - 85",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/1757/The-University-of-British-Columbia-Vancouver/Astronomy",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Physics and Mathematics",
    "programLink": "https://www.studyincanada.com/Programs/90542/University-of-Manitoba/Physics-and-Mathematics",
    "entranceGrade": "85 - 85",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Physics (B.Sc.)",
    "programLink": "https://www.studyincanada.com/Programs/157/Trent-University/Physics-(B.Sc.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Physics and Biochemistry",
    "programLink": "https://www.studyincanada.com/Programs/90543/University-of-Manitoba/Physics-and-Biochemistry",
    "entranceGrade": "85 - 85",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/12235/University-of-Victoria/Astronomy",
    "entranceGrade": "80 - 100",
    "tutionValue": "$27,949",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/81830/The-University-of-British-Columbia-Vancouver/Astronomy",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Computer Science / Physics & Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/60033/University-of-Manitoba/Computer-Science-/-Physics-&-Astronomy",
    "entranceGrade": "N/S",
    "tutionValue": "$19,800",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Victoria",
    "location": "Canada, British Columbia",
    "programName": "Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/27195/University-of-Victoria/Astronomy",
    "entranceGrade": "80 - 85",
    "tutionValue": "$27,949",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/77386/The-University-of-British-Columbia-Vancouver/Astronomy",
    "entranceGrade": "N/S",
    "tutionValue": "$44,091",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Physics and Chemistry",
    "programLink": "https://www.studyincanada.com/Programs/90541/University-of-Manitoba/Physics-and-Chemistry",
    "entranceGrade": "85 - 85",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Astrophysics",
    "programLink": "https://www.studyincanada.com/Programs/23159/Queen-s-University/Astrophysics",
    "entranceGrade": "80 - 100",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Physics and Astronomy",
    "programLink": "https://www.studyincanada.com/Programs/90553/University-of-Manitoba/Physics-and-Astronomy",
    "entranceGrade": "85 - 85",
    "tutionValue": "$19,800",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge - School of Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Earth, Space, and Physical Science",
    "programLink": "https://www.studyincanada.com/Programs/33529/University-of-Lethbridge-School-of-Graduate-Studies/Earth,-Space,-and-Physical-Science",
    "entranceGrade": "N/S",
    "tutionValue": "$15,851",
    "length": "48 Month(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Planetary Sciences",
    "programLink": "https://www.studyincanada.com/Programs/39818/McGill-University/Planetary-Sciences",
    "entranceGrade": "92",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.algonquincollege.com/mediaanddesign/program/graphic-design/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,824",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/graphic-design/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,403",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Fleming College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design - Visual Communication",
    "programLink": "https://flemingcollege.ca/programs/graphic-design-visual-communication",
    "entranceGrade": "N/S",
    "tutionValue": "$28,449",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "OCAD University",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://admissions.ocadu.ca/program/graphic-design",
    "entranceGrade": "70 - 70",
    "tutionValue": "$29,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "LaSalle College Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Graphic Design & Foundation for Design",
    "programLink": "https://www.studyincanada.com/Programs/52601/LaSalle-College-Vancouver/Graphic-Design-&-Foundation-for-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "18 Month(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/8854/St.-Lawrence-College/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$18,929",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "CDI College (Mississauga Campus)",
    "location": "Canada, Ontario",
    "programName": "Graphic Design Technology",
    "programLink": "https://www.studyincanada.com/Programs/99363/CDI-College-(Mississauga-Campus)/Graphic-Design-Technology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/35999/Humber-College-Institute-of-Technology-&-Advanced-Learning/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$19,615",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/3633/Fanshawe-College/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$16,753",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Vancouver Community College",
    "location": "Canada, British Columbia",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/97175/Vancouver-Community-College/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/51367/Vancouver-Island-University/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$24,146",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/102051/University-of-Manitoba/Fine-Arts-Graphic-Design",
    "entranceGrade": "70 - 70",
    "tutionValue": "$22,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Red River College Polytechnic",
    "location": "Canada, Manitoba",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/25499/Red-River-College-Polytechnic/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$17,312",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Cambrian College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/27224/Cambrian-College/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$17,776",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Herzing College - Toronto",
    "location": "Canada, Ontario",
    "programName": "Computer Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/16923/Herzing-College-Toronto/Computer-Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "900 Hour(s)"
  },
  {
    "schoolName": "Academy of Learning Career College - Toronto (Downtown)",
    "location": "Canada, Ontario",
    "programName": "Graphic Designer",
    "programLink": "https://www.studyincanada.com/Programs/100937/Academy-of-Learning-Career-College-Toronto-(Downtown)/Graphic-Designer",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "50 Week(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/70780/Mohawk-College/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$18,400",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Nova Scotia Community College",
    "location": "Canada, Nova Scotia",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/15205/Nova-Scotia-Community-College/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$11,690",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "George Brown College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/1279/George-Brown-College/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$15,190",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Herzing College - Montreal",
    "location": "Canada, Québec",
    "programName": "Graphic Design for the Web (NWE.1T)",
    "programLink": "https://www.studyincanada.com/Programs/6352/Herzing-College-Montreal/Graphic-Design-for-the-Web-(NWE.1T)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1000 Hour(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design Production",
    "programLink": "https://www.studyincanada.com/Programs/3455/Georgian-College/Graphic-Design-Production",
    "entranceGrade": "N/S",
    "tutionValue": "$14,003",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/5445/Seneca-Polytechnic/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$17,739",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "ITD Canada",
    "location": "Canada, British Columbia",
    "programName": "Graphic Design Diploma with Co-op",
    "programLink": "https://www.studyincanada.com/Programs/75998/ITD-Canada/Graphic-Design-Diploma-with-Co-op",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/7920/Canadore-College/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$17,590",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "LaSalle College Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Graphic Design (BDes)",
    "programLink": "https://www.studyincanada.com/Programs/69727/LaSalle-College-Vancouver/Graphic-Design-(BDes)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "36 Month(s)"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/5375/St.-Clair-College/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$15,685",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/3453/Georgian-College/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$14,003",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Graphic Design for Marketing (BDes)",
    "programLink": "https://www.studyincanada.com/Programs/64415/Kwantlen-Polytechnic-University/Graphic-Design-for-Marketing-(BDes)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "LaSalle College Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Graphic Design - Branding",
    "programLink": "https://www.studyincanada.com/Programs/101165/LaSalle-College-Vancouver/Graphic-Design-Branding",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "12 Month(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/102045/University-of-Manitoba/Fine-Arts-Graphic-Design",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/5017/Durham-College/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$16,746",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "College of New Caledonia",
    "location": "Canada, British Columbia",
    "programName": "Web and Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/81467/College-of-New-Caledonia/Web-and-Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "LaSalle College Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/16883/LaSalle-College-Vancouver/Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "14 Month(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Advertising and Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/1297/Humber-College-Institute-of-Technology-&-Advanced-Learning/Advertising-and-Graphic-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$16,394",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centre for Arts and Technology (Kelowna Campus)",
    "location": "Canada, British Columbia",
    "programName": "Graphic Design & Digital Art",
    "programLink": "https://www.studyincanada.com/Programs/32875/Centre-for-Arts-and-Technology-(Kelowna-Campus)/Graphic-Design-&-Digital-Art",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/102046/University-of-Manitoba/Fine-Arts-Graphic-Design",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Fine Arts - Studio Practice",
    "programLink": "https://uwaterloo.ca/future-students/programs/fine-arts?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$55,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Fine Arts Studio",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/fine-arts-studio/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,453",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Fine Arts - Visual Culture",
    "programLink": "https://uwaterloo.ca/future-students/programs/fine-arts?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "80 - 83",
    "tutionValue": "$55,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Fine Arts - Teaching Preparation Specialization",
    "programLink": "https://uwaterloo.ca/academic-calendar/undergraduate-studies/catalog#/programs/r1NVek0Ri3/none?q=teaching&&limit=20&skip=0&bc=true&bcCurrent=Fine%20Arts%20-%20Teaching%20Preparation%20Specialization&bcItemType=programs",
    "entranceGrade": "80 - 83",
    "tutionValue": "$55,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Alberta University of the Arts",
    "location": "Canada, Alberta",
    "programName": "Fine Arts (BFA)",
    "programLink": "https://www.auarts.ca/learn-with-us/undergraduate/bfa",
    "entranceGrade": "N/S",
    "tutionValue": "$19,658",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Sculpture",
    "programLink": "https://www.studyincanada.com/Programs/102066/University-of-Manitoba/Fine-Arts-Sculpture",
    "entranceGrade": "70 - 70",
    "tutionValue": "$22,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Painting",
    "programLink": "https://www.studyincanada.com/Programs/102052/University-of-Manitoba/Fine-Arts-Painting",
    "entranceGrade": "70 - 70",
    "tutionValue": "$22,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/102046/University-of-Manitoba/Fine-Arts-Graphic-Design",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Painting",
    "programLink": "https://www.studyincanada.com/Programs/102059/University-of-Manitoba/Fine-Arts-Painting",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Photography",
    "programLink": "https://www.studyincanada.com/Programs/102048/University-of-Manitoba/Fine-Arts-Photography",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Emily Carr University - Graduate Programs",
    "location": "Canada, British Columbia",
    "programName": "Fine Arts (Low residency)",
    "programLink": "https://www.studyincanada.com/Programs/115593/Emily-Carr-University-Graduate-Programs/Fine-Arts-(Low-residency)",
    "entranceGrade": "70",
    "tutionValue": "$23,846",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Fine Arts and Secondary Education",
    "programLink": "https://www.studyincanada.com/Programs/55688/Bishop-s-University/Fine-Arts-and-Secondary-Education",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ottawa School of Art",
    "location": "Canada, Ontario",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/40551/Ottawa-School-of-Art/Fine-Arts",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/90557/University-of-Manitoba/Fine-Arts",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/47013/St.-Lawrence-College/Fine-Arts",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Fine Arts (BFA)",
    "programLink": "https://www.ufv.ca/arts/programs/bachelor-of-fine-arts/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Drawing",
    "programLink": "https://www.studyincanada.com/Programs/102058/University-of-Manitoba/Fine-Arts-Drawing",
    "entranceGrade": "70 - 70",
    "tutionValue": "$22,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Art History and Theory - Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/72932/Bishop-s-University/Art-History-and-Theory-Fine-Arts",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Drawing",
    "programLink": "https://www.studyincanada.com/Programs/102043/University-of-Manitoba/Fine-Arts-Drawing",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Print Media",
    "programLink": "https://www.studyincanada.com/Programs/102065/University-of-Manitoba/Fine-Arts-Print-Media",
    "entranceGrade": "70 - 70",
    "tutionValue": "$22,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/3445/Georgian-College/Fine-Arts",
    "entranceGrade": "N/S",
    "tutionValue": "$14,089",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Painting",
    "programLink": "https://www.studyincanada.com/Programs/102047/University-of-Manitoba/Fine-Arts-Painting",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Fine Arts | Art Studio",
    "programLink": "https://www.studyincanada.com/Programs/58593/University-of-Lethbridge/Bachelor-of-Fine-Arts-|-Art-Studio",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Emily Carr University - Graduate Programs",
    "location": "Canada, British Columbia",
    "programName": "Fine Arts (Full residency)",
    "programLink": "https://www.studyincanada.com/Programs/51158/Emily-Carr-University-Graduate-Programs/Fine-Arts-(Full-residency)",
    "entranceGrade": "70",
    "tutionValue": "$23,846",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Print Media",
    "programLink": "https://www.studyincanada.com/Programs/102050/University-of-Manitoba/Fine-Arts-Print-Media",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "North Island College (Comox Valley Campus)",
    "location": "Canada, British Columbia",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/69005/North-Island-College-(Comox-Valley-Campus)/Fine-Arts",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Video",
    "programLink": "https://www.studyincanada.com/Programs/102064/University-of-Manitoba/Fine-Arts-Video",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/102051/University-of-Manitoba/Fine-Arts-Graphic-Design",
    "entranceGrade": "70 - 70",
    "tutionValue": "$22,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Performing and Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/91900/Douglas-College/Performing-and-Fine-Arts",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "St. Lawrence College",
    "location": "Canada, Ontario",
    "programName": "Fine Arts - Visual & Creative Arts",
    "programLink": "https://www.studyincanada.com/Programs/59270/St.-Lawrence-College/Fine-Arts-Visual-&-Creative-Arts",
    "entranceGrade": "N/S",
    "tutionValue": "$17,092",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/11275/Kwantlen-Polytechnic-University/Fine-Arts",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Fine Arts | Art",
    "programLink": "https://www.studyincanada.com/Programs/2925/University-of-Lethbridge/Bachelor-of-Fine-Arts-|-Art",
    "entranceGrade": "N/S",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Fine Arts | New Media",
    "programLink": "https://www.studyincanada.com/Programs/33524/University-of-Lethbridge/Bachelor-of-Fine-Arts-|-New-Media",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Photography",
    "programLink": "https://www.studyincanada.com/Programs/102053/University-of-Manitoba/Fine-Arts-Photography",
    "entranceGrade": "70 - 70",
    "tutionValue": "$22,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Tyndale University",
    "location": "Canada, Ontario",
    "programName": "Media Arts - Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/115976/Tyndale-University/Media-Arts-Fine-Arts",
    "entranceGrade": "65 - 100",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Fine Arts | Indigenous Art (Art History/Museum Studi...",
    "programLink": "https://www.studyincanada.com/Programs/82272/University-of-Lethbridge/Bachelor-of-Fine-Arts-|-Indigenous-Art-(Art-History/Museum-Studi...",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Visual Arts: Bachelor of Fine Arts Degree",
    "programLink": "https://www.studyincanada.com/Programs/63010/Thompson-Rivers-University/Visual-Arts-Bachelor-of-Fine-Arts-Degree",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Photography",
    "programLink": "https://www.studyincanada.com/Programs/102060/University-of-Manitoba/Fine-Arts-Photography",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Tyndale University",
    "location": "Canada, Ontario",
    "programName": "Media Arts - Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/115975/Tyndale-University/Media-Arts-Fine-Arts",
    "entranceGrade": "65 - 100",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Fine Arts | Multidisciplinary",
    "programLink": "https://www.studyincanada.com/Programs/109261/University-of-Lethbridge/Bachelor-of-Fine-Arts-|-Multidisciplinary",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/4284/Bishop-s-University/Fine-Arts",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/4282/Bishop-s-University/Fine-Arts",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Ceramics",
    "programLink": "https://www.studyincanada.com/Programs/102057/University-of-Manitoba/Fine-Arts-Ceramics",
    "entranceGrade": "70 - 70",
    "tutionValue": "$22,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Video",
    "programLink": "https://www.studyincanada.com/Programs/102063/University-of-Manitoba/Fine-Arts-Video",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Fine Arts (BFA)",
    "programLink": "https://www.studyincanada.com/Programs/64408/Kwantlen-Polytechnic-University/Fine-Arts-(BFA)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Department of Art and Art History",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Fine Arts (BFA) in Visual Studies",
    "programLink": "https://www.studyincanada.com/Programs/108191/University-of-Calgary-Department-of-Art-and-Art-History/Bachelor-of-Fine-Arts-(BFA)-in-Visual-Studies",
    "entranceGrade": "75",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Certificate in Indian Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/90962/University-of-Regina/Certificate-in-Indian-Fine-Arts",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Department of Art and Art History",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Fine Arts in Visual Studies (BFA)",
    "programLink": "https://www.studyincanada.com/Programs/108281/University-of-Calgary-Department-of-Art-and-Art-History/Bachelor-of-Fine-Arts-in-Visual-Studies-(BFA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Fine Arts - Advanced",
    "programLink": "https://www.studyincanada.com/Programs/56380/Georgian-College/Fine-Arts-Advanced",
    "entranceGrade": "N/S",
    "tutionValue": "$14,089",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "St. Thomas University",
    "location": "Canada, New Brunswick",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/84945/St.-Thomas-University/Fine-Arts",
    "entranceGrade": "70",
    "tutionValue": "$17,208",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Ceramics",
    "programLink": "https://www.studyincanada.com/Programs/102068/University-of-Manitoba/Fine-Arts-Ceramics",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Fine Arts | Drama Technical/Design",
    "programLink": "https://www.studyincanada.com/Programs/109232/University-of-Lethbridge/Bachelor-of-Fine-Arts-|-Drama-Technical/Design",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Graphic Design",
    "programLink": "https://www.studyincanada.com/Programs/102045/University-of-Manitoba/Fine-Arts-Graphic-Design",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Calgary - School of Creative and Performing Arts - Graduate Programs",
    "location": "Canada, Alberta",
    "programName": "Master of Fine Arts in Drama - MFA",
    "programLink": "https://www.studyincanada.com/Programs/108874/University-of-Calgary-School-of-Creative-and-Performing-Arts-Graduate-Programs/Master-of-Fine-Arts-in-Drama-MFA",
    "entranceGrade": "N/S",
    "tutionValue": "$5,990",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Fine Arts - Studio Art",
    "programLink": "https://www.studyincanada.com/Programs/1042/Graduate-Studies-at-University-of-Waterloo/Fine-Arts-Studio-Art",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Fine Arts | Indigenous Art (Art Studio)",
    "programLink": "https://www.studyincanada.com/Programs/109297/University-of-Lethbridge/Bachelor-of-Fine-Arts-|-Indigenous-Art-(Art-Studio)",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Drawing",
    "programLink": "https://www.studyincanada.com/Programs/102044/University-of-Manitoba/Fine-Arts-Drawing",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Ceramics",
    "programLink": "https://www.studyincanada.com/Programs/102056/University-of-Manitoba/Fine-Arts-Ceramics",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Calgary - Department of Art and Art History - Graduate Studies",
    "location": "Canada, Alberta",
    "programName": "Master of Fine Arts (MFA)",
    "programLink": "https://www.studyincanada.com/Programs/108577/University-of-Calgary-Department-of-Art-and-Art-History-Graduate-Studies/Master-of-Fine-Arts-(MFA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Print Media",
    "programLink": "https://www.studyincanada.com/Programs/102049/University-of-Manitoba/Fine-Arts-Print-Media",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Allison University",
    "location": "Canada, New Brunswick",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/9627/Mount-Allison-University/Fine-Arts",
    "entranceGrade": "65",
    "tutionValue": "$20,885",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Sculpture",
    "programLink": "https://www.studyincanada.com/Programs/102061/University-of-Manitoba/Fine-Arts-Sculpture",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Sculpture",
    "programLink": "https://www.studyincanada.com/Programs/102062/University-of-Manitoba/Fine-Arts-Sculpture",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts - Video",
    "programLink": "https://www.studyincanada.com/Programs/102067/University-of-Manitoba/Fine-Arts-Video",
    "entranceGrade": "70 - 70",
    "tutionValue": "$22,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/59987/University-of-Manitoba/Fine-Arts",
    "entranceGrade": "70 - 70",
    "tutionValue": "$22,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Indigenous Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/6985/University-of-Regina/Indigenous-Fine-Arts",
    "entranceGrade": "65",
    "tutionValue": "$11,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Fine Arts | Drama Performance",
    "programLink": "https://www.studyincanada.com/Programs/109231/University-of-Lethbridge/Bachelor-of-Fine-Arts-|-Drama-Performance",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/37326/University-of-Manitoba/Fine-Arts",
    "entranceGrade": "70 - 70",
    "tutionValue": "$24,400",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Bachelor of Fine Arts | Art History/Museum Studies",
    "programLink": "https://www.studyincanada.com/Programs/58608/University-of-Lethbridge/Bachelor-of-Fine-Arts-|-Art-History/Museum-Studies",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Fine Arts",
    "programLink": "https://www.studyincanada.com/Programs/8621/Langara-College/Fine-Arts",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Internet of Things - System Architecture and Integration",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/internet-of-things/",
    "entranceGrade": "N/S",
    "tutionValue": "$26,567",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technician",
    "programLink": "https://www.algonquincollege.com/acce/program/architectural-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,826",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/architectural-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,026",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Architectural Studies (BAS)",
    "programLink": "https://admissions.carleton.ca/programs/architectural-studies/",
    "entranceGrade": "74 - 76",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Architectural Science",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/architectural-science/",
    "entranceGrade": "80",
    "tutionValue": "$36,486",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology",
    "programLink": "https://www.algonquincollege.com/acce/program/architectural-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,826",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology",
    "programLink": "https://www.algonquincollege.com/acce/program/architectural-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,826",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Architecture",
    "programLink": "https://continuing.torontomu.ca/public/category/courseCategoryCertificateProfile.do?method=load&certificateId=173916",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology (Fast-Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/architectural-technology-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,026",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Advanced Architecture",
    "programLink": "https://continuing.torontomu.ca/public/category/courseCategoryCertificateProfile.do?method=load&certificateId=173937",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/architectural-technology/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,026",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Architectural Engineering",
    "programLink": "https://uwaterloo.ca/future-students/programs/architectural-engineering?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "84 - 89",
    "tutionValue": "$73,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "History and Theory of Architecture (BA)",
    "programLink": "https://admissions.carleton.ca/programs/history-and-theory-of-architecture/",
    "entranceGrade": "73 - 76",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "History and Theory of Architecture (BA)",
    "programLink": "https://admissions.carleton.ca/programs/history-and-theory-of-architecture/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Sustainable Architectural Design",
    "programLink": "https://www.algonquincollege.com/acce/program/sustainable-architectural-design/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology (Fast-Track)",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/architectural-technology-fast-track/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,314",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technician",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/architectural-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,026",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Architecture",
    "programLink": "https://uwaterloo.ca/future-students/programs/architecture?utm_source=mur-schoolfinderprofile&utm_medium=referral&utm_campaign=mur-schoolfinder",
    "entranceGrade": "83 - 87",
    "tutionValue": "$73,000",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technician",
    "programLink": "https://www.algonquincollege.com/acce/program/architectural-technician/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,826",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Applied Technology (Honours) - Architecture - Project and Facili...",
    "programLink": "https://www.conestogac.on.ca/fulltime/bachelor-of-applied-technology-honours-architecture-project-and-facility-management",
    "entranceGrade": "65",
    "tutionValue": "$18,442",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "OCAD University",
    "location": "Canada, Ontario",
    "programName": "Environmental Design: Interior Design Specialization",
    "programLink": "https://admissions.ocadu.ca/program/environmental-design-interior-design-specialization",
    "entranceGrade": "70 - 70",
    "tutionValue": "$29,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "OCAD University",
    "location": "Canada, Ontario",
    "programName": "Environmental Design",
    "programLink": "https://admissions.ocadu.ca/program/environmental-design",
    "entranceGrade": "70 - 70",
    "tutionValue": "$29,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Landscape Architecture (MLArch)",
    "programLink": "https://www.studyincanada.com/Programs/13939/University-of-Manitoba-Faculty-of-Graduate-Studies/Landscape-Architecture-(MLArch)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver - Faculty of Graduate Studies",
    "location": "Canada, British Columbia",
    "programName": "Naval Architecture & Marine Engineering (MEL) - Master of Engine...",
    "programLink": "https://www.studyincanada.com/Programs/91639/The-University-of-British-Columbia-Vancouver-Faculty-of-Graduate-Studies/Naval-Architecture-&-Marine-Engineering-(MEL)-Master-of-Engine...",
    "entranceGrade": "76",
    "tutionValue": "$58,803",
    "length": "12 Month(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Architecture",
    "programLink": "https://www.studyincanada.com/Programs/88566/The-University-of-British-Columbia-Vancouver/Architecture",
    "entranceGrade": "75",
    "tutionValue": "$40,248",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Landscape Technician",
    "programLink": "https://www.studyincanada.com/Programs/1562/Humber-College-Institute-of-Technology-&-Advanced-Learning/Landscape-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$16,394",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Pacific Design Academy",
    "location": "Canada, British Columbia",
    "programName": "Interior Architectural Design",
    "programLink": "https://www.studyincanada.com/Programs/47794/Pacific-Design-Academy/Interior-Architectural-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Loyalist College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology",
    "programLink": "https://www.studyincanada.com/Programs/7849/Loyalist-College/Architectural-Technology",
    "entranceGrade": "N/S",
    "tutionValue": "$16,468",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology (Fast Track)",
    "programLink": "https://www.studyincanada.com/Programs/109639/Centennial-College-Graduate-Programs/Architectural-Technology-(Fast-Track)",
    "entranceGrade": "N/S",
    "tutionValue": "$17,475",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Landscape Architecture",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/degree-programs/bachelor-landscape-architecture-bla/",
    "entranceGrade": "N/S",
    "tutionValue": "$41,557",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Architectural Technologies",
    "programLink": "https://www.studyincanada.com/Programs/30687/SAIT/Architectural-Technologies",
    "entranceGrade": "50 - 50",
    "tutionValue": "$19,677",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Architecture (Two-Year) (MArch)",
    "programLink": "https://www.studyincanada.com/Programs/19071/Carleton-University-Faculty-of-Graduate-Studies-and-Research/Architecture-(Two-Year)-(MArch)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Laurentian University/Universite Laurentienne",
    "location": "Canada, Ontario",
    "programName": "Architectural Studies (BAS)",
    "programLink": "https://www.studyincanada.com/Programs/78346/Laurentian-University/Universite-Laurentienne/Architectural-Studies-(BAS)",
    "entranceGrade": "75",
    "tutionValue": "$32,668",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technician",
    "programLink": "https://www.studyincanada.com/Programs/12021/Mohawk-College/Architectural-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$17,335",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Athabasca University",
    "location": "Canada, Alberta",
    "programName": "Architecture",
    "programLink": "https://www.studyincanada.com/Programs/95426/Athabasca-University/Architecture",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "City Planning",
    "programLink": "https://www.studyincanada.com/Programs/13383/University-of-Manitoba-Faculty-of-Graduate-Studies/City-Planning",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Architecture",
    "programLink": "https://www.studyincanada.com/Programs/7398/McGill-University/Architecture",
    "entranceGrade": "90",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Pacific Design Academy",
    "location": "Canada, British Columbia",
    "programName": "Building Technology",
    "programLink": "https://www.studyincanada.com/Programs/13205/Pacific-Design-Academy/Building-Technology",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Architecture",
    "programLink": "https://www.studyincanada.com/Programs/13363/University-of-Manitoba-Faculty-of-Graduate-Studies/Architecture",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Architecture, Landscape Architecture, and Urbanism",
    "programLink": "https://www.studyincanada.com/Programs/104326/The-University-of-British-Columbia-Vancouver/Architecture,-Landscape-Architecture,-and-Urbanism",
    "entranceGrade": "N/S",
    "tutionValue": "$42,695",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver - Faculty of Graduate Studies",
    "location": "Canada, British Columbia",
    "programName": "Naval Architecture & Marine Engineering (MEng) - Master of Engin...",
    "programLink": "https://www.studyincanada.com/Programs/91630/The-University-of-British-Columbia-Vancouver-Faculty-of-Graduate-Studies/Naval-Architecture-&-Marine-Engineering-(MEng)-Master-of-Engin...",
    "entranceGrade": "76",
    "tutionValue": "$52,156",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology",
    "programLink": "https://www.studyincanada.com/Programs/22138/Humber-College-Institute-of-Technology-&-Advanced-Learning/Architectural-Technology",
    "entranceGrade": "N/S",
    "tutionValue": "$19,615",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Environmental Design",
    "programLink": "https://www.studyincanada.com/Programs/13362/University-of-Manitoba/Environmental-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$20,400",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Art History and Visual Culture (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/44495/University-of-Toronto-Scarborough/Art-History-and-Visual-Culture-(HBA)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technician",
    "programLink": "https://www.studyincanada.com/Programs/56389/Georgian-College/Architectural-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$14,319",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology (Fast Track/Co-Op)",
    "programLink": "https://www.studyincanada.com/Programs/109638/Centennial-College-Graduate-Programs/Architectural-Technology-(Fast-Track/Co-Op)",
    "entranceGrade": "N/S",
    "tutionValue": "$17,475",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Loyalist College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technician",
    "programLink": "https://www.studyincanada.com/Programs/26587/Loyalist-College/Architectural-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$16,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "City Studies (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/101909/University-of-Toronto-Scarborough/City-Studies-(HBA)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Computer Assisted Drafting",
    "programLink": "https://www.ufv.ca/calendar/2008_09/ProgramsC-E/CS_CAD.htm",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "NAIT",
    "location": "Canada, Alberta",
    "programName": "Architectural Technology",
    "programLink": "https://www.studyincanada.com/Programs/7700/NAIT/Architectural-Technology",
    "entranceGrade": "65",
    "tutionValue": "$19,188",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology",
    "programLink": "https://www.studyincanada.com/Programs/56387/Georgian-College/Architectural-Technology",
    "entranceGrade": "N/S",
    "tutionValue": "$14,319",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology (Fast Track)",
    "programLink": "https://www.studyincanada.com/Programs/31554/Centennial-College-Graduate-Programs/Architectural-Technology-(Fast-Track)",
    "entranceGrade": "N/S",
    "tutionValue": "$18,026",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology (Fast Track/Co-Op)",
    "programLink": "https://www.studyincanada.com/Programs/31560/Centennial-College-Graduate-Programs/Architectural-Technology-(Fast-Track/Co-Op)",
    "entranceGrade": "N/S",
    "tutionValue": "$18,026",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "City Studies (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/44564/University-of-Toronto-Scarborough/City-Studies-(HBA)",
    "entranceGrade": "80 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Graduate Studies at University of Waterloo",
    "location": "Canada, Ontario",
    "programName": "Architecture",
    "programLink": "https://www.studyincanada.com/Programs/12447/Graduate-Studies-at-University-of-Waterloo/Architecture",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Environmental Design Studies (BEDS)",
    "programLink": "https://www.studyincanada.com/Programs/56678/Dalhousie-University/Environmental-Design-Studies-(BEDS)",
    "entranceGrade": "N/S",
    "tutionValue": "$31,295",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Landscape Architecture (BTech)",
    "programLink": "https://www.studyincanada.com/Programs/80834/Dalhousie-University/Landscape-Architecture-(BTech)",
    "entranceGrade": "70",
    "tutionValue": "$31,295",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lethbridge College",
    "location": "Canada, Alberta",
    "programName": "Engineering Design Technology",
    "programLink": "https://www.studyincanada.com/Programs/2953/Lethbridge-College/Engineering-Design-Technology",
    "entranceGrade": "N/S",
    "tutionValue": "$15,565",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Athabasca University",
    "location": "Canada, Alberta",
    "programName": "Architecture",
    "programLink": "https://www.studyincanada.com/Programs/95424/Athabasca-University/Architecture",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Community Design (BCD)",
    "programLink": "https://www.studyincanada.com/Programs/34087/Dalhousie-University/Community-Design-(BCD)",
    "entranceGrade": "75",
    "tutionValue": "$28,933",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Architectural Technology",
    "programLink": "https://www.studyincanada.com/Programs/90564/Mohawk-College/Architectural-Technology",
    "entranceGrade": "N/S",
    "tutionValue": "$17,835",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Toronto - St. George",
    "location": "Canada, Ontario",
    "programName": "Architectural Studies (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/8199/University-of-Toronto-St.-George/Architectural-Studies-(HBA)",
    "entranceGrade": "85 - 100",
    "tutionValue": "$59,300",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Interior Design",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/Arts/Departments/BachelorofInteriorDesign/index.htm",
    "entranceGrade": "75 - 80",
    "tutionValue": "$26,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Interior Design (Entry Level 06)",
    "programLink": "https://www.algonquincollege.com/mediaanddesign/program/bachelor-of-interior-design-entry-level/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Interior Design",
    "programLink": "https://www.algonquincollege.com/mediaanddesign/program/bachelor-of-interior-design/",
    "entranceGrade": "70",
    "tutionValue": "$19,450",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Interior Design",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/interior-design/",
    "entranceGrade": "75",
    "tutionValue": "$33,462",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "OCAD University",
    "location": "Canada, Ontario",
    "programName": "Environmental Design: Interior Design Specialization",
    "programLink": "https://admissions.ocadu.ca/program/environmental-design-interior-design-specialization",
    "entranceGrade": "70 - 70",
    "tutionValue": "$29,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "ITD Canada",
    "location": "Canada, British Columbia",
    "programName": "Interior Design Diploma with Co-op",
    "programLink": "https://www.studyincanada.com/Programs/75999/ITD-Canada/Interior-Design-Diploma-with-Co-op",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Interior Design",
    "programLink": "https://www.studyincanada.com/Programs/3759/Fanshawe-College/Interior-Design",
    "entranceGrade": "65",
    "tutionValue": "$17,303",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Interior Design (Part-Time)",
    "programLink": "https://www.studyincanada.com/Programs/104762/British-Columbia-Institute-of-Technology/Interior-Design-(Part-Time)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "9 Month(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Interior Design",
    "programLink": "https://www.studyincanada.com/Programs/51333/Vancouver-Island-University/Interior-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$26,346",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lethbridge College",
    "location": "Canada, Alberta",
    "programName": "Interior Design Technology",
    "programLink": "https://www.studyincanada.com/Programs/13069/Lethbridge-College/Interior-Design-Technology",
    "entranceGrade": "N/S",
    "tutionValue": "$12,445",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "NAIT",
    "location": "Canada, Alberta",
    "programName": "Interior Design Technology",
    "programLink": "https://www.studyincanada.com/Programs/7708/NAIT/Interior-Design-Technology",
    "entranceGrade": "60",
    "tutionValue": "$19,008",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Interior Design",
    "programLink": "https://www.studyincanada.com/Programs/15269/British-Columbia-Institute-of-Technology/Interior-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "9 Month(s)"
  },
  {
    "schoolName": "Herzing College - Montreal",
    "location": "Canada, Québec",
    "programName": "Interior Design",
    "programLink": "https://www.studyincanada.com/Programs/17954/Herzing-College-Montreal/Interior-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1425 Hour(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Interior Design",
    "programLink": "https://www.studyincanada.com/Programs/22862/Sheridan-College/Interior-Design",
    "entranceGrade": "65",
    "tutionValue": "$23,526",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "LaSalle College Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Interior Design",
    "programLink": "https://www.studyincanada.com/Programs/13213/LaSalle-College-Vancouver/Interior-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Pacific Design Academy",
    "location": "Canada, British Columbia",
    "programName": "Interior Design",
    "programLink": "https://www.studyincanada.com/Programs/13202/Pacific-Design-Academy/Interior-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Georgian College",
    "location": "Canada, Ontario",
    "programName": "Honours Bachelor of Interior Design",
    "programLink": "https://www.studyincanada.com/Programs/90861/Georgian-College/Honours-Bachelor-of-Interior-Design",
    "entranceGrade": "65 - 100",
    "tutionValue": "$16,853",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Interior Design (IDSN)",
    "programLink": "https://www.studyincanada.com/Programs/45542/Kwantlen-Polytechnic-University/Interior-Design-(IDSN)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Interior Design Fundamentals",
    "programLink": "https://www.studyincanada.com/Programs/104763/British-Columbia-Institute-of-Technology/Interior-Design-Fundamentals",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "9 Month(s)"
  },
  {
    "schoolName": "LaSalle College Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Interior Design",
    "programLink": "https://www.studyincanada.com/Programs/101162/LaSalle-College-Vancouver/Interior-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "16 Month(s)"
  },
  {
    "schoolName": "Centre for Arts and Technology (Kelowna Campus)",
    "location": "Canada, British Columbia",
    "programName": "Interior Design",
    "programLink": "https://www.studyincanada.com/Programs/46914/Centre-for-Arts-and-Technology-(Kelowna-Campus)/Interior-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Lakeland College",
    "location": "Canada, Alberta",
    "programName": "Interior Design Technology",
    "programLink": "https://www.studyincanada.com/Programs/17535/Lakeland-College/Interior-Design-Technology",
    "entranceGrade": "N/S",
    "tutionValue": "$17,655",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Interior Design (Pre-Master's)",
    "programLink": "https://www.studyincanada.com/Programs/111612/University-of-Manitoba-Faculty-of-Graduate-Studies/Interior-Design-(Pre-Master-s)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Manitoba - Faculty of Graduate Studies",
    "location": "Canada, Manitoba",
    "programName": "Interior Design (MID)",
    "programLink": "https://www.studyincanada.com/Programs/13937/University-of-Manitoba-Faculty-of-Graduate-Studies/Interior-Design-(MID)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Interior Design",
    "programLink": "https://www.studyincanada.com/Programs/6587/St.-Clair-College/Interior-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$15,685",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "LaSalle College Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Interior Design (BAD)",
    "programLink": "https://www.studyincanada.com/Programs/69729/LaSalle-College-Vancouver/Interior-Design-(BAD)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "36 Month(s)"
  },
  {
    "schoolName": "LaSalle College Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Fashion Design",
    "programLink": "https://www.studyincanada.com/Programs/17206/LaSalle-College-Vancouver/Fashion-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "18 Month(s)"
  },
  {
    "schoolName": "Pacific Design Academy",
    "location": "Canada, British Columbia",
    "programName": "Fashion Design",
    "programLink": "https://www.studyincanada.com/Programs/33230/Pacific-Design-Academy/Fashion-Design",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Fashion Design",
    "programLink": "https://www.studyincanada.com/Programs/3757/Fanshawe-College/Fashion-Design",
    "entranceGrade": "N/S",
    "tutionValue": "$16,667",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Fashion Design & Technology",
    "programLink": "https://www.studyincanada.com/Programs/96457/Kwantlen-Polytechnic-University/Fashion-Design-&-Technology",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Fashion Design Technician",
    "programLink": "https://www.studyincanada.com/Programs/74634/St.-Clair-College/Fashion-Design-Technician",
    "entranceGrade": "N/S",
    "tutionValue": "$16,344",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Journalism and Digital Media",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/Communications/Programs/BachelorofCommunication-JournalismAndDigitalMedia/index.htm",
    "entranceGrade": "65 - 70",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Journalism",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/journalism/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,303",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Sports Journalism",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/sports-journalism/",
    "entranceGrade": "N/S",
    "tutionValue": "$25,140",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Journalism (BJ)",
    "programLink": "https://admissions.carleton.ca/programs/journalism/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Journalism (BA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/journalism.html",
    "entranceGrade": "75",
    "tutionValue": "$32,525",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Journalism",
    "programLink": "https://www.algonquincollege.com/mediaanddesign/program/journalism/",
    "entranceGrade": "N/S",
    "tutionValue": "$16,720",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "News Studies",
    "programLink": "https://continuing.torontomu.ca/public/category/courseCategoryCertificateProfile.do?method=load&certificateId=170741",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Journalism and Humanities (BJ Hum)",
    "programLink": "https://carleton.ca/bhum/journalism-and-humanities/",
    "entranceGrade": "80 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Journalism",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/journalism/",
    "entranceGrade": "88 - 92",
    "tutionValue": "$32,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Journalism (BJ)",
    "programLink": "https://admissions.carleton.ca/programs/journalism/",
    "entranceGrade": "78 - 82",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph-Humber",
    "location": "Canada, Ontario",
    "programName": "Media and Communication Studies",
    "programLink": "https://www.guelphhumber.ca/futurestudents/media",
    "entranceGrade": "75 - 80",
    "tutionValue": "$31,833",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Journalism",
    "programLink": "https://www.conestogac.on.ca/fulltime/journalism",
    "entranceGrade": "N/S",
    "tutionValue": "$16,307",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Management (MSc)",
    "programLink": "https://www.studyincanada.com/Programs/113191/Concordia-University-School-of-Graduate-Studies/Management-(MSc)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Digital Innovation in Journalism Studies (MA)",
    "programLink": "https://www.studyincanada.com/Programs/90216/Concordia-University-School-of-Graduate-Studies/Digital-Innovation-in-Journalism-Studies-(MA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Journalism",
    "programLink": "https://www.studyincanada.com/Programs/6584/St.-Clair-College/Journalism",
    "entranceGrade": "N/S",
    "tutionValue": "$15,685",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Journalism and Creative Writing",
    "programLink": "https://www.studyincanada.com/Programs/109885/Trent-University/Journalism-and-Creative-Writing",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Journalism (Joint Seneca/York)",
    "programLink": "https://www.studyincanada.com/Programs/6532/Seneca-Polytechnic/Journalism-(Joint-Seneca/York)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Journalism (GrDip)",
    "programLink": "https://www.studyincanada.com/Programs/68883/Concordia-University-School-of-Graduate-Studies/Journalism-(GrDip)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Journalism (BJourn)",
    "programLink": "https://www.studyincanada.com/Programs/64417/Kwantlen-Polytechnic-University/Journalism-(BJourn)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Lethbridge",
    "location": "Canada, Alberta",
    "programName": "Pre-Professional Transfer | Journalism",
    "programLink": "https://www.studyincanada.com/Programs/33520/University-of-Lethbridge/Pre-Professional-Transfer-|-Journalism",
    "entranceGrade": "65 - 100",
    "tutionValue": "$20,784",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Journalism and Creative Writing (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/69853/Trent-University/Journalism-and-Creative-Writing-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Loyalist College",
    "location": "Canada, Ontario",
    "programName": "Journalism",
    "programLink": "https://www.studyincanada.com/Programs/64259/Loyalist-College/Journalism",
    "entranceGrade": "70",
    "tutionValue": "$16,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Broadcast and Digital Journalism",
    "programLink": "https://www.studyincanada.com/Programs/104664/British-Columbia-Institute-of-Technology/Broadcast-and-Digital-Journalism",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Thompson Rivers University",
    "location": "Canada, British Columbia",
    "programName": "Communication: Bachelor of Arts Degree - Major",
    "programLink": "https://www.studyincanada.com/Programs/107891/Thompson-Rivers-University/Communication-Bachelor-of-Arts-Degree-Major",
    "entranceGrade": "N/S",
    "tutionValue": "$18,355",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Journalism",
    "programLink": "https://www.studyincanada.com/Programs/13005/SAIT/Journalism",
    "entranceGrade": "60 - 60",
    "tutionValue": "$22,390",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Journalism (BJourn)",
    "programLink": "https://www.studyincanada.com/Programs/45515/Kwantlen-Polytechnic-University/Journalism-(BJourn)",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Seneca Polytechnic",
    "location": "Canada, Ontario",
    "programName": "Journalism",
    "programLink": "https://www.studyincanada.com/Programs/6531/Seneca-Polytechnic/Journalism",
    "entranceGrade": "N/S",
    "tutionValue": "$16,667",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Développement économique communautaire (dipl. 2e c.)",
    "programLink": "https://www.studyincanada.com/Programs/113183/Concordia-University-School-of-Graduate-Studies/D%c3%a9veloppement-%c3%a9conomique-communautaire-(dipl.-2e-c.)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Loyalist College",
    "location": "Canada, Ontario",
    "programName": "Photojournalism",
    "programLink": "https://www.studyincanada.com/Programs/7889/Loyalist-College/Photojournalism",
    "entranceGrade": "N/S",
    "tutionValue": "$16,468",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Mohawk College",
    "location": "Canada, Ontario",
    "programName": "Journalism",
    "programLink": "https://www.studyincanada.com/Programs/87318/Mohawk-College/Journalism",
    "entranceGrade": "N/S",
    "tutionValue": "$17,335",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Media, Journalism & Digital Cultures (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/96713/University-of-Toronto-Scarborough/Media,-Journalism-&-Digital-Cultures-(HBA)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University - Durham Greater Toronto Area",
    "location": "Canada, Ontario",
    "programName": "Journalism and Creative Writing",
    "programLink": "https://www.studyincanada.com/Programs/105223/Trent-University-Durham-Greater-Toronto-Area/Journalism-and-Creative-Writing",
    "entranceGrade": "70",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Journalism and Creative Writing",
    "programLink": "https://www.studyincanada.com/Programs/104873/Durham-College/Journalism-and-Creative-Writing",
    "entranceGrade": "70",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Journalism (Pre-Journalism)",
    "programLink": "https://www.studyincanada.com/Programs/84650/University-of-Regina/Journalism-(Pre-Journalism)",
    "entranceGrade": "65",
    "tutionValue": "$11,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Digital Journalism",
    "programLink": "https://www.studyincanada.com/Programs/81522/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Digital-Journalism",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "Journalism (Joint Centennial) (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/44577/University-of-Toronto-Scarborough/Journalism-(Joint-Centennial)-(HBA)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Okanagan College",
    "location": "Canada, British Columbia",
    "programName": "Diploma in Communications, Culture, and Journalism Studies",
    "programLink": "https://www.studyincanada.com/Programs/81141/Okanagan-College/Diploma-in-Communications,-Culture,-and-Journalism-Studies",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Journalism",
    "programLink": "https://www.studyincanada.com/Programs/31189/Sheridan-College/Journalism",
    "entranceGrade": "65",
    "tutionValue": "$22,479",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Broadcast and Online Journalism",
    "programLink": "https://www.studyincanada.com/Programs/14486/British-Columbia-Institute-of-Technology/Broadcast-and-Online-Journalism",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Journalism",
    "programLink": "https://www.studyincanada.com/Programs/88554/The-University-of-British-Columbia-Vancouver/Journalism",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "20 Month(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Marketing (MSc)",
    "programLink": "https://www.studyincanada.com/Programs/113192/Concordia-University-School-of-Graduate-Studies/Marketing-(MSc)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Journalism - Mass Media",
    "programLink": "https://www.studyincanada.com/Programs/76675/Durham-College/Journalism-Mass-Media",
    "entranceGrade": "N/S",
    "tutionValue": "$16,334",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Royal Roads University",
    "location": "Canada, British Columbia",
    "programName": "Professional Communication (BA)",
    "programLink": "https://www.studyincanada.com/Programs/22748/Royal-Roads-University/Professional-Communication-(BA)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$43,260",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Journalism - Broadcast",
    "programLink": "https://www.studyincanada.com/Programs/3854/Fanshawe-College/Journalism-Broadcast",
    "entranceGrade": "N/S",
    "tutionValue": "$17,404",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Journalism",
    "programLink": "https://www.studyincanada.com/Programs/1559/Humber-College-Institute-of-Technology-&-Advanced-Learning/Journalism",
    "entranceGrade": "N/S",
    "tutionValue": "$16,394",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Douglas College",
    "location": "Canada, British Columbia",
    "programName": "Professional Communication (Post-Degree Diploma)",
    "programLink": "https://www.studyincanada.com/Programs/85044/Douglas-College/Professional-Communication-(Post-Degree-Diploma)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Visual Journalism (GrDip)",
    "programLink": "https://www.studyincanada.com/Programs/80959/Concordia-University-School-of-Graduate-Studies/Visual-Journalism-(GrDip)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Creative Writing and Journalism",
    "programLink": "https://www.studyincanada.com/Programs/14364/Vancouver-Island-University/Creative-Writing-and-Journalism",
    "entranceGrade": "N/S",
    "tutionValue": "$24,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Thomas University",
    "location": "Canada, New Brunswick",
    "programName": "Journalism",
    "programLink": "https://www.studyincanada.com/Programs/101973/St.-Thomas-University/Journalism",
    "entranceGrade": "70",
    "tutionValue": "$17,208",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Journalism (MJ)",
    "programLink": "https://www.studyincanada.com/Programs/6693/Carleton-University-Faculty-of-Graduate-Studies-and-Research/Journalism-(MJ)",
    "entranceGrade": "70",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Medicine Hat College",
    "location": "Canada, Alberta",
    "programName": "Journalism",
    "programLink": "https://www.studyincanada.com/Programs/6126/Medicine-Hat-College/Journalism",
    "entranceGrade": "60",
    "tutionValue": "$14,131",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College - Graduate Programs",
    "location": "Canada, Ontario",
    "programName": "Journalism (Fast Track Option)",
    "programLink": "https://www.studyincanada.com/Programs/13830/Centennial-College-Graduate-Programs/Journalism-(Fast-Track-Option)",
    "entranceGrade": "N/S",
    "tutionValue": "$14,278",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Media Studies",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-media-studies.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Broadcast Media Studies",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/Communications/Programs/BachelorofCommunication-BroadcastMediaStudies/index.htm",
    "entranceGrade": "65 - 70",
    "tutionValue": "$26,938",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Communication and Media Studies (BCoMS)",
    "programLink": "https://admissions.carleton.ca/programs/communication-media-studies/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Communication & Media Studies",
    "programLink": "https://futurestudents.yorku.ca/program/communication-studies",
    "entranceGrade": "75 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Communication and Digital Media Studies - GAS",
    "programLink": "https://ontariotechu.ca/programs/social-science-and-humanities/communication-and-digital-media-studies-gas-transfer.php",
    "entranceGrade": "77 - 100",
    "tutionValue": "$29,196",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Communication and Digital Media Studies",
    "programLink": "https://ontariotechu.ca/programs/undergraduate/social-science-and-humanities/communication-and-digital-media-studies/index.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Ontario Tech University",
    "location": "Canada, Ontario",
    "programName": "Communication and Digital Media Studies - Advanced Entry",
    "programLink": "https://ontariotechu.ca/programs/social-science-and-humanities/communication-advanced-entry.php",
    "entranceGrade": "70 - 100",
    "tutionValue": "$29,196",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Trent University - Durham Greater Toronto Area",
    "location": "Canada, Ontario",
    "programName": "Media Studies",
    "programLink": "https://www.studyincanada.com/Programs/95588/Trent-University-Durham-Greater-Toronto-Area/Media-Studies",
    "entranceGrade": "70",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Toronto - Scarborough",
    "location": "Canada, Ontario",
    "programName": "New Media Studies (Joint Centennial) (HBA)",
    "programLink": "https://www.studyincanada.com/Programs/55829/University-of-Toronto-Scarborough/New-Media-Studies-(Joint-Centennial)-(HBA)",
    "entranceGrade": "74 - 100",
    "tutionValue": "$58,160",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Media Studies (MA)",
    "programLink": "https://www.studyincanada.com/Programs/39615/Concordia-University-School-of-Graduate-Studies/Media-Studies-(MA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Vancouver Island University",
    "location": "Canada, British Columbia",
    "programName": "Digital Media Studies",
    "programLink": "https://www.studyincanada.com/Programs/106145/Vancouver-Island-University/Digital-Media-Studies",
    "entranceGrade": "N/S",
    "tutionValue": "$24,646",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Media Studies (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/69859/Trent-University/Media-Studies-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Dalhousie University",
    "location": "Canada, Nova Scotia",
    "programName": "Cinema and Media Studies (BA)",
    "programLink": "https://www.studyincanada.com/Programs/96626/Dalhousie-University/Cinema-and-Media-Studies-(BA)",
    "entranceGrade": "70",
    "tutionValue": "$27,351",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Media Studies",
    "programLink": "https://www.studyincanada.com/Programs/76875/The-University-of-British-Columbia-Vancouver/Media-Studies",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "English - Film and Media Studies",
    "programLink": "https://www.studyincanada.com/Programs/90896/Bishop-s-University/English-Film-and-Media-Studies",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Media Studies",
    "programLink": "https://www.studyincanada.com/Programs/81811/The-University-of-British-Columbia-Vancouver/Media-Studies",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "English - Film and Media Studies",
    "programLink": "https://www.studyincanada.com/Programs/55717/Bishop-s-University/English-Film-and-Media-Studies",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Film Production (BFA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/film-production.html",
    "entranceGrade": "65",
    "tutionValue": "$30,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Art History and Film Studies (BFA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/art-history-filmstudiesbfa.html",
    "entranceGrade": "65",
    "tutionValue": "$30,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Film and Media Production",
    "programLink": "https://www.algonquincollege.com/mediaanddesign/program/film-and-media-production/",
    "entranceGrade": "N/S",
    "tutionValue": "$19,986",
    "length": "42 Week(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Film Animation (BFA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/film-animation.html",
    "entranceGrade": "65",
    "tutionValue": "$30,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Film Studies (BA)",
    "programLink": "https://admissions.carleton.ca/programs/film-studies/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Film Studies (BA)",
    "programLink": "https://admissions.carleton.ca/programs/film-studies/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Advanced Television and Film - Script to Screen",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/script-to-screen-advanced-television-film",
    "entranceGrade": "N/S",
    "tutionValue": "$17,105",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Toronto Metropolitan University",
    "location": "Canada, Ontario",
    "programName": "Image Arts - Film Studies",
    "programLink": "https://www.torontomu.ca/programs/undergraduate/film/",
    "entranceGrade": "76",
    "tutionValue": "$31,976",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Film Studies",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-film-studies.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Broadcasting - Radio, Television, Film & Digital Media",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/radio-television-film-digital-media/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,085",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Music, Media and Film Foundations",
    "programLink": "https://www.algonquincollege.com/mediaanddesign/program/music-media-and-film-foundations/",
    "entranceGrade": "N/S",
    "tutionValue": "$15,022",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Television and Film - Business",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/television-film-business/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,005",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University",
    "location": "Canada, Ontario",
    "programName": "Film Studies (BA)",
    "programLink": "https://wlu.ca/programs/arts/undergraduate/film-studies-ba/index.html",
    "entranceGrade": "70 - 100",
    "tutionValue": "$33,220",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Film and Moving Image Studies (BFA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/film-studies.html",
    "entranceGrade": "65",
    "tutionValue": "$30,650",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Wilfrid Laurier University - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "English and Film Studies (MA)",
    "programLink": "https://www.wlu.ca/programs/arts/graduate/english-and-film-studies-ma/index.html",
    "entranceGrade": "77",
    "tutionValue": "$14,540",
    "length": "8 Month(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Film Production",
    "programLink": "https://www.studyincanada.com/Programs/77553/The-University-of-British-Columbia-Vancouver/Film-Production",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "English - Film and Media Studies",
    "programLink": "https://www.studyincanada.com/Programs/90896/Bishop-s-University/English-Film-and-Media-Studies",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Film and Media",
    "programLink": "https://www.studyincanada.com/Programs/81425/Queen-s-University/Film-and-Media",
    "entranceGrade": "80",
    "tutionValue": "$55,567",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Film Studies",
    "programLink": "https://www.studyincanada.com/Programs/21211/University-of-Regina/Film-Studies",
    "entranceGrade": "65 - 65",
    "tutionValue": "$11,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Film School",
    "location": "Canada, Ontario",
    "programName": "Film Production",
    "programLink": "https://www.studyincanada.com/Programs/13571/Toronto-Film-School/Film-Production",
    "entranceGrade": "N/S",
    "tutionValue": "$58,060",
    "length": "18 Month(s)"
  },
  {
    "schoolName": "Lakehead University - Orillia",
    "location": "Canada, Ontario",
    "programName": "Media, Film and Communications",
    "programLink": "https://www.studyincanada.com/Programs/74747/Lakehead-University-Orillia/Media,-Film-and-Communications",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,590",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Cultural Studies - Specialization in Film & Media (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/105847/Trent-University/Cultural-Studies-Specialization-in-Film-&-Media-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Film and Media Production",
    "programLink": "https://www.studyincanada.com/Programs/56757/Humber-College-Institute-of-Technology-&-Advanced-Learning/Bachelor-of-Film-and-Media-Production",
    "entranceGrade": "70",
    "tutionValue": "$20,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "ITD Canada",
    "location": "Canada, British Columbia",
    "programName": "Digital Film Production Diploma with Co-op\t",
    "programLink": "https://www.studyincanada.com/Programs/76006/ITD-Canada/Digital-Film-Production-Diploma-with-Co-op",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Trebas Institute Quebec Inc.",
    "location": "Canada, Québec",
    "programName": "Production cinématographique et télévisuelle - Film and Televisi...",
    "programLink": "https://www.studyincanada.com/Programs/2345/Trebas-Institute-Quebec-Inc./Production-cin%c3%a9matographique-et-t%c3%a9l%c3%a9visuelle-Film-and-Televisi...",
    "entranceGrade": "N/S",
    "tutionValue": "$14,630",
    "length": "12 Month(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Film and Moving Image Studies (PhD)",
    "programLink": "https://www.studyincanada.com/Programs/67153/Concordia-University-School-of-Graduate-Studies/Film-and-Moving-Image-Studies-(PhD)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University - Faculty of Graduate Studies and Research",
    "location": "Canada, Ontario",
    "programName": "Film Studies (MA)",
    "programLink": "https://www.studyincanada.com/Programs/6661/Carleton-University-Faculty-of-Graduate-Studies-and-Research/Film-Studies-(MA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Film Studies (General Major)",
    "programLink": "https://www.studyincanada.com/Programs/37422/University-of-Manitoba/Film-Studies-(General-Major)",
    "entranceGrade": "N/S",
    "tutionValue": "$16,600",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Sheridan College",
    "location": "Canada, Ontario",
    "programName": "Film and Television",
    "programLink": "https://www.studyincanada.com/Programs/77716/Sheridan-College/Film-and-Television",
    "entranceGrade": "65",
    "tutionValue": "$28,158",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Trent University",
    "location": "Canada, Ontario",
    "programName": "Cultural Studies - Specialization in Film & Media (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/85225/Trent-University/Cultural-Studies-Specialization-in-Film-&-Media-(B.A.)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$28,285",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Film Production",
    "programLink": "https://www.studyincanada.com/Programs/84656/University-of-Regina/Film-Production",
    "entranceGrade": "65",
    "tutionValue": "$11,397",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Communication, Media & Film (BA)/Concurrent Education (BEd)",
    "programLink": "https://www.studyincanada.com/Programs/114214/University-of-Windsor/Communication,-Media-&-Film-(BA)/Concurrent-Education-(BEd)",
    "entranceGrade": "75 - 100",
    "tutionValue": "$34,617",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "SAIT",
    "location": "Canada, Alberta",
    "programName": "Film and Video Production",
    "programLink": "https://www.studyincanada.com/Programs/37552/SAIT/Film-and-Video-Production",
    "entranceGrade": "60",
    "tutionValue": "$19,128",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Film and Television Production",
    "programLink": "https://www.studyincanada.com/Programs/1482/Humber-College-Institute-of-Technology-&-Advanced-Learning/Film-and-Television-Production",
    "entranceGrade": "N/S",
    "tutionValue": "$19,615",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of British Columbia - Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Film Production",
    "programLink": "https://www.studyincanada.com/Programs/16778/The-University-of-British-Columbia-Vancouver/Film-Production",
    "entranceGrade": "N/S",
    "tutionValue": "$42,803",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Manitoba",
    "location": "Canada, Manitoba",
    "programName": "Film Studies (Advanced Major)",
    "programLink": "https://www.studyincanada.com/Programs/59970/University-of-Manitoba/Film-Studies-(Advanced-Major)",
    "entranceGrade": "N/S",
    "tutionValue": "$16,600",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Durham College",
    "location": "Canada, Ontario",
    "programName": "Film and Motion Design -- Media Fundamentals",
    "programLink": "https://www.studyincanada.com/Programs/115895/Durham-College/Film-and-Motion-Design-Media-Fundamentals",
    "entranceGrade": "N/S",
    "tutionValue": "$14,884",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Windsor",
    "location": "Canada, Ontario",
    "programName": "Film Production",
    "programLink": "https://www.studyincanada.com/Programs/114202/University-of-Windsor/Film-Production",
    "entranceGrade": "70 - 100",
    "tutionValue": "$34,641",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "English - Film and Media Studies",
    "programLink": "https://www.studyincanada.com/Programs/55717/Bishop-s-University/English-Film-and-Media-Studies",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bow Valley College",
    "location": "Canada, Alberta",
    "programName": "Advanced Film Production Diploma",
    "programLink": "https://www.studyincanada.com/Programs/112195/Bow-Valley-College/Advanced-Film-Production-Diploma",
    "entranceGrade": "N/S",
    "tutionValue": "$67",
    "length": "24 Month(s)"
  },
  {
    "schoolName": "LaSalle College Vancouver",
    "location": "Canada, British Columbia",
    "programName": "VFX for Film & Television",
    "programLink": "https://www.studyincanada.com/Programs/69862/LaSalle-College-Vancouver/VFX-for-Film-&-Television",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "78 Week(s)"
  },
  {
    "schoolName": "Queen's University",
    "location": "Canada, Ontario",
    "programName": "Film and Media",
    "programLink": "https://www.studyincanada.com/Programs/5756/Queen-s-University/Film-and-Media",
    "entranceGrade": "80 - 100",
    "tutionValue": "$55,567",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Theatre and Film Studies and Another Subject (BA) (Combined Hono...",
    "programLink": "https://www.studyincanada.com/Programs/91540/McMaster-University/Theatre-and-Film-Studies-and-Another-Subject-(BA)-(Combined-Hono...",
    "entranceGrade": "77 - 79",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Western University",
    "location": "Canada, Ontario",
    "programName": "Film Studies",
    "programLink": "https://www.studyincanada.com/Programs/3819/Western-University/Film-Studies",
    "entranceGrade": "N/S",
    "tutionValue": "$45,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Toronto Academy of Acting for Film and Television",
    "location": "Canada, Ontario",
    "programName": "Film making & Television Production Full Time Diploma Program",
    "programLink": "https://www.studyincanada.com/Programs/71821/Toronto-Academy-of-Acting-for-Film-and-Television/Film-making-&-Television-Production-Full-Time-Diploma-Program",
    "entranceGrade": "60 - 100",
    "tutionValue": "$15,000",
    "length": "500 Hour(s)"
  },
  {
    "schoolName": "Red Deer Polytechnic",
    "location": "Canada, Alberta",
    "programName": "Applied Arts in Film Production",
    "programLink": "https://www.studyincanada.com/Programs/103563/Red-Deer-Polytechnic/Applied-Arts-in-Film-Production",
    "entranceGrade": "N/S",
    "tutionValue": "$23,500",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Fanshawe College",
    "location": "Canada, Ontario",
    "programName": "Broadcasting - Television and Film Production",
    "programLink": "https://www.studyincanada.com/Programs/3654/Fanshawe-College/Broadcasting-Television-and-Film-Production",
    "entranceGrade": "N/S",
    "tutionValue": "$17,018",
    "length": "60 Week(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Diploma in Film Production",
    "programLink": "https://www.studyincanada.com/Programs/103786/University-of-Regina/Diploma-in-Film-Production",
    "entranceGrade": "65",
    "tutionValue": "$11,397",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "LaSalle College Vancouver",
    "location": "Canada, British Columbia",
    "programName": "Digital Film and Video",
    "programLink": "https://www.studyincanada.com/Programs/86091/LaSalle-College-Vancouver/Digital-Film-and-Video",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "67 Week(s)"
  },
  {
    "schoolName": "University of Windsor - Faculty of Graduate Studies",
    "location": "Canada, Ontario",
    "programName": "Film and Media Arts (MFA)",
    "programLink": "https://www.studyincanada.com/Programs/92301/University-of-Windsor-Faculty-of-Graduate-Studies/Film-and-Media-Arts-(MFA)",
    "entranceGrade": "70 - 100",
    "tutionValue": "$8,863",
    "length": "N/S"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Theatre and Film Studies",
    "programLink": "https://www.studyincanada.com/Programs/26459/McMaster-University/Theatre-and-Film-Studies",
    "entranceGrade": "77 - 79",
    "tutionValue": "$43,116",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Film Studies (B.A.)",
    "programLink": "https://www.studyincanada.com/Programs/99409/University-of-Calgary/Film-Studies-(B.A.)",
    "entranceGrade": "75",
    "tutionValue": "$20,172",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Capilano University",
    "location": "Canada, British Columbia",
    "programName": "3D Animation for Film and Games",
    "programLink": "https://www.studyincanada.com/Programs/14193/Capilano-University/3D-Animation-for-Film-and-Games",
    "entranceGrade": "N/S",
    "tutionValue": "$38,439",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Emily Carr University of Art + Design",
    "location": "Canada, British Columbia",
    "programName": "Film + Screen Arts",
    "programLink": "https://www.studyincanada.com/Programs/14701/Emily-Carr-University-of-Art-+-Design/Film-+-Screen-Arts",
    "entranceGrade": "N/S",
    "tutionValue": "$25,156",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Acting for Film and Television",
    "programLink": "https://www.studyincanada.com/Programs/1288/Humber-College-Institute-of-Technology-&-Advanced-Learning/Acting-for-Film-and-Television",
    "entranceGrade": "N/S",
    "tutionValue": "$16,394",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Concordia University - School of Graduate Studies",
    "location": "Canada, Québec",
    "programName": "Film and Moving Image Studies (MA)",
    "programLink": "https://www.studyincanada.com/Programs/39574/Concordia-University-School-of-Graduate-Studies/Film-and-Moving-Image-Studies-(MA)",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Trebas Institute - Toronto",
    "location": "Canada, Ontario",
    "programName": "Film and Television Production and Post-Production",
    "programLink": "https://www.studyincanada.com/Programs/2281/Trebas-Institute-Toronto/Film-and-Television-Production-and-Post-Production",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "48 Week(s)"
  },
  {
    "schoolName": "University of Calgary",
    "location": "Canada, Alberta",
    "programName": "Film Studies (B.F.S.)",
    "programLink": "https://www.studyincanada.com/Programs/99410/University-of-Calgary/Film-Studies-(B.F.S.)",
    "entranceGrade": "75",
    "tutionValue": "$20,172",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Confederation College",
    "location": "Canada, Ontario",
    "programName": "Film Production",
    "programLink": "https://www.studyincanada.com/Programs/221/Confederation-College/Film-Production",
    "entranceGrade": "N/S",
    "tutionValue": "$31,330",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Public Relations Management",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/honours-bachelor-public-relations-management/",
    "entranceGrade": "N/S",
    "tutionValue": "$20,190",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Centennial College",
    "location": "Canada, Ontario",
    "programName": "Public Relations - Corporate Communications",
    "programLink": "https://www.centennialcollege.ca/programs-courses/full-time/public-relations-corporate-communications/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,353",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Public Relations",
    "programLink": "https://www.algonquincollege.com/mediaanddesign/program/public-relations/",
    "entranceGrade": "N/S",
    "tutionValue": "$17,824",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Algonquin College",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Digital Marketing Communication - Pathway for Public...",
    "programLink": "https://www.algonquincollege.com/mediaanddesign/program/bachelor-of-digital-marketing-communication-honours-pathway-for-public-relations/",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Public Relations",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/Communications/Programs/BachelorofCommunication-PublicRelations/index.htm",
    "entranceGrade": "65 - 70",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "York University",
    "location": "Canada, Ontario",
    "programName": "Communication, Social Media & Public Relations",
    "programLink": "https://futurestudents.yorku.ca/program/communication-social-media-and-public-relations",
    "entranceGrade": "50 - 100",
    "tutionValue": "$32,502",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Conestoga College",
    "location": "Canada, Ontario",
    "programName": "Public Relations",
    "programLink": "https://www.studyincanada.com/Tracking/ProfileProgramsTracking.aspx?programID=909",
    "entranceGrade": "N/S",
    "tutionValue": "$16,944",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Public Relations",
    "programLink": "https://www.studyincanada.com/Programs/1595/Humber-College-Institute-of-Technology-&-Advanced-Learning/Public-Relations",
    "entranceGrade": "N/S",
    "tutionValue": "$16,394",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Kwantlen Polytechnic University",
    "location": "Canada, British Columbia",
    "programName": "Public Relations",
    "programLink": "https://www.studyincanada.com/Programs/11269/Kwantlen-Polytechnic-University/Public-Relations",
    "entranceGrade": "70",
    "tutionValue": "$22,754",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Canadore College",
    "location": "Canada, Ontario",
    "programName": "Public Relations",
    "programLink": "https://www.studyincanada.com/Programs/103761/Canadore-College/Public-Relations",
    "entranceGrade": "N/S",
    "tutionValue": "$16,807",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "British Columbia Institute of Technology",
    "location": "Canada, British Columbia",
    "programName": "Marketing Management - Public Relations",
    "programLink": "https://www.studyincanada.com/Programs/28016/British-Columbia-Institute-of-Technology/Marketing-Management-Public-Relations",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "N/S"
  },
  {
    "schoolName": "Nova Scotia Community College",
    "location": "Canada, Nova Scotia",
    "programName": "Public Relations",
    "programLink": "https://www.studyincanada.com/Programs/15236/Nova-Scotia-Community-College/Public-Relations",
    "entranceGrade": "N/S",
    "tutionValue": "$11,690",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "University of Ottawa/Université d'Ottawa",
    "location": "Canada, Ontario",
    "programName": "Public Relations",
    "programLink": "https://www.studyincanada.com/Programs/66075/University-of-Ottawa/Universit%c3%a9-d-Ottawa/Public-Relations",
    "entranceGrade": "73 - 76",
    "tutionValue": "$36,161",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "St. Clair College",
    "location": "Canada, Ontario",
    "programName": "Public Relations",
    "programLink": "https://www.studyincanada.com/Programs/70681/St.-Clair-College/Public-Relations",
    "entranceGrade": "N/S",
    "tutionValue": "$15,685",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "MacEwan University",
    "location": "Canada, Alberta",
    "programName": "Public Relations",
    "programLink": "https://www.studyincanada.com/Programs/6275/MacEwan-University/Public-Relations",
    "entranceGrade": "N/S",
    "tutionValue": "$28,715",
    "length": "10 Month(s)"
  },
  {
    "schoolName": "Concordia University",
    "location": "Canada, Québec",
    "programName": "Early Childhood and Elementary Education (BA)",
    "programLink": "https://www.concordia.ca/academics/undergraduate/early-childhood-elementary-education.html",
    "entranceGrade": "80",
    "tutionValue": "$30,650",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Elementary Education - Native Studies",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-in-native-studies-elementary-education-native-studies.html",
    "entranceGrade": "70",
    "tutionValue": "$33,968",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Generalist Degree (BEd in Elementary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-elementary-education-generalist-degree.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Elementary Education",
    "programLink": "https://www.studyincanada.com/Programs/10863/Selkirk-College/Elementary-Education",
    "entranceGrade": "N/S",
    "tutionValue": "$13,409",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Kindergarten and Elementary Education (Jewish Studies)",
    "programLink": "https://www.studyincanada.com/Programs/105284/McGill-University/Kindergarten-and-Elementary-Education-(Jewish-Studies)",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "McGill University",
    "location": "Canada, Québec",
    "programName": "Kindergarten and Elementary Education (First Nations and Inuit S...",
    "programLink": "https://www.studyincanada.com/Programs/105288/McGill-University/Kindergarten-and-Elementary-Education-(First-Nations-and-Inuit-S...",
    "entranceGrade": "80",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Drama (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-drama-drama.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Second Languages - Japanese (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-second-languages-japanese.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "General Sciences (BSc/BEd in Secondary Education) (Augustana Fac...",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-general-sciences.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "English Language Arts (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-english-language-arts.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Second Languages - Cree (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-second-languages-cree.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Secondary Education - Native Studies",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-in-native-studies-secondary-education-native-studies.html",
    "entranceGrade": "70",
    "tutionValue": "$33,968",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Second Languages - French (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-second-languages-french.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Second Languages - Other (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-second-languages-other.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Second Languages - German (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-after-degree-second-languages-german.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Music (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-music-education-music-secondary.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Physical Sciences (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-physical-sciences.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Second Languages - Ukrainian (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-second-languages-ukrainian.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Social Studies (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-social-studies.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Career and Technology Studies: Human Sciences (BEd in Secondary ...",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-career-and-technology-studies-human-sciences.html",
    "entranceGrade": "75",
    "tutionValue": "$27,540",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Mathematics (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-mathematics.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Second Languages - Spanish (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-second-languages-spanish.html",
    "entranceGrade": "N/S",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Physical Education (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-physical-education.html",
    "entranceGrade": "70",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Career and Technology Studies: Computer Science (BEd in Secondar...",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-education-in-secondary-education-career-and-technology-studies-computer-science.html",
    "entranceGrade": "75",
    "tutionValue": "$27,540",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Biological Sciences (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-science-with-honors-biological-sciences.html",
    "entranceGrade": "75",
    "tutionValue": "$30,090",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Art (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/art-design/undergraduate-programs/bachelor-of-education-majors.html",
    "entranceGrade": "75",
    "tutionValue": "$30,090",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Physics (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/index.html#sort=relevancy",
    "entranceGrade": "75",
    "tutionValue": "$27,540",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Drama (BA/BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/undergraduate-programs/bachelor-of-arts-drama-drama.html",
    "entranceGrade": "72",
    "tutionValue": "$33,968",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of Alberta",
    "location": "Canada, Alberta",
    "programName": "Chemistry (BEd in Secondary Education)",
    "programLink": "https://www.ualberta.ca/en/augustana/programs/degree/bed-bsc/index.html",
    "entranceGrade": "75",
    "tutionValue": "$33,968",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Adult Learning and Post-Secondary Education",
    "programLink": "https://www.studyincanada.com/Programs/106429/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Adult-Learning-and-Post-Secondary-Education",
    "entranceGrade": "65",
    "tutionValue": "$20,790",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Fine Arts and Secondary Education",
    "programLink": "https://www.studyincanada.com/Programs/55688/Bishop-s-University/Fine-Arts-and-Secondary-Education",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Music and Secondary Education",
    "programLink": "https://www.studyincanada.com/Programs/55656/Bishop-s-University/Music-and-Secondary-Education",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Bishop's University",
    "location": "Canada, Québec",
    "programName": "Drama and Secondary Education",
    "programLink": "https://www.studyincanada.com/Programs/55715/Bishop-s-University/Drama-and-Secondary-Education",
    "entranceGrade": "80",
    "tutionValue": "$28,771",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Institute for Human Services Education",
    "location": "Canada, Nova Scotia",
    "programName": "Special Education",
    "programLink": "https://www.studyincanada.com/Programs/16925/Institute-for-Human-Services-Education/Special-Education",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Selkirk College",
    "location": "Canada, British Columbia",
    "programName": "Adult Special Education",
    "programLink": "https://www.studyincanada.com/Programs/10902/Selkirk-College/Adult-Special-Education",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Memorial University – St. John’s Campus",
    "location": "Canada, Newfoundland and Labrador",
    "programName": "Special Education",
    "programLink": "https://www.studyincanada.com/Programs/58347/Memorial-University-%e2%80%93-St.-John%e2%80%99s-Campus/Special-Education",
    "entranceGrade": "65",
    "tutionValue": "$20,790",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Mount Royal University",
    "location": "Canada, Alberta",
    "programName": "Criminal Justice",
    "programLink": "https://www.mtroyal.ca/ProgramsCourses/FacultiesSchoolsCentres/Arts/Departments/EconomicsJusticePolicyStudies/DegreePrograms/BachelorofArts-CriminalJustice/index.htm",
    "entranceGrade": "85 - 90",
    "tutionValue": "$25,604",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Carleton University",
    "location": "Canada, Ontario",
    "programName": "Criminology and Criminal Justice (BA)",
    "programLink": "https://admissions.carleton.ca/programs/criminology-and-criminal-justice/",
    "entranceGrade": "75 - 77",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of New Brunswick - Saint John",
    "location": "Canada, New Brunswick",
    "programName": "Criminal Justice Studies",
    "programLink": "https://www.studyincanada.com/Programs/90990/University-of-New-Brunswick-Saint-John/Criminal-Justice-Studies",
    "entranceGrade": "65",
    "tutionValue": "N/S",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Columbia College (Closed)",
    "location": "Canada, Alberta",
    "programName": "Criminal Justice Professional",
    "programLink": "https://www.studyincanada.com/Programs/34465/Columbia-College-(Closed)/Criminal-Justice-Professional",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Criminal Justice (Internship Designation)",
    "programLink": "https://www.studyincanada.com/Programs/72764/Camosun-College/Criminal-Justice-(Internship-Designation)",
    "entranceGrade": "N/S",
    "tutionValue": "$31,950",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Criminal Justice",
    "programLink": "https://www.ufv.ca/criminology/programs/bachelor-of-arts-criminal-justice-degree/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Criminal Justice and Public Policy",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/criminal-justice-public-policy-co-op-cjpp-c/",
    "entranceGrade": "80 - 83",
    "tutionValue": "$32,594",
    "length": "5 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Criminal Justice",
    "programLink": "https://www.ufv.ca/criminology/programs/bachelor-of-arts-criminal-justice-degree/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Criminal Justice Studies (Three-Year)",
    "programLink": "https://www.studyincanada.com/Programs/8010/The-University-of-Winnipeg/Criminal-Justice-Studies-(Three-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "Langara College",
    "location": "Canada, British Columbia",
    "programName": "Criminal Justice",
    "programLink": "https://www.studyincanada.com/Programs/8611/Langara-College/Criminal-Justice",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Criminal Justice (Corrections)",
    "programLink": "https://www.studyincanada.com/Programs/62811/Nipissing-University/Criminal-Justice-(Corrections)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "University of the Fraser Valley",
    "location": "Canada, British Columbia",
    "programName": "Criminal Justice",
    "programLink": "https://www.ufv.ca/criminology/programs/criminal-justice-diploma/",
    "entranceGrade": "N/S",
    "tutionValue": "$18,084",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Guelph",
    "location": "Canada, Ontario",
    "programName": "Criminal Justice and Public Policy",
    "programLink": "https://calendar.uoguelph.ca/undergraduate-calendar/programs-majors-minors/criminal-justice-public-policy-cjpp/",
    "entranceGrade": "80 - 85",
    "tutionValue": "$32,594",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Camosun College",
    "location": "Canada, British Columbia",
    "programName": "Criminal Justice",
    "programLink": "https://www.studyincanada.com/Programs/53482/Camosun-College/Criminal-Justice",
    "entranceGrade": "N/S",
    "tutionValue": "$31,950",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Criminal Justice (Policing)",
    "programLink": "https://www.studyincanada.com/Programs/62815/Nipissing-University/Criminal-Justice-(Policing)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Medicine Hat College",
    "location": "Canada, Alberta",
    "programName": "Criminal Justice",
    "programLink": "https://www.studyincanada.com/Programs/45914/Medicine-Hat-College/Criminal-Justice",
    "entranceGrade": "N/S",
    "tutionValue": "$14,481",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Criminal Justice Studies",
    "programLink": "https://www.studyincanada.com/Programs/62862/The-University-of-Winnipeg/Criminal-Justice-Studies",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Criminal Justice (Criminology)",
    "programLink": "https://www.studyincanada.com/Programs/62817/Nipissing-University/Criminal-Justice-(Criminology)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Redeemer University",
    "location": "Canada, Ontario",
    "programName": "Criminal Justice",
    "programLink": "https://www.studyincanada.com/Programs/86267/Redeemer-University/Criminal-Justice",
    "entranceGrade": "N/S",
    "tutionValue": "$19,494",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Medicine Hat College",
    "location": "Canada, Alberta",
    "programName": "Criminal Justice",
    "programLink": "https://www.studyincanada.com/Programs/6136/Medicine-Hat-College/Criminal-Justice",
    "entranceGrade": "60",
    "tutionValue": "$14,481",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Nipissing University",
    "location": "Canada, Ontario",
    "programName": "Criminal Justice (Criminal Justice Studies)",
    "programLink": "https://www.studyincanada.com/Programs/90352/Nipissing-University/Criminal-Justice-(Criminal-Justice-Studies)",
    "entranceGrade": "70 - 70",
    "tutionValue": "$23,000",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Humber College Institute of Technology & Advanced Learning",
    "location": "Canada, Ontario",
    "programName": "Bachelor of Social Science - Criminal Justice",
    "programLink": "https://www.studyincanada.com/Programs/56758/Humber-College-Institute-of-Technology-&-Advanced-Learning/Bachelor-of-Social-Science-Criminal-Justice",
    "entranceGrade": "65",
    "tutionValue": "$19,387",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Lethbridge College",
    "location": "Canada, Alberta",
    "programName": "Criminal Justice – Policing",
    "programLink": "https://www.studyincanada.com/Programs/24982/Lethbridge-College/Criminal-Justice-%e2%80%93-Policing",
    "entranceGrade": "N/S",
    "tutionValue": "$15,649",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "Athabasca University",
    "location": "Canada, Alberta",
    "programName": "Criminal Justice",
    "programLink": "https://www.studyincanada.com/Programs/9029/Athabasca-University/Criminal-Justice",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Criminal Justice Studies (Four-Year)",
    "programLink": "https://www.studyincanada.com/Programs/85143/The-University-of-Winnipeg/Criminal-Justice-Studies-(Four-Year)",
    "entranceGrade": "65",
    "tutionValue": "$15,810",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "The University of Winnipeg",
    "location": "Canada, Manitoba",
    "programName": "Pre-Law",
    "programLink": "https://www.studyincanada.com/Programs/68606/The-University-of-Winnipeg/Pre-Law",
    "entranceGrade": "N/S",
    "tutionValue": "$15,810",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Diploma in Justice Studies",
    "programLink": "https://www.studyincanada.com/Programs/90941/University-of-Regina/Diploma-in-Justice-Studies",
    "entranceGrade": "65 - 100",
    "tutionValue": "$11,397",
    "length": "2 Year(s)"
  },
  {
    "schoolName": "McMaster University",
    "location": "Canada, Ontario",
    "programName": "Justice, Political Philosophy & Law",
    "programLink": "https://www.studyincanada.com/Programs/79425/McMaster-University/Justice,-Political-Philosophy-&-Law",
    "entranceGrade": "77 - 79",
    "tutionValue": "$40,960",
    "length": "4 Year(s)"
  },
  {
    "schoolName": "Redeemer University",
    "location": "Canada, Ontario",
    "programName": "Pre-Law",
    "programLink": "https://www.studyincanada.com/Programs/116328/Redeemer-University/Pre-Law",
    "entranceGrade": "N/S",
    "tutionValue": "$19,494",
    "length": "1 Year(s)"
  },
  {
    "schoolName": "Lakehead University - Thunder Bay",
    "location": "Canada, Ontario",
    "programName": "Law",
    "programLink": "https://www.studyincanada.com/Programs/78100/Lakehead-University-Thunder-Bay/Law",
    "entranceGrade": "N/S",
    "tutionValue": "N/S",
    "length": "3 Year(s)"
  },
  {
    "schoolName": "University of Regina",
    "location": "Canada, Saskatchewan",
    "programName": "Pre-Law",
    "programLink": "https://www.studyincanada.com/Programs/71833/University-of-Regina/Pre-Law",
    "entranceGrade": "65 - 70",
    "tutionValue": "$12,049",
    "length": "N/S"
  }
]